/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('OptionCategoryFactory', function($resource) {
	return $resource(
		'api/optionCategories/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigOptionCategoryViewDto
 */
angular.module('mmmApp').factory('CarConfigOptionCategoryViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/optioncategory/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigOptionCategoryListDto's
 */
angular.module('mmmApp').factory('CarConfigOptionCategoryListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/optioncategory',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigOptionCategoryEditDto
 */
angular.module('mmmApp').factory('CarConfigOptionCategoryEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/optioncategory/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigOptionCategoryEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigOptionCategoryNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/optioncategory/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiOptionCategoryEditDto
 */
angular.module('mmmApp').factory('ArticleApiOptionCategoryEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/optioncategory/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiOptionCategoryEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiOptionCategoryNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/optioncategory/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewOptionCategoryFactory', function($resource) {
	return $resource(
		'api/optionCategories/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all OptionCategories without the use of paging
angular.module('mmmApp').factory('NoPagingOptionCategoryFactory', function($resource) {
	return $resource(
		'api/optionCategories/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible OptionCategoryTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingOptionCategoryTypeFactory', function($resource) {
	return $resource(
		'api/optionCategory/optionCategoryTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/optionCategory/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarOptionsByOptionCategoryFactory', function($resource) {
	return $resource(
		'api/carOptions/optionCategory/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllOptionCategoriesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/optionCategories/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
