/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtConsignmentAgreementListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ConsignmentAgreementFactory,
		StockMgmtConsignmentAgreementListDtoSearchFactory,
		StockMgmtConsignmentAgreementViewDtoFactory
	) {
		$scope.consignmentAgreementList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeConsignmentAgreement)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeConsignmentAgreement);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeConsignmentAgreement = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-consignmentagreement.new');
		};

		// handle attribute contractDate of javaType java.time.LocalDate
		$scope.processContractDate = function() {
			var contractDateFromDefined = $rootScope.isDefinedAndNotNull($scope.ConsignmentAgreementSearchOverview.contractDateFrom);
			var contractDateToDefined = $rootScope.isDefinedAndNotNull($scope.ConsignmentAgreementSearchOverview.contractDateTo);

			if (contractDateFromDefined) {
				$scope.ConsignmentAgreementSearchOverview.contractDateFromString = $rootScope.convertDateWithoutZone(
					$scope.ConsignmentAgreementSearchOverview.contractDateFrom
				);
			} else {
				$scope.ConsignmentAgreementSearchOverview.contractDateFromString = undefined;
			}

			if (contractDateToDefined) {
				$scope.ConsignmentAgreementSearchOverview.contractDateToString = $rootScope.convertDateWithoutZone(
					$scope.ConsignmentAgreementSearchOverview.contractDateTo
				);
			} else {
				$scope.ConsignmentAgreementSearchOverview.contractDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processContractDate();

			$rootScope.ConsignmentAgreementSearchOverview = angular.copy($scope.ConsignmentAgreementSearchOverview);

			$rootScope.pageSizeConsignmentAgreement = angular.copy($scope.pageSize);

			StockMgmtConsignmentAgreementListDtoSearchFactory.query(
				{
					page: $scope.ConsignmentAgreementSearchOverview.page - 1,
					size: $scope.pageSize,
					contractDateFrom: $scope.ConsignmentAgreementSearchOverview.contractDateFromString,
					contractDateTo: $scope.ConsignmentAgreementSearchOverview.contractDateToString,
					ownerIdList: $scope.ConsignmentAgreementSearchOverview.ownerIdList,
					sellerIdList: $scope.ConsignmentAgreementSearchOverview.sellerIdList,

					sort: [
						$scope.ConsignmentAgreementSearchOverview.predicate +
							',' +
							($scope.ConsignmentAgreementSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.consignmentAgreementList = result.items;

					$scope.ownerReferenceFilterList = result.ownerSearchOptions;

					$scope.sellerReferenceFilterList = result.sellerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isConsignmentAgreementSearchOverviewDirty = function() {
			return !angular.equals($scope.ConsignmentAgreementSearchOverview, $scope.originalConsignmentAgreementSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ConsignmentAgreementSearchOverview = {};
			$rootScope.ConsignmentAgreementSearchOverview.page = 1;
			$rootScope.ConsignmentAgreementSearchOverview.predicate = 'id';
			$rootScope.ConsignmentAgreementSearchOverview.ascending = false;

			$scope.ConsignmentAgreementSearchOverview = angular.copy($rootScope.ConsignmentAgreementSearchOverview);

			$scope.originalConsignmentAgreementSearchOverview = angular.copy($scope.ConsignmentAgreementSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ConsignmentAgreementSearchOverview) || $rootScope.ConsignmentAgreementSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ConsignmentAgreementSearchOverview = angular.copy($rootScope.ConsignmentAgreementSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedConsignmentAgreementId = id;
			$('#deleteConsignmentAgreementConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtConsignmentAgreementViewDtoFactory.delete(
				{ id: $scope.selectedConsignmentAgreementId },
				function() {
					$scope.search();
					$('#deleteConsignmentAgreementConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteConsignmentAgreementConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ConsignmentAgreementSearchOverview.predicate === itemName) {
				if ($scope.ConsignmentAgreementSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateOwnerUISelect = function(item, model) {
			$scope.search();
		};
	});
