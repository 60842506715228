// CodeComposer:ignore : Stopped generating because of AMI-11600: Adding 2 indirect columns to angularjs list
/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCrmCarOptionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarOptionFactory,
		StockMgmtCrmCarOptionListDtoSearchFactory,
		StockMgmtCrmCarOptionViewDtoFactory,
		AutocompleteStockMgmtCarsByQueryParamsFactory,
		AutocompleteStockMgmtCarOptionsByQueryParamsFactory
	) {
		$scope.crmCarOptionList = [];
		$scope.showSearchForm = true;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCarOption)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCarOption);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCarOption = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-crmcaroption.new');
		};

		var getCrmCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarOptionSearchOverview.crmCar)) {
				return $scope.CrmCarOptionSearchOverview.crmCar.id;
			} else {
				return undefined;
			}
		};

		var getCarOptionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarOptionSearchOverview.carOption)) {
				return $scope.CrmCarOptionSearchOverview.carOption.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CrmCarOptionSearchOverview = angular.copy($scope.CrmCarOptionSearchOverview);

			$rootScope.pageSizeCrmCarOption = angular.copy($scope.pageSize);

			StockMgmtCrmCarOptionListDtoSearchFactory.query(
				{
					page: $scope.CrmCarOptionSearchOverview.page - 1,
					size: $scope.pageSize,
					quantityFrom: $scope.CrmCarOptionSearchOverview.quantityFrom,
					quantityTo: $scope.CrmCarOptionSearchOverview.quantityFrom,
					crmCarId: getCrmCarId(),
					carOptionId: getCarOptionId(),
					crmCarOptionOriginList: $scope.CrmCarOptionSearchOverview.crmCarOptionOriginList,
                    carOptionFactoryCode: $scope.CrmCarOptionSearchOverview.carOptionFactoryCode,
                    carOptionOptionOriginList: $scope.CrmCarOptionSearchOverview.carOptionOptionOriginList,

					sort: [$scope.CrmCarOptionSearchOverview.predicate + ',' + ($scope.CrmCarOptionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.crmCarOptionList = result.items;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteStockMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.autocompleteCarOptions = function(val) {
						var params = {
							carOptionTypeAheadSearchString: val,
						};

						return AutocompleteStockMgmtCarOptionsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.crmCarOptionOriginList = result.crmCarOptionOriginSearchOptions;
					$scope.carOptionOptionOriginList = result.carOptionOptionOriginSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCrmCarOptionSearchOverviewDirty = function() {
			return !angular.equals($scope.CrmCarOptionSearchOverview, $scope.originalCrmCarOptionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CrmCarOptionSearchOverview = {};
			$rootScope.CrmCarOptionSearchOverview.page = 1;
			$rootScope.CrmCarOptionSearchOverview.predicate = 'id';
			$rootScope.CrmCarOptionSearchOverview.ascending = false;

			$scope.CrmCarOptionSearchOverview = angular.copy($rootScope.CrmCarOptionSearchOverview);

			$scope.originalCrmCarOptionSearchOverview = angular.copy($scope.CrmCarOptionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CrmCarOptionSearchOverview) || $rootScope.CrmCarOptionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CrmCarOptionSearchOverview = angular.copy($rootScope.CrmCarOptionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarOptionId = id;
			$('#deleteCrmCarOptionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtCrmCarOptionViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarOptionId },
				function() {
					$scope.search();
					$('#deleteCrmCarOptionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarOptionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CrmCarOptionSearchOverview.predicate === itemName) {
				if ($scope.CrmCarOptionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCrmCarOptionOriginUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarOptionOptionOriginUISelect = function(item, model) {
			$scope.search();
		};

	});
