/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCrmCarMediaDocumentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarMediaDocumentFactory,
		RelationMgmtCrmCarMediaDocumentListDtoSearchFactory,
		RelationMgmtCrmCarMediaDocumentViewDtoFactory,
		AutocompleteRelationMgmtCarsByQueryParamsFactory
	) {
		$scope.crmCarMediaDocumentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCarMediaDocument)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCarMediaDocument);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCarMediaDocument = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-crmcarmediadocument.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarMediaDocumentSearchOverview.tenant)) {
				return $scope.CrmCarMediaDocumentSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getCrmCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarMediaDocumentSearchOverview.crmCar)) {
				return $scope.CrmCarMediaDocumentSearchOverview.crmCar.id;
			} else {
				return undefined;
			}
		};

		var getDocumentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarMediaDocumentSearchOverview.document)) {
				return $scope.CrmCarMediaDocumentSearchOverview.document.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CrmCarMediaDocumentSearchOverview = angular.copy($scope.CrmCarMediaDocumentSearchOverview);

			$rootScope.pageSizeCrmCarMediaDocument = angular.copy($scope.pageSize);

			RelationMgmtCrmCarMediaDocumentListDtoSearchFactory.query(
				{
					page: $scope.CrmCarMediaDocumentSearchOverview.page - 1,
					size: $scope.pageSize,
					tenantId: getTenantId(),
					crmCarId: getCrmCarId(),
					documentId: getDocumentId(),

					sort: [
						$scope.CrmCarMediaDocumentSearchOverview.predicate +
							',' +
							($scope.CrmCarMediaDocumentSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.crmCarMediaDocumentList = result.items;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.documentReferenceFilterList = result.documentSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCrmCarMediaDocumentSearchOverviewDirty = function() {
			return !angular.equals($scope.CrmCarMediaDocumentSearchOverview, $scope.originalCrmCarMediaDocumentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CrmCarMediaDocumentSearchOverview = {};
			$rootScope.CrmCarMediaDocumentSearchOverview.page = 1;
			$rootScope.CrmCarMediaDocumentSearchOverview.predicate = 'id';
			$rootScope.CrmCarMediaDocumentSearchOverview.ascending = false;

			$scope.CrmCarMediaDocumentSearchOverview = angular.copy($rootScope.CrmCarMediaDocumentSearchOverview);

			$scope.originalCrmCarMediaDocumentSearchOverview = angular.copy($scope.CrmCarMediaDocumentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CrmCarMediaDocumentSearchOverview) || $rootScope.CrmCarMediaDocumentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CrmCarMediaDocumentSearchOverview = angular.copy($rootScope.CrmCarMediaDocumentSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarMediaDocumentId = id;
			$('#deleteCrmCarMediaDocumentConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtCrmCarMediaDocumentViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarMediaDocumentId },
				function() {
					$scope.search();
					$('#deleteCrmCarMediaDocumentConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarMediaDocumentConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CrmCarMediaDocumentSearchOverview.predicate === itemName) {
				if ($scope.CrmCarMediaDocumentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
