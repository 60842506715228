/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtExternalCarDataListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ExternalCarDataFactory,
		StockMgmtExternalCarDataListDtoSearchFactory
	) {
		$scope.externalCarDataList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeExternalCarData)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeExternalCarData);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeExternalCarData = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-externalcardata.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getExternalCarDataList = function() {
			StockMgmtExternalCarDataListDtoSearchFactory.query(
				{
					sourceList: angular.isDefined($scope.sourceId) ? $scope.sourceId : null,
					messageTypeList: angular.isDefined($scope.messageTypeId) ? $scope.messageTypeId : null,
					crmCarId: angular.isDefined($scope.crmCarId) ? $scope.crmCarId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.externalCarDataList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getExternalCarDataList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateMessageTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
