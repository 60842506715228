/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingBodyTypeListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingBodyTypeFactory,
		StockMgmtMappingBodyTypeListDtoSearchFactory,
		StockMgmtMappingBodyTypeViewDtoFactory
	) {
		$scope.mappingBodyTypeList = [];
		$scope.selectedMappingBodyTypeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingBodyType)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingBodyType);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingBodyType = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-mappingbodytype.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getMappingBodyTypeList = function() {
			StockMgmtMappingBodyTypeListDtoSearchFactory.query(
				{
					sourceList: angular.isDefined($scope.sourceId) ? $scope.sourceId : null,
					bodyTypeList: angular.isDefined($scope.bodyTypeId) ? $scope.bodyTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingBodyTypeList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getMappingBodyTypeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingBodyTypeId = id;
			$('#deleteMappingBodyTypeConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingBodyTypeViewDtoFactory.delete(
				{ id: $scope.selectedMappingBodyTypeId },
				function() {
					$scope.loadAll();
					$('#deleteMappingBodyTypeConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteMappingBodyTypeConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingBodyTypeConfirmationInTab').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingBodyTypeList, function(value, key) {
				promises.push(StockMgmtMappingBodyTypeViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.loadAll();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingBodyTypeConfirmationInTab').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingBodyTypeList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingBodyTypeList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingBodyTypeList = [];

			angular.forEach($scope.mappingBodyTypeList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingBodyTypeList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingBodyTypeUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBodyTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
