/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CrmCarDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarDocuments/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCrmCarDocumentViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarDocumentViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcardocument/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCrmCarDocumentListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcardocument',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCrmCarDocumentEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarDocumentEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcardocument/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCrmCarDocumentEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarDocumentNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcardocument/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of StockMgmtCrmCarDocumentListDto's
 */
angular.module('mmmApp').factory('StockMgmtCrmCarDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcardocument',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCrmCarDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarDocuments/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CrmCarDocuments without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarDocuments/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCrmCarDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/crmCarDocuments/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
