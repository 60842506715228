/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarBodytypeFactory', function($resource) {
	return $resource(
		'api/carBodytypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarBodytypeViewDto
 */
angular.module('mmmApp').factory('CarConfigCarBodytypeViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbodytype/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarBodytypeListDto's
 */
angular.module('mmmApp').factory('CarConfigCarBodytypeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carbodytype',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarBodytypeEditDto
 */
angular.module('mmmApp').factory('CarConfigCarBodytypeEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbodytype/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarBodytypeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarBodytypeNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbodytype/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarBodytypeEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarBodytypeEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carbodytype/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarBodytypeEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarBodytypeEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carbodytype/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarBodytypeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarBodytypeNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carbodytype/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarBodytypeEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarBodytypeEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carbodytype/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarBodytypeFactory', function($resource) {
	return $resource(
		'api/carBodytypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarBodytypes without the use of paging
angular.module('mmmApp').factory('NoPagingCarBodytypeFactory', function($resource) {
	return $resource(
		'api/carBodytypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carBodytype/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carBodytype/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carBodytype/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carBodytype/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carBodytype/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carBodytype/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carBodytype/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BodyTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingBodyTypeFactory', function($resource) {
	return $resource(
		'api/carBodytype/bodyTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationsByCarBodytypeFactory', function($resource) {
	return $resource(
		'api/carConfigurations/carBodytype/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarBodytypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carBodytypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarBodytypeSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carbodytypes/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
