/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('DealerLocationFactory', function($resource) {
	return $resource(
		'api/locations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtDealerLocationViewDto
 */
angular.module('mmmApp').factory('StockMgmtDealerLocationViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerlocation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtDealerLocationListDto's
 */
angular.module('mmmApp').factory('StockMgmtDealerLocationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerlocation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtDealerLocationEditDto
 */
angular.module('mmmApp').factory('StockMgmtDealerLocationEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerlocation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDealerLocationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtDealerLocationNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerlocation/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewDealerLocationFactory', function($resource) {
	return $resource(
		'api/locations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Locations without the use of paging
angular.module('mmmApp').factory('NoPagingDealerLocationFactory', function($resource) {
	return $resource(
		'api/locations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllLocationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/locations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
