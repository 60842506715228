/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtEmployeeListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EmployeeFactory,
		RelationMgmtEmployeeListDtoSearchFactory,
		RelationMgmtEmployeeViewDtoFactory
	) {
		$scope.employeeList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEmployee)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEmployee);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEmployee = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-employee.new');
		};

		var getPrimaryPhoneId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.primaryPhone)) {
				return $scope.EmployeeSearchOverview.primaryPhone.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryAddressId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.primaryAddress)) {
				return $scope.EmployeeSearchOverview.primaryAddress.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryEmailId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.primaryEmail)) {
				return $scope.EmployeeSearchOverview.primaryEmail.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.tenant)) {
				return $scope.EmployeeSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getUserId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.user)) {
				return $scope.EmployeeSearchOverview.user.id;
			} else {
				return undefined;
			}
		};

		// handle attribute optIn of javaType boolean
		// handle attribute optInDate of javaType java.time.LocalDate
		$scope.processOptInDate = function() {
			var optInDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.optInDateFrom);
			var optInDateToDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.optInDateTo);

			if (optInDateFromDefined) {
				$scope.EmployeeSearchOverview.optInDateFromString = $rootScope.convertDateWithoutZone($scope.EmployeeSearchOverview.optInDateFrom);
			} else {
				$scope.EmployeeSearchOverview.optInDateFromString = undefined;
			}

			if (optInDateToDefined) {
				$scope.EmployeeSearchOverview.optInDateToString = $rootScope.convertDateWithoutZone($scope.EmployeeSearchOverview.optInDateTo);
			} else {
				$scope.EmployeeSearchOverview.optInDateToString = undefined;
			}
		};

		// handle attribute optOut of javaType boolean
		// handle attribute optOutDate of javaType java.time.LocalDate
		$scope.processOptOutDate = function() {
			var optOutDateFromDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.optOutDateFrom);
			var optOutDateToDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.optOutDateTo);

			if (optOutDateFromDefined) {
				$scope.EmployeeSearchOverview.optOutDateFromString = $rootScope.convertDateWithoutZone(
					$scope.EmployeeSearchOverview.optOutDateFrom
				);
			} else {
				$scope.EmployeeSearchOverview.optOutDateFromString = undefined;
			}

			if (optOutDateToDefined) {
				$scope.EmployeeSearchOverview.optOutDateToString = $rootScope.convertDateWithoutZone($scope.EmployeeSearchOverview.optOutDateTo);
			} else {
				$scope.EmployeeSearchOverview.optOutDateToString = undefined;
			}
		};

		// handle attribute firstNameTerms of javaType boolean
		// handle attribute dateOfBirth of javaType java.time.LocalDate
		$scope.processDateOfBirth = function() {
			var dateOfBirthFromDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.dateOfBirthFrom);
			var dateOfBirthToDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.dateOfBirthTo);

			if (dateOfBirthFromDefined) {
				$scope.EmployeeSearchOverview.dateOfBirthFromString = $rootScope.convertDateWithoutZone(
					$scope.EmployeeSearchOverview.dateOfBirthFrom
				);
			} else {
				$scope.EmployeeSearchOverview.dateOfBirthFromString = undefined;
			}

			if (dateOfBirthToDefined) {
				$scope.EmployeeSearchOverview.dateOfBirthToString = $rootScope.convertDateWithoutZone($scope.EmployeeSearchOverview.dateOfBirthTo);
			} else {
				$scope.EmployeeSearchOverview.dateOfBirthToString = undefined;
			}
		};

		// handle attribute licenseValidThru of javaType java.time.LocalDate
		$scope.processLicenseValidThru = function() {
			var licenseValidThruFromDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.licenseValidThruFrom);
			var licenseValidThruToDefined = $rootScope.isDefinedAndNotNull($scope.EmployeeSearchOverview.licenseValidThruTo);

			if (licenseValidThruFromDefined) {
				$scope.EmployeeSearchOverview.licenseValidThruFromString = $rootScope.convertDateWithoutZone(
					$scope.EmployeeSearchOverview.licenseValidThruFrom
				);
			} else {
				$scope.EmployeeSearchOverview.licenseValidThruFromString = undefined;
			}

			if (licenseValidThruToDefined) {
				$scope.EmployeeSearchOverview.licenseValidThruToString = $rootScope.convertDateWithoutZone(
					$scope.EmployeeSearchOverview.licenseValidThruTo
				);
			} else {
				$scope.EmployeeSearchOverview.licenseValidThruToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processOptInDate();
			$scope.processOptOutDate();
			$scope.processDateOfBirth();
			$scope.processLicenseValidThru();

			$rootScope.EmployeeSearchOverview = angular.copy($scope.EmployeeSearchOverview);

			$rootScope.pageSizeEmployee = angular.copy($scope.pageSize);

			RelationMgmtEmployeeListDtoSearchFactory.query(
				{
					page: $scope.EmployeeSearchOverview.page - 1,
					size: $scope.pageSize,
					optIn: $scope.EmployeeSearchOverview.optInGroup,
					optInDateFrom: $scope.EmployeeSearchOverview.optInDateFromString,
					optInDateTo: $scope.EmployeeSearchOverview.optInDateToString,
					optOut: $scope.EmployeeSearchOverview.optOutGroup,
					optOutDateFrom: $scope.EmployeeSearchOverview.optOutDateFromString,
					optOutDateTo: $scope.EmployeeSearchOverview.optOutDateToString,
					firstName: $scope.EmployeeSearchOverview.firstName,
					infix: $scope.EmployeeSearchOverview.infix,
					surname: $scope.EmployeeSearchOverview.surname,
					initials: $scope.EmployeeSearchOverview.initials,
					firstNameTerms: $scope.EmployeeSearchOverview.firstNameTermsGroup,
					dateOfBirthFrom: $scope.EmployeeSearchOverview.dateOfBirthFromString,
					dateOfBirthTo: $scope.EmployeeSearchOverview.dateOfBirthToString,
					licenseNumber: $scope.EmployeeSearchOverview.licenseNumber,
					licenseValidThruFrom: $scope.EmployeeSearchOverview.licenseValidThruFromString,
					licenseValidThruTo: $scope.EmployeeSearchOverview.licenseValidThruToString,
					status: $scope.EmployeeSearchOverview.status,
					relationType: $scope.EmployeeSearchOverview.relationType,
					primaryPhoneId: getPrimaryPhoneId(),
					primaryAddressId: getPrimaryAddressId(),
					primaryEmailId: getPrimaryEmailId(),
					tenantId: getTenantId(),
					genderList: $scope.EmployeeSearchOverview.genderList,
					userId: getUserId(),

					sort: [$scope.EmployeeSearchOverview.predicate + ',' + ($scope.EmployeeSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.employeeList = result.items;

					$scope.relationStatusList = result.statusSearchOptions;

					$scope.relationTypeList = result.relationTypeSearchOptions;

					$scope.genderTypeList = result.genderSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isEmployeeSearchOverviewDirty = function() {
			return !angular.equals($scope.EmployeeSearchOverview, $scope.originalEmployeeSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EmployeeSearchOverview = {};
			$rootScope.EmployeeSearchOverview.page = 1;
			$rootScope.EmployeeSearchOverview.predicate = 'id';
			$rootScope.EmployeeSearchOverview.ascending = false;

			$scope.EmployeeSearchOverview = angular.copy($rootScope.EmployeeSearchOverview);

			$scope.originalEmployeeSearchOverview = angular.copy($scope.EmployeeSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EmployeeSearchOverview) || $rootScope.EmployeeSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EmployeeSearchOverview = angular.copy($rootScope.EmployeeSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEmployeeId = id;
			$('#deleteEmployeeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtEmployeeViewDtoFactory.delete(
				{ id: $scope.selectedEmployeeId },
				function() {
					$scope.search();
					$('#deleteEmployeeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteEmployeeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EmployeeSearchOverview.predicate === itemName) {
				if ($scope.EmployeeSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateGenderUISelect = function(item, model) {
			$scope.search();
		};
	});
