/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('NoteFactory', function($resource) {
	return $resource(
		'api/notes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtNoteViewDto
 */
angular.module('mmmApp').factory('RelationMgmtNoteViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/note/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtNoteListDto's
 */
angular.module('mmmApp').factory('RelationMgmtNoteListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/note',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtNoteEditDto
 */
angular.module('mmmApp').factory('RelationMgmtNoteEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/note/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtNoteEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtNoteNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/note/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewNoteFactory', function($resource) {
	return $resource(
		'api/notes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Notes without the use of paging
angular.module('mmmApp').factory('NoPagingNoteFactory', function($resource) {
	return $resource(
		'api/notes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible NoteTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingNoteTypeFactory', function($resource) {
	return $resource(
		'api/note/noteTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllNotesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/notes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
