/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtHexonPortalListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		HexonPortalFactory,
		StockMgmtHexonPortalListDtoSearchFactory,
		StockMgmtHexonPortalViewDtoFactory
	) {
		$scope.hexonPortalList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeHexonPortal)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeHexonPortal);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeHexonPortal = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-hexonportal.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getHexonPortalList = function() {
			StockMgmtHexonPortalListDtoSearchFactory.query(
				{
					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.hexonPortalList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getHexonPortalList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedHexonPortalId = id;
			$('#deleteHexonPortalConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtHexonPortalViewDtoFactory.delete(
				{ id: $scope.selectedHexonPortalId },
				function() {
					$scope.loadAll();
					$('#deleteHexonPortalConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteHexonPortalConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:hexonPortalUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
