/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationdocumentsOverviewMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationdocument/relationdocument-tab.html',
		controller: 'RelationDocumentTabController',
		scope: {
			documentId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationdocument/relationdocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('sectiondocumentsOverviewMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/sectiondocument/sectiondocument-tab.html',
		controller: 'SectionDocumentTabController',
		scope: {
			documentId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtSectionDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/sectiondocument/sectiondocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtSectionDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcardocumentsOverviewMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-tab.html',
		controller: 'CrmCarDocumentTabController',
		scope: {
			documentId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcarmediadocumentsOverviewMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-tab.html',
		controller: 'CrmCarMediaDocumentTabController',
		scope: {
			documentId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarMediaDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarMediaDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarMediaDocumentMappedByDocument', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarMediaDocumentListDtoTabController',
		scope: {
			documentId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
