/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCarMileageListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarMileageFactory,
		StockMgmtCarMileageListDtoSearchFactory
	) {
		$scope.carMileageList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarMileage)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarMileage);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarMileage = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-carmileage.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getCarMileageList = function() {
			StockMgmtCarMileageListDtoSearchFactory.query(
				{
					recordedByList: angular.isDefined($scope.recordedById) ? $scope.recordedById : null,
					carId: angular.isDefined($scope.carId) ? $scope.carId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carMileageList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarMileageList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateRecordedByUISelect = function(item, model) {
			$scope.search();
		};
	});
