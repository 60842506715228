angular.module("mmmApp").service("LocalizedInputService", function() {
    return {
        createInputIdentifier: function(locale, entityName, propertyName) {
            if (!locale || !propertyName || !entityName) {
                throw new Error("Required parameter is not provided");
            }
            return locale + "__" + entityName + "__" + propertyName;
        },
        findLocalizedEntryIndex: function(
            localizedEntries,
            locale,
            entityName,
            propertyName
        ) {
            if (!localizedEntries || !locale || !propertyName || !entityName) {
                throw new Error("Required parameter is not provided");
            }
            let found = -1;
            for (let i = 0; i < localizedEntries.length; i += 1) {
                const localizedEntry = localizedEntries[i];
                if (
                    localizedEntry.locale === locale &&
                    localizedEntry.entityName === entityName &&
                    localizedEntry.propertyName === propertyName
                ) {
                    found = i;
                    break;
                }
            }
            return found;
        },
        createLocalizedEntryInstance: function(
            locale,
            entityName,
            propertyName
        ) {
            if (!locale || !entityName || !propertyName) {
                throw new Error("Required parameter is not provided");
            }
            return {
                locale: locale,
                entityName: entityName,
                propertyName: propertyName,
                translationValue: ""
            };
        },
        getLocalizedFormFieldStatus: function(
            statusName,
            form,
            dto,
            entityName,
            propertyName
        ) {
            const self = this;
            if (statusName !== "error" && statusName !== "success") {
                throw new Error("Request for unknown form field status");
            }
            const fieldsNames = dto.localized.supportedLocales.map(function(
                localeName
            ) {
                return self.createInputIdentifier(
                    localeName,
                    entityName,
                    propertyName
                );
            });

            if (statusName === "error") {
                return fieldsNames.some(function(formFieldName) {
                    // No form element? Consider it without error
                    return form[formFieldName]
                        ? form[formFieldName].$invalid
                        : false;
                });
            } else if (statusName === "success") {
                return fieldsNames.every(function(formFieldName) {
                    // No form element? consider then it's valid
                    return form[formFieldName]
                        ? form[formFieldName].$valid &&
                              form[formFieldName].$dirty
                        : true;
                });
            }
        }
    };
});
