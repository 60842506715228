/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarTransmissionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarTransmissionFactory,
		CarConfigCarTransmissionListDtoSearchFactory,
		CarConfigCarTransmissionViewDtoFactory
	) {
		$scope.carTransmissionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarTransmission)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarTransmission);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarTransmission = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-cartransmission.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarTransmissionSearchOverview.equivalent)) {
				return $scope.CarTransmissionSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarTransmissionSearchOverview = angular.copy($scope.CarTransmissionSearchOverview);

			$rootScope.pageSizeCarTransmission = angular.copy($scope.pageSize);

			CarConfigCarTransmissionListDtoSearchFactory.query(
				{
					page: $scope.CarTransmissionSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarTransmissionSearchOverview.name,
					productCode: $scope.CarTransmissionSearchOverview.productCode,
					factoryCode: $scope.CarTransmissionSearchOverview.factoryCode,
					description: $scope.CarTransmissionSearchOverview.description,
					canBeOrdered: $scope.CarTransmissionSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarTransmissionSearchOverview.spotlightGroup,
					creator: $scope.CarTransmissionSearchOverview.creator,
					updater: $scope.CarTransmissionSearchOverview.updater,
					numberOfGearsFrom: $scope.CarTransmissionSearchOverview.numberOfGearsFrom,
					numberOfGearsTo: $scope.CarTransmissionSearchOverview.numberOfGearsTo,
					statusList: $scope.CarTransmissionSearchOverview.statusList,
					typeList: $scope.CarTransmissionSearchOverview.typeList,
					imageSize: $scope.CarTransmissionSearchOverview.imageSize,
					vatType: $scope.CarTransmissionSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarTransmissionSearchOverview.priceTypeList,
					brandIdList: $scope.CarTransmissionSearchOverview.brandIdList,
					countryList: $scope.CarTransmissionSearchOverview.countryList,
					locale: $scope.CarTransmissionSearchOverview.locale,
					transmissionTypeList: $scope.CarTransmissionSearchOverview.transmissionTypeList,

					sort: [$scope.CarTransmissionSearchOverview.predicate + ',' + ($scope.CarTransmissionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carTransmissionList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.transmissionTypeList = result.transmissionTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarTransmissionSearchOverviewDirty = function() {
			return !angular.equals($scope.CarTransmissionSearchOverview, $scope.originalCarTransmissionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarTransmissionSearchOverview = {};
			$rootScope.CarTransmissionSearchOverview.page = 1;
			$rootScope.CarTransmissionSearchOverview.predicate = 'id';
			$rootScope.CarTransmissionSearchOverview.ascending = false;

			$scope.CarTransmissionSearchOverview = angular.copy($rootScope.CarTransmissionSearchOverview);

			$scope.originalCarTransmissionSearchOverview = angular.copy($scope.CarTransmissionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarTransmissionSearchOverview) || $rootScope.CarTransmissionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarTransmissionSearchOverview = angular.copy($rootScope.CarTransmissionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarTransmissionId = id;
			$('#deleteCarTransmissionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarTransmissionViewDtoFactory.delete(
				{ id: $scope.selectedCarTransmissionId },
				function() {
					$scope.search();
					$('#deleteCarTransmissionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarTransmissionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carTransmissionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarTransmissionSearchOverview.predicate === itemName) {
				if ($scope.CarTransmissionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTransmissionTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
