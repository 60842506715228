/** CodeComposer:ignore @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CountryFactory', function($resource) {
	return $resource(
		'api/countries/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCountryViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCountryViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/country/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCountryListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCountryListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/country',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCountryEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCountryEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/country/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCountryEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCountryNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/country/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCountryFactory', function($resource) {
	return $resource(
		'api/countries/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Countries without the use of paging
angular.module('mmmApp').factory('NoPagingCountryFactory', function($resource) {
	return $resource(
		'api/countries/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCountriesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/countries/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('WorkingCountryFactory', function ($resource) {
    console.debug($resource.code)
    return $resource(
        'api/context/country/setworkingcountry/:code',
        {},
        {
            setWorkingCountry: {
                method: 'GET',
            },
        }
    );
})
