/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtNoteListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		NoteFactory,
		RelationMgmtNoteListDtoSearchFactory,
		RelationMgmtNoteViewDtoFactory
	) {
		$scope.noteList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeNote)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeNote);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeNote = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-note.new');
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.NoteSearchOverview.relation)) {
				return $scope.NoteSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getSectionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.NoteSearchOverview.section)) {
				return $scope.NoteSearchOverview.section.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.NoteSearchOverview.tenant)) {
				return $scope.NoteSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.NoteSearchOverview = angular.copy($scope.NoteSearchOverview);

			$rootScope.pageSizeNote = angular.copy($scope.pageSize);

			RelationMgmtNoteListDtoSearchFactory.query(
				{
					page: $scope.NoteSearchOverview.page - 1,
					size: $scope.pageSize,
					title: $scope.NoteSearchOverview.title,
					content: $scope.NoteSearchOverview.content,
					noteTypeList: $scope.NoteSearchOverview.noteTypeList,
					relationId: getRelationId(),
					sectionId: getSectionId(),
					tenantId: getTenantId(),

					sort: [$scope.NoteSearchOverview.predicate + ',' + ($scope.NoteSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.noteList = result.items;

					$scope.noteTypeList = result.noteTypeSearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.sectionReferenceFilterList = result.sectionSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isNoteSearchOverviewDirty = function() {
			return !angular.equals($scope.NoteSearchOverview, $scope.originalNoteSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.NoteSearchOverview = {};
			$rootScope.NoteSearchOverview.page = 1;
			$rootScope.NoteSearchOverview.predicate = 'createdOn';
			$rootScope.NoteSearchOverview.ascending = false;

			$scope.NoteSearchOverview = angular.copy($rootScope.NoteSearchOverview);

			$scope.originalNoteSearchOverview = angular.copy($scope.NoteSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.NoteSearchOverview) || $rootScope.NoteSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.NoteSearchOverview = angular.copy($rootScope.NoteSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedNoteId = id;
			$('#deleteNoteConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtNoteViewDtoFactory.delete(
				{ id: $scope.selectedNoteId },
				function() {
					$scope.search();
					$('#deleteNoteConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteNoteConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.NoteSearchOverview.predicate === itemName) {
				if ($scope.NoteSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateNoteTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
