/** @format */

'use strict';

// MappingNumberOfDoors has edit layout enabled. Edit layout template url: mappingnumberofdoors-dialog.html
// MappingNumberOfDoors has detail layout enabled. Detail layout template url: mappingnumberofdoors-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'MappingNumberOfDoors'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('mappingnumberofdoors'); // main translation loaded for mappingnumberofdoors
		$translatePartialLoader.addPart('mappingbase'); // main translation loaded for mappingnumberofdoors
		$translatePartialLoader.addPart('mappingsource'); // one to many reference from main entity
		$translatePartialLoader.addPart('numberofdoors'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-mappingnumberofdoors', {
		/* abstract state for DTO StockMgmtMappingNumberOfDoorsListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/mappingnumberofdoors',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'MappingNumberOfDoors',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtMappingNumberOfDoorsListDto */
		/* to access: ui-ref="stock-mgmt-mappingnumberofdoors.list" */
		/* url: http://<hostname>/stockmgmt/mappingnumberofdoors/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/mappingnumberofdoors/mappingnumberofdoors-list-stock-mgmt.html',
				controller: 'StockMgmtMappingNumberOfDoorsListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-mappingnumberofdoors.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtMappingNumberOfDoorsViewDto */
		/* to access: ui-sref="stock-mgmt-mappingnumberofdoors.detail" */
		/* url: http://<hostname>/stockmgmt/mappingnumberofdoors/detail/:mappingNumberOfDoorsId */
		url: '/detail/:mappingNumberOfDoorsId',
		views: {
			'content@': {
				templateUrl: 'app/entities/mappingnumberofdoors/mappingnumberofdoors-view-stock-mgmt.html',
				controller: 'MappingNumberOfDoorsDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtMappingNumberOfDoorsViewDtoFactory',
				function($stateParams, StockMgmtMappingNumberOfDoorsViewDtoFactory) {
					return StockMgmtMappingNumberOfDoorsViewDtoFactory.get({ id: $stateParams.mappingNumberOfDoorsId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-mappingnumberofdoors.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtMappingNumberOfDoorsEditDto */
		/* to access: ui-sref="stock-mgmt-mappingnumberofdoors.edit" */
		/* url: http://<hostname>/stockmgmt/mappingnumberofdoors/edit/:mappingNumberOfDoorsId */
		url: '/edit/:mappingNumberOfDoorsId',
		views: {
			'content@': {
				templateUrl: 'app/entities/mappingnumberofdoors/mappingnumberofdoors-edit-stock-mgmt.html',
				controller: 'StockMgmtMappingNumberOfDoorsEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtMappingNumberOfDoorsEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtMappingNumberOfDoorsEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtMappingNumberOfDoorsEditDtoFactory.get({ id: $stateParams.mappingNumberOfDoorsId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-mappingnumberofdoors.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtMappingNumberOfDoorsEditDto */
		/* to access: ui-sref="stock-mgmt-mappingnumberofdoors.new" */
		/* url: http://<hostname>/stockmgmt/mappingnumberofdoors/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/mappingnumberofdoors/mappingnumberofdoors-edit-stock-mgmt.html',
				controller: 'StockMgmtMappingNumberOfDoorsEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtMappingNumberOfDoorsNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtMappingNumberOfDoorsNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtMappingNumberOfDoorsNewDtoFactory.get({ id: $stateParams.mappingNumberOfDoorsId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-mappingnumberofdoors.new', dtoCreateState);
});
