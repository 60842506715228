/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('ApplicationTimingLogDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.applicationTimingLog = entity;
		$scope.objectName = 'ApplicationTimingLog';
		$scope.applicationTimingLogId = $stateParams.applicationTimingLogId;

		if (angular.isUndefined($rootScope.applicationTimingLogActiveTab)) {
			//set the default here
			$rootScope.applicationTimingLogActiveTab = 0;
		}

		$scope.goToEditState = function(applicationTimingLog) {
			$state.go(
				'applicationtiminglog.detail.edit({applicationTimingLogId:applicationTimingLog.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:applicationTimingLogUpdate', function(event, result) {
			$scope.applicationTimingLog = result;
		});
		/**
		 * Opens the configurator simulator page and sets the form fields with previously used parameters so the user can repeat his action.
		 */
		$scope.openSimulator = function() {
			// then go to simulator state.
			$state.go('car-config-carconfiguratorsimulator.list', {
				callInput: $scope.applicationTimingLog.callInput,
			});
		};
	});
