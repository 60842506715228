/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('PhoneDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.phone = entity;
	$scope.objectName = 'Phone';
	$scope.phoneId = $stateParams.phoneId;

	if (angular.isUndefined($rootScope.phoneActiveTab)) {
		//set the default here
		$rootScope.phoneActiveTab = 0;
	}

	$scope.goToEditState = function(phone) {
		$state.go('phone.detail.edit({phoneId:phone.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:phoneUpdate', function(event, result) {
		$scope.phone = result;
	});
});
