/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('DealerFaqDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.dealerFaq = entity;
	$scope.objectName = 'DealerFaq';
	$scope.dealerFaqId = $stateParams.dealerFaqId;

	if (angular.isUndefined($rootScope.dealerFaqActiveTab)) {
		//set the default here
		$rootScope.dealerFaqActiveTab = 0;
	}

	$scope.goToEditState = function(dealerFaq) {
		$state.go('dealerfaq.detail.edit({dealerFaqId:dealerFaq.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:dealerFaqUpdate', function(event, result) {
		$scope.dealerFaq = result;
	});
});
