/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('ColorDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.color = entity;
	$scope.objectName = 'Color';
	$scope.colorId = $stateParams.colorId;

	if (angular.isUndefined($rootScope.colorActiveTab)) {
		//set the default here
		$rootScope.colorActiveTab = 0;
	}

	$scope.goToEditState = function(color) {
		$state.go('color.detail.edit({colorId:color.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:colorUpdate', function(event, result) {
		$scope.color = result;
	});
});
