/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('ConsignmentAgreementDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.consignmentAgreement = entity;
		$scope.objectName = 'ConsignmentAgreement';
		$scope.consignmentAgreementId = $stateParams.consignmentAgreementId;

		if (angular.isUndefined($rootScope.consignmentAgreementActiveTab)) {
			//set the default here
			$rootScope.consignmentAgreementActiveTab = 0;
		}

		$scope.goToEditState = function(consignmentAgreement) {
			$state.go(
				'consignmentagreement.detail.edit({consignmentAgreementId:consignmentAgreement.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:consignmentAgreementUpdate', function(event, result) {
			$scope.consignmentAgreement = result;
		});
	});
