/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('AddressDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.address = entity;
	$scope.objectName = 'Address';
	$scope.addressId = $stateParams.addressId;

	if (angular.isUndefined($rootScope.addressActiveTab)) {
		//set the default here
		$rootScope.addressActiveTab = 0;
	}

	$scope.goToEditState = function(address) {
		$state.go('address.detail.edit({addressId:address.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:addressUpdate', function(event, result) {
		$scope.address = result;
	});
});
