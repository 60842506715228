/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ExternalCarDataFieldFactory', function($resource) {
	return $resource(
		'api/externalCarDataFields/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtExternalCarDataFieldViewDto
 */
angular.module('mmmApp').factory('StockMgmtExternalCarDataFieldViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/externalcardatafield/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtExternalCarDataFieldListDto's
 */
angular.module('mmmApp').factory('StockMgmtExternalCarDataFieldListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/externalcardatafield',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewExternalCarDataFieldFactory', function($resource) {
	return $resource(
		'api/externalCarDataFields/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ExternalCarDataFields without the use of paging
angular.module('mmmApp').factory('NoPagingExternalCarDataFieldFactory', function($resource) {
	return $resource(
		'api/externalCarDataFields/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllExternalCarDataFieldsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/externalCarDataFields/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
