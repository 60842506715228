'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('QuoteFactory', function($resource) {
	return $resource('api/car/config/quote/pdf', {}, {
		'pdf' : {
			method : 'POST',
			responseType : 'arraybuffer',
			transformResponse : function(data) {
				// Stores the ArrayBuffer object in a property called "data"
				return {
					data : data
				};
			}
		}
	});
});
