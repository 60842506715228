/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarConfigurationFactory', function($resource) {
	return $resource(
		'api/carConfigurations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to search / retrieve an array of ArticleManagementCarConfigurationListDto's
 */
angular.module('mmmApp').factory('ArticleManagementCarConfigurationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/articlemanagement/carconfiguration',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarConfigurationViewDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfiguration/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarConfigurationListDto's
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carconfiguration',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarConfigurationEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfiguration/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfiguration/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarConfigurationEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carconfiguration/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiCarConfigurationListDto's
 */
angular.module('mmmApp').factory('WebsiteApiCarConfigurationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/carconfiguration',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarConfigurationEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfiguration/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarConfigurationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfiguration/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarConfigurationEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfiguration/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarConfigurationFactory', function($resource) {
	return $resource(
		'api/carConfigurations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarConfigurations without the use of paging
angular.module('mmmApp').factory('NoPagingCarConfigurationFactory', function($resource) {
	return $resource(
		'api/carConfigurations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carConfiguration/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carConfiguration/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carConfiguration/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carConfiguration/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carConfiguration/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carConfiguration/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carConfiguration/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VehicleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVehicleTypeFactory', function($resource) {
	return $resource(
		'api/carConfiguration/vehicleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible EnergyLabels Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/carConfiguration/energyLabels',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible NumberOfDoors Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/carConfiguration/numberOfDoors',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible NumberOfSeats Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingNumberOfSeatsFactory', function($resource) {
	return $resource(
		'api/carConfiguration/numberOfSeats',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible EmissionClasses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingEmissionClassFactory', function($resource) {
	return $resource(
		'api/carConfiguration/emissionClasses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationPricesByCarConfigurationFactory', function($resource) {
	return $resource(
		'api/carConfigurationPrices/carConfiguration/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarStockDiscountsByCarConfigurationFactory', function($resource) {
	return $resource(
		'api/carStockDiscounts/carConfiguration/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarConfigurationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carConfigurations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarConfigurationSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carconfigurations/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
