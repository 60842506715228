/** @format */

'use strict';

// SubTask has edit layout enabled. Edit layout template url: subtask-dialog.html
// SubTask has detail layout enabled. Detail layout template url: subtask-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'SubTask'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('subtask'); // main translation loaded for subtask
		$translatePartialLoader.addPart('basetask'); // main translation loaded for subtask
		$translatePartialLoader.addPart('tenantawarebasemodel'); // main translation loaded for subtask
		$translatePartialLoader.addPart('basemodel'); // main translation loaded for subtask
		$translatePartialLoader.addPart('taskstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('group'); // one to many reference from main entity
		$translatePartialLoader.addPart('person'); // main translation loaded for parent object Person of reference assignedEmployee
		$translatePartialLoader.addPart('relation'); // main translation loaded for parent object Relation of reference assignedEmployee
		$translatePartialLoader.addPart('employee'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('basetask'); // main translation loaded for parent object BaseTask of reference task
		$translatePartialLoader.addPart('tenantawarebasemodel'); // main translation loaded for parent object TenantAwareBaseModel of reference task
		$translatePartialLoader.addPart('basemodel'); // main translation loaded for parent object BaseModel of reference task
		$translatePartialLoader.addPart('task'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-subtask', {
		/* abstract state for DTO RelationMgmtSubTaskListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/subtask',
		data: {
			authorities: ['ROLE_TASK_ADMIN', 'ROLE_ADMIN'],
			pageTitle: 'SubTask',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtSubTaskListDto */
		/* to access: ui-ref="relation-mgmt-subtask.list" */
		/* url: http://<hostname>/relationmgmt/subtask/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/subtask/subtask-list-relation-mgmt.html',
				controller: 'RelationMgmtSubTaskListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-subtask.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtSubTaskViewDto */
		/* to access: ui-sref="relation-mgmt-subtask.detail" */
		/* url: http://<hostname>/relationmgmt/subtask/detail/:subTaskId */
		url: '/detail/:subTaskId',
		views: {
			'content@': {
				templateUrl: 'app/entities/subtask/subtask-view-relation-mgmt.html',
				controller: 'SubTaskDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtSubTaskViewDtoFactory',
				function($stateParams, RelationMgmtSubTaskViewDtoFactory) {
					return RelationMgmtSubTaskViewDtoFactory.get({ id: $stateParams.subTaskId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-subtask.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtSubTaskEditDto */
		/* to access: ui-sref="relation-mgmt-subtask.edit" */
		/* url: http://<hostname>/relationmgmt/subtask/edit/:subTaskId */
		url: '/edit/:subTaskId',
		views: {
			'content@': {
				templateUrl: 'app/entities/subtask/subtask-edit-relation-mgmt.html',
				controller: 'RelationMgmtSubTaskEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtSubTaskEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtSubTaskEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtSubTaskEditDtoFactory.get({ id: $stateParams.subTaskId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-subtask.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtSubTaskEditDto */
		/* to access: ui-sref="relation-mgmt-subtask.new" */
		/* url: http://<hostname>/relationmgmt/subtask/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/subtask/subtask-edit-relation-mgmt.html',
				controller: 'RelationMgmtSubTaskEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtSubTaskNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtSubTaskNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtSubTaskNewDtoFactory.get({ id: $stateParams.subTaskId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-subtask.new', dtoCreateState);
});
