/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarGradeDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carGrade = entity;
	$scope.objectName = 'CarGrade';
	$scope.carGradeId = $stateParams.carGradeId;

	if (angular.isUndefined($rootScope.carGradeActiveTab)) {
		//set the default here
		$rootScope.carGradeActiveTab = 0;
	}

	$scope.goToEditState = function(carGrade) {
		$state.go('cargrade.detail.edit({carGradeId:carGrade.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carGradeUpdate', function(event, result) {
		$scope.carGrade = result;
	});
});
