/** @format */

'use strict';

// Relation has edit layout enabled. Edit layout template url: relation-dialog.html
// Relation has detail layout enabled. Detail layout template url: relation-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Relation'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('relation'); // main translation loaded for relation
		$translatePartialLoader.addPart('relationstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('phone'); // one to many reference from main entity
		$translatePartialLoader.addPart('address'); // one to many reference from main entity
		$translatePartialLoader.addPart('email'); // one to many reference from main entity
		$translatePartialLoader.addPart('note'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationrole'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationdocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationsalesorderrole'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationcarrole'); // one to many reference from main entity
		$translatePartialLoader.addPart('section'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-relation', {
		/* abstract state for DTO RelationMgmtRelationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/relation',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Relation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtRelationListDto */
		/* to access: ui-ref="relation-mgmt-relation.list" */
		/* url: http://<hostname>/relationmgmt/relation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-list-relation-mgmt.html',
				controller: 'RelationMgmtRelationListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-relation.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtRelationViewDto */
		/* to access: ui-sref="relation-mgmt-relation.detail" */
		/* url: http://<hostname>/relationmgmt/relation/detail/:relationId */
		url: '/detail/:relationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-view-relation-mgmt.html',
				controller: 'RelationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtRelationViewDtoFactory',
				function($stateParams, RelationMgmtRelationViewDtoFactory) {
					return RelationMgmtRelationViewDtoFactory.get({ id: $stateParams.relationId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relation.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtRelationEditDto */
		/* to access: ui-sref="relation-mgmt-relation.edit" */
		/* url: http://<hostname>/relationmgmt/relation/edit/:relationId */
		url: '/edit/:relationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relation/relation-edit-relation-mgmt.html',
				controller: 'RelationMgmtRelationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtRelationEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtRelationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtRelationEditDtoFactory.get({ id: $stateParams.relationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relation.edit', dtoEditState);

	$stateProvider.state('owner-relation', {
		/* abstract state for DTO OwnerRelationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/owner/relation',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Relation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});
});
