/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtBaseTaskTemplateListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BaseTaskTemplateFactory,
		RelationMgmtBaseTaskTemplateListDtoSearchFactory
	) {
		$scope.baseTaskTemplateList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBaseTaskTemplate)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBaseTaskTemplate);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeBaseTaskTemplate = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-basetasktemplate.new');
		};

		var getAssignedEmployeeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BaseTaskTemplateSearchOverview.assignedEmployee)) {
				return $scope.BaseTaskTemplateSearchOverview.assignedEmployee.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BaseTaskTemplateSearchOverview.tenant)) {
				return $scope.BaseTaskTemplateSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.BaseTaskTemplateSearchOverview = angular.copy($scope.BaseTaskTemplateSearchOverview);

			$rootScope.pageSizeBaseTaskTemplate = angular.copy($scope.pageSize);

			RelationMgmtBaseTaskTemplateListDtoSearchFactory.query(
				{
					page: $scope.BaseTaskTemplateSearchOverview.page - 1,
					size: $scope.pageSize,
					title: $scope.BaseTaskTemplateSearchOverview.title,
					description: $scope.BaseTaskTemplateSearchOverview.description,
					assignedGroupIdList: $scope.BaseTaskTemplateSearchOverview.assignedGroupIdList,
					assignedEmployeeId: getAssignedEmployeeId(),
					tenantId: getTenantId(),

					sort: [
						$scope.BaseTaskTemplateSearchOverview.predicate + ',' + ($scope.BaseTaskTemplateSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.baseTaskTemplateList = result.items;

					$scope.assignedGroupReferenceFilterList = result.assignedGroupSearchOptions;

					$scope.autocompleteEmployees = function(val) {
						var params = {
							employeeTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBaseTaskTemplateSearchOverviewDirty = function() {
			return !angular.equals($scope.BaseTaskTemplateSearchOverview, $scope.originalBaseTaskTemplateSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BaseTaskTemplateSearchOverview = {};
			$rootScope.BaseTaskTemplateSearchOverview.page = 1;
			$rootScope.BaseTaskTemplateSearchOverview.predicate = 'id';
			$rootScope.BaseTaskTemplateSearchOverview.ascending = false;

			$scope.BaseTaskTemplateSearchOverview = angular.copy($rootScope.BaseTaskTemplateSearchOverview);

			$scope.originalBaseTaskTemplateSearchOverview = angular.copy($scope.BaseTaskTemplateSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BaseTaskTemplateSearchOverview) || $rootScope.BaseTaskTemplateSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BaseTaskTemplateSearchOverview = angular.copy($rootScope.BaseTaskTemplateSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BaseTaskTemplateSearchOverview.predicate === itemName) {
				if ($scope.BaseTaskTemplateSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateAssignedGroupUISelect = function(item, model) {
			$scope.search();
		};
	});
