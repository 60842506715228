/** @format */

'use strict';

/**
 * Renders all historyState items from the history stack seperated by "/".
 * This might help the developer to debug state history problems.
 */
angular.module('mmmApp').directive('historyBreadcrumb', function() {
	return {
		template:
			'<div class="row history-breadcrumb">' +
			'   <div class="col-xs-12">' +
			'       <span ng-repeat="historyState in history">{{historyState.stateName}}&nbsp;/&nbsp;</span>' +
			'   </div>' +
			'</div>',
		controller: function($scope, HistoryService) {
			$scope.history = HistoryService.breadcrumb();
		},
	};
});

/**
 * This HistoryService provides functionality to maintain a history stack.
 * It also provides a way to navigate back to the previous state.
 */
angular.module('mmmApp').factory('HistoryService', function($window, $state) {
	/**
	 * The history stack. The last item always reflects the current state we are in. The 2nd last item is the previous history state.
	 * @type {Array} An array of historyState items.
	 */
	var history = [];

	/**
	 * A history state object. It contains the state that was transitioned to and the state params that were passed.
	 * @param toStateName
	 * @param toStateParams
	 * @returns {{stateName: *, stateParams: *}}
	 */
	var historyState = function(toStateName, toStateParams) {
		return {
			stateName: toStateName,
			stateParams: toStateParams,
		};
	};

	return {
		/**
		 * Navigates back to the 2nd last history state. The last history state is the current state, as all states that
		 * we transition to are pushed onto the stack after a successful state change (see app.js).
		 */
		back: function() {
			// the current state is the last on the stack, the previous state in history is the one prior to the last, hence we check for a stack size of 2 or more
			if (history.length >= 2) {
				// the first pop gets the state from the stack we were already in
				history.pop();

				// the next pop will retrieve the state we were in prior to the current state, which is the state we want to go to
				// NOTE it will be pushed onto the stack again by the state change listener
				var toState = history.pop();

				console.log('navigate back to history state', toState.stateName, 'with params:', toState.stateParams);

				// the popped state is used to navigate to, and so it reappears on the stack as the state change success event handling catches and pushes it
				$state.go(toState.stateName, toState.stateParams, { reload: true });
			} else {
				// there is not enough history in the stack to navigate to, hence we fall back to the window history back
				// $window.history.back();
				// TODO I uncommented the window history back as it causes weird behaviour in use with dialogs
				$state.go('home', {}, {});
			}
		},
		/**
		 * Adds the state with params as a new history item to the stack by pushing it onto it.
		 * @param toState The state that was transitioned to
		 * @param toStateParams The state params that were passed
		 */
		addHistory: function(toState, toStateParams) {
			history.push(new historyState(toState.name, toStateParams));
		},
		/**
		 * Removes the last state from the stack. Can be used to prevent the state from being navigated to via history.
		 */
		removeHistory: function() {
			var thisState = history.pop();

			console.log('Navigated to state ' + thisState.stateName + '. Removed from history stack.');
		},
		/**
		 * Updates the 2nd last history item state params dataModel with a data model. This data model is a model as a result of a modal dialog action.
		 * @param data the ng model as a result of a modal dialog
		 */
		updateHistoryParamsData: function(data, type) {
			var historyToUpdate = history[history.length - 2];
			historyToUpdate.stateParams.dataModel = data;
			historyToUpdate.stateParams.dataModelType = type;

			console.log('updated history state', historyToUpdate.stateName, 'with new params:', historyToUpdate.stateParams);
		},
		/**
		 * Resets the current history item state params dataModel with an empty data model.
		 */
		resetCurrentHistoryParamsData: function() {
			var historyToUpdate = history[history.length - 1];
			historyToUpdate.stateParams.dataModel = null;
			historyToUpdate.stateParams.dataModelType = null;

			console.log('updated current history state', historyToUpdate.stateName, 'with new params:', historyToUpdate.stateParams);
		},
		/**
		 * Returns the complete history stack. Could be used for breadcrumb like purposes.
		 * @returns {Array}
		 */
		breadcrumb: function() {
			return history;
		},
	};
});
