/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('PersonDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.person = entity;
	$scope.objectName = 'Person';
	$scope.personId = $stateParams.personId;

	if (angular.isUndefined($rootScope.personActiveTab)) {
		//set the default here
		$rootScope.personActiveTab = 0;
	}

	$scope.goToEditState = function(person) {
		$state.go('person.detail.edit({personId:person.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:personUpdate', function(event, result) {
		$scope.person = result;
	});
});
