/** @format */

'use strict';

// RelationSalesOrderRole has edit layout enabled. Edit layout template url: relationsalesorderrole-dialog.html
// RelationSalesOrderRole has detail layout enabled. Detail layout template url: relationsalesorderrole-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'RelationSalesOrderRole'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('relationsalesorderrole'); // main translation loaded for relationsalesorderrole
		$translatePartialLoader.addPart('connectiontype'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('relation'); // one to many reference from main entity
		$translatePartialLoader.addPart('salesorder'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-relationsalesorderrole', {
		/* abstract state for DTO RelationMgmtRelationSalesOrderRoleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/relationsalesorderrole',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'RelationSalesOrderRole',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtRelationSalesOrderRoleListDto */
		/* to access: ui-ref="relation-mgmt-relationsalesorderrole.list" */
		/* url: http://<hostname>/relationmgmt/relationsalesorderrole/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-list-relation-mgmt.html',
				controller: 'RelationMgmtRelationSalesOrderRoleListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-relationsalesorderrole.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtRelationSalesOrderRoleViewDto */
		/* to access: ui-sref="relation-mgmt-relationsalesorderrole.detail" */
		/* url: http://<hostname>/relationmgmt/relationsalesorderrole/detail/:relationSalesOrderRoleId */
		url: '/detail/:relationSalesOrderRoleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-view-relation-mgmt.html',
				controller: 'RelationSalesOrderRoleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtRelationSalesOrderRoleViewDtoFactory',
				function($stateParams, RelationMgmtRelationSalesOrderRoleViewDtoFactory) {
					return RelationMgmtRelationSalesOrderRoleViewDtoFactory.get({ id: $stateParams.relationSalesOrderRoleId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationsalesorderrole.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtRelationSalesOrderRoleEditDto */
		/* to access: ui-sref="relation-mgmt-relationsalesorderrole.edit" */
		/* url: http://<hostname>/relationmgmt/relationsalesorderrole/edit/:relationSalesOrderRoleId */
		url: '/edit/:relationSalesOrderRoleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-edit-relation-mgmt.html',
				controller: 'RelationMgmtRelationSalesOrderRoleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtRelationSalesOrderRoleEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtRelationSalesOrderRoleEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtRelationSalesOrderRoleEditDtoFactory.get({ id: $stateParams.relationSalesOrderRoleId }).$promise.then(
							function(result) {
								return result;
							}
						);
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationsalesorderrole.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtRelationSalesOrderRoleEditDto */
		/* to access: ui-sref="relation-mgmt-relationsalesorderrole.new" */
		/* url: http://<hostname>/relationmgmt/relationsalesorderrole/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-edit-relation-mgmt.html',
				controller: 'RelationMgmtRelationSalesOrderRoleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtRelationSalesOrderRoleNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtRelationSalesOrderRoleNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtRelationSalesOrderRoleNewDtoFactory.get({ id: $stateParams.relationSalesOrderRoleId }).$promise.then(
							function(result) {
								return result;
							}
						);
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationsalesorderrole.new', dtoCreateState);
});
