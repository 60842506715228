/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticleCarModelYearListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleCarModelYearFactory,
		CarConfigArticleCarModelYearListDtoSearchFactory,
		CarConfigArticleCarModelYearViewDtoFactory
	) {
		$scope.articleCarModelYearList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticleCarModelYear)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticleCarModelYear);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticleCarModelYear = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-articlecarmodelyear.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getArticleCarModelYearList = function() {
			CarConfigArticleCarModelYearListDtoSearchFactory.query(
				{
					articleId: angular.isDefined($scope.articleId) ? $scope.articleId : null,
					carModelYearIdList: angular.isDefined($scope.carModelYearId) ? $scope.carModelYearId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleCarModelYearList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleCarModelYearList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleCarModelYearId = id;
			$('#deleteArticleCarModelYearConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigArticleCarModelYearViewDtoFactory.delete(
				{ id: $scope.selectedArticleCarModelYearId },
				function() {
					$scope.loadAll();
					$('#deleteArticleCarModelYearConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteArticleCarModelYearConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarModelYearUISelect = function(item, model) {
			$scope.search();
		};
	});
