/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CategoryFactory', function($resource) {
	return $resource(
		'api/categories/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCategoryViewDto
 */
angular.module('mmmApp').factory('CarConfigCategoryViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/category/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCategoryListDto's
 */
angular.module('mmmApp').factory('CarConfigCategoryListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/category',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCategoryEditDto
 */
angular.module('mmmApp').factory('CarConfigCategoryEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/category/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCategoryEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCategoryNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/category/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCategoryEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('CarConfigCategoryNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/category/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of CarConfigCategoryEditDto
 */
angular.module('mmmApp').factory('CarConfigCategoryBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/category/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCategoryEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCategoryEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/category/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCategoryEditDto
 */
angular.module('mmmApp').factory('ArticleApiCategoryEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/category/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCategoryEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCategoryNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/category/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCategoryEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCategoryEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/category/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCategoryFactory', function($resource) {
	return $resource(
		'api/categories/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Categories without the use of paging
angular.module('mmmApp').factory('NoPagingCategoryFactory', function($resource) {
	return $resource(
		'api/categories/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For dialogs only, to make sure that you can't select 'self' in potential parent dropdown box
angular.module('mmmApp').factory('potentialParentsOfCategoryFactory', function($resource) {
	return $resource(
		'api/category/parents/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/category/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCategoriesByCategoryFactory', function($resource) {
	return $resource(
		'api/articleCategories/category/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('ArticleFromArticleCategoryPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleCategoryList/AsArticleList/category/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllCategoriesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/categories/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CategorySaveAndCreateFactory', function($resource) {
	return $resource(
		'api/categories/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
