/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('OpeningHourDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.openingHour = entity;
	$scope.objectName = 'OpeningHour';
	$scope.openingHourId = $stateParams.openingHourId;

	if (angular.isUndefined($rootScope.openingHourActiveTab)) {
		//set the default here
		$rootScope.openingHourActiveTab = 0;
	}

	$scope.goToEditState = function(openingHour) {
		$state.go('openinghour.detail.edit({openingHourId:openingHour.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:openingHourUpdate', function(event, result) {
		$scope.openingHour = result;
	});
});
