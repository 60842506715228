/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CrmCarValuationFactory', function($resource) {
	return $resource(
		'api/crmCarValuations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCrmCarValuationViewDto
 */
angular.module('mmmApp').factory('StockMgmtCrmCarValuationViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcarvaluation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCrmCarValuationListDto's
 */
angular.module('mmmApp').factory('StockMgmtCrmCarValuationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcarvaluation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtCrmCarValuationEditDto
 */
angular.module('mmmApp').factory('StockMgmtCrmCarValuationEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcarvaluation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtCrmCarValuationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtCrmCarValuationNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcarvaluation/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCrmCarValuationViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarValuationViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarvaluation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCrmCarValuationListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarValuationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarvaluation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCrmCarValuationEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarValuationEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarvaluation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCrmCarValuationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarValuationNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarvaluation/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCrmCarValuationFactory', function($resource) {
	return $resource(
		'api/crmCarValuations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CrmCarValuations without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarValuationFactory', function($resource) {
	return $resource(
		'api/crmCarValuations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCrmCarValuationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/crmCarValuations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCrmCarValuationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/crmCarValuations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteStockMgmtCrmCarValuationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/stockmgmt/autocomplete/crmcarvaluations',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtCrmCarValuationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/crmcarvaluations',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
