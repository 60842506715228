/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articlefilteroptionsOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlefilteroption/articlefilteroption-tab.html',
		controller: 'ArticleFilterOptionTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticleFilterOptionMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlefilteroption/articlefilteroption-list-car-config.tab.html',
		controller: 'CarConfigArticleFilterOptionListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articlecarmodelyearsOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecarmodelyear/articlecarmodelyear-tab.html',
		controller: 'ArticleCarModelYearTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticleCarModelYearMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecarmodelyear/articlecarmodelyear-list-car-config.tab.html',
		controller: 'CarConfigArticleCarModelYearListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('billofmaterialsOverviewMappedByParentarticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/billofmaterials/billofmaterials-tab.html',
		controller: 'BillOfMaterialsTabController',
		scope: {
			parentArticleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigBillOfMaterialsMappedByParentArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/billofmaterials/billofmaterials-list-car-config.tab.html',
		controller: 'CarConfigBillOfMaterialsListDtoTabController',
		scope: {
			parentArticleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('imagesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/image/image-tab.html',
		controller: 'ImageTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigImageMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/image/image-list-car-config.tab.html',
		controller: 'CarConfigImageListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articlecombisOverviewMappedByFromarticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecombi/articlecombi-tab.html',
		controller: 'ArticleCombiTabController',
		scope: {
			fromArticleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticleCombiMappedByFromArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecombi/articlecombi-list-car-config.tab.html',
		controller: 'CarConfigArticleCombiListDtoTabController',
		scope: {
			fromArticleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articletagsOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articletag/articletag-tab.html',
		controller: 'ArticleTagTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticleTagMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articletag/articletag-list-car-config.tab.html',
		controller: 'CarConfigArticleTagListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carconfigurationpackagearticlesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carconfigurationpackagearticle/carconfigurationpackagearticle-tab.html',
		controller: 'CarConfigurationPackageArticleTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigCarConfigurationPackageArticleMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carconfigurationpackagearticle/carconfigurationpackagearticle-list-car-config.tab.html',
		controller: 'CarConfigCarConfigurationPackageArticleListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCarConfigurationPackageArticleMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carconfigurationpackagearticle/carconfigurationpackagearticle-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCarConfigurationPackageArticleListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articlecategoriesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecategory/articlecategory-tab.html',
		controller: 'ArticleCategoryTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticleCategoryMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articlecategory/articlecategory-list-car-config.tab.html',
		controller: 'CarConfigArticleCategoryListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('orderlinesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/orderline/orderline-tab.html',
		controller: 'OrderLineTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtOrderLineMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/orderline/orderline-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtOrderLineListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('articlepricesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articleprice/articleprice-tab.html',
		controller: 'ArticlePriceTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigArticlePriceMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/articleprice/articleprice-list-car-config.tab.html',
		controller: 'CarConfigArticlePriceListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carconfigurationdependentpricesOverviewMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-tab.html',
		controller: 'CarConfigurationDependentPriceTabController',
		scope: {
			articleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigCarConfigurationDependentPriceMappedByArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-list-car-config.tab.html',
		controller: 'CarConfigCarConfigurationDependentPriceListDtoTabController',
		scope: {
			articleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('caroptiondependentpricesOverviewMappedByTargetarticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/caroptiondependentprice/caroptiondependentprice-tab.html',
		controller: 'CarOptionDependentPriceTabController',
		scope: {
			targetArticleId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigCarOptionDependentPriceMappedByTargetArticle', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/caroptiondependentprice/caroptiondependentprice-list-car-config.tab.html',
		controller: 'CarConfigCarOptionDependentPriceListDtoTabController',
		scope: {
			targetArticleId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
