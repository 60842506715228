'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarGradeDependentPriceFactory', function($resource) {
	return $resource('api/carGradeDependentPrices/:id', {}, {
		'query' : {
			method : 'GET',
			isArray : true
		},
		'get' : {
			method : 'GET',
			transformResponse : function(data) {
				data = angular.fromJson(data);
				return data;
			}
		},
		'update' : {
			method : 'PUT'
		}
	});
});

/**
 * Factory to get or delete an instance of CarConfigCarGradeDependentPriceViewDto
 */
angular.module('mmmApp').factory('CarConfigCarGradeDependentPriceViewDtoFactory', function($resource) {
	return $resource('api/car/config/cargradedependentprice/:id', {}, {
		'get' : {
			method : 'GET',
			transformResponse : function(data) {
				data = angular.fromJson(data);
				return data;
			}
		}
	});
});
/**
 * Factory to search / retrieve an array of CarConfigCarGradeDependentPriceListDto's
 */
angular.module('mmmApp').factory('CarConfigCarGradeDependentPriceListDtoSearchFactory', function($resource) {
	return $resource('api/car/config/cargradedependentprice', {}, {
		'query' : {
			method : 'GET',
			isArray : false
		}
	});
});

/**
 * Factory to get / update an instance of CarConfigCarGradeDependentPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarGradeDependentPriceEditDtoFactory', function($resource) {
	return $resource('api/car/config/cargradedependentprice/edit/:id', {}, {
		'get' : {
			method : 'GET',
			transformResponse : function(data) {
				data = angular.fromJson(data);
				return data;
			}
		},
		'update' : {
			method : 'PUT'
		}
	});
});

/*
 * Factory to fetch a new object of CarConfigCarGradeDependentPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarGradeDependentPriceNewDtoFactory', function($resource) {
	return $resource('api/car/config/cargradedependentprice/edit/new', {}, {
		'get' : {
			method : 'GET',
			transformResponse : function(data) {
				data = angular.fromJson(data);
				return data;
			}
		}
	})
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarGradeDependentPriceFactory', function($resource) {
	return $resource('api/carGradeDependentPrices/new', {}, {
		'get' : {
			method : 'GET',
			transformResponse : function(data) {
				data = angular.fromJson(data);
				return data;
			}
		}
	})
});

//Get all CarGradeDependentPrices without the use of paging
angular.module('mmmApp').factory('NoPagingCarGradeDependentPriceFactory', function($resource) {
	return $resource('api/carGradeDependentPrices/nopaging', {}, {
		'query' : {
			method : 'GET',
			isArray : true
		}
	});
});

angular.module('mmmApp').factory('FindAllCarGradeDependentPricesByQueryParamsFactory', function($resource) {
	return $resource('api/query/carGradeDependentPrices/', {}, {
		'query' : {
			method : 'GET',
			isArray : true
		}
	});
});
