/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtSubTaskListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SubTaskFactory,
		RelationMgmtSubTaskListDtoSearchFactory,
		RelationMgmtSubTaskViewDtoFactory,
		AutocompleteRelationMgmtEmployeesByQueryParamsFactory
	) {
		$scope.subTaskList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSubTask)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSubTask);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSubTask = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-subtask.new');
		};

		var getAssignedEmployeeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubTaskSearchOverview.assignedEmployee)) {
				return $scope.SubTaskSearchOverview.assignedEmployee.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubTaskSearchOverview.tenant)) {
				return $scope.SubTaskSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SubTaskSearchOverview = angular.copy($scope.SubTaskSearchOverview);

			$rootScope.pageSizeSubTask = angular.copy($scope.pageSize);

			RelationMgmtSubTaskListDtoSearchFactory.query(
				{
					page: $scope.SubTaskSearchOverview.page - 1,
					size: $scope.pageSize,
					title: $scope.SubTaskSearchOverview.title,
					description: $scope.SubTaskSearchOverview.description,
					statusList: $scope.SubTaskSearchOverview.statusList,
					assignedGroupIdList: $scope.SubTaskSearchOverview.assignedGroupIdList,
					assignedEmployeeId: getAssignedEmployeeId(),
					tenantId: getTenantId(),
					taskIdList: $scope.SubTaskSearchOverview.taskIdList,

					sort: [$scope.SubTaskSearchOverview.predicate + ',' + ($scope.SubTaskSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.subTaskList = result.items;

					$scope.taskStatusList = result.statusSearchOptions;

					$scope.assignedGroupReferenceFilterList = result.assignedGroupSearchOptions;

					$scope.autocompleteEmployees = function(val) {
						var params = {
							employeeTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.taskReferenceFilterList = result.taskSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSubTaskSearchOverviewDirty = function() {
			return !angular.equals($scope.SubTaskSearchOverview, $scope.originalSubTaskSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SubTaskSearchOverview = {};
			$rootScope.SubTaskSearchOverview.page = 1;
			$rootScope.SubTaskSearchOverview.predicate = 'id';
			$rootScope.SubTaskSearchOverview.ascending = false;

			$scope.SubTaskSearchOverview = angular.copy($rootScope.SubTaskSearchOverview);

			$scope.originalSubTaskSearchOverview = angular.copy($scope.SubTaskSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SubTaskSearchOverview) || $rootScope.SubTaskSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SubTaskSearchOverview = angular.copy($rootScope.SubTaskSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSubTaskId = id;
			$('#deleteSubTaskConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtSubTaskViewDtoFactory.delete(
				{ id: $scope.selectedSubTaskId },
				function() {
					$scope.search();
					$('#deleteSubTaskConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSubTaskConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SubTaskSearchOverview.predicate === itemName) {
				if ($scope.SubTaskSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateAssignedGroupUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTaskUISelect = function(item, model) {
			$scope.search();
		};
	});
