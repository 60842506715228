/** @format */

(function() {
	'use strict';

	angular.module('mmmApp').config(pagerConfig);

	pagerConfig.$inject = ['uibPagerConfig', 'paginationConstants'];

	function pagerConfig(uibPagerConfig, paginationConstants) {
		uibPagerConfig.itemsPerPage = paginationConstants.itemsPerPage;
		uibPagerConfig.previousText = '«';
		uibPagerConfig.nextText = '»';
	}
})();
