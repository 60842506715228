/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationPriceEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigCarConfigurationPriceEditDtoFactory,
		AlertService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.carConfigurationPrice = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.carConfigurationPrice['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return (
				$rootScope.isDefinedAndNotNull($scope.carConfigurationPrice) && $rootScope.isDefinedAndNotNull($scope.carConfigurationPrice.id)
			);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carConfigurationPriceUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			CarConfigCarConfigurationPriceEditDtoFactory.update($scope.carConfigurationPrice, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.compareCo2Fields = function() {
			if ($scope.carConfigurationPrice.co2Min > $scope.carConfigurationPrice.co2Base) {
				$scope.submitButtonIsActive = false;
				$scope.co2MinSmallerThanCo2BaseValidation = true;
				$scope.co2BaseSmallerThanCo2MaxValidation = false;
			} else if ($scope.carConfigurationPrice.co2Base > $scope.carConfigurationPrice.co2Max) {
				$scope.submitButtonIsActive = false;
				$scope.co2BaseSmallerThanCo2MaxValidation = true;
				$scope.co2MinSmallerThanCo2BaseValidation = false;
			} else {
				$scope.submitButtonIsActive = true;
				$scope.co2MinSmallerThanCo2BaseValidation = false;
				$scope.co2BaseSmallerThanCo2MaxValidation = false;
			}
		};
	});
