/** @format */

'use strict';

// CarOption has edit layout enabled. Edit layout template url: caroption-dialog.html
// CarOption has detail layout enabled. Detail layout template url: caroption-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarOption'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('caroption'); // main translation loaded for caroption
		$translatePartialLoader.addPart('article'); // main translation loaded for caroption
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('optionorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('optiontype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('optioncategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('vehicleoptioncategory'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-caroption', {
		/* abstract state for DTO CarConfigCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/caroption',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarOptionListDto */
		/* to access: ui-ref="car-config-caroption.list" */
		/* url: http://<hostname>/car/config/caroption/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-list-car-config.html',
				controller: 'CarConfigCarOptionListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-caroption.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarOptionViewDto */
		/* to access: ui-sref="car-config-caroption.detail" */
		/* url: http://<hostname>/car/config/caroption/detail/:carOptionId */
		url: '/detail/:carOptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-view-car-config.html',
				controller: 'CarOptionDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarOptionViewDtoFactory',
				function($stateParams, CarConfigCarOptionViewDtoFactory) {
					return CarConfigCarOptionViewDtoFactory.get({ id: $stateParams.carOptionId });
				},
			],
		},
	};

	$stateProvider.state('car-config-caroption.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarOptionEditDto */
		/* to access: ui-sref="car-config-caroption.edit" */
		/* url: http://<hostname>/car/config/caroption/edit/:carOptionId */
		url: '/edit/:carOptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-edit-car-config.html',
				controller: 'CarConfigCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarOptionEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarOptionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as optionCategory has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.optionCategory)) {
							// push the created optionCategory to the list with selectable optionCategory items
							$stateParams.dataModel.optionCategoryList.push($stateParams.dataModel.optionCategory);

							// set the created optionCategory as selected optionCategory
							$stateParams.dataModel.selectedOptionCategory = $stateParams.dataModel.optionCategory;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarOptionEditDtoFactory.get({ id: $stateParams.carOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-caroption.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarOptionEditDto */
		/* to access: ui-sref="car-config-caroption.new" */
		/* url: http://<hostname>/car/config/caroption/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-edit-car-config.html',
				controller: 'CarConfigCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarOptionNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarOptionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as optionCategory has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.optionCategory)) {
							// push the created optionCategory to the list with selectable optionCategory items
							$stateParams.dataModel.optionCategoryList.push($stateParams.dataModel.optionCategory);

							// set the created optionCategory as selected optionCategory
							$stateParams.dataModel.selectedOptionCategory = $stateParams.dataModel.optionCategory;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarOptionNewDtoFactory.get({ id: $stateParams.carOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-caroption.new', dtoCreateState);

	$stateProvider.state('relation-mgmt-caroption', {
		/* abstract state for DTO RelationMgmtCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/caroption',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'CarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('stock-mgmt-caroption', {
		/* abstract state for DTO StockMgmtCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/caroption',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('website-api-caroption', {
		/* abstract state for DTO WebsiteApiCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/caroption',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO website.api */
	var dtoListState = {
		/* list state for DTO WebsiteApiCarOptionListDto */
		/* to access: ui-ref="website-api-caroption.list" */
		/* url: http://<hostname>/websiteapi/caroption/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-list-website-api.html',
				controller: 'WebsiteApiCarOptionListDtoController',
			},
		},
	};

	$stateProvider.state('website-api-caroption.list', dtoListState);

	$stateProvider.state('article-api-caroption', {
		/* abstract state for DTO ArticleApiCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/caroption',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarOptionEditDto */
		/* to access: ui-sref="article-api-caroption.edit" */
		/* url: http://<hostname>/articleapi/caroption/edit/:carOptionId */
		url: '/edit/:carOptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-edit-article-api.html',
				controller: 'ArticleApiCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarOptionEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarOptionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as optionCategory has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.optionCategory)) {
							// push the created optionCategory to the list with selectable optionCategory items
							$stateParams.dataModel.optionCategoryList.push($stateParams.dataModel.optionCategory);

							// set the created optionCategory as selected optionCategory
							$stateParams.dataModel.selectedOptionCategory = $stateParams.dataModel.optionCategory;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarOptionEditDtoFactory.get({ id: $stateParams.carOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-caroption.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarOptionEditDto */
		/* to access: ui-sref="article-api-caroption.new" */
		/* url: http://<hostname>/articleapi/caroption/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/caroption/caroption-edit-article-api.html',
				controller: 'ArticleApiCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarOptionNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarOptionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as optionCategory has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.optionCategory)) {
							// push the created optionCategory to the list with selectable optionCategory items
							$stateParams.dataModel.optionCategoryList.push($stateParams.dataModel.optionCategory);

							// set the created optionCategory as selected optionCategory
							$stateParams.dataModel.selectedOptionCategory = $stateParams.dataModel.optionCategory;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarOptionNewDtoFactory.get({ id: $stateParams.carOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-caroption.new', dtoCreateState);
});
