/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ArticleCarModelYearFactory', function($resource) {
	return $resource(
		'api/articleCarModelYears/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigArticleCarModelYearViewDto
 */
angular.module('mmmApp').factory('CarConfigArticleCarModelYearViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articlecarmodelyear/:id',
		{},
		{
			// Factory is only used to delete a CarConfigArticleCarModelYearViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigArticleCarModelYearListDto's
 */
angular.module('mmmApp').factory('CarConfigArticleCarModelYearListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/articlecarmodelyear',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewArticleCarModelYearFactory', function($resource) {
	return $resource(
		'api/articleCarModelYears/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ArticleCarModelYears without the use of paging
angular.module('mmmApp').factory('NoPagingArticleCarModelYearFactory', function($resource) {
	return $resource(
		'api/articleCarModelYears/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllArticleCarModelYearsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articleCarModelYears/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
