/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('BaseTaskFactory', function($resource) {
	return $resource(
		'api/baseTasks/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtBaseTaskViewDto
 */
angular.module('mmmApp').factory('RelationMgmtBaseTaskViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/basetask/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtBaseTaskListDto's
 */
angular.module('mmmApp').factory('RelationMgmtBaseTaskListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/basetask',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtBaseTaskEditDto
 */
angular.module('mmmApp').factory('RelationMgmtBaseTaskEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/basetask/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewBaseTaskFactory', function($resource) {
	return $resource(
		'api/baseTasks/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all BaseTasks without the use of paging
angular.module('mmmApp').factory('NoPagingBaseTaskFactory', function($resource) {
	return $resource(
		'api/baseTasks/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TaskStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTaskStatusFactory', function($resource) {
	return $resource(
		'api/baseTask/taskStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllBaseTasksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/baseTasks/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
