/** @format */

'use strict';

// CarMileage has edit layout enabled. Edit layout template url: carmileage-dialog.html
// CarMileage has detail layout enabled. Detail layout template url: carmileage-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarMileage'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carmileage'); // main translation loaded for carmileage
		$translatePartialLoader.addPart('recordedby'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-carmileage', {
		/* abstract state for DTO StockMgmtCarMileageListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/carmileage',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarMileage',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCarMileageListDto */
		/* to access: ui-ref="stock-mgmt-carmileage.list" */
		/* url: http://<hostname>/stockmgmt/carmileage/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-list-stock-mgmt.html',
				controller: 'StockMgmtCarMileageListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-carmileage.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtCarMileageViewDto */
		/* to access: ui-sref="stock-mgmt-carmileage.detail" */
		/* url: http://<hostname>/stockmgmt/carmileage/detail/:carMileageId */
		url: '/detail/:carMileageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-view-stock-mgmt.html',
				controller: 'CarMileageDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtCarMileageViewDtoFactory',
				function($stateParams, StockMgmtCarMileageViewDtoFactory) {
					return StockMgmtCarMileageViewDtoFactory.get({ id: $stateParams.carMileageId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carmileage.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCarMileageEditDto */
		/* to access: ui-sref="stock-mgmt-carmileage.edit" */
		/* url: http://<hostname>/stockmgmt/carmileage/edit/:carMileageId */
		url: '/edit/:carMileageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-edit-stock-mgmt.html',
				controller: 'StockMgmtCarMileageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarMileageEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarMileageEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarMileageEditDtoFactory.get({ id: $stateParams.carMileageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carmileage.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtCarMileageEditDto */
		/* to access: ui-sref="stock-mgmt-carmileage.new" */
		/* url: http://<hostname>/stockmgmt/carmileage/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-edit-stock-mgmt.html',
				controller: 'StockMgmtCarMileageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarMileageNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarMileageNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarMileageNewDtoFactory.get({ id: $stateParams.carMileageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carmileage.new', dtoCreateState);

	$stateProvider.state('relation-mgmt-carmileage', {
		/* abstract state for DTO RelationMgmtCarMileageListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/carmileage',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarMileage',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCarMileageListDto */
		/* to access: ui-ref="relation-mgmt-carmileage.list" */
		/* url: http://<hostname>/relationmgmt/carmileage/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-list-relation-mgmt.html',
				controller: 'RelationMgmtCarMileageListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-carmileage.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtCarMileageViewDto */
		/* to access: ui-sref="relation-mgmt-carmileage.detail" */
		/* url: http://<hostname>/relationmgmt/carmileage/detail/:carMileageId */
		url: '/detail/:carMileageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-view-relation-mgmt.html',
				controller: 'CarMileageDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtCarMileageViewDtoFactory',
				function($stateParams, RelationMgmtCarMileageViewDtoFactory) {
					return RelationMgmtCarMileageViewDtoFactory.get({ id: $stateParams.carMileageId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-carmileage.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCarMileageEditDto */
		/* to access: ui-sref="relation-mgmt-carmileage.edit" */
		/* url: http://<hostname>/relationmgmt/carmileage/edit/:carMileageId */
		url: '/edit/:carMileageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-edit-relation-mgmt.html',
				controller: 'RelationMgmtCarMileageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCarMileageEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCarMileageEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCarMileageEditDtoFactory.get({ id: $stateParams.carMileageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-carmileage.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCarMileageEditDto */
		/* to access: ui-sref="relation-mgmt-carmileage.new" */
		/* url: http://<hostname>/relationmgmt/carmileage/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmileage/carmileage-edit-relation-mgmt.html',
				controller: 'RelationMgmtCarMileageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCarMileageNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCarMileageNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCarMileageNewDtoFactory.get({ id: $stateParams.carMileageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-carmileage.new', dtoCreateState);
});
