/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CrmCarOptionFactory', function($resource) {
	return $resource(
		'api/crmCarOptions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCrmCarOptionViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarOptionViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcaroption/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCrmCarOptionListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcaroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCrmCarOptionEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarOptionEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcaroption/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCrmCarOptionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarOptionNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcaroption/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCrmCarOptionViewDto
 */
angular.module('mmmApp').factory('StockMgmtCrmCarOptionViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcaroption/:id',
		{},
		{
			// Factory is only used to delete a StockMgmtCrmCarOptionViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCrmCarOptionListDto's
 */
angular.module('mmmApp').factory('StockMgmtCrmCarOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcaroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCrmCarOptionFactory', function($resource) {
	return $resource(
		'api/crmCarOptions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CrmCarOptions without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarOptionFactory', function($resource) {
	return $resource(
		'api/crmCarOptions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible CrmCarOptionOrigins Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarOptionOriginFactory', function($resource) {
	return $resource(
		'api/crmCarOption/crmCarOptionOrigins',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCrmCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/crmCarOptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
