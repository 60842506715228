/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('MappingFailedLogDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.mappingFailedLog = entity;
		$scope.objectName = 'MappingFailedLog';
		$scope.mappingFailedLogId = $stateParams.mappingFailedLogId;

		if (angular.isUndefined($rootScope.mappingFailedLogActiveTab)) {
			//set the default here
			$rootScope.mappingFailedLogActiveTab = 0;
		}

		$scope.goToEditState = function(mappingFailedLog) {
			$state.go('mappingfailedlog.detail.edit({mappingFailedLogId:mappingFailedLog.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:mappingFailedLogUpdate', function(event, result) {
			$scope.mappingFailedLog = result;
		});
	});
