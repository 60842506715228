'use strict';

angular.module('mmmApp').controller(
		'ArticleApiFilterOptionEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, ArticleApiFilterOptionEditDtoFactory, AlertService,
				HistoryService) {

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.submitButtonIsActive = true;
			};

			$scope.filterOption = entity;
			$scope.submitButtonIsActive = true;

			if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
				$scope.mappedBy = $stateParams.mappedBy;
			}

			//This function preselects and disables the parent reference when called from tab
			$scope.filterOption['selected' + _.upperFirst($stateParams.mappedBy)] = {
				id : $stateParams.mappedById,
				displayValue : $stateParams.mappedByDisplayValue
			};

			/**
			 * Checks if the page is in create or edit state based on the scope model and it's id value
			 */
			$scope.isEdit = function() {
				return ($rootScope.isDefinedAndNotNull($scope.filterOption) && $rootScope.isDefinedAndNotNull($scope.filterOption.id));
			};

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:filterOptionUpdate', result);
				$scope.submitButtonIsActive = true;
				$rootScope.back();
			};

			$scope.save = function() {
				$scope.submitButtonIsActive = false;
				ArticleApiFilterOptionEditDtoFactory.update($scope.filterOption, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

		});
