/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('GroupMemberFactory', function($resource) {
	return $resource(
		'api/groupMembers/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtGroupMemberViewDto
 */
angular.module('mmmApp').factory('RelationMgmtGroupMemberViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/groupmember/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtGroupMemberListDto's
 */
angular.module('mmmApp').factory('RelationMgmtGroupMemberListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/groupmember',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtGroupMemberEditDto
 */
angular.module('mmmApp').factory('RelationMgmtGroupMemberEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/groupmember/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewGroupMemberFactory', function($resource) {
	return $resource(
		'api/groupMembers/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all GroupMembers without the use of paging
angular.module('mmmApp').factory('NoPagingGroupMemberFactory', function($resource) {
	return $resource(
		'api/groupMembers/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllGroupMembersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/groupMembers/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
