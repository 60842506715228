/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtGroupMemberListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		GroupMemberFactory,
		RelationMgmtGroupMemberListDtoSearchFactory,
		RelationMgmtGroupMemberViewDtoFactory
	) {
		$scope.groupMemberList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeGroupMember)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeGroupMember);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeGroupMember = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-groupmember.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getGroupMemberList = function() {
			RelationMgmtGroupMemberListDtoSearchFactory.query(
				{
					groupIdList: angular.isDefined($scope.groupId) ? $scope.groupId : null,
					employeeId: angular.isDefined($scope.employeeId) ? $scope.employeeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.groupMemberList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getGroupMemberList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateGroupUISelect = function(item, model) {
			$scope.search();
		};
	});
