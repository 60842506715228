/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RelationSalesOrderRoleFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRoles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtRelationSalesOrderRoleViewDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationSalesOrderRoleViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationsalesorderrole/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtRelationSalesOrderRoleListDto's
 */
angular.module('mmmApp').factory('RelationMgmtRelationSalesOrderRoleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationsalesorderrole',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtRelationSalesOrderRoleEditDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationSalesOrderRoleEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationsalesorderrole/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtRelationSalesOrderRoleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtRelationSalesOrderRoleNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationsalesorderrole/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRelationSalesOrderRoleFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRoles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RelationSalesOrderRoles without the use of paging
angular.module('mmmApp').factory('NoPagingRelationSalesOrderRoleFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRoles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ConnectionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingConnectionTypeFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRole/connectionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRelationSalesOrderRolesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relationSalesOrderRoles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
