/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarTransmissionListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarTransmissionFactory,
		CarConfigCarTransmissionListDtoSearchFactory,
		CarConfigCarTransmissionViewDtoFactory
	) {
		$scope.carTransmissionList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarTransmission)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarTransmission);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarTransmission = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-cartransmission.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getCarTransmissionList = function() {
			CarConfigCarTransmissionListDtoSearchFactory.query(
				{
					statusList: angular.isDefined($scope.statusId) ? $scope.statusId : null,
					typeList: angular.isDefined($scope.typeId) ? $scope.typeId : null,
					imageSize: angular.isDefined($scope.imageSizeId) ? $scope.imageSizeId : null,
					vatType: angular.isDefined($scope.vatTypeId) ? $scope.vatTypeId : null,
					equivalentId: angular.isDefined($scope.equivalentId) ? $scope.equivalentId : null,
					priceTypeList: angular.isDefined($scope.priceTypeId) ? $scope.priceTypeId : null,
					brandIdList: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					countryList: angular.isDefined($scope.countryId) ? $scope.countryId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,
					transmissionTypeList: angular.isDefined($scope.transmissionTypeId) ? $scope.transmissionTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carTransmissionList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarTransmissionList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarTransmissionId = id;
			$('#deleteCarTransmissionConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarTransmissionViewDtoFactory.delete(
				{ id: $scope.selectedCarTransmissionId },
				function() {
					$scope.loadAll();
					$('#deleteCarTransmissionConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarTransmissionConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carTransmissionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTransmissionTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
