/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCrmCarListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarFactory,
		RelationMgmtCrmCarListDtoSearchFactory,
		RelationMgmtCrmCarViewDtoFactory
	) {
		$scope.crmCarList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCar)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCar);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCar = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-crmcar.new');
		};

		var getCarConfigurationPackageId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.carConfigurationPackage)) {
				return $scope.CrmCarSearchOverview.carConfigurationPackage.id;
			} else {
				return undefined;
			}
		};

		var getPaintId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.paint)) {
				return $scope.CrmCarSearchOverview.paint.id;
			} else {
				return undefined;
			}
		};

		var getVehicleDetailsId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.vehicleDetails)) {
				return $scope.CrmCarSearchOverview.vehicleDetails.id;
			} else {
				return undefined;
			}
		};

		// handle attribute productionDate of javaType java.time.LocalDate
		$scope.processProductionDate = function() {
			var productionDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.productionDateFrom);
			var productionDateToDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.productionDateTo);

			if (productionDateFromDefined) {
				$scope.CrmCarSearchOverview.productionDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarSearchOverview.productionDateFrom
				);
			} else {
				$scope.CrmCarSearchOverview.productionDateFromString = undefined;
			}

			if (productionDateToDefined) {
				$scope.CrmCarSearchOverview.productionDateToString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarSearchOverview.productionDateTo
				);
			} else {
				$scope.CrmCarSearchOverview.productionDateToString = undefined;
			}
		};

		// handle attribute localDocPrintDate of javaType java.time.LocalDate
		$scope.processLocalDocPrintDate = function() {
			var localDocPrintDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.localDocPrintDateFrom);
			var localDocPrintDateToDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarSearchOverview.localDocPrintDateTo);

			if (localDocPrintDateFromDefined) {
				$scope.CrmCarSearchOverview.localDocPrintDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarSearchOverview.localDocPrintDateFrom
				);
			} else {
				$scope.CrmCarSearchOverview.localDocPrintDateFromString = undefined;
			}

			if (localDocPrintDateToDefined) {
				$scope.CrmCarSearchOverview.localDocPrintDateToString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarSearchOverview.localDocPrintDateTo
				);
			} else {
				$scope.CrmCarSearchOverview.localDocPrintDateToString = undefined;
			}
		};

		// handle attribute newCar of javaType boolean
		// handle attribute importCar of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processProductionDate();
			$scope.processLocalDocPrintDate();

			$rootScope.CrmCarSearchOverview = angular.copy($scope.CrmCarSearchOverview);

			$rootScope.pageSizeCrmCar = angular.copy($scope.pageSize);

			RelationMgmtCrmCarListDtoSearchFactory.query(
				{
					page: $scope.CrmCarSearchOverview.page - 1,
					size: $scope.pageSize,
					seriesDescription: $scope.CrmCarSearchOverview.seriesDescription,
					chassis: $scope.CrmCarSearchOverview.chassis,
					productCode: $scope.CrmCarSearchOverview.productCode,
					factoryCode: $scope.CrmCarSearchOverview.factoryCode,
					description: $scope.CrmCarSearchOverview.description,
					exteriorColorCode: $scope.CrmCarSearchOverview.exteriorColorCode,
					interiorColorCode: $scope.CrmCarSearchOverview.interiorColorCode,
					interiorColorDescription: $scope.CrmCarSearchOverview.interiorColorDescription,
					productionDateFrom: $scope.CrmCarSearchOverview.productionDateFromString,
					productionDateTo: $scope.CrmCarSearchOverview.productionDateToString,
					licensePlate: $scope.CrmCarSearchOverview.licensePlate,
					localDocPrintDateFrom: $scope.CrmCarSearchOverview.localDocPrintDateFromString,
					localDocPrintDateTo: $scope.CrmCarSearchOverview.localDocPrintDateToString,
					bpmFrom: $scope.CrmCarSearchOverview.bpmFrom,
					bpmTo: $scope.CrmCarSearchOverview.bpmTo,
					marge: $scope.CrmCarSearchOverview.margeGroup,
					newCar: $scope.CrmCarSearchOverview.newCarGroup,
					batteryConditionFrom: $scope.CrmCarSearchOverview.batteryConditionFrom,
					batteryConditionTo: $scope.CrmCarSearchOverview.batteryConditionTo,
					importCar: $scope.CrmCarSearchOverview.importCarGroup,
					numberOfKeysFrom: $scope.CrmCarSearchOverview.numberOfKeysFrom,
					numberOfKeysTo: $scope.CrmCarSearchOverview.numberOfKeysTo,
					externalCarId: $scope.CrmCarSearchOverview.externalCarId,
					co2From: $scope.CrmCarSearchOverview.co2From,
					co2To: $scope.CrmCarSearchOverview.co2To,
					carConfigurationIdList: $scope.CrmCarSearchOverview.carConfigurationIdList,
					carConfigurationPackageId: getCarConfigurationPackageId(),
					modelIdList: $scope.CrmCarSearchOverview.modelIdList,
					brandIdList: $scope.CrmCarSearchOverview.brandIdList,
					paintId: getPaintId(),
					colorIdList: $scope.CrmCarSearchOverview.colorIdList,
					transmissionList: $scope.CrmCarSearchOverview.transmissionList,
					bodyTypeList: $scope.CrmCarSearchOverview.bodyTypeList,
					fuelTypeList: $scope.CrmCarSearchOverview.fuelTypeList,
					doorsList: $scope.CrmCarSearchOverview.doorsList,
					energyLabelList: $scope.CrmCarSearchOverview.energyLabelList,
					locale: $scope.CrmCarSearchOverview.locale,
					countryList: $scope.CrmCarSearchOverview.countryList,
					co2origin: $scope.CrmCarSearchOverview.co2origin,
					vehicleDetailsId: getVehicleDetailsId(),

					sort: [$scope.CrmCarSearchOverview.predicate + ',' + ($scope.CrmCarSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.crmCarList = result.items;

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$scope.modelReferenceFilterList = result.modelSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.colorReferenceFilterList = result.colorSearchOptions;

					$scope.transmissionTypeList = result.transmissionSearchOptions;

					$scope.bodyTypeList = result.bodyTypeSearchOptions;

					$scope.fuelTypeList = result.fuelTypeSearchOptions;

					$scope.numberOfDoorsList = result.doorsSearchOptions;

					$scope.energyLabelList = result.energyLabelSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCrmCarSearchOverviewDirty = function() {
			return !angular.equals($scope.CrmCarSearchOverview, $scope.originalCrmCarSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CrmCarSearchOverview = {};
			$rootScope.CrmCarSearchOverview.page = 1;
			$rootScope.CrmCarSearchOverview.predicate = 'id';
			$rootScope.CrmCarSearchOverview.ascending = false;

			$scope.CrmCarSearchOverview = angular.copy($rootScope.CrmCarSearchOverview);

			$scope.originalCrmCarSearchOverview = angular.copy($scope.CrmCarSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CrmCarSearchOverview) || $rootScope.CrmCarSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CrmCarSearchOverview = angular.copy($rootScope.CrmCarSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarId = id;
			$('#deleteCrmCarConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtCrmCarViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarId },
				function() {
					$scope.search();
					$('#deleteCrmCarConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CrmCarSearchOverview.predicate === itemName) {
				if ($scope.CrmCarSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateModelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateColorUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTransmissionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBodyTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateFuelTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateDoorsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};
	});
