'use strict';

angular.module('mmmApp').controller('CarOrderLineDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carOrderLine = entity;
	$scope.objectName = "CarOrderLine";
	$scope.carOrderLineId = $stateParams.carOrderLineId;

	if (angular.isUndefined($rootScope.carOrderLineActiveTab)) {
		//set the default here
		$rootScope.carOrderLineActiveTab = 0;
	}

	$scope.goToEditState = function(carOrderLine) {
		$state.go('carorderline.detail.edit({carOrderLineId:carOrderLine.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carOrderLineUpdate', function(event, result) {
		$scope.carOrderLine = result;
	});

});
