/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarPowertrainDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carPowertrain = entity;
	$scope.objectName = 'CarPowertrain';
	$scope.carPowertrainId = $stateParams.carPowertrainId;

	if (angular.isUndefined($rootScope.carPowertrainActiveTab)) {
		//set the default here
		$rootScope.carPowertrainActiveTab = 0;
	}

	$scope.goToEditState = function(carPowertrain) {
		$state.go('carpowertrain.detail.edit({carPowertrainId:carPowertrain.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carPowertrainUpdate', function(event, result) {
		$scope.carPowertrain = result;
	});
});
