/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingEnergyLabelListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingEnergyLabelFactory,
		StockMgmtMappingEnergyLabelListDtoSearchFactory,
		StockMgmtMappingEnergyLabelViewDtoFactory
	) {
		$scope.mappingEnergyLabelList = [];
		$scope.selectedMappingEnergyLabelList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingEnergyLabel)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingEnergyLabel);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingEnergyLabel = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingenergylabel.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingEnergyLabelSearchOverview = angular.copy($scope.MappingEnergyLabelSearchOverview);

			$rootScope.pageSizeMappingEnergyLabel = angular.copy($scope.pageSize);

			StockMgmtMappingEnergyLabelListDtoSearchFactory.query(
				{
					page: $scope.MappingEnergyLabelSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingEnergyLabelSearchOverview.sourceKey,
					sourceField: $scope.MappingEnergyLabelSearchOverview.sourceField,
					sourceList: $scope.MappingEnergyLabelSearchOverview.sourceList,
					energyLabelList: $scope.MappingEnergyLabelSearchOverview.energyLabelList,

					sort: [
						$scope.MappingEnergyLabelSearchOverview.predicate + ',' + ($scope.MappingEnergyLabelSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingEnergyLabelList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.energyLabelList = result.energyLabelSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingEnergyLabelSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingEnergyLabelSearchOverview, $scope.originalMappingEnergyLabelSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingEnergyLabelSearchOverview = {};
			$rootScope.MappingEnergyLabelSearchOverview.page = 1;
			$rootScope.MappingEnergyLabelSearchOverview.predicate = 'id';
			$rootScope.MappingEnergyLabelSearchOverview.ascending = false;

			$scope.MappingEnergyLabelSearchOverview = angular.copy($rootScope.MappingEnergyLabelSearchOverview);

			$scope.originalMappingEnergyLabelSearchOverview = angular.copy($scope.MappingEnergyLabelSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingEnergyLabelSearchOverview) || $rootScope.MappingEnergyLabelSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingEnergyLabelSearchOverview = angular.copy($rootScope.MappingEnergyLabelSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingEnergyLabelId = id;
			$('#deleteMappingEnergyLabelConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingEnergyLabelViewDtoFactory.delete(
				{ id: $scope.selectedMappingEnergyLabelId },
				function() {
					$scope.search();
					$('#deleteMappingEnergyLabelConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteMappingEnergyLabelConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingEnergyLabelConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingEnergyLabelList, function(value, key) {
				promises.push(StockMgmtMappingEnergyLabelViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingEnergyLabelConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingEnergyLabelList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingEnergyLabelList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingEnergyLabelList = [];

			angular.forEach($scope.mappingEnergyLabelList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingEnergyLabelList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingEnergyLabelUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingEnergyLabelSearchOverview.predicate === itemName) {
				if ($scope.MappingEnergyLabelSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};
	});
