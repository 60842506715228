/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtUserCarStockAccessBoundCarBrandListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		UserCarStockAccessBoundCarBrandFactory,
		StockMgmtUserCarStockAccessBoundCarBrandListDtoSearchFactory
	) {
		$scope.userCarStockAccessBoundCarBrandList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeUserCarStockAccessBoundCarBrand)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeUserCarStockAccessBoundCarBrand);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeUserCarStockAccessBoundCarBrand = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-usercarstockaccessboundcarbrand.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.UserCarStockAccessBoundCarBrandSearchOverview.tenant)) {
				return $scope.UserCarStockAccessBoundCarBrandSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.UserCarStockAccessBoundCarBrandSearchOverview = angular.copy($scope.UserCarStockAccessBoundCarBrandSearchOverview);

			$rootScope.pageSizeUserCarStockAccessBoundCarBrand = angular.copy($scope.pageSize);

			StockMgmtUserCarStockAccessBoundCarBrandListDtoSearchFactory.query(
				{
					page: $scope.UserCarStockAccessBoundCarBrandSearchOverview.page - 1,
					size: $scope.pageSize,
					tenantId: getTenantId(),
					userCarStockAccessBoundIdList: $scope.UserCarStockAccessBoundCarBrandSearchOverview.userCarStockAccessBoundIdList,
					carBrandIdList: $scope.UserCarStockAccessBoundCarBrandSearchOverview.carBrandIdList,

					sort: [
						$scope.UserCarStockAccessBoundCarBrandSearchOverview.predicate +
							',' +
							($scope.UserCarStockAccessBoundCarBrandSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.userCarStockAccessBoundCarBrandList = result.items;

					$scope.userCarStockAccessBoundReferenceFilterList = result.userCarStockAccessBoundSearchOptions;

					$scope.carBrandReferenceFilterList = result.carBrandSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isUserCarStockAccessBoundCarBrandSearchOverviewDirty = function() {
			return !angular.equals(
				$scope.UserCarStockAccessBoundCarBrandSearchOverview,
				$scope.originalUserCarStockAccessBoundCarBrandSearchOverview
			);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.UserCarStockAccessBoundCarBrandSearchOverview = {};
			$rootScope.UserCarStockAccessBoundCarBrandSearchOverview.page = 1;
			$rootScope.UserCarStockAccessBoundCarBrandSearchOverview.predicate = 'id';
			$rootScope.UserCarStockAccessBoundCarBrandSearchOverview.ascending = false;

			$scope.UserCarStockAccessBoundCarBrandSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundCarBrandSearchOverview);

			$scope.originalUserCarStockAccessBoundCarBrandSearchOverview = angular.copy($scope.UserCarStockAccessBoundCarBrandSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.UserCarStockAccessBoundCarBrandSearchOverview) ||
				$rootScope.UserCarStockAccessBoundCarBrandSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.UserCarStockAccessBoundCarBrandSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundCarBrandSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.UserCarStockAccessBoundCarBrandSearchOverview.predicate === itemName) {
				if ($scope.UserCarStockAccessBoundCarBrandSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateUserCarStockAccessBoundUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
