/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerContentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerContentFactory,
		StockMgmtDealerContentListDtoSearchFactory,
		StockMgmtDealerContentViewDtoFactory
	) {
		$scope.dealerContentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealerContent)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealerContent);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealerContent = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-dealercontent.new');
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerContentSearchOverview.dealer)) {
				return $scope.DealerContentSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerContentSearchOverview.tenant)) {
				return $scope.DealerContentSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DealerContentSearchOverview = angular.copy($scope.DealerContentSearchOverview);

			$rootScope.pageSizeDealerContent = angular.copy($scope.pageSize);

			StockMgmtDealerContentListDtoSearchFactory.query(
				{
					page: $scope.DealerContentSearchOverview.page - 1,
					size: $scope.pageSize,
					title: $scope.DealerContentSearchOverview.title,
					content: $scope.DealerContentSearchOverview.content,
					contentTypeList: $scope.DealerContentSearchOverview.contentTypeList,
					dealerId: getDealerId(),
					tenantId: getTenantId(),

					sort: [$scope.DealerContentSearchOverview.predicate + ',' + ($scope.DealerContentSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerContentList = result.items;

					$scope.dealerContentTypeList = result.contentTypeSearchOptions;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDealerContentSearchOverviewDirty = function() {
			return !angular.equals($scope.DealerContentSearchOverview, $scope.originalDealerContentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DealerContentSearchOverview = {};
			$rootScope.DealerContentSearchOverview.page = 1;
			$rootScope.DealerContentSearchOverview.predicate = 'id';
			$rootScope.DealerContentSearchOverview.ascending = false;

			$scope.DealerContentSearchOverview = angular.copy($rootScope.DealerContentSearchOverview);

			$scope.originalDealerContentSearchOverview = angular.copy($scope.DealerContentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DealerContentSearchOverview) || $rootScope.DealerContentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DealerContentSearchOverview = angular.copy($rootScope.DealerContentSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDealerContentId = id;
			$('#deleteDealerContentConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtDealerContentViewDtoFactory.delete(
				{ id: $scope.selectedDealerContentId },
				function() {
					$scope.search();
					$('#deleteDealerContentConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteDealerContentConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DealerContentSearchOverview.predicate === itemName) {
				if ($scope.DealerContentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateContentTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
