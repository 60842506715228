/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtGroupListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		GroupFactory,
		RelationMgmtGroupListDtoSearchFactory,
		RelationMgmtGroupViewDtoFactory
	) {
		$scope.groupList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeGroup)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeGroup);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeGroup = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-group.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.GroupSearchOverview.tenant)) {
				return $scope.GroupSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.GroupSearchOverview = angular.copy($scope.GroupSearchOverview);

			$rootScope.pageSizeGroup = angular.copy($scope.pageSize);

			if ($rootScope.isDefinedAndNotNull($scope.GroupSearchOverview.categories)) {
				$scope.GroupSearchOverview.predicate = 'categories';
			}

			RelationMgmtGroupListDtoSearchFactory.query(
				{
					page: $scope.GroupSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.GroupSearchOverview.name,
					categories: $scope.GroupSearchOverview.categories,
					tenantId: getTenantId(),
					dealerIdList: $scope.GroupSearchOverview.dealerIdList,

					sort: [$scope.GroupSearchOverview.predicate + ',' + ($scope.GroupSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.groupList = result.items;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isGroupSearchOverviewDirty = function() {
			return !angular.equals($scope.GroupSearchOverview, $scope.originalGroupSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.GroupSearchOverview = {};
			$rootScope.GroupSearchOverview.page = 1;
			$rootScope.GroupSearchOverview.predicate = 'id';
			$rootScope.GroupSearchOverview.ascending = false;

			$scope.GroupSearchOverview = angular.copy($rootScope.GroupSearchOverview);

			$scope.originalGroupSearchOverview = angular.copy($scope.GroupSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.GroupSearchOverview) || $rootScope.GroupSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.GroupSearchOverview = angular.copy($rootScope.GroupSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedGroupId = id;
			$('#deleteGroupConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtGroupViewDtoFactory.delete(
				{ id: $scope.selectedGroupId },
				function() {
					$scope.search();
					$('#deleteGroupConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteGroupConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.GroupSearchOverview.predicate === itemName) {
				if ($scope.GroupSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateDealerUISelect = function(item, model) {
			$scope.search();
		};
	});
