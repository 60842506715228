/** @format */

'use strict';

// ApplicationTimingLog has detail layout enabled. Detail layout template url: applicationtiminglog-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ApplicationTimingLog'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('applicationtiminglog'); // main translation loaded for applicationtiminglog
		$translatePartialLoader.addPart('tabpage'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('admin-applicationtiminglog', {
		/* abstract state for DTO AdminApplicationTimingLogListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/admin/applicationtiminglog',
		data: {
			authorities: ['ROLE_ADMIN'],
			pageTitle: 'ApplicationTimingLog',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO admin */
	var dtoListState = {
		/* list state for DTO AdminApplicationTimingLogListDto */
		/* to access: ui-ref="admin-applicationtiminglog.list" */
		/* url: http://<hostname>/admin/applicationtiminglog/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/applicationtiminglog/applicationtiminglog-list-admin.html',
				controller: 'AdminApplicationTimingLogListDtoController',
			},
		},
	};

	$stateProvider.state('admin-applicationtiminglog.list', dtoListState);

	/* detail state for DTO admin */
	var dtoDetailState = {
		/* detail state for DTO AdminApplicationTimingLogViewDto */
		/* to access: ui-sref="admin-applicationtiminglog.detail" */
		/* url: http://<hostname>/admin/applicationtiminglog/detail/:applicationTimingLogId */
		url: '/detail/:applicationTimingLogId',
		views: {
			'content@': {
				templateUrl: 'app/entities/applicationtiminglog/applicationtiminglog-view-admin.html',
				controller: 'ApplicationTimingLogDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'AdminApplicationTimingLogViewDtoFactory',
				function($stateParams, AdminApplicationTimingLogViewDtoFactory) {
					return AdminApplicationTimingLogViewDtoFactory.get({ id: $stateParams.applicationTimingLogId });
				},
			],
		},
	};

	$stateProvider.state('admin-applicationtiminglog.detail', dtoDetailState);
});
