/** @format */

'use strict';

// CarStockDiscount has edit layout enabled. Edit layout template url: carstockdiscount-dialog.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarStockDiscount'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carstockdiscount'); // main translation loaded for carstockdiscount
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-carstockdiscount', {
		/* abstract state for DTO StockMgmtCarStockDiscountListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/carstockdiscount',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarStockDiscount',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCarStockDiscountListDto */
		/* to access: ui-ref="stock-mgmt-carstockdiscount.list" */
		/* url: http://<hostname>/stockmgmt/carstockdiscount/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstockdiscount/carstockdiscount-list-stock-mgmt.html',
				controller: 'StockMgmtCarStockDiscountListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-carstockdiscount.list', dtoListState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCarStockDiscountEditDto */
		/* to access: ui-sref="stock-mgmt-carstockdiscount.edit" */
		/* url: http://<hostname>/stockmgmt/carstockdiscount/edit/:carStockDiscountId */
		url: '/edit/:carStockDiscountId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstockdiscount/carstockdiscount-edit-stock-mgmt.html',
				controller: 'StockMgmtCarStockDiscountEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarStockDiscountEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarStockDiscountEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarStockDiscountEditDtoFactory.get({ id: $stateParams.carStockDiscountId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carstockdiscount.edit', dtoEditState);
});
