/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarOptionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarOptionFactory,
		CarConfigCarOptionListDtoSearchFactory,
		CarConfigCarOptionViewDtoFactory
	) {
		$scope.carOptionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarOption)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarOption);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarOption = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-caroption.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOptionSearchOverview.equivalent)) {
				return $scope.CarOptionSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean
		// handle attribute optionPackage of javaType boolean
		// handle attribute orderMoreThanOneAllowed of javaType boolean
		// handle attribute hidden of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarOptionSearchOverview = angular.copy($scope.CarOptionSearchOverview);

			$rootScope.pageSizeCarOption = angular.copy($scope.pageSize);

			CarConfigCarOptionListDtoSearchFactory.query(
				{
					page: $scope.CarOptionSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarOptionSearchOverview.name,
					productCode: $scope.CarOptionSearchOverview.productCode,
					factoryCode: $scope.CarOptionSearchOverview.factoryCode,
					description: $scope.CarOptionSearchOverview.description,
					canBeOrdered: $scope.CarOptionSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarOptionSearchOverview.spotlightGroup,
					creator: $scope.CarOptionSearchOverview.creator,
					updater: $scope.CarOptionSearchOverview.updater,
					optionPackage: $scope.CarOptionSearchOverview.optionPackageGroup,
					orderMoreThanOneAllowed: $scope.CarOptionSearchOverview.orderMoreThanOneAllowedGroup,
					hidden: $scope.CarOptionSearchOverview.hiddenGroup,
					statusList: $scope.CarOptionSearchOverview.statusList,
					typeList: $scope.CarOptionSearchOverview.typeList,
					imageSize: $scope.CarOptionSearchOverview.imageSize,
					vatType: $scope.CarOptionSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarOptionSearchOverview.priceTypeList,
					brandIdList: $scope.CarOptionSearchOverview.brandIdList,
					countryList: $scope.CarOptionSearchOverview.countryList,
					locale: $scope.CarOptionSearchOverview.locale,
					optionOriginList: $scope.CarOptionSearchOverview.optionOriginList,
					optionTypeList: $scope.CarOptionSearchOverview.optionTypeList,
					optionCategoryIdList: $scope.CarOptionSearchOverview.optionCategoryIdList,
					vehicleOptionCategoryIdList: $scope.CarOptionSearchOverview.vehicleOptionCategoryIdList,

					sort: [$scope.CarOptionSearchOverview.predicate + ',' + ($scope.CarOptionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carOptionList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.optionOriginList = result.optionOriginSearchOptions;

					$scope.optionTypeList = result.optionTypeSearchOptions;

					$scope.optionCategoryReferenceFilterList = result.optionCategorySearchOptions;

					$scope.vehicleOptionCategoryReferenceFilterList = result.vehicleOptionCategorySearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarOptionSearchOverviewDirty = function() {
			return !angular.equals($scope.CarOptionSearchOverview, $scope.originalCarOptionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarOptionSearchOverview = {};
			$rootScope.CarOptionSearchOverview.page = 1;
			$rootScope.CarOptionSearchOverview.predicate = 'id';
			$rootScope.CarOptionSearchOverview.ascending = false;

			$scope.CarOptionSearchOverview = angular.copy($rootScope.CarOptionSearchOverview);

			$scope.originalCarOptionSearchOverview = angular.copy($scope.CarOptionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarOptionSearchOverview) || $rootScope.CarOptionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarOptionSearchOverview = angular.copy($rootScope.CarOptionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarOptionId = id;
			$('#deleteCarOptionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarOptionViewDtoFactory.delete(
				{ id: $scope.selectedCarOptionId },
				function() {
					$scope.search();
					$('#deleteCarOptionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarOptionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carOptionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarOptionSearchOverview.predicate === itemName) {
				if ($scope.CarOptionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateOptionOriginUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateOptionTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateOptionCategoryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateVehicleOptionCategoryUISelect = function(item, model) {
			$scope.search();
		};
	});
