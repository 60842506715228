/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigRdwColorListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RdwColorFactory,
		CarConfigRdwColorListDtoSearchFactory,
		CarConfigRdwColorViewDtoFactory
	) {
		$scope.rdwColorList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRdwColor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRdwColor);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRdwColor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-rdwcolor.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getRdwColorList = function() {
			CarConfigRdwColorListDtoSearchFactory.query(
				{
					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.rdwColorList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getRdwColorList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRdwColorId = id;
			$('#deleteRdwColorConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigRdwColorViewDtoFactory.delete(
				{ id: $scope.selectedRdwColorId },
				function() {
					$scope.loadAll();
					$('#deleteRdwColorConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteRdwColorConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
