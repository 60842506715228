/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('AdminUserListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		UserFactory,
		AdminUserListDtoSearchFactory,
		AdminUserViewDtoFactory
	) {
		$scope.userList = [];
		$scope.selectedUserList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeUser)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeUser);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeUser = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('user-management.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.UserSearchOverview.tenant)) {
				return $scope.UserSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.UserSearchOverview = angular.copy($scope.UserSearchOverview);

			$rootScope.pageSizeUser = angular.copy($scope.pageSize);

			AdminUserListDtoSearchFactory.query(
				{
					page: $scope.UserSearchOverview.page - 1,
					size: $scope.pageSize,
					login: $scope.UserSearchOverview.login,
					firstName: $scope.UserSearchOverview.firstName,
					lastName: $scope.UserSearchOverview.lastName,
					email: $scope.UserSearchOverview.email,
					langKey: $scope.UserSearchOverview.langKey,
					tenantId: getTenantId(),
					localeList: $scope.UserSearchOverview.localeList,

					sort: [$scope.UserSearchOverview.predicate + ',' + ($scope.UserSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.userList = result.items;

					$scope.tenantReferenceFilterList = result.tenantSearchOptions;

					$scope.supportedLocaleList = result.localeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isUserSearchOverviewDirty = function() {
			return !angular.equals($scope.UserSearchOverview, $scope.originalUserSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.UserSearchOverview = {};
			$rootScope.UserSearchOverview.page = 1;
			$rootScope.UserSearchOverview.predicate = 'id';
			$rootScope.UserSearchOverview.ascending = false;

			$scope.UserSearchOverview = angular.copy($rootScope.UserSearchOverview);

			$scope.originalUserSearchOverview = angular.copy($scope.UserSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.UserSearchOverview) || $rootScope.UserSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.UserSearchOverview = angular.copy($rootScope.UserSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedUserId = id;
			$('#deleteUserConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			AdminUserViewDtoFactory.delete(
				{ id: $scope.selectedUserId },
				function() {
					$scope.search();
					$('#deleteUserConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteUserConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleUserConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedUserList, function(value, key) {
				promises.push(AdminUserViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleUserConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.userList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedUserList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedUserList = [];

			angular.forEach($scope.userList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedUserList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.UserSearchOverview.predicate === itemName) {
				if ($scope.UserSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateLocaleUISelect = function(item, model) {
			$scope.search();
		};
	});
