/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtPhoneListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		PhoneFactory,
		RelationMgmtPhoneListDtoSearchFactory,
		RelationMgmtPhoneViewDtoFactory
	) {
		$scope.phoneList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizePhone)) {
			$scope.pageSize = angular.copy($rootScope.pageSizePhone);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizePhone = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-phone.new');
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.PhoneSearchOverview.relation)) {
				return $scope.PhoneSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryPhoneOfId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.PhoneSearchOverview.primaryPhoneOf)) {
				return $scope.PhoneSearchOverview.primaryPhoneOf.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.PhoneSearchOverview.tenant)) {
				return $scope.PhoneSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.PhoneSearchOverview = angular.copy($scope.PhoneSearchOverview);

			$rootScope.pageSizePhone = angular.copy($scope.pageSize);

			RelationMgmtPhoneListDtoSearchFactory.query(
				{
					page: $scope.PhoneSearchOverview.page - 1,
					size: $scope.pageSize,
					number: $scope.PhoneSearchOverview.number,
					phoneTypeList: $scope.PhoneSearchOverview.phoneTypeList,
					relationId: getRelationId(),
					primaryPhoneOfId: getPrimaryPhoneOfId(),
					tenantId: getTenantId(),

					sort: [$scope.PhoneSearchOverview.predicate + ',' + ($scope.PhoneSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.phoneList = result.items;

					$scope.phoneTypeList = result.phoneTypeSearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isPhoneSearchOverviewDirty = function() {
			return !angular.equals($scope.PhoneSearchOverview, $scope.originalPhoneSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.PhoneSearchOverview = {};
			$rootScope.PhoneSearchOverview.page = 1;
			$rootScope.PhoneSearchOverview.predicate = 'id';
			$rootScope.PhoneSearchOverview.ascending = false;

			$scope.PhoneSearchOverview = angular.copy($rootScope.PhoneSearchOverview);

			$scope.originalPhoneSearchOverview = angular.copy($scope.PhoneSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.PhoneSearchOverview) || $rootScope.PhoneSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.PhoneSearchOverview = angular.copy($rootScope.PhoneSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedPhoneId = id;
			$('#deletePhoneConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtPhoneViewDtoFactory.delete(
				{ id: $scope.selectedPhoneId },
				function() {
					$scope.search();
					$('#deletePhoneConfirmation').modal('hide');
				},
				function(error) {
					$('#deletePhoneConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.PhoneSearchOverview.predicate === itemName) {
				if ($scope.PhoneSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updatePhoneTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
