/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('OrderLineGroupFactory', function($resource) {
	return $resource(
		'api/orderLineGroups/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtOrderLineGroupViewDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineGroupViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinegroup/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtOrderLineGroupListDto's
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineGroupListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinegroup',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtOrderLineGroupEditDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineGroupEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinegroup/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtOrderLineGroupEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineGroupNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinegroup/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewOrderLineGroupFactory', function($resource) {
	return $resource(
		'api/orderLineGroups/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all OrderLineGroups without the use of paging
angular.module('mmmApp').factory('NoPagingOrderLineGroupFactory', function($resource) {
	return $resource(
		'api/orderLineGroups/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('OrderLinesByOrderLineGroupFactory', function($resource) {
	return $resource(
		'api/orderLines/orderLineGroup/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllOrderLineGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/orderLineGroups/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteOrderLineGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/orderLineGroups/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/orderlinegroups',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
