/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCategoryListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CategoryFactory,
		CarConfigCategoryListDtoSearchFactory,
		CarConfigCategoryViewDtoFactory
	) {
		$scope.categoryList = [];
		$scope.selectedCategoryList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCategory)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCategory);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCategory = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-category.new');
		};

		var getParentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CategorySearchOverview.parent)) {
				return $scope.CategorySearchOverview.parent.id;
			} else {
				return undefined;
			}
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CategorySearchOverview.brand)) {
				return $scope.CategorySearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		// handle attribute replaces of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CategorySearchOverview = angular.copy($scope.CategorySearchOverview);

			$rootScope.pageSizeCategory = angular.copy($scope.pageSize);

			CarConfigCategoryListDtoSearchFactory.query(
				{
					page: $scope.CategorySearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CategorySearchOverview.name,
					description: $scope.CategorySearchOverview.description,
					replaces: $scope.CategorySearchOverview.replacesGroup,
					parentId: getParentId(),
					brandId: getBrandId(),
					locale: $scope.CategorySearchOverview.locale,

					sort: [$scope.CategorySearchOverview.predicate + ',' + ($scope.CategorySearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.categoryList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCategorySearchOverviewDirty = function() {
			return !angular.equals($scope.CategorySearchOverview, $scope.originalCategorySearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CategorySearchOverview = {};
			$rootScope.CategorySearchOverview.page = 1;
			$rootScope.CategorySearchOverview.predicate = 'id';
			$rootScope.CategorySearchOverview.ascending = false;

			$scope.CategorySearchOverview = angular.copy($rootScope.CategorySearchOverview);

			$scope.originalCategorySearchOverview = angular.copy($scope.CategorySearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CategorySearchOverview) || $rootScope.CategorySearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CategorySearchOverview = angular.copy($rootScope.CategorySearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCategoryId = id;
			$('#deleteCategoryConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCategoryViewDtoFactory.delete(
				{ id: $scope.selectedCategoryId },
				function() {
					$scope.search();
					$('#deleteCategoryConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCategoryConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleCategoryConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedCategoryList, function(value, key) {
				promises.push(CarConfigCategoryViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleCategoryConfirmation').modal('hide');
				});
		};
		$scope.multipleUpdate = function() {
			$state.go('car-config-category.bulkedit', { selectedCategoryList: $scope.selectedCategoryList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.categoryList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedCategoryList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedCategoryList = [];

			angular.forEach($scope.categoryList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedCategoryList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:categoryUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CategorySearchOverview.predicate === itemName) {
				if ($scope.CategorySearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
