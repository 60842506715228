/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('ArticleManagementCarConfigurationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationFactory,
		ArticleManagementCarConfigurationListDtoSearchFactory
	) {
		$scope.carConfigurationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfiguration)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfiguration);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfiguration = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('article-management-carconfiguration.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchOverview.equivalent)) {
				return $scope.CarConfigurationSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		var getCarModelVersionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchOverview.carModelVersion)) {
				return $scope.CarConfigurationSearchOverview.carModelVersion.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarConfigurationSearchOverview = angular.copy($scope.CarConfigurationSearchOverview);

			$rootScope.pageSizeCarConfiguration = angular.copy($scope.pageSize);

			ArticleManagementCarConfigurationListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarConfigurationSearchOverview.name,
					productCode: $scope.CarConfigurationSearchOverview.productCode,
					factoryCode: $scope.CarConfigurationSearchOverview.factoryCode,
					description: $scope.CarConfigurationSearchOverview.description,
					canBeOrdered: $scope.CarConfigurationSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarConfigurationSearchOverview.spotlightGroup,
					creator: $scope.CarConfigurationSearchOverview.creator,
					updater: $scope.CarConfigurationSearchOverview.updater,
					weightEmptyVehicleFrom: $scope.CarConfigurationSearchOverview.weightEmptyVehicleFrom,
					weightEmptyVehicleTo: $scope.CarConfigurationSearchOverview.weightEmptyVehicleTo,
					accelerationFrom: $scope.CarConfigurationSearchOverview.accelerationFrom,
					accelerationTo: $scope.CarConfigurationSearchOverview.accelerationTo,
					fuelConsumptionFrom: $scope.CarConfigurationSearchOverview.fuelConsumptionFrom,
					fuelConsumptionTo: $scope.CarConfigurationSearchOverview.fuelConsumptionTo,
					batteryConsumptionFrom: $scope.CarConfigurationSearchOverview.batteryConsumptionFrom,
					batteryConsumptionTo: $scope.CarConfigurationSearchOverview.batteryConsumptionTo,
					fuelConsumptionCityFrom: $scope.CarConfigurationSearchOverview.fuelConsumptionCityFrom,
					fuelConsumptionCityTo: $scope.CarConfigurationSearchOverview.fuelConsumptionCityTo,
					fuelConsumptionHighWayFrom: $scope.CarConfigurationSearchOverview.fuelConsumptionHighWayFrom,
					fuelConsumptionHighWayTo: $scope.CarConfigurationSearchOverview.fuelConsumptionHighWayTo,
					weightReadyForUseFrom: $scope.CarConfigurationSearchOverview.weightReadyForUseFrom,
					weightReadyForUseTo: $scope.CarConfigurationSearchOverview.weightReadyForUseTo,
					trailerWeightBrakesFrom: $scope.CarConfigurationSearchOverview.trailerWeightBrakesFrom,
					trailerWeightBrakesTo: $scope.CarConfigurationSearchOverview.trailerWeightBrakesTo,
					unbrakedTrailerWeightFrom: $scope.CarConfigurationSearchOverview.unbrakedTrailerWeightFrom,
					unbrakedTrailerWeightTo: $scope.CarConfigurationSearchOverview.unbrakedTrailerWeightTo,
					maxCargoCapacityFrom: $scope.CarConfigurationSearchOverview.maxCargoCapacityFrom,
					maxCargoCapacityTo: $scope.CarConfigurationSearchOverview.maxCargoCapacityTo,
					tankCapacityFrom: $scope.CarConfigurationSearchOverview.tankCapacityFrom,
					tankCapacityTo: $scope.CarConfigurationSearchOverview.tankCapacityTo,
					loadCapacityFrom: $scope.CarConfigurationSearchOverview.loadCapacityFrom,
					loadCapacityTo: $scope.CarConfigurationSearchOverview.loadCapacityTo,
					maxWeightFrontAxleFrom: $scope.CarConfigurationSearchOverview.maxWeightFrontAxleFrom,
					maxWeightFrontAxleTo: $scope.CarConfigurationSearchOverview.maxWeightFrontAxleTo,
					maxWeightBackAxleFrom: $scope.CarConfigurationSearchOverview.maxWeightBackAxleFrom,
					maxWeightBackAxleTo: $scope.CarConfigurationSearchOverview.maxWeightBackAxleTo,
					cargoSpaceLengthFrom: $scope.CarConfigurationSearchOverview.cargoSpaceLengthFrom,
					cargoSpaceLengthTo: $scope.CarConfigurationSearchOverview.cargoSpaceLengthTo,
					cargoSpaceWidthFrom: $scope.CarConfigurationSearchOverview.cargoSpaceWidthFrom,
					cargoSpaceWidthTo: $scope.CarConfigurationSearchOverview.cargoSpaceWidthTo,
					cargoSpaceHeightFrom: $scope.CarConfigurationSearchOverview.cargoSpaceHeightFrom,
					cargoSpaceHeightTo: $scope.CarConfigurationSearchOverview.cargoSpaceHeightTo,
					cargoSpaceWidthOfWheelFrom: $scope.CarConfigurationSearchOverview.cargoSpaceWidthOfWheelFrom,
					cargoSpaceWidthOfWheelTo: $scope.CarConfigurationSearchOverview.cargoSpaceWidthOfWheelTo,
					cargoSpaceLengthFloorFrom: $scope.CarConfigurationSearchOverview.cargoSpaceLengthFloorFrom,
					cargoSpaceLengthFloorTo: $scope.CarConfigurationSearchOverview.cargoSpaceLengthFloorTo,
					turningCircleFrom: $scope.CarConfigurationSearchOverview.turningCircleFrom,
					turningCircleTo: $scope.CarConfigurationSearchOverview.turningCircleTo,
					statusList: $scope.CarConfigurationSearchOverview.statusList,
					typeList: $scope.CarConfigurationSearchOverview.typeList,
					imageSize: $scope.CarConfigurationSearchOverview.imageSize,
					vatType: $scope.CarConfigurationSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarConfigurationSearchOverview.priceTypeList,
					brandIdList: $scope.CarConfigurationSearchOverview.brandIdList,
					country: $scope.CarConfigurationSearchOverview.country,
					locale: $scope.CarConfigurationSearchOverview.locale,
					vehicleTypeList: $scope.CarConfigurationSearchOverview.vehicleTypeList,
					carModelVersionId: getCarModelVersionId(),
					carBodytypeIdList: $scope.CarConfigurationSearchOverview.carBodytypeIdList,
					carEngineIdList: $scope.CarConfigurationSearchOverview.carEngineIdList,
					carGradeIdList: $scope.CarConfigurationSearchOverview.carGradeIdList,
					carTransmissionIdList: $scope.CarConfigurationSearchOverview.carTransmissionIdList,
					carPowertrainIdList: $scope.CarConfigurationSearchOverview.carPowertrainIdList,
					energyLabelList: $scope.CarConfigurationSearchOverview.energyLabelList,
					numberOfDoorsList: $scope.CarConfigurationSearchOverview.numberOfDoorsList,
					numberOfSeatsList: $scope.CarConfigurationSearchOverview.numberOfSeatsList,
					emissionClassList: $scope.CarConfigurationSearchOverview.emissionClassList,

					sort: [
						$scope.CarConfigurationSearchOverview.predicate + ',' + ($scope.CarConfigurationSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.vehicleTypeList = result.vehicleTypeSearchOptions;

					$scope.carModelVersionReferenceFilterList = result.carModelVersionSearchOptions;

					$scope.carBodytypeReferenceFilterList = result.carBodytypeSearchOptions;

					$scope.carEngineReferenceFilterList = result.carEngineSearchOptions;

					$scope.carGradeReferenceFilterList = result.carGradeSearchOptions;

					$scope.carTransmissionReferenceFilterList = result.carTransmissionSearchOptions;

					$scope.carPowertrainReferenceFilterList = result.carPowertrainSearchOptions;

					$scope.energyLabelList = result.energyLabelSearchOptions;

					$scope.numberOfDoorsList = result.numberOfDoorsSearchOptions;

					$scope.numberOfSeatsList = result.numberOfSeatsSearchOptions;

					$scope.emissionClassList = result.emissionClassSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationSearchOverviewDirty = function() {
			return !angular.equals($scope.CarConfigurationSearchOverview, $scope.originalCarConfigurationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationSearchOverview = {};
			$rootScope.CarConfigurationSearchOverview.page = 1;
			$rootScope.CarConfigurationSearchOverview.predicate = 'id';
			$rootScope.CarConfigurationSearchOverview.ascending = false;

			$scope.CarConfigurationSearchOverview = angular.copy($rootScope.CarConfigurationSearchOverview);

			$scope.originalCarConfigurationSearchOverview = angular.copy($scope.CarConfigurationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarConfigurationSearchOverview) || $rootScope.CarConfigurationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationSearchOverview = angular.copy($rootScope.CarConfigurationSearchOverview);

			$scope.search();
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carConfigurationUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationSearchOverview.predicate === itemName) {
				if ($scope.CarConfigurationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateVehicleTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarBodytypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarEngineUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarGradeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarTransmissionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarPowertrainUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateNumberOfDoorsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateNumberOfSeatsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEmissionClassUISelect = function(item, model) {
			$scope.search();
		};
	});
