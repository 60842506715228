/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('SubTaskTemplateFactory', function($resource) {
	return $resource(
		'api/subTaskTemplates/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtSubTaskTemplateViewDto
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskTemplateViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtasktemplate/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtSubTaskTemplateListDto's
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskTemplateListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtasktemplate',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtSubTaskTemplateEditDto
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskTemplateEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtasktemplate/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtSubTaskTemplateEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskTemplateNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtasktemplate/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewSubTaskTemplateFactory', function($resource) {
	return $resource(
		'api/subTaskTemplates/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all SubTaskTemplates without the use of paging
angular.module('mmmApp').factory('NoPagingSubTaskTemplateFactory', function($resource) {
	return $resource(
		'api/subTaskTemplates/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllSubTaskTemplatesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/subTaskTemplates/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
