/** @format */

'use strict';

// BaseTaskTemplate has edit layout enabled. Edit layout template url: basetasktemplate-dialog.html
// BaseTaskTemplate has detail layout enabled. Detail layout template url: basetasktemplate-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'BaseTaskTemplate'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('basetasktemplate'); // main translation loaded for basetasktemplate
		$translatePartialLoader.addPart('group'); // one to many reference from main entity
		$translatePartialLoader.addPart('person'); // main translation loaded for parent object Person of reference assignedEmployee
		$translatePartialLoader.addPart('relation'); // main translation loaded for parent object Relation of reference assignedEmployee
		$translatePartialLoader.addPart('employee'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-basetasktemplate', {
		/* abstract state for DTO RelationMgmtBaseTaskTemplateListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/basetasktemplate',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'BaseTaskTemplate',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtBaseTaskTemplateListDto */
		/* to access: ui-ref="relation-mgmt-basetasktemplate.list" */
		/* url: http://<hostname>/relationmgmt/basetasktemplate/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/basetasktemplate/basetasktemplate-list-relation-mgmt.html',
				controller: 'RelationMgmtBaseTaskTemplateListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-basetasktemplate.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtBaseTaskTemplateViewDto */
		/* to access: ui-sref="relation-mgmt-basetasktemplate.detail" */
		/* url: http://<hostname>/relationmgmt/basetasktemplate/detail/:baseTaskTemplateId */
		url: '/detail/:baseTaskTemplateId',
		views: {
			'content@': {
				templateUrl: 'app/entities/basetasktemplate/basetasktemplate-view-relation-mgmt.html',
				controller: 'BaseTaskTemplateDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtBaseTaskTemplateViewDtoFactory',
				function($stateParams, RelationMgmtBaseTaskTemplateViewDtoFactory) {
					return RelationMgmtBaseTaskTemplateViewDtoFactory.get({ id: $stateParams.baseTaskTemplateId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-basetasktemplate.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtBaseTaskTemplateEditDto */
		/* to access: ui-sref="relation-mgmt-basetasktemplate.edit" */
		/* url: http://<hostname>/relationmgmt/basetasktemplate/edit/:baseTaskTemplateId */
		url: '/edit/:baseTaskTemplateId',
		views: {
			'content@': {
				templateUrl: 'app/entities/basetasktemplate/basetasktemplate-edit-relation-mgmt.html',
				controller: 'RelationMgmtBaseTaskTemplateEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtBaseTaskTemplateEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtBaseTaskTemplateEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtBaseTaskTemplateEditDtoFactory.get({ id: $stateParams.baseTaskTemplateId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-basetasktemplate.edit', dtoEditState);
});
