/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('EquivalentFactory', function($resource) {
	return $resource(
		'api/equivalents/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigEquivalentViewDto
 */
angular.module('mmmApp').factory('CarConfigEquivalentViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/equivalent/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigEquivalentListDto's
 */
angular.module('mmmApp').factory('CarConfigEquivalentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/equivalent',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigEquivalentEditDto
 */
angular.module('mmmApp').factory('CarConfigEquivalentEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/equivalent/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigEquivalentEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigEquivalentNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/equivalent/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewEquivalentFactory', function($resource) {
	return $resource(
		'api/equivalents/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Equivalents without the use of paging
angular.module('mmmApp').factory('NoPagingEquivalentFactory', function($resource) {
	return $resource(
		'api/equivalents/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticlesByEquivalentFactory', function($resource) {
	return $resource(
		'api/articles/equivalent/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllEquivalentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/equivalents/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
