/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RdwColorFactory', function($resource) {
	return $resource(
		'api/rdwColors/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigRdwColorViewDto
 */
angular.module('mmmApp').factory('CarConfigRdwColorViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/rdwcolor/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigRdwColorListDto's
 */
angular.module('mmmApp').factory('CarConfigRdwColorListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/rdwcolor',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigRdwColorEditDto
 */
angular.module('mmmApp').factory('CarConfigRdwColorEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/rdwcolor/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigRdwColorEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigRdwColorNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/rdwcolor/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRdwColorFactory', function($resource) {
	return $resource(
		'api/rdwColors/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RdwColors without the use of paging
angular.module('mmmApp').factory('NoPagingRdwColorFactory', function($resource) {
	return $resource(
		'api/rdwColors/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ColorsByRdwColorFactory', function($resource) {
	return $resource(
		'api/colors/rdwColor/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRdwColorsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/rdwColors/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
