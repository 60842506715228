'use strict';

// CarGradeDependentPrice has edit layout enabled. Edit layout template url: cargradedependentprice-dialog.html
// CarGradeDependentPrice has detail layout enabled. Detail layout template url: cargradedependentprice-detail.html
angular.module('mmmApp').config(
		function($stateProvider) {

			/**
			 * Add all translations for a 'CarGradeDependentPrice'
			 */
			var getTranslations = function($translate, $translatePartialLoader) {
				$translatePartialLoader.addPart('cargradedependentprice'); // main translation loaded for cargradedependentprice
				$translatePartialLoader.addPart('article'); // one to many reference from main entity
				$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
				$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
				$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
				$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carGrade
				$translatePartialLoader.addPart('cargrade'); // one to many reference from main entity

				return $translate.refresh();
			};

			$stateProvider.state('car-config-cargradedependentprice', {
				/* abstract state for DTO CarConfigCarGradeDependentPriceListDto */
				/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
				abstract : true,
				parent : 'entity',
				url : '/car/config/cargradedependentprice',
				data : {
					authorities : [ 'ROLE_CARCONFIG', 'ROLE_ADMIN' ],
					pageTitle : 'CarGradeDependentPrice',
					isModal : false
				},
				params : {
					mappedBy : null,
					mappedByType : null,
					mappedById : null,
					dataModel : null, // this data model is set from the new relation state OR from the save and new logic
					mappedByDisplayValue : null,
					dataModelType : null, // this data model type is the name of the modelobject, lowercase

					hiddenParam : 'YES' // hides params from url
				},
				resolve : {
					translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						return getTranslations($translate, $translatePartialLoader);
					} ]
				}
			});

			/* list state for DTO car.config */
			var dtoListState = {
				/* list state for DTO CarConfigCarGradeDependentPriceListDto */
				/* to access: ui-ref="car-config-cargradedependentprice.list" */
				/* url: http://<hostname>/car/config/cargradedependentprice/list */
				url : '/list',
				views : {
					'content@' : {
						templateUrl : 'app/entities/cargradedependentprice/cargradedependentprice-list-car-config.html',
						controller : 'CarConfigCarGradeDependentPriceListDtoController'
					}
				}
			};

			$stateProvider.state('car-config-cargradedependentprice.list', dtoListState);

			/* detail state for DTO car.config */
			var dtoDetailState = {
				/* detail state for DTO CarConfigCarGradeDependentPriceViewDto */
				/* to access: ui-sref="car-config-cargradedependentprice.detail" */
				/* url: http://<hostname>/car/config/cargradedependentprice/detail/:carGradeDependentPriceId */
				url : '/detail/:carGradeDependentPriceId',
				views : {
					'content@' : {
						templateUrl : 'app/entities/cargradedependentprice/cargradedependentprice-view-car-config.html',
						controller : 'CarGradeDependentPriceDetailController'
					}
				},
				resolve : {
					entity : [ '$stateParams', 'CarConfigCarGradeDependentPriceViewDtoFactory',
							function($stateParams, CarConfigCarGradeDependentPriceViewDtoFactory) {
								return CarConfigCarGradeDependentPriceViewDtoFactory.get({
									id : $stateParams.carGradeDependentPriceId
								});
							} ]
				}
			};

			$stateProvider.state('car-config-cargradedependentprice.detail', dtoDetailState);

			/* edit state for DTO car.config */
			var dtoEditState = {
				/* edit state for DTO CarConfigCarGradeDependentPriceEditDto */
				/* to access: ui-sref="car-config-cargradedependentprice.edit" */
				/* url: http://<hostname>/car/config/cargradedependentprice/edit/:carGradeDependentPriceId */
				url : '/edit/:carGradeDependentPriceId',
				views : {
					'content@' : {
						templateUrl : 'app/entities/cargradedependentprice/cargradedependentprice-edit-car-config.html',
						controller : 'CarConfigCarGradeDependentPriceEditDtoController'
					}
				},
				resolve : {
					entity : [ '$stateParams', '$rootScope', 'CarConfigCarGradeDependentPriceEditDtoFactory',
							function($stateParams, $rootScope, CarConfigCarGradeDependentPriceEditDtoFactory) {
								// if dataModel is defined, we're coming back from a new created x..1 reference
								if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {

									var model = $stateParams.dataModel;

									// reset 'remembered' values to prevent future misbehaviour
									$rootScope.previousStateParams.dataModel = null;
									$stateParams.dataModel = null;

									// instead of returning a new model, we return the updated model
									return model;
								} else {
									return CarConfigCarGradeDependentPriceEditDtoFactory.get({
										id : $stateParams.carGradeDependentPriceId
									}).$promise.then(function(result) {
										return result;
									});
								}
							} ]
				}
			};

			$stateProvider.state('car-config-cargradedependentprice.edit', dtoEditState);

			/* new (create) state for DTO car.config */
			var dtoCreateState = {
				/* new state for DTO CarConfigCarGradeDependentPriceEditDto */
				/* to access: ui-sref="car-config-cargradedependentprice.new" */
				/* url: http://<hostname>/car/config/cargradedependentprice/new */
				url : '/new',
				views : {
					'content@' : {
						templateUrl : 'app/entities/cargradedependentprice/cargradedependentprice-edit-car-config.html',
						controller : 'CarConfigCarGradeDependentPriceEditDtoController'
					}
				},
				resolve : {
					entity : [ '$stateParams', '$rootScope', 'CarConfigCarGradeDependentPriceNewDtoFactory',
							function($stateParams, $rootScope, CarConfigCarGradeDependentPriceNewDtoFactory) {
								// if dataModel is defined, we're coming back from a new created x..1 reference
								if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {

									var model = $stateParams.dataModel;

									// reset 'remembered' values to prevent future misbehaviour
									$rootScope.previousStateParams.dataModel = null;
									$stateParams.dataModel = null;

									// instead of returning a new model, we return the updated model
									return model;
								} else {
									return CarConfigCarGradeDependentPriceNewDtoFactory.get({
										id : $stateParams.carGradeDependentPriceId
									}).$promise.then(function(result) {
										return result;
									});
								}
							} ]
				}
			};

			$stateProvider.state('car-config-cargradedependentprice.new', dtoCreateState);

		});
