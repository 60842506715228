'use strict';

angular.module('mmmApp').controller(
		'CarConfigCarGradeDependentPriceEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, CarConfigCarGradeDependentPriceEditDtoFactory,
				AlertService, AutocompleteCarConfigArticlesByQueryParamsFactory) {

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.submitButtonIsActive = true;
			};

			$scope.carGradeDependentPrice = entity;
			$scope.submitButtonIsActive = true;

			if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
				$scope.mappedBy = $stateParams.mappedBy;
			}

			//This function preselects and disables the parent reference when called from tab
			$scope.carGradeDependentPrice['selected' + _.upperFirst($stateParams.mappedBy)] = {
				id : $stateParams.mappedById,
				displayValue : $stateParams.mappedByDisplayValue
			};

			/**
			 * Checks if the page is in create or edit state based on the scope model and it's id value
			 */
			$scope.isEdit = function() {
				return ($rootScope.isDefinedAndNotNull($scope.carGradeDependentPrice) && $rootScope
						.isDefinedAndNotNull($scope.carGradeDependentPrice.id));
			};

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:carGradeDependentPriceUpdate', result);
				$scope.submitButtonIsActive = true;
				$rootScope.back();
			};

			$scope.save = function() {
				$scope.submitButtonIsActive = false;
				CarConfigCarGradeDependentPriceEditDtoFactory.update($scope.carGradeDependentPrice, onSaveFinishedCallback,
						saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

			$scope.autocompleteArticles = function(val) {
				var params = {

					/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
					'articleTypeAheadSearchString' : val
				};

				return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
					return results;
				});
			};

		});
