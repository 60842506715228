/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtUserCarStockAccessBoundListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		UserCarStockAccessBoundFactory,
		StockMgmtUserCarStockAccessBoundListDtoSearchFactory,
		StockMgmtUserCarStockAccessBoundViewDtoFactory
	) {
		$scope.userCarStockAccessBoundList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeUserCarStockAccessBound)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeUserCarStockAccessBound);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeUserCarStockAccessBound = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-usercarstockaccessbound.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.UserCarStockAccessBoundSearchOverview.tenant)) {
				return $scope.UserCarStockAccessBoundSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.UserCarStockAccessBoundSearchOverview = angular.copy($scope.UserCarStockAccessBoundSearchOverview);

			$rootScope.pageSizeUserCarStockAccessBound = angular.copy($scope.pageSize);

			StockMgmtUserCarStockAccessBoundListDtoSearchFactory.query(
				{
					page: $scope.UserCarStockAccessBoundSearchOverview.page - 1,
					size: $scope.pageSize,
					tenantId: getTenantId(),
					userIdList: $scope.UserCarStockAccessBoundSearchOverview.userIdList,

					sort: [
						$scope.UserCarStockAccessBoundSearchOverview.predicate +
							',' +
							($scope.UserCarStockAccessBoundSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.userCarStockAccessBoundList = result.items;

					$scope.userReferenceFilterList = result.userSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isUserCarStockAccessBoundSearchOverviewDirty = function() {
			return !angular.equals($scope.UserCarStockAccessBoundSearchOverview, $scope.originalUserCarStockAccessBoundSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.UserCarStockAccessBoundSearchOverview = {};
			$rootScope.UserCarStockAccessBoundSearchOverview.page = 1;
			$rootScope.UserCarStockAccessBoundSearchOverview.predicate = 'id';
			$rootScope.UserCarStockAccessBoundSearchOverview.ascending = false;

			$scope.UserCarStockAccessBoundSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundSearchOverview);

			$scope.originalUserCarStockAccessBoundSearchOverview = angular.copy($scope.UserCarStockAccessBoundSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.UserCarStockAccessBoundSearchOverview) ||
				$rootScope.UserCarStockAccessBoundSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.UserCarStockAccessBoundSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedUserCarStockAccessBoundId = id;
			$('#deleteUserCarStockAccessBoundConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtUserCarStockAccessBoundViewDtoFactory.delete(
				{ id: $scope.selectedUserCarStockAccessBoundId },
				function() {
					$scope.search();
					$('#deleteUserCarStockAccessBoundConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteUserCarStockAccessBoundConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.UserCarStockAccessBoundSearchOverview.predicate === itemName) {
				if ($scope.UserCarStockAccessBoundSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateUserUISelect = function(item, model) {
			$scope.search();
		};
	});
