/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtBaseTaskTemplateListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BaseTaskTemplateFactory,
		RelationMgmtBaseTaskTemplateListDtoSearchFactory
	) {
		$scope.baseTaskTemplateList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBaseTaskTemplate)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBaseTaskTemplate);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeBaseTaskTemplate = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-basetasktemplate.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getBaseTaskTemplateList = function() {
			RelationMgmtBaseTaskTemplateListDtoSearchFactory.query(
				{
					assignedGroupIdList: angular.isDefined($scope.assignedGroupId) ? $scope.assignedGroupId : null,
					assignedEmployeeId: angular.isDefined($scope.assignedEmployeeId) ? $scope.assignedEmployeeId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.baseTaskTemplateList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getBaseTaskTemplateList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateAssignedGroupUISelect = function(item, model) {
			$scope.search();
		};
	});
