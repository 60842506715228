/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('BusinessDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.business = entity;
	$scope.objectName = 'Business';
	$scope.businessId = $stateParams.businessId;

	if (angular.isUndefined($rootScope.businessActiveTab)) {
		//set the default here
		$rootScope.businessActiveTab = 0;
	}

	$scope.goToEditState = function(business) {
		$state.go('business.detail.edit({businessId:business.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:businessUpdate', function(event, result) {
		$scope.business = result;
	});
});
