'use strict';

angular.module('mmmApp').controller(
		'CarConfigCarConfiguratorSimulatorListDtoController',
		function($scope, $rootScope, $state, $stateParams, $q, AlertService, SimulatorSearchFactory, SimulatorDefaultsFactory,
				CarConfigCarOptionListDtoSearchFactory, AutocompleteCarConfigArticlesByQueryParamsFactory) {
			$scope.carConfiguratorSimulatorList = [];
			$scope.result;
			$scope.resultString = "";
			$scope.showSearchForm = true;
			$scope.totalTimeSeconds = null;

			// handle attribute debugEnabled of javaType boolean

			$scope.search = function() {
				$scope.result = undefined;

				$("#search-button").addClass("glyphicon-search-animate");

				$rootScope.CarConfiguratorSimulatorSearchOverview = angular.copy($scope.CarConfiguratorSimulatorSearchOverview);
				$rootScope.pageSizeCarConfiguratorSimulator = angular.copy($scope.pageSize);

				var secondsStart = new Date().getTime() / 1000;

				SimulatorSearchFactory.query({
					debugEnabled : $scope.CarConfiguratorSimulatorSearchOverview.debugEnabledGroup,
					tabPage : $scope.CarConfiguratorSimulatorSearchOverview.tabPage,
					selectedFilterArticles : $scope.CarConfiguratorSimulatorSearchOverview.selectedFilterArticles,
					selectedFilterOptions : $scope.CarConfiguratorSimulatorSearchOverview.selectedFilterOptions,
					selectedCarOptions : $scope.CarConfiguratorSimulatorSearchOverview.selectedCarOptions
				}, function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.carConfiguratorSimulatorList = result.items;
					$scope.resultString = JSON.stringify(result.carListDto, null, 3); // formats the json to indented readable format
					$scope.result = result.carListDto;

					$scope.tabPageList = result.tabPageSearchOptions;
					$scope.carOptionSearchOptions = result.carOptionSearchOptions;
					$scope.filterOptionSearchOptions = result.filterOptionSearchOptions;
					$scope.filterArticleSearchOptions = result.filterArticleSearchOptions;

					$("#search-button").removeClass("glyphicon-search-animate");

					// calculate XHR time (including backend work) in seconds
					var secondsEnd = new Date().getTime() / 1000;
					$scope.totalTimeSeconds = round(secondsEnd - secondsStart, 2) + "s";
				});

			};

			/**
			 * Rounds value to "precision" decimals
			 * @param value value to round
			 * @param precision precision in decimals to round to
			 * @returns {number} rounded value
			 */
			function round(value, precision) {
				var multiplier = Math.pow(10, precision || 0);
				return parseFloat(Math.round(value * multiplier) / multiplier).toFixed(precision);
			}

			$scope.isCarConfiguratorSimulatorSearchOverviewDirty = function() {
				return !angular
						.equals($scope.CarConfiguratorSimulatorSearchOverview, $scope.originalCarConfiguratorSimulatorSearchOverview);
			};

			$scope.loadDefaultSearchParams = function() {

				$rootScope.CarConfiguratorSimulatorSearchOverview = {};

				$scope.CarConfiguratorSimulatorSearchOverview = angular.copy($rootScope.CarConfiguratorSimulatorSearchOverview);

				$scope.originalCarConfiguratorSimulatorSearchOverview = angular.copy($scope.CarConfiguratorSimulatorSearchOverview);

				SimulatorDefaultsFactory.get({}, function(result, headers) {
					$scope.tabPageList = result.tabPageSearchOptions;
					$scope.carOptionSearchOptions = result.carOptionSearchOptions;
					$scope.filterOptionSearchOptions = result.filterOptionSearchOptions;
					$scope.filterArticleSearchOptions = result.filterArticleSearchOptions;
				});
			};

			$scope.reset = function() {
				$scope.loadDefaultSearchParams();
				if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
					// we check the availability of search form as it is still used in search forms that are not inlined in the table
					$scope.searchForm.$setPristine();
				}
				$scope.search();
			};

			$scope.loadPage = function() {
				if (angular.isUndefined($rootScope.CarConfiguratorSimulatorSearchOverview)
						|| $rootScope.CarConfiguratorSimulatorSearchOverview === null) {
					$scope.loadDefaultSearchParams(); // load defaults
				}

				if (!$stateParams.callInput) {
					$scope.CarConfiguratorSimulatorSearchOverview = angular.copy($rootScope.CarConfiguratorSimulatorSearchOverview);
				} else {
					// if $stateParams.callInput is populated we come from an existing ApplicationTimingLog
					// load data from callInput in the form so we can repeat the simulation

					$scope.loadDefaultSearchParams();

					var callInput = angular.fromJson($stateParams.callInput);

					$scope.CarConfiguratorSimulatorSearchOverview.tabPage = callInput.currentTabPage;
					$scope.CarConfiguratorSimulatorSearchOverview.selectedFilterArticles = callInput.filterArticles;
					$scope.CarConfiguratorSimulatorSearchOverview.selectedFilterOptions = callInput.filterOptions;
					$scope.CarConfiguratorSimulatorSearchOverview.selectedCarOptions = callInput.dealerOptions;
				}
			};

			$scope.loadPage();

		});
