/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtConsignmentAgreementEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtConsignmentAgreementEditDtoFactory,
		AlertService,
		HistoryService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
            $('#editUsePriceConfirmation').modal('hide');
		};

		$scope.consignmentAgreement = entity;
		$scope.submitButtonIsActive = true;

        $scope.formData = {
            field_usePricesFromOwner: false
        };

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.consignmentAgreement['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.consignmentAgreement) && $rootScope.isDefinedAndNotNull($scope.consignmentAgreement.id);
		};

		var onSaveFinishedCallback = function(result) {
            $scope.$emit('mmmApp:consignmentAgreementUpdate', result);
            $scope.submitButtonIsActive = true;
            $('#editUsePriceConfirmation').hide();
            $('.modal-backdrop').hide();
            $rootScope.back();
		};

		$scope.save = function(form) {
            if (form.field_usePricesFromOwner.$dirty || $scope.usePricesFromOwnerChanged ||
                form.field_useDiscountsFromOwner.$dirty || $scope.useDiscountsFromOwnerChanged) {
                // if usePricesFromOwner or useDiscountsFromOwner has changed, show confirmation modal
                $('#editUsePriceConfirmation').modal('show');
            } else {
                // else, save it right away
                $scope.submitButtonIsActive = false;
                StockMgmtConsignmentAgreementEditDtoFactory.update($scope.consignmentAgreement, onSaveFinishedCallback, saveOrUpdateErrorCallback);
            }
		};

        $scope.confirmEditUsePrice = function () {
            $scope.submitButtonIsActive = false;
            StockMgmtConsignmentAgreementEditDtoFactory.update($scope.consignmentAgreement, onSaveFinishedCallback, saveOrUpdateErrorCallback);
        }

        $scope.closeConfirmation = function() {
            $('#editUsePriceConfirmation').modal('hide');
        };

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		/**
		 * Tells caller whether the current shown screen (and state) is in edit mode, hence a dialog is shown
		 */
		$scope.isInEditState = function() {
			// check on stateName: *.edit  or  *.detail.edit
			return $state.current.name.toUpperCase().endsWith('EDIT');
		};

        $scope.$watch('consignmentAgreement.usePricesFromOwner', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.usePricesFromOwnerChanged = true;
            }
        });

        $scope.$watch('consignmentAgreement.useDiscountsFromOwner', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.useDiscountsFromOwnerChanged = true;
            }
        });
	});
