// CodeComposer:ignore : Stopped generating because of AMI-11600: Adding 2 indirect columns to angularjs list
/** @format */

'use strict';

// CrmCarOption has edit layout enabled. Edit layout template url: crmcaroption-dialog.html
// CrmCarOption has detail layout enabled. Detail layout template url: crmcaroption-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CrmCarOption'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('crmcaroption'); // main translation loaded for crmcaroption
		$translatePartialLoader.addPart('crmcaroptionorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carOption
		$translatePartialLoader.addPart('caroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('optionorigin'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-crmcaroption', {
		/* abstract state for DTO RelationMgmtCrmCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/crmcaroption',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCrmCarOptionListDto */
		/* to access: ui-ref="relation-mgmt-crmcaroption.list" */
		/* url: http://<hostname>/relationmgmt/crmcaroption/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcaroption/crmcaroption-list-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarOptionListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-crmcaroption.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtCrmCarOptionViewDto */
		/* to access: ui-sref="relation-mgmt-crmcaroption.detail" */
		/* url: http://<hostname>/relationmgmt/crmcaroption/detail/:crmCarOptionId */
		url: '/detail/:crmCarOptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcaroption/crmcaroption-view-relation-mgmt.html',
				controller: 'CrmCarOptionDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtCrmCarOptionViewDtoFactory',
				function($stateParams, RelationMgmtCrmCarOptionViewDtoFactory) {
					return RelationMgmtCrmCarOptionViewDtoFactory.get({ id: $stateParams.crmCarOptionId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcaroption.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCrmCarOptionEditDto */
		/* to access: ui-sref="relation-mgmt-crmcaroption.edit" */
		/* url: http://<hostname>/relationmgmt/crmcaroption/edit/:crmCarOptionId */
		url: '/edit/:crmCarOptionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcaroption/crmcaroption-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarOptionEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarOptionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarOptionEditDtoFactory.get({ id: $stateParams.crmCarOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcaroption.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCrmCarOptionEditDto */
		/* to access: ui-sref="relation-mgmt-crmcaroption.new" */
		/* url: http://<hostname>/relationmgmt/crmcaroption/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcaroption/crmcaroption-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarOptionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarOptionNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarOptionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarOptionNewDtoFactory.get({ id: $stateParams.crmCarOptionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcaroption.new', dtoCreateState);

	$stateProvider.state('stock-mgmt-crmcaroption', {
		/* abstract state for DTO StockMgmtCrmCarOptionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/crmcaroption',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarOption',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCrmCarOptionListDto */
		/* to access: ui-ref="stock-mgmt-crmcaroption.list" */
		/* url: http://<hostname>/stockmgmt/crmcaroption/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcaroption/crmcaroption-list-stock-mgmt.html',
				controller: 'StockMgmtCrmCarOptionListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-crmcaroption.list', dtoListState);
});
