/** @format */

'use strict';

// ExternalCarDataField has detail layout enabled. Detail layout template url: externalcardatafield-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ExternalCarDataField'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('externalcardatafield'); // main translation loaded for externalcardatafield
		$translatePartialLoader.addPart('externalcardata'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-externalcardatafield', {
		/* abstract state for DTO StockMgmtExternalCarDataFieldListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/externalcardatafield',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'ExternalCarDataField',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtExternalCarDataFieldListDto */
		/* to access: ui-ref="stock-mgmt-externalcardatafield.list" */
		/* url: http://<hostname>/stockmgmt/externalcardatafield/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/externalcardatafield/externalcardatafield-list-stock-mgmt.html',
				controller: 'StockMgmtExternalCarDataFieldListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-externalcardatafield.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtExternalCarDataFieldViewDto */
		/* to access: ui-sref="stock-mgmt-externalcardatafield.detail" */
		/* url: http://<hostname>/stockmgmt/externalcardatafield/detail/:externalCarDataFieldId */
		url: '/detail/:externalCarDataFieldId',
		views: {
			'content@': {
				templateUrl: 'app/entities/externalcardatafield/externalcardatafield-view-stock-mgmt.html',
				controller: 'ExternalCarDataFieldDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtExternalCarDataFieldViewDtoFactory',
				function($stateParams, StockMgmtExternalCarDataFieldViewDtoFactory) {
					return StockMgmtExternalCarDataFieldViewDtoFactory.get({ id: $stateParams.externalCarDataFieldId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-externalcardatafield.detail', dtoDetailState);
});
