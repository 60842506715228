'use strict';

angular.module('mmmApp').controller('CarConfigCarBrandBulkEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, CarConfigCarBrandBulkEditDtoFactory, AlertService) {

			$scope.selectedCarBrandIds = _.map($stateParams.selectedCarBrandList, 'id');
			$scope.selectedItemsAmount = $scope.selectedCarBrandIds.length;
			$scope.carBrand = entity;
			$scope.submitButtonIsActive = true;

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:carBrandUpdate', result);
				$scope.submitButtonIsActive = true;
				// process success result, if necessary
				if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
					console.log("as we came on this create new page from plus button we will update history");

					// set relation as reference in the referredBy data model
					$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

					// update previous state
					HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
				}
				$rootScope.back();
			};

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.updatingItemsInProgress = false;
			};

			$scope.save = function() {
				$scope.updatingItemsInProgress = true;
				CarConfigCarBrandBulkEditDtoFactory.update({
					'carBrandIds' : $scope.selectedCarBrandIds
				}, $scope.carBrand, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

		});
