/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCrmCarValuationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarValuationFactory,
		StockMgmtCrmCarValuationListDtoSearchFactory,
		StockMgmtCrmCarValuationViewDtoFactory,
		AutocompleteStockMgmtCarsByQueryParamsFactory
	) {
		$scope.crmCarValuationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCarValuation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCarValuation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCarValuation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-crmcarvaluation.new');
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarValuationSearchOverview.car)) {
				return $scope.CrmCarValuationSearchOverview.car.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarValuationSearchOverview.tenant)) {
				return $scope.CrmCarValuationSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute valuationDate of javaType java.time.LocalDate
		$scope.processValuationDate = function() {
			var valuationDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarValuationSearchOverview.valuationDateFrom);
			var valuationDateToDefined = $rootScope.isDefinedAndNotNull($scope.CrmCarValuationSearchOverview.valuationDateTo);

			if (valuationDateFromDefined) {
				$scope.CrmCarValuationSearchOverview.valuationDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarValuationSearchOverview.valuationDateFrom
				);
			} else {
				$scope.CrmCarValuationSearchOverview.valuationDateFromString = undefined;
			}

			if (valuationDateToDefined) {
				$scope.CrmCarValuationSearchOverview.valuationDateToString = $rootScope.convertDateWithoutZone(
					$scope.CrmCarValuationSearchOverview.valuationDateTo
				);
			} else {
				$scope.CrmCarValuationSearchOverview.valuationDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValuationDate();

			$rootScope.CrmCarValuationSearchOverview = angular.copy($scope.CrmCarValuationSearchOverview);

			$rootScope.pageSizeCrmCarValuation = angular.copy($scope.pageSize);

			StockMgmtCrmCarValuationListDtoSearchFactory.query(
				{
					page: $scope.CrmCarValuationSearchOverview.page - 1,
					size: $scope.pageSize,
					valuationDateFrom: $scope.CrmCarValuationSearchOverview.valuationDateFromString,
					valuationDateTo: $scope.CrmCarValuationSearchOverview.valuationDateToString,
					valueFrom: $scope.CrmCarValuationSearchOverview.valueFrom,
					valueTo: $scope.CrmCarValuationSearchOverview.valueTo,
					carId: getCarId(),
					tenantId: getTenantId(),

					sort: [$scope.CrmCarValuationSearchOverview.predicate + ',' + ($scope.CrmCarValuationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.crmCarValuationList = result.items;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteStockMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCrmCarValuationSearchOverviewDirty = function() {
			return !angular.equals($scope.CrmCarValuationSearchOverview, $scope.originalCrmCarValuationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CrmCarValuationSearchOverview = {};
			$rootScope.CrmCarValuationSearchOverview.page = 1;
			$rootScope.CrmCarValuationSearchOverview.predicate = 'id';
			$rootScope.CrmCarValuationSearchOverview.ascending = false;

			$scope.CrmCarValuationSearchOverview = angular.copy($rootScope.CrmCarValuationSearchOverview);

			$scope.originalCrmCarValuationSearchOverview = angular.copy($scope.CrmCarValuationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CrmCarValuationSearchOverview) || $rootScope.CrmCarValuationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CrmCarValuationSearchOverview = angular.copy($rootScope.CrmCarValuationSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarValuationId = id;
			$('#deleteCrmCarValuationConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtCrmCarValuationViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarValuationId },
				function() {
					$scope.search();
					$('#deleteCrmCarValuationConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarValuationConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CrmCarValuationSearchOverview.predicate === itemName) {
				if ($scope.CrmCarValuationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
