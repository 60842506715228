/** @format */

'use strict';

// CrmCarValuation has edit layout enabled. Edit layout template url: crmcarvaluation-dialog.html
// CrmCarValuation has detail layout enabled. Detail layout template url: crmcarvaluation-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CrmCarValuation'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('crmcarvaluation'); // main translation loaded for crmcarvaluation
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-crmcarvaluation', {
		/* abstract state for DTO StockMgmtCrmCarValuationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/crmcarvaluation',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarValuation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCrmCarValuationListDto */
		/* to access: ui-ref="stock-mgmt-crmcarvaluation.list" */
		/* url: http://<hostname>/stockmgmt/crmcarvaluation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-list-stock-mgmt.html',
				controller: 'StockMgmtCrmCarValuationListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-crmcarvaluation.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtCrmCarValuationViewDto */
		/* to access: ui-sref="stock-mgmt-crmcarvaluation.detail" */
		/* url: http://<hostname>/stockmgmt/crmcarvaluation/detail/:crmCarValuationId */
		url: '/detail/:crmCarValuationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-view-stock-mgmt.html',
				controller: 'CrmCarValuationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtCrmCarValuationViewDtoFactory',
				function($stateParams, StockMgmtCrmCarValuationViewDtoFactory) {
					return StockMgmtCrmCarValuationViewDtoFactory.get({ id: $stateParams.crmCarValuationId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcarvaluation.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCrmCarValuationEditDto */
		/* to access: ui-sref="stock-mgmt-crmcarvaluation.edit" */
		/* url: http://<hostname>/stockmgmt/crmcarvaluation/edit/:crmCarValuationId */
		url: '/edit/:crmCarValuationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-edit-stock-mgmt.html',
				controller: 'StockMgmtCrmCarValuationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCrmCarValuationEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCrmCarValuationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCrmCarValuationEditDtoFactory.get({ id: $stateParams.crmCarValuationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcarvaluation.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtCrmCarValuationEditDto */
		/* to access: ui-sref="stock-mgmt-crmcarvaluation.new" */
		/* url: http://<hostname>/stockmgmt/crmcarvaluation/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-edit-stock-mgmt.html',
				controller: 'StockMgmtCrmCarValuationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCrmCarValuationNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtCrmCarValuationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCrmCarValuationNewDtoFactory.get({ id: $stateParams.crmCarValuationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcarvaluation.new', dtoCreateState);

	$stateProvider.state('relation-mgmt-crmcarvaluation', {
		/* abstract state for DTO RelationMgmtCrmCarValuationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/crmcarvaluation',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarValuation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCrmCarValuationListDto */
		/* to access: ui-ref="relation-mgmt-crmcarvaluation.list" */
		/* url: http://<hostname>/relationmgmt/crmcarvaluation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-list-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarValuationListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-crmcarvaluation.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtCrmCarValuationViewDto */
		/* to access: ui-sref="relation-mgmt-crmcarvaluation.detail" */
		/* url: http://<hostname>/relationmgmt/crmcarvaluation/detail/:crmCarValuationId */
		url: '/detail/:crmCarValuationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-view-relation-mgmt.html',
				controller: 'CrmCarValuationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtCrmCarValuationViewDtoFactory',
				function($stateParams, RelationMgmtCrmCarValuationViewDtoFactory) {
					return RelationMgmtCrmCarValuationViewDtoFactory.get({ id: $stateParams.crmCarValuationId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcarvaluation.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCrmCarValuationEditDto */
		/* to access: ui-sref="relation-mgmt-crmcarvaluation.edit" */
		/* url: http://<hostname>/relationmgmt/crmcarvaluation/edit/:crmCarValuationId */
		url: '/edit/:crmCarValuationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarValuationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarValuationEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarValuationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarValuationEditDtoFactory.get({ id: $stateParams.crmCarValuationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcarvaluation.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCrmCarValuationEditDto */
		/* to access: ui-sref="relation-mgmt-crmcarvaluation.new" */
		/* url: http://<hostname>/relationmgmt/crmcarvaluation/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarValuationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarValuationNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarValuationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarValuationNewDtoFactory.get({ id: $stateParams.crmCarValuationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcarvaluation.new', dtoCreateState);
});
