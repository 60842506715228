/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'TradeInOfSalesOrderPlacedInCarStock'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('tradeinofsalesorderplacedincarstock'); // main translation loaded for tradeinofsalesorderplacedincarstock
		$translatePartialLoader.addPart('carstock'); // one to many reference from main entity

		return $translate.refresh();
	};
});
