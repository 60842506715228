/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('EmployeeFactory', function($resource) {
	return $resource(
		'api/employees/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtEmployeeViewDto
 */
angular.module('mmmApp').factory('RelationMgmtEmployeeViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/employee/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtEmployeeListDto's
 */
angular.module('mmmApp').factory('RelationMgmtEmployeeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/employee',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtEmployeeEditDto
 */
angular.module('mmmApp').factory('RelationMgmtEmployeeEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/employee/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtEmployeeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtEmployeeNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/employee/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewEmployeeFactory', function($resource) {
	return $resource(
		'api/employees/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Employees without the use of paging
angular.module('mmmApp').factory('NoPagingEmployeeFactory', function($resource) {
	return $resource(
		'api/employees/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationStatusses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationStatusFactory', function($resource) {
	return $resource(
		'api/employee/relationStatusses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationTypeFactory', function($resource) {
	return $resource(
		'api/employee/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible GenderTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingGenderTypeFactory', function($resource) {
	return $resource(
		'api/employee/genderTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllEmployeesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/employees/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteEmployeesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/employees/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtEmployeesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/employees',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteOwnerEmployeesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/owner/autocomplete/employees',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
