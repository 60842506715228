/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingCarBrandFactory', function($resource) {
	return $resource(
		'api/mappingCarBrands/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingCarBrandViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingCarBrandViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingcarbrand/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingCarBrandListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingCarBrandListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingcarbrand',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingCarBrandEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingCarBrandEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingcarbrand/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingCarBrandEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingCarBrandNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingcarbrand/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingCarBrandEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingCarBrandEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingcarbrand/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingCarBrandFactory', function($resource) {
	return $resource(
		'api/mappingCarBrands/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingCarBrands without the use of paging
angular.module('mmmApp').factory('NoPagingMappingCarBrandFactory', function($resource) {
	return $resource(
		'api/mappingCarBrands/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingCarBrand/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingCarBrandsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingCarBrands/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('SetWorkingCarBrandFactory', function($resource) {
	return $resource(
		'api/articlemanagement/context/workingbrand/:id',
		{},
		{
			setWorkingCarBrand: {
				method: 'GET',
			},
		}
	);
});

angular.module('mmmApp').factory('MappingCarBrandSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingcarbrands/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
