/** @format */

'use strict';

// Translation has edit layout enabled. Edit layout template url: translation-dialog.html
// Translation has detail layout enabled. Detail layout template url: translation-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Translation'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('translation'); // main translation loaded for translation
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-translation', {
		/* abstract state for DTO CarConfigTranslationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/translation',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Translation',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigTranslationListDto */
		/* to access: ui-ref="car-config-translation.list" */
		/* url: http://<hostname>/car/config/translation/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/translation/translation-list-car-config.html',
				controller: 'CarConfigTranslationListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-translation.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigTranslationViewDto */
		/* to access: ui-sref="car-config-translation.detail" */
		/* url: http://<hostname>/car/config/translation/detail/:translationId */
		url: '/detail/:translationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/translation/translation-view-car-config.html',
				controller: 'TranslationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigTranslationViewDtoFactory',
				function($stateParams, CarConfigTranslationViewDtoFactory) {
					return CarConfigTranslationViewDtoFactory.get({ id: $stateParams.translationId });
				},
			],
		},
	};

	$stateProvider.state('car-config-translation.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigTranslationEditDto */
		/* to access: ui-sref="car-config-translation.edit" */
		/* url: http://<hostname>/car/config/translation/edit/:translationId */
		url: '/edit/:translationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/translation/translation-edit-car-config.html',
				controller: 'CarConfigTranslationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigTranslationEditDtoFactory',
				function($stateParams, $rootScope, CarConfigTranslationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigTranslationEditDtoFactory.get({ id: $stateParams.translationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-translation.edit', dtoEditState);
});
