/** @format */

'use strict';

// CarBrandDealer has edit layout enabled. Edit layout template url: carbranddealer-dialog.html
// CarBrandDealer has detail layout enabled. Detail layout template url: carbranddealer-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarBrandDealer'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carbranddealer'); // main translation loaded for carbranddealer
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-carbranddealer', {
		/* abstract state for DTO StockMgmtCarBrandDealerListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/carbranddealer',
		data: {
			authorities: ['ROLE_DEALER_ADMIN', 'ROLE_ DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarBrandDealer',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCarBrandDealerListDto */
		/* to access: ui-ref="stock-mgmt-carbranddealer.list" */
		/* url: http://<hostname>/stockmgmt/carbranddealer/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbranddealer/carbranddealer-list-stock-mgmt.html',
				controller: 'StockMgmtCarBrandDealerListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-carbranddealer.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtCarBrandDealerViewDto */
		/* to access: ui-sref="stock-mgmt-carbranddealer.detail" */
		/* url: http://<hostname>/stockmgmt/carbranddealer/detail/:carBrandDealerId */
		url: '/detail/:carBrandDealerId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbranddealer/carbranddealer-view-stock-mgmt.html',
				controller: 'CarBrandDealerDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtCarBrandDealerViewDtoFactory',
				function($stateParams, StockMgmtCarBrandDealerViewDtoFactory) {
					return StockMgmtCarBrandDealerViewDtoFactory.get({ id: $stateParams.carBrandDealerId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carbranddealer.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCarBrandDealerEditDto */
		/* to access: ui-sref="stock-mgmt-carbranddealer.edit" */
		/* url: http://<hostname>/stockmgmt/carbranddealer/edit/:carBrandDealerId */
		url: '/edit/:carBrandDealerId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbranddealer/carbranddealer-edit-stock-mgmt.html',
				controller: 'StockMgmtCarBrandDealerEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarBrandDealerEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarBrandDealerEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarBrandDealerEditDtoFactory.get({ id: $stateParams.carBrandDealerId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carbranddealer.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtCarBrandDealerEditDto */
		/* to access: ui-sref="stock-mgmt-carbranddealer.new" */
		/* url: http://<hostname>/stockmgmt/carbranddealer/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbranddealer/carbranddealer-edit-stock-mgmt.html',
				controller: 'StockMgmtCarBrandDealerEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarBrandDealerNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarBrandDealerNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarBrandDealerNewDtoFactory.get({ id: $stateParams.carBrandDealerId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carbranddealer.new', dtoCreateState);
});
