/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ConsignmentFactory', function($resource) {
	return $resource(
		'api/consignments/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtConsignmentViewDto
 */
angular.module('mmmApp').factory('StockMgmtConsignmentViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignment/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtConsignmentListDto's
 */
angular.module('mmmApp').factory('StockMgmtConsignmentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignment',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtConsignmentEditDto
 */
angular.module('mmmApp').factory('StockMgmtConsignmentEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignment/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewConsignmentFactory', function($resource) {
	return $resource(
		'api/consignments/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Consignments without the use of paging
angular.module('mmmApp').factory('NoPagingConsignmentFactory', function($resource) {
	return $resource(
		'api/consignments/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllConsignmentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/consignments/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
