'use strict';

/**
 * Factory to save/update an instance of ImportGradeDto
 */
angular.module('mmmApp').factory('PdfImportGradeEditDtoFactory', function($resource) {
	return $resource('pdf/savegrade/:id', {}, {
		'save' : {
			method : 'POST'
		}
	});
});

angular.module('mmmApp').factory('PdfImportArticleEditDtoFactory', function($resource) {
	return $resource('pdf/savearticle/:id', {}, {
		'save' : {
			method : 'POST'
		}
	});
});
