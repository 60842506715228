/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarBrandDealerDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carBrandDealer = entity;
		$scope.objectName = 'CarBrandDealer';
		$scope.carBrandDealerId = $stateParams.carBrandDealerId;

		if (angular.isUndefined($rootScope.carBrandDealerActiveTab)) {
			//set the default here
			$rootScope.carBrandDealerActiveTab = 0;
		}

		$scope.goToEditState = function(carBrandDealer) {
			$state.go('carbranddealer.detail.edit({carBrandDealerId:carBrandDealer.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carBrandDealerUpdate', function(event, result) {
			$scope.carBrandDealer = result;
		});
	});
