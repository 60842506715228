/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtEmployeeListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EmployeeFactory,
		RelationMgmtEmployeeListDtoSearchFactory,
		RelationMgmtEmployeeViewDtoFactory
	) {
		$scope.employeeList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEmployee)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEmployee);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEmployee = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-employee.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getEmployeeList = function() {
			RelationMgmtEmployeeListDtoSearchFactory.query(
				{
					status: angular.isDefined($scope.statusId) ? $scope.statusId : null,
					relationType: angular.isDefined($scope.relationTypeId) ? $scope.relationTypeId : null,
					primaryPhoneId: angular.isDefined($scope.primaryPhoneId) ? $scope.primaryPhoneId : null,
					primaryAddressId: angular.isDefined($scope.primaryAddressId) ? $scope.primaryAddressId : null,
					primaryEmailId: angular.isDefined($scope.primaryEmailId) ? $scope.primaryEmailId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,
					genderList: angular.isDefined($scope.genderId) ? $scope.genderId : null,
					userId: angular.isDefined($scope.userId) ? $scope.userId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.employeeList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getEmployeeList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEmployeeId = id;
			$('#deleteEmployeeConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtEmployeeViewDtoFactory.delete(
				{ id: $scope.selectedEmployeeId },
				function() {
					$scope.loadAll();
					$('#deleteEmployeeConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteEmployeeConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateGenderUISelect = function(item, model) {
			$scope.search();
		};
	});
