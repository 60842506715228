/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtEmailListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EmailFactory,
		RelationMgmtEmailListDtoSearchFactory,
		RelationMgmtEmailViewDtoFactory
	) {
		$scope.emailList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEmail)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEmail);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEmail = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-email.new');
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmailSearchOverview.relation)) {
				return $scope.EmailSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryEmailOfId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmailSearchOverview.primaryEmailOf)) {
				return $scope.EmailSearchOverview.primaryEmailOf.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.EmailSearchOverview.tenant)) {
				return $scope.EmailSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.EmailSearchOverview = angular.copy($scope.EmailSearchOverview);

			$rootScope.pageSizeEmail = angular.copy($scope.pageSize);

			RelationMgmtEmailListDtoSearchFactory.query(
				{
					page: $scope.EmailSearchOverview.page - 1,
					size: $scope.pageSize,
					emailAddress: $scope.EmailSearchOverview.emailAddress,
					emailTypeList: $scope.EmailSearchOverview.emailTypeList,
					relationId: getRelationId(),
					primaryEmailOfId: getPrimaryEmailOfId(),
					tenantId: getTenantId(),

					sort: [$scope.EmailSearchOverview.predicate + ',' + ($scope.EmailSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.emailList = result.items;

					$scope.emailTypeList = result.emailTypeSearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isEmailSearchOverviewDirty = function() {
			return !angular.equals($scope.EmailSearchOverview, $scope.originalEmailSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.EmailSearchOverview = {};
			$rootScope.EmailSearchOverview.page = 1;
			$rootScope.EmailSearchOverview.predicate = 'id';
			$rootScope.EmailSearchOverview.ascending = false;

			$scope.EmailSearchOverview = angular.copy($rootScope.EmailSearchOverview);

			$scope.originalEmailSearchOverview = angular.copy($scope.EmailSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.EmailSearchOverview) || $rootScope.EmailSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.EmailSearchOverview = angular.copy($rootScope.EmailSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEmailId = id;
			$('#deleteEmailConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtEmailViewDtoFactory.delete(
				{ id: $scope.selectedEmailId },
				function() {
					$scope.search();
					$('#deleteEmailConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteEmailConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.EmailSearchOverview.predicate === itemName) {
				if ($scope.EmailSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateEmailTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
