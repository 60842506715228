/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('UserCarStockAccessBoundDealerFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundDealers/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to search / retrieve an array of StockMgmtUserCarStockAccessBoundDealerListDto's
 */
angular.module('mmmApp').factory('StockMgmtUserCarStockAccessBoundDealerListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/usercarstockaccessbounddealer',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewUserCarStockAccessBoundDealerFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundDealers/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all UserCarStockAccessBoundDealers without the use of paging
angular.module('mmmApp').factory('NoPagingUserCarStockAccessBoundDealerFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundDealers/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllUserCarStockAccessBoundDealersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/userCarStockAccessBoundDealers/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
