/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarOptionFactory', function($resource) {
	return $resource(
		'api/carOptions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarOptionViewDto
 */
angular.module('mmmApp').factory('CarConfigCarOptionViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroption/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarOptionListDto's
 */
angular.module('mmmApp').factory('CarConfigCarOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/caroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarOptionEditDto
 */
angular.module('mmmApp').factory('CarConfigCarOptionEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroption/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarOptionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarOptionNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroption/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarOptionEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarOptionEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/caroption/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiCarOptionListDto's
 */
angular.module('mmmApp').factory('WebsiteApiCarOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/caroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarOptionEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarOptionEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/caroption/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarOptionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarOptionNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/caroption/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarOptionEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarOptionEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/caroption/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarOptionFactory', function($resource) {
	return $resource(
		'api/carOptions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarOptions without the use of paging
angular.module('mmmApp').factory('NoPagingCarOptionFactory', function($resource) {
	return $resource(
		'api/carOptions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carOption/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carOption/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carOption/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carOption/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carOption/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carOption/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carOption/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible OptionOrigins Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingOptionOriginFactory', function($resource) {
	return $resource(
		'api/carOption/optionOrigins',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible OptionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingOptionTypeFactory', function($resource) {
	return $resource(
		'api/carOption/optionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carOptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/carOptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarConfigCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/car/config/autocomplete/caroptions',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/caroptions',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteStockMgmtCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/stockmgmt/autocomplete/caroptions',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteWebsiteApiCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/websiteapi/autocomplete/caroptions',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteArticleApiCarOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/articleapi/autocomplete/caroptions',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarOptionSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/caroptions/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
