/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('SubTaskFactory', function($resource) {
	return $resource(
		'api/subTasks/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtSubTaskViewDto
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtask/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtSubTaskListDto's
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtask',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtSubTaskEditDto
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtask/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtSubTaskEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtSubTaskNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/subtask/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewSubTaskFactory', function($resource) {
	return $resource(
		'api/subTasks/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all SubTasks without the use of paging
angular.module('mmmApp').factory('NoPagingSubTaskFactory', function($resource) {
	return $resource(
		'api/subTasks/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TaskStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTaskStatusFactory', function($resource) {
	return $resource(
		'api/subTask/taskStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllSubTasksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/subTasks/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
