(function() {
	'use strict';

	angular.module('mmmApp').controller('NavbarAdminController', NavbarAdminController);

	NavbarAdminController.$inject = [ '$resource', 'AlertService' ];

	function NavbarAdminController($resource, AlertService) {
		var navbarAdminVm = this;

		navbarAdminVm.clearCache = clearCache;

		function clearCache() {
			$resource('/api/admin/cache/clear').get().$promise.then(function(result) {
				AlertService.info(result.response);
			});
		}
	}
})();
