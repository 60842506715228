/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('MappingCarModelDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.mappingCarModel = entity;
		$scope.objectName = 'MappingCarModel';
		$scope.mappingCarModelId = $stateParams.mappingCarModelId;

		if (angular.isUndefined($rootScope.mappingCarModelActiveTab)) {
			//set the default here
			$rootScope.mappingCarModelActiveTab = 0;
		}

		$scope.goToEditState = function(mappingCarModel) {
			$state.go('mappingcarmodel.detail.edit({mappingCarModelId:mappingCarModel.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:mappingCarModelUpdate', function(event, result) {
			$scope.mappingCarModel = result;
		});
	});
