/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ConsignmentAgreementFactory', function($resource) {
	return $resource(
		'api/consignmentAgreements/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtConsignmentAgreementViewDto
 */
angular.module('mmmApp').factory('StockMgmtConsignmentAgreementViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignmentagreement/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtConsignmentAgreementListDto's
 */
angular.module('mmmApp').factory('StockMgmtConsignmentAgreementListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignmentagreement',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtConsignmentAgreementEditDto
 */
angular.module('mmmApp').factory('StockMgmtConsignmentAgreementEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignmentagreement/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtConsignmentAgreementEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtConsignmentAgreementNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/consignmentagreement/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewConsignmentAgreementFactory', function($resource) {
	return $resource(
		'api/consignmentAgreements/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ConsignmentAgreements without the use of paging
angular.module('mmmApp').factory('NoPagingConsignmentAgreementFactory', function($resource) {
	return $resource(
		'api/consignmentAgreements/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllConsignmentAgreementsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/consignmentAgreements/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
