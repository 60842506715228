/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('RelationCarRoleDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.relationCarRole = entity;
		$scope.objectName = 'RelationCarRole';
		$scope.relationCarRoleId = $stateParams.relationCarRoleId;

		if (angular.isUndefined($rootScope.relationCarRoleActiveTab)) {
			//set the default here
			$rootScope.relationCarRoleActiveTab = 0;
		}

		$scope.goToEditState = function(relationCarRole) {
			$state.go('relationcarrole.detail.edit({relationCarRoleId:relationCarRole.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:relationCarRoleUpdate', function(event, result) {
			$scope.relationCarRole = result;
		});
	});
