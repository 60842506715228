/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('FilterPageFactory', function($resource) {
	return $resource(
		'api/filterPages/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigFilterPageViewDto
 */
angular.module('mmmApp').factory('CarConfigFilterPageViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filterpage/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigFilterPageListDto's
 */
angular.module('mmmApp').factory('CarConfigFilterPageListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/filterpage',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigFilterPageEditDto
 */
angular.module('mmmApp').factory('CarConfigFilterPageEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filterpage/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigFilterPageEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigFilterPageNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filterpage/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewFilterPageFactory', function($resource) {
	return $resource(
		'api/filterPages/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all FilterPages without the use of paging
angular.module('mmmApp').factory('NoPagingFilterPageFactory', function($resource) {
	return $resource(
		'api/filterPages/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TabPages Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTabPageFactory', function($resource) {
	return $resource(
		'api/filterPage/tabPages',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Placements Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPlacementFactory', function($resource) {
	return $resource(
		'api/filterPage/placements',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllFilterPagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/filterPages/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
