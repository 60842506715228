/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCarStockListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarStockFactory,
		RelationMgmtCarStockListDtoSearchFactory,
		AutocompleteRelationMgmtCarsByQueryParamsFactory,
		$uibModal,
		Upload,
		$timeout,
		QuoteFactory
	) {
		$scope.carStockList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarStock)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarStock);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarStock = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-carstock.new');
		};

		var getVehicleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.vehicle)) {
				return $scope.CarStockSearchOverview.vehicle.id;
			} else {
				return undefined;
			}
		};

		var getFinancialOwnerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.financialOwner)) {
				return $scope.CarStockSearchOverview.financialOwner.id;
			} else {
				return undefined;
			}
		};

		var getDealerSwappedFromId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.dealerSwappedFrom)) {
				return $scope.CarStockSearchOverview.dealerSwappedFrom.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.tenant)) {
				return $scope.CarStockSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getGuaranteeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.guarantee)) {
				return $scope.CarStockSearchOverview.guarantee.id;
			} else {
				return undefined;
			}
		};

		var getCarBrandDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.carBrandDealer)) {
				return $scope.CarStockSearchOverview.carBrandDealer.id;
			} else {
				return undefined;
			}
		};

		var getConsignmentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.consignment)) {
				return $scope.CarStockSearchOverview.consignment.id;
			} else {
				return undefined;
			}
		};

		var getGenericVehiclePriceInformationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.genericVehiclePriceInformation)) {
				return $scope.CarStockSearchOverview.genericVehiclePriceInformation.id;
			} else {
				return undefined;
			}
		};

		// handle attribute estimatedDeliveryDate of javaType java.time.LocalDate
		$scope.processEstimatedDeliveryDate = function() {
			var estimatedDeliveryDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.estimatedDeliveryDateFrom);
			var estimatedDeliveryDateToDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.estimatedDeliveryDateTo);

			if (estimatedDeliveryDateFromDefined) {
				$scope.CarStockSearchOverview.estimatedDeliveryDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CarStockSearchOverview.estimatedDeliveryDateFrom
				);
			} else {
				$scope.CarStockSearchOverview.estimatedDeliveryDateFromString = undefined;
			}

			if (estimatedDeliveryDateToDefined) {
				$scope.CarStockSearchOverview.estimatedDeliveryDateToString = $rootScope.convertDateWithoutZone(
					$scope.CarStockSearchOverview.estimatedDeliveryDateTo
				);
			} else {
				$scope.CarStockSearchOverview.estimatedDeliveryDateToString = undefined;
			}
		};

		// handle attribute customer of javaType Boolean
		// handle attribute callOff of javaType Boolean
		// handle attribute publish of javaType boolean
		// handle attribute publishDate of javaType java.time.LocalDate
		$scope.processPublishDate = function() {
			var publishDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.publishDateFrom);
			var publishDateToDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.publishDateTo);

			if (publishDateFromDefined) {
				$scope.CarStockSearchOverview.publishDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CarStockSearchOverview.publishDateFrom
				);
			} else {
				$scope.CarStockSearchOverview.publishDateFromString = undefined;
			}

			if (publishDateToDefined) {
				$scope.CarStockSearchOverview.publishDateToString = $rootScope.convertDateWithoutZone($scope.CarStockSearchOverview.publishDateTo);
			} else {
				$scope.CarStockSearchOverview.publishDateToString = undefined;
			}
		};

		// handle attribute depublishDate of javaType java.time.LocalDate
		$scope.processDepublishDate = function() {
			var depublishDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.depublishDateFrom);
			var depublishDateToDefined = $rootScope.isDefinedAndNotNull($scope.CarStockSearchOverview.depublishDateTo);

			if (depublishDateFromDefined) {
				$scope.CarStockSearchOverview.depublishDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CarStockSearchOverview.depublishDateFrom
				);
			} else {
				$scope.CarStockSearchOverview.depublishDateFromString = undefined;
			}

			if (depublishDateToDefined) {
				$scope.CarStockSearchOverview.depublishDateToString = $rootScope.convertDateWithoutZone(
					$scope.CarStockSearchOverview.depublishDateTo
				);
			} else {
				$scope.CarStockSearchOverview.depublishDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processEstimatedDeliveryDate();
			$scope.processPublishDate();
			$scope.processDepublishDate();

			$rootScope.CarStockSearchOverview = angular.copy($scope.CarStockSearchOverview);

			$rootScope.pageSizeCarStock = angular.copy($scope.pageSize);

			RelationMgmtCarStockListDtoSearchFactory.query(
				{
					page: $scope.CarStockSearchOverview.page - 1,
					size: $scope.pageSize,
					estimatedDeliveryDateFrom: $scope.CarStockSearchOverview.estimatedDeliveryDateFromString,
					estimatedDeliveryDateTo: $scope.CarStockSearchOverview.estimatedDeliveryDateToString,
					customer: $scope.CarStockSearchOverview.customerGroup,
					callOff: $scope.CarStockSearchOverview.callOffGroup,
					publish: $scope.CarStockSearchOverview.publishGroup,
					publishDateFrom: $scope.CarStockSearchOverview.publishDateFromString,
					publishDateTo: $scope.CarStockSearchOverview.publishDateToString,
					depublishDateFrom: $scope.CarStockSearchOverview.depublishDateFromString,
					depublishDateTo: $scope.CarStockSearchOverview.depublishDateToString,
					retailPriceFrom: $scope.CarStockSearchOverview.retailPriceFrom,
					retailPriceTo: $scope.CarStockSearchOverview.retailPriceTo,
					referenceNo: $scope.CarStockSearchOverview.referenceNo,
					priceStatusList: $scope.CarStockSearchOverview.priceStatusList,
					vehicleId: getVehicleId(),
					stockStatusList: $scope.CarStockSearchOverview.stockStatusList,
					typeList: $scope.CarStockSearchOverview.typeList,
					sellingStatusList: $scope.CarStockSearchOverview.sellingStatusList,
					pipelineStatusList: $scope.CarStockSearchOverview.pipelineStatusList,
					locationIdList: $scope.CarStockSearchOverview.locationIdList,
					sellerIdList: $scope.CarStockSearchOverview.sellerIdList,
					financialOwnerId: getFinancialOwnerId(),
					dealerSwappedFromId: getDealerSwappedFromId(),
					transferStatus: $scope.CarStockSearchOverview.transferStatus,
					publishSourceList: $scope.CarStockSearchOverview.publishSourceList,
					carStockOrigin: $scope.CarStockSearchOverview.carStockOrigin,
					tenantId: getTenantId(),
					guaranteeId: getGuaranteeId(),
					carStockDiscountOriginList: $scope.CarStockSearchOverview.carStockDiscountOriginList,
					imaginStudioImageSourceStatus: $scope.CarStockSearchOverview.imaginStudioImageSourceStatus,
					carSpecificImageSourceStatus: $scope.CarStockSearchOverview.carSpecificImageSourceStatus,
					brandImageSourceStatus: $scope.CarStockSearchOverview.brandImageSourceStatus,
					communityImageSourceStatus: $scope.CarStockSearchOverview.communityImageSourceStatus,
					matchingCarImageSourceStatus: $scope.CarStockSearchOverview.matchingCarImageSourceStatus,
					imageSetImageSourceStatus: $scope.CarStockSearchOverview.imageSetImageSourceStatus,
					activeImageSource: $scope.CarStockSearchOverview.activeImageSource,
					carBrandDealerId: getCarBrandDealerId(),
					locale: $scope.CarStockSearchOverview.locale,
					consignmentId: getConsignmentId(),
					preferredPriceSource: $scope.CarStockSearchOverview.preferredPriceSource,
					preferredDiscountSource: $scope.CarStockSearchOverview.preferredDiscountSource,
					genericVehiclePriceInformationId: getGenericVehiclePriceInformationId(),

					sort: [$scope.CarStockSearchOverview.predicate + ',' + ($scope.CarStockSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carStockList = result.items;

					$scope.carStockPriceStatusList = result.priceStatusSearchOptions;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.stockStatusList = result.stockStatusSearchOptions;

					$scope.carStockTypeList = result.typeSearchOptions;

					$scope.sellingStatusList = result.sellingStatusSearchOptions;

					$scope.pipelineStatusList = result.pipelineStatusSearchOptions;

					$scope.locationReferenceFilterList = result.locationSearchOptions;

					$scope.sellerReferenceFilterList = result.sellerSearchOptions;

					$scope.publishSourceTypeList = result.publishSourceSearchOptions;

					$scope.carStockDiscountOriginList = result.carStockDiscountOriginSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarStockSearchOverviewDirty = function() {
			return !angular.equals($scope.CarStockSearchOverview, $scope.originalCarStockSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarStockSearchOverview = {};
			$rootScope.CarStockSearchOverview.page = 1;
			$rootScope.CarStockSearchOverview.predicate = 'publishDate';
			$rootScope.CarStockSearchOverview.ascending = false;

			$scope.CarStockSearchOverview = angular.copy($rootScope.CarStockSearchOverview);

			$scope.originalCarStockSearchOverview = angular.copy($scope.CarStockSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarStockSearchOverview) || $rootScope.CarStockSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarStockSearchOverview = angular.copy($rootScope.CarStockSearchOverview);

			$scope.search();
		};

		$scope.showUpload = function(size, parentSelector) {
			var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
			$scope.modalInstance = $uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'uploadEMotiveFile.html',
				// controller: 'ModalStatusController',
				scope: $scope,
				resolve: {},
				// size: size,
				appendTo: parentElem,
			});
		};

		$scope.gotoCarConfiguration = function(carConfigurationId) {
			if ($rootScope.isDefinedAndNotNull(carConfigurationId)) {
				// TODO why is injecting the constant APP_SETTINGS not working?
				var url = $rootScope.CARCONFIG_URL;
				window.open(url + '/loadConfiguration.php?id=' + carConfigurationId);
			}
		};

		/**
		 * Gets the quote as PDF. Clicking the element that fires this method show a "save as" dialog to save the PDF after
		 * the XHR request retrieved all bytes (a byte[]) from the REST endpoint.
		 *
		 * // FIXME add the carpaint to the call
		 *
		 * @param carConfigurationId
		 */
		$scope.getQuotePdf = function(carConfigurationId) {
			QuoteFactory.pdf({
				quoteCarConfigurationId: carConfigurationId,
				filterOptions: [],
				filterArticles: [],
				dealerOptions: [],
				currentTabPage: 'TAB_QUOTE',
				financeType: 'PRIVATE_ONCE',
				financeDuration: null,
				carPaintId: null,
			})
				.$promise.then(function(data) {
					// var file = new Blob([data], {type: 'application/pdf'});
					// var fileURL = URL.createObjectURL(file);
					// result is PDF, we created an url to it which will be opened in new tab/window
					//for browser compatibility
					var ieEDGE = navigator.userAgent.match(/Edge/g);
					var ie = navigator.userAgent.match(/.NET/g); // IE 11+
					var oldIE = navigator.userAgent.match(/MSIE/g);
					var name = 'quote-' + carConfigurationId;
					var blob = new window.Blob([data.data], { type: 'application/pdf' });

					if (ie || oldIE || ieEDGE) {
						// IE browsers
						var fileName = name + '.pdf';
						window.navigator.msSaveBlob(blob, fileName); // shows the "save as" dialog in browser
					} else {
						// non IE browsers
						var file = new Blob([data.data], {
							type: 'application/pdf',
						});
						var fileURL = URL.createObjectURL(file);
						console.log('Quote PDF was found and will download with ' + fileURL);

						var a = document.createElement('a');
						a.href = fileURL;
						a.target = '_blank';
						a.download = name + '.pdf';
						document.body.appendChild(a);
						a.click(); // shows the "save as" dialog in browser
					}
				})
				.catch(function(error) {
					// something went wrong...
					console.error('Quote PDF was not found or something else went wrong', error);
					AlertService.error('Failed to download quote pdf');
				});
		};

		$scope.uploadExcel = function(file) {
			file.upload = Upload.upload({
				url: '/api/emotive/upload',
				data: {
					file: file,
					selectedModelVersion: $scope.selectedModelVersion,
				},
			});

			file.upload
				.then(function(response) {
					$timeout(function() {
						var data = (file.result = response.data);
					});
					AlertService.success('excelimport.messages.import.success', { excelFileName: file.name });
					$timeout(function() {
						$scope.showProgress = false;
						$scope.loadPage();
					}, 2000);
				})
				.catch(function(error) {
					AlertService.error(error.data.detail);
					$timeout(function() {
						$scope.showProgress = false;
						$scope.excelFile = undefined;
						$scope.loadPage();
					}, 2000);
				})
				.finally(function(evt) {
					$scope.closeModal();
					// Math.min is to fix IE which reports 200% sometimes
					file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
				});
		};

		$scope.clearForm = function() {
			$scope.excelFile = undefined;
		};

		$scope.closeModal = function() {
			$scope.modalInstance.close();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarStockSearchOverview.predicate === itemName) {
				if ($scope.CarStockSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updatePriceStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateStockStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateSellingStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePipelineStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateLocationUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateSellerUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePublishSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarStockDiscountOriginUISelect = function(item, model) {
			$scope.search();
		};
	});
