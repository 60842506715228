/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigInterestListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		InterestFactory,
		CarConfigInterestListDtoSearchFactory,
		CarConfigInterestViewDtoFactory
	) {
		$scope.interestList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeInterest)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeInterest);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeInterest = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-interest.new');
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.InterestSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.InterestSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.InterestSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone($scope.InterestSearchOverview.validFromFrom);
			} else {
				$scope.InterestSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.InterestSearchOverview.validFromToString = $rootScope.convertDateWithoutZone($scope.InterestSearchOverview.validFromTo);
			} else {
				$scope.InterestSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.InterestSearchOverview = angular.copy($scope.InterestSearchOverview);

			$rootScope.pageSizeInterest = angular.copy($scope.pageSize);

			CarConfigInterestListDtoSearchFactory.query(
				{
					page: $scope.InterestSearchOverview.page - 1,
					size: $scope.pageSize,
					percentageFrom: $scope.InterestSearchOverview.percentageFrom,
					percentageTo: $scope.InterestSearchOverview.percentageTo,
					validFromFrom: $scope.InterestSearchOverview.validFromFromString,
					validFromTo: $scope.InterestSearchOverview.validFromToString,
					interestTypeList: $scope.InterestSearchOverview.interestTypeList,

					sort: [$scope.InterestSearchOverview.predicate + ',' + ($scope.InterestSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.interestList = result.items;

					$scope.interestTypeList = result.interestTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isInterestSearchOverviewDirty = function() {
			return !angular.equals($scope.InterestSearchOverview, $scope.originalInterestSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.InterestSearchOverview = {};
			$rootScope.InterestSearchOverview.page = 1;
			$rootScope.InterestSearchOverview.predicate = 'id';
			$rootScope.InterestSearchOverview.ascending = false;

			$scope.InterestSearchOverview = angular.copy($rootScope.InterestSearchOverview);

			$scope.originalInterestSearchOverview = angular.copy($scope.InterestSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.InterestSearchOverview) || $rootScope.InterestSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.InterestSearchOverview = angular.copy($rootScope.InterestSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedInterestId = id;
			$('#deleteInterestConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigInterestViewDtoFactory.delete(
				{ id: $scope.selectedInterestId },
				function() {
					$scope.search();
					$('#deleteInterestConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteInterestConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:interestUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.InterestSearchOverview.predicate === itemName) {
				if ($scope.InterestSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateInterestTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
