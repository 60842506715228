/**
 * Component receives DTO from parent component and modifies it using it as a ng-model for inputs. This is only interaction of component with external world
 * Component will create instances of the localized values if supported locales contain values and localized inputs are not defined for supportedLocales locales
 */

// $ctrl contains reference to an instance of the controller (the one defined together with component)
// $ctrl.dto is provided from parent component and is modified by this component
angular.module("mmmApp").component("localizedInput", {
    template:
    '          <div\n' +
        '            ng-if="errorStateMessage"\n' +
        '            class="alert alert-danger"\n' +
        '          >\n' +
        '            {{errorStateMessage}}\n' +
        '          </div>\n' +
        '          <div\n' +
        '            ng-if="!errorStateMessage"\n' +
        '            ng-repeat="inputDescriptor in inputDescriptors"\n' +
        '            class="localizedinput-group"\n' +
        '          >\n' +
        '            <div\n' +
        '            class="input-group"\n' +
        '              ng-class="{ \'has-error\': $ctrl.parentform.{{inputDescriptor.inputIdentifier}}.$invalid, \'has-success\' : $ctrl.parentform.{{inputDescriptor.inputIdentifier}}.$valid && $ctrl.parentform.{{inputDescriptor.inputIdentifier}}.$dirty }"\n' +
        '            >\n' +
        '                <span class="input-group-addon">{{inputDescriptor.locale}}</span>\n' +
        '                <input\n' +
        '                  ng-if="inputType === \'input\'"\n' +
        '                  type="text"\n' +
        '                  name="{{inputDescriptor.inputIdentifier}}"\n' +
        '                  id="{{inputDescriptor.inputIdentifier}}"\n' +
        '                  ng-model="$ctrl.dto.localized.translations[inputDescriptor.dtoLocalizedIndex].translationValue"\n' +
        '                  class="form-control input-sm"\n' +
        '                  ng-required="isRequired"\n' +
        '                  ng-maxlength="{{maxLength}}"\n' +
        '                />\n' +
        '                <textarea\n' +
        '                  ng-if="inputType === \'textarea\'"\n' +
        '                  rows="{{inputLinesCount}}"\n' +
        '                  name="{{inputDescriptor.inputIdentifier}}"\n' +
        '                  ng-model="$ctrl.dto.localized.translations[inputDescriptor.dtoLocalizedIndex].translationValue"\n' +
        '                  class="form-control input-sm"\n' +
        '                  ng-required="isRequired"\n' +
        '                  ng-maxlength="{{maxLength}}"\n' +
        '                />\n' +
        '            </div>\n' +
        '            <p\n' +
        '                class="help-block"\n' +
        '                ng-show="{{inputDescriptor.validationPathRequired}}"\n' +
        '                translate="entity.validation.required"\n' +
        '            ></p>\n' +
        '            <p\n' +
        '                class="help-block"\n' +
        '                ng-show="{{inputDescriptor.validationPathMaxLength}}"\n' +
        '                translate="entity.validation.maxlength"\n' +
        '                translate-value-maxlength="{{maxLength}}"\n' +
        '            ></p>\n' +
        '          </div>\n'
    ,
    bindings: {
        // Full DTO object. Component will change some values in that object
        dto: "=",
        // [start:dmitro]  ACHTUNG using camelCase will break functionality. No idea why
        parentform: "=",
        entityname: "@",
        propertyname: "@",
        // [end:dmitro]
        // This property will contain an object
        params: "<"
    },
    controller: function($scope, LocalizedInputService) {
        /**
         * Holds value if any of the validation checks failed. Should simplify debugging of the integration
         * @type {string}
         */
        $scope.errorStateMessage = "";
        const errorStateMessage = verifyComponentsBindings(this);
        if (errorStateMessage) {
            console.error(errorStateMessage);
            $scope.errorStateMessage = errorStateMessage;
            return;
        }
        ensureDTOConsistency(this, LocalizedInputService);
        /**
         * These will contain object describing how to render input and to what
         * value to map it in the externally provided DTO
         */
        $scope.inputDescriptors = getInputDescriptors(
            this,
            LocalizedInputService
        );
        $scope.parentform = this.parentform;
        $scope.inputType = this.params.linesCount === 1 ? "input" : "textarea";
        $scope.inputLinesCount = this.params.linesCount;
        $scope.isRequired = !!this.params.required;
        $scope.maxLength = this.params.maxLength || '';
        $scope.minLength = this.params.minLength || '';
    }
});

function verifyComponentsBindings(controllerInstance) {
    if (!controllerInstance.parentform) {
        return "parentForm object is not provided";
    }
    if (!controllerInstance.dto) {
        return "DTO object is not provided";
    }
    if (!controllerInstance.dto.localized) {
        return "Input's DTO does not contain localized field";
    }
    if (!Array.isArray(controllerInstance.dto.localized.supportedLocales)) {
        return "Input's DTO.localized.supportedLocales should be an array";
    }
    if (!Array.isArray(controllerInstance.dto.localized.translatableFields)) {
        return "Input's DTO.localized.translatableFields should be an array";
    }
    if (!Array.isArray(controllerInstance.dto.localized.translations)) {
        return "Input's DTO.localized.translations should be an array";
    }
    if (!controllerInstance.propertyname) {
        return "Propery name is not provided";
    }
    if (!controllerInstance.entityname) {
        return "Entity name is not provided";
    }
    if (!controllerInstance.params) {
        return "Mandatory input with params is not provided";
    }
    if (
        typeof controllerInstance.params.linesCount !== "number" ||
        controllerInstance.params.linesCount <= 0
    ) {
        return "Mandatory params.linesCount must be a number greater than 0";
    }
    return "";
}

/**
 * Ensures that corresponding localized records present in the DTO. These records might be absent for
 * DTO when one is provided in response to creation of the DTO's entity.
 */
function ensureDTOConsistency(controllerInstance, LocalizedInputService) {
    for (
        let i = 0;
        i < controllerInstance.dto.localized.supportedLocales.length;
        i += 1
    ) {
        const locale = controllerInstance.dto.localized.supportedLocales[i];
        const localizedIndex = LocalizedInputService.findLocalizedEntryIndex(
            controllerInstance.dto.localized.translations,
            locale,
            controllerInstance.entityname,
            controllerInstance.propertyname
        );
        if (localizedIndex === -1) {
            controllerInstance.dto.localized.translations.push(
                LocalizedInputService.createLocalizedEntryInstance(
                    locale,
                    controllerInstance.entityname,
                    controllerInstance.propertyname
                )
            );
        }
    }
}

/**
 * Takes controller and based values provided by the parent component generate objects which describe what inputs to render and where to map their value in the provided dto.localized...
 */
function getInputDescriptors(controllerInstance, LocalizedInputService) {
    const res = [];
    for (
        let i = 0;
        i < controllerInstance.dto.localized.translations.length;
        i += 1
    ) {
        const localizedInstance = controllerInstance.dto.localized.translations[i];
        if (
            localizedInstance.entityName === controllerInstance.entityname &&
            localizedInstance.propertyName === controllerInstance.propertyname
        ) {
            const inputIdentifier = LocalizedInputService.createInputIdentifier(
                localizedInstance.locale,
                localizedInstance.entityName,
                localizedInstance.propertyName
            );
            res.push({
                locale: localizedInstance.locale,
                dtoLocalizedIndex: i,
                inputIdentifier: inputIdentifier,
                validationPathRequired:
                    "parentform." + inputIdentifier + ".$error.required",
                validationPathMaxLength:
                    "parentform." + inputIdentifier + ".$error.maxlength",
                validationPathMinLength:
                    "parentform." + inputIdentifier + ".$error.minlength"
            });
        }
    }
    return res;
}
