/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtMappingCarModelEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtMappingCarModelEditDtoFactory,
		AlertService,
		HistoryService,
		StockMgmtMappingCarModelEditDtoSaveAndCreateFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.mappingCarModel = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.mappingCarModel['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.mappingCarModel) && $rootScope.isDefinedAndNotNull($scope.mappingCarModel.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('stock-mgmt-mappingcarmodel.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:mappingCarModelUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			StockMgmtMappingCarModelEditDtoFactory.update($scope.mappingCarModel, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.mappingCarModel.id != null) {
				StockMgmtMappingCarModelEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.mappingCarModel,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				StockMgmtMappingCarModelEditDtoSaveAndCreateFactory.createAndNew(
					$scope.mappingCarModel,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
