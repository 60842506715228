/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarModelYearListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarModelYearFactory,
		CarConfigCarModelYearListDtoSearchFactory,
		CarConfigCarModelYearViewDtoFactory
	) {
		$scope.carModelYearList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarModelYear)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarModelYear);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarModelYear = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-carmodelyear.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getCarModelYearList = function() {
			CarConfigCarModelYearListDtoSearchFactory.query(
				{
					brandId: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					carModelId: angular.isDefined($scope.carModelId) ? $scope.carModelId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carModelYearList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarModelYearList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarModelYearId = id;
			$('#deleteCarModelYearConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarModelYearViewDtoFactory.delete(
				{ id: $scope.selectedCarModelYearId },
				function() {
					$scope.loadAll();
					$('#deleteCarModelYearConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarModelYearConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
