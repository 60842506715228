/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ArticleFilterOptionFactory', function($resource) {
	return $resource(
		'api/articleFilterOptions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigArticleFilterOptionViewDto
 */
angular.module('mmmApp').factory('CarConfigArticleFilterOptionViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articlefilteroption/:id',
		{},
		{
			// Factory is only used to delete a CarConfigArticleFilterOptionViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigArticleFilterOptionListDto's
 */
angular.module('mmmApp').factory('CarConfigArticleFilterOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/articlefilteroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewArticleFilterOptionFactory', function($resource) {
	return $resource(
		'api/articleFilterOptions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ArticleFilterOptions without the use of paging
angular.module('mmmApp').factory('NoPagingArticleFilterOptionFactory', function($resource) {
	return $resource(
		'api/articleFilterOptions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllArticleFilterOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articleFilterOptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
