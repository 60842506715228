/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CategoryDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.category = entity;
	$scope.objectName = 'Category';
	$scope.categoryId = $stateParams.categoryId;

	if (angular.isUndefined($rootScope.categoryActiveTab)) {
		//set the default here
		$rootScope.categoryActiveTab = 0;
	}

	$scope.goToEditState = function(category) {
		$state.go('category.detail.edit({categoryId:category.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:categoryUpdate', function(event, result) {
		$scope.category = result;
	});
});
