/** @format */

'use strict';

// CrmCarMediaDocument has edit layout enabled. Edit layout template url: crmcarmediadocument-dialog.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CrmCarMediaDocument'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('crmcarmediadocument'); // main translation loaded for crmcarmediadocument
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('document'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // coupling entity reference loaded for crmcar
		$translatePartialLoader.addPart('document'); // coupling entity reference loaded for document

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-crmcarmediadocument', {
		/* abstract state for DTO RelationMgmtCrmCarMediaDocumentListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/crmcarmediadocument',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarMediaDocument',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCrmCarMediaDocumentListDto */
		/* to access: ui-ref="relation-mgmt-crmcarmediadocument.list" */
		/* url: http://<hostname>/relationmgmt/crmcarmediadocument/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarMediaDocumentListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-crmcarmediadocument.list', dtoListState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCrmCarMediaDocumentEditDto */
		/* to access: ui-sref="relation-mgmt-crmcarmediadocument.edit" */
		/* url: http://<hostname>/relationmgmt/crmcarmediadocument/edit/:crmCarMediaDocumentId */
		url: '/edit/:crmCarMediaDocumentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarMediaDocumentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarMediaDocumentEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarMediaDocumentEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarMediaDocumentEditDtoFactory.get({ id: $stateParams.crmCarMediaDocumentId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcarmediadocument.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCrmCarMediaDocumentEditDto */
		/* to access: ui-sref="relation-mgmt-crmcarmediadocument.new" */
		/* url: http://<hostname>/relationmgmt/crmcarmediadocument/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarMediaDocumentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarMediaDocumentNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarMediaDocumentNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarMediaDocumentNewDtoFactory.get({ id: $stateParams.crmCarMediaDocumentId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcarmediadocument.new', dtoCreateState);

	$stateProvider.state('stock-mgmt-crmcarmediadocument', {
		/* abstract state for DTO StockMgmtCrmCarMediaDocumentListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/crmcarmediadocument',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCarMediaDocument',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCrmCarMediaDocumentListDto */
		/* to access: ui-ref="stock-mgmt-crmcarmediadocument.list" */
		/* url: http://<hostname>/stockmgmt/crmcarmediadocument/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-stock-mgmt.html',
				controller: 'StockMgmtCrmCarMediaDocumentListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-crmcarmediadocument.list', dtoListState);
});
