/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtVehicleOptionCategoryListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		VehicleOptionCategoryFactory,
		StockMgmtVehicleOptionCategoryListDtoSearchFactory,
		StockMgmtVehicleOptionCategoryViewDtoFactory
	) {
		$scope.vehicleOptionCategoryList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeVehicleOptionCategory)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeVehicleOptionCategory);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeVehicleOptionCategory = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-vehicleoptioncategory.new');
		};

		$scope.getVehicleOptionCategoryList = function() {
			StockMgmtVehicleOptionCategoryListDtoSearchFactory.query(
				{
					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.vehicleOptionCategoryList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getVehicleOptionCategoryList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedVehicleOptionCategoryId = id;
			$('#deleteVehicleOptionCategoryConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtVehicleOptionCategoryViewDtoFactory.delete(
				{ id: $scope.selectedVehicleOptionCategoryId },
				function() {
					$scope.loadAll();
					$('#deleteVehicleOptionCategoryConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteVehicleOptionCategoryConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
