/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('GroupMemberDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.groupMember = entity;
	$scope.objectName = 'GroupMember';
	$scope.groupMemberId = $stateParams.groupMemberId;

	if (angular.isUndefined($rootScope.groupMemberActiveTab)) {
		//set the default here
		$rootScope.groupMemberActiveTab = 0;
	}

	$scope.goToEditState = function(groupMember) {
		$state.go('groupmember.detail.edit({groupMemberId:groupMember.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:groupMemberUpdate', function(event, result) {
		$scope.groupMember = result;
	});
});
