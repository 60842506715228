/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/mappingEnergyLabels/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingEnergyLabelViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingEnergyLabelViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingenergylabel/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingEnergyLabelListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingEnergyLabelListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingenergylabel',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingEnergyLabelEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingEnergyLabelEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingenergylabel/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingEnergyLabelEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingEnergyLabelNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingenergylabel/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingEnergyLabelEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingEnergyLabelEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingenergylabel/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/mappingEnergyLabels/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingEnergyLabels without the use of paging
angular.module('mmmApp').factory('NoPagingMappingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/mappingEnergyLabels/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingEnergyLabel/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible EnergyLabels Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/mappingEnergyLabel/energyLabels',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingEnergyLabelsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingEnergyLabels/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingEnergyLabelSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingenergylabels/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
