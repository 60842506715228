/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('FilterOptionFactory', function($resource) {
	return $resource(
		'api/filterOptions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigFilterOptionViewDto
 */
angular.module('mmmApp').factory('CarConfigFilterOptionViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filteroption/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigFilterOptionListDto's
 */
angular.module('mmmApp').factory('CarConfigFilterOptionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/filteroption',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigFilterOptionEditDto
 */
angular.module('mmmApp').factory('CarConfigFilterOptionEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filteroption/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigFilterOptionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigFilterOptionNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filteroption/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewFilterOptionFactory', function($resource) {
	return $resource(
		'api/filterOptions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all FilterOptions without the use of paging
angular.module('mmmApp').factory('NoPagingFilterOptionFactory', function($resource) {
	return $resource(
		'api/filterOptions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleFilterOptionsByFilterOptionFactory', function($resource) {
	return $resource(
		'api/articleFilterOptions/filterOption/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('ArticleFromArticleFilterOptionPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleFilterOptionList/AsArticleList/filterOption/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllFilterOptionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/filterOptions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
