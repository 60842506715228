/** @format */

(function() {
	'use strict';

	angular.module('mmmApp').directive('pageRibbon', pageRibbon);

	pageRibbon.$inject = ['ProfileService', '$rootScope', '$translate'];

	function pageRibbon(ProfileService, $rootScope, $translate) {
		var directive = {
			replace: true,
			restrict: 'AE',
			template: '<div class="ribbon hidden"><a href="" data-translate="global.ribbon.{{ribbonEnv}}">{{ribbonEnv}}</a></div>',
			link: linkFunc,
		};

		return directive;

		function linkFunc(scope, element, attrs) {
			ProfileService.getProfileInfo().then(function(response) {
				// [dmitro] adding condition as sometimes response is undefined
				if (response && response.ribbonEnv) {
					scope.ribbonEnv = response.ribbonEnv;
					element.addClass(response.ribbonEnv);
					element.removeClass('hidden');
				}
			});
		}
	}
})();
