/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarOptionDependentPriceFactory', function($resource) {
	return $resource(
		'api/carOptionDependentPrices/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarOptionDependentPriceViewDto
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarOptionDependentPriceListDto's
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarOptionDependentPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarOptionDependentPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarOptionDependentPriceEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of CarConfigCarOptionDependentPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarOptionDependentPriceBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/caroptiondependentprice/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarOptionDependentPriceEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarOptionDependentPriceEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/caroptiondependentprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarOptionDependentPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarOptionDependentPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/caroptiondependentprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarOptionDependentPriceFactory', function($resource) {
	return $resource(
		'api/carOptionDependentPrices/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarOptionDependentPrices without the use of paging
angular.module('mmmApp').factory('NoPagingCarOptionDependentPriceFactory', function($resource) {
	return $resource(
		'api/carOptionDependentPrices/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarOptionDependentPricesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carOptionDependentPrices/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
