/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('ArticleTagDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.articleTag = entity;
	$scope.objectName = 'ArticleTag';
	$scope.articleTagId = $stateParams.articleTagId;

	if (angular.isUndefined($rootScope.articleTagActiveTab)) {
		//set the default here
		$rootScope.articleTagActiveTab = 0;
	}

	$scope.goToEditState = function(articleTag) {
		$state.go('articletag.detail.edit({articleTagId:articleTag.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:articleTagUpdate', function(event, result) {
		$scope.articleTag = result;
	});
});
