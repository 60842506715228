/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('AdminApplicationLogListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ApplicationLogFactory,
		AdminApplicationLogListDtoSearchFactory
	) {
		$scope.applicationLogList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeApplicationLog)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeApplicationLog);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeApplicationLog = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('admin-applicationlog.new');
		};

		// handle attribute timestamp of javaType java.time.ZonedDateTime
		$scope.processTimestamp = function() {
			var timestampFromDefined = $rootScope.isDefinedAndNotNull($scope.ApplicationLogSearchOverview.timestampFrom);
			var timestampToDefined = $rootScope.isDefinedAndNotNull($scope.ApplicationLogSearchOverview.timestampTo);

			// TODO either we assign a value, or undefined, plus we check for undefined, hence we could assign the variable, period

			if (timestampFromDefined) {
				$scope.ApplicationLogSearchOverview.timestampFromString = $rootScope.convertDateWithoutZone(
					$scope.ApplicationLogSearchOverview.timestampFrom
				);
			} else {
				$scope.ApplicationLogSearchOverview.timestampFromString = undefined;
			}

			if (timestampToDefined) {
				$scope.ApplicationLogSearchOverview.timestampToString = $rootScope.convertDateWithoutZone(
					$scope.ApplicationLogSearchOverview.timestampTo
				);
			} else {
				$scope.ApplicationLogSearchOverview.timestampToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processTimestamp();

			$rootScope.ApplicationLogSearchOverview = angular.copy($scope.ApplicationLogSearchOverview);

			$rootScope.pageSizeApplicationLog = angular.copy($scope.pageSize);

			AdminApplicationLogListDtoSearchFactory.query(
				{
					page: $scope.ApplicationLogSearchOverview.page - 1,
					size: $scope.pageSize,
					timestampFrom: $scope.ApplicationLogSearchOverview.timestampFromString,
					timestampTo: $scope.ApplicationLogSearchOverview.timestampToString,
					description: $scope.ApplicationLogSearchOverview.description,
					additionalInfo: $scope.ApplicationLogSearchOverview.additionalInfo,
					typeList: $scope.ApplicationLogSearchOverview.typeList,
					statusList: $scope.ApplicationLogSearchOverview.statusList,

					sort: [$scope.ApplicationLogSearchOverview.predicate + ',' + ($scope.ApplicationLogSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.applicationLogList = result.items;

					$scope.applicationTypeList = result.typeSearchOptions;

					$scope.applicationLogStatusList = result.statusSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isApplicationLogSearchOverviewDirty = function() {
			return !angular.equals($scope.ApplicationLogSearchOverview, $scope.originalApplicationLogSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ApplicationLogSearchOverview = {};
			$rootScope.ApplicationLogSearchOverview.page = 1;
			$rootScope.ApplicationLogSearchOverview.predicate = 'id';
			$rootScope.ApplicationLogSearchOverview.ascending = false;

			$scope.ApplicationLogSearchOverview = angular.copy($rootScope.ApplicationLogSearchOverview);

			$scope.originalApplicationLogSearchOverview = angular.copy($scope.ApplicationLogSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ApplicationLogSearchOverview) || $rootScope.ApplicationLogSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ApplicationLogSearchOverview = angular.copy($rootScope.ApplicationLogSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ApplicationLogSearchOverview.predicate === itemName) {
				if ($scope.ApplicationLogSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};
	});
