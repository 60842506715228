/** @format */

'use strict';

// CarConfigurationPackage has edit layout enabled. Edit layout template url: carconfigurationpackage-dialog.html
// CarConfigurationPackage has detail layout enabled. Detail layout template url: carconfigurationpackage-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarConfigurationPackage'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carconfigurationpackage'); // main translation loaded for carconfigurationpackage
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('article-api-carconfigurationpackage', {
		/* abstract state for DTO ArticleApiCarConfigurationPackageListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carconfigurationpackage',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarConfigurationPackage',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarConfigurationPackageEditDto */
		/* to access: ui-sref="article-api-carconfigurationpackage.edit" */
		/* url: http://<hostname>/articleapi/carconfigurationpackage/edit/:carConfigurationPackageId */
		url: '/edit/:carConfigurationPackageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationPackageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationPackageEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationPackageEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationPackageEditDtoFactory.get({ id: $stateParams.carConfigurationPackageId }).$promise.then(
							function(result) {
								return result;
							}
						);
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfigurationpackage.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarConfigurationPackageEditDto */
		/* to access: ui-sref="article-api-carconfigurationpackage.new" */
		/* url: http://<hostname>/articleapi/carconfigurationpackage/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationPackageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationPackageNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationPackageNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationPackageNewDtoFactory.get({ id: $stateParams.carConfigurationPackageId }).$promise.then(
							function(result) {
								return result;
							}
						);
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfigurationpackage.new', dtoCreateState);

	$stateProvider.state('car-config-carconfigurationpackage', {
		/* abstract state for DTO CarConfigCarConfigurationPackageListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carconfigurationpackage',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarConfigurationPackage',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarConfigurationPackageListDto */
		/* to access: ui-ref="car-config-carconfigurationpackage.list" */
		/* url: http://<hostname>/car/config/carconfigurationpackage/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-list-car-config.html',
				controller: 'CarConfigCarConfigurationPackageListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carconfigurationpackage.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarConfigurationPackageViewDto */
		/* to access: ui-sref="car-config-carconfigurationpackage.detail" */
		/* url: http://<hostname>/car/config/carconfigurationpackage/detail/:carConfigurationPackageId */
		url: '/detail/:carConfigurationPackageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-view-car-config.html',
				controller: 'CarConfigurationPackageDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarConfigurationPackageViewDtoFactory',
				function($stateParams, CarConfigCarConfigurationPackageViewDtoFactory) {
					return CarConfigCarConfigurationPackageViewDtoFactory.get({ id: $stateParams.carConfigurationPackageId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationpackage.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarConfigurationPackageEditDto */
		/* to access: ui-sref="car-config-carconfigurationpackage.edit" */
		/* url: http://<hostname>/car/config/carconfigurationpackage/edit/:carConfigurationPackageId */
		url: '/edit/:carConfigurationPackageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-edit-car-config.html',
				controller: 'CarConfigCarConfigurationPackageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationPackageEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationPackageEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationPackageEditDtoFactory.get({ id: $stateParams.carConfigurationPackageId }).$promise.then(
							function(result) {
								return result;
							}
						);
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationpackage.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarConfigurationPackageEditDto */
		/* to access: ui-sref="car-config-carconfigurationpackage.new" */
		/* url: http://<hostname>/car/config/carconfigurationpackage/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationpackage/carconfigurationpackage-edit-car-config.html',
				controller: 'CarConfigCarConfigurationPackageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationPackageNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationPackageNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationPackageNewDtoFactory.get({ id: $stateParams.carConfigurationPackageId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationpackage.new', dtoCreateState);
});
