/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtMappingCarBrandEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtMappingCarBrandEditDtoFactory,
		AlertService,
		HistoryService,
		StockMgmtMappingCarBrandEditDtoSaveAndCreateFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.mappingCarBrand = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.mappingCarBrand['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.mappingCarBrand) && $rootScope.isDefinedAndNotNull($scope.mappingCarBrand.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('stock-mgmt-mappingcarbrand.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:mappingCarBrandUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			StockMgmtMappingCarBrandEditDtoFactory.update($scope.mappingCarBrand, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.mappingCarBrand.id != null) {
				StockMgmtMappingCarBrandEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.mappingCarBrand,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				StockMgmtMappingCarBrandEditDtoSaveAndCreateFactory.createAndNew(
					$scope.mappingCarBrand,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
