/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigurationDependentPriceDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carConfigurationDependentPrice = entity;
		$scope.objectName = 'CarConfigurationDependentPrice';
		$scope.carConfigurationDependentPriceId = $stateParams.carConfigurationDependentPriceId;

		if (angular.isUndefined($rootScope.carConfigurationDependentPriceActiveTab)) {
			//set the default here
			$rootScope.carConfigurationDependentPriceActiveTab = 0;
		}

		$scope.goToEditState = function(carConfigurationDependentPrice) {
			$state.go(
				'carconfigurationdependentprice.detail.edit({carConfigurationDependentPriceId:carConfigurationDependentPrice.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carConfigurationDependentPriceUpdate', function(event, result) {
			$scope.carConfigurationDependentPrice = result;
		});
	});
