/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('EmailDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.email = entity;
	$scope.objectName = 'Email';
	$scope.emailId = $stateParams.emailId;

	if (angular.isUndefined($rootScope.emailActiveTab)) {
		//set the default here
		$rootScope.emailActiveTab = 0;
	}

	$scope.goToEditState = function(email) {
		$state.go('email.detail.edit({emailId:email.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:emailUpdate', function(event, result) {
		$scope.email = result;
	});
});
