/** @format */

'use strict';

// Country has edit layout enabled. Edit layout template url: country-dialog.html
// Country has detail layout enabled. Detail layout template url: country-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Country'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('country'); // main translation loaded for country

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-country', {
		/* abstract state for DTO RelationMgmtCountryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/country',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Country',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCountryListDto */
		/* to access: ui-ref="relation-mgmt-country.list" */
		/* url: http://<hostname>/relationmgmt/country/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-list-relation-mgmt.html',
				controller: 'RelationMgmtCountryListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-country.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtCountryViewDto */
		/* to access: ui-sref="relation-mgmt-country.detail" */
		/* url: http://<hostname>/relationmgmt/country/detail/:countryId */
		url: '/detail/:countryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-view-relation-mgmt.html',
				controller: 'CountryDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtCountryViewDtoFactory',
				function($stateParams, RelationMgmtCountryViewDtoFactory) {
					return RelationMgmtCountryViewDtoFactory.get({ id: $stateParams.countryId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-country.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCountryEditDto */
		/* to access: ui-sref="relation-mgmt-country.edit" */
		/* url: http://<hostname>/relationmgmt/country/edit/:countryId */
		url: '/edit/:countryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-edit-relation-mgmt.html',
				controller: 'RelationMgmtCountryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCountryEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCountryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCountryEditDtoFactory.get({ id: $stateParams.countryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-country.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCountryEditDto */
		/* to access: ui-sref="relation-mgmt-country.new" */
		/* url: http://<hostname>/relationmgmt/country/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/country/country-edit-relation-mgmt.html',
				controller: 'RelationMgmtCountryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCountryNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCountryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCountryNewDtoFactory.get({ id: $stateParams.countryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-country.new', dtoCreateState);
});
