/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCrmCarListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarFactory,
		StockMgmtCrmCarListDtoSearchFactory,
		StockMgmtCrmCarViewDtoFactory
	) {
		$scope.crmCarList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCar)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCar);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCar = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-crmcar.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getCrmCarList = function() {
			StockMgmtCrmCarListDtoSearchFactory.query(
				{
					carConfigurationIdList: angular.isDefined($scope.carConfigurationId) ? $scope.carConfigurationId : null,
					carConfigurationPackageId: angular.isDefined($scope.carConfigurationPackageId) ? $scope.carConfigurationPackageId : null,
					modelIdList: angular.isDefined($scope.modelId) ? $scope.modelId : null,
					brandIdList: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					paintId: angular.isDefined($scope.paintId) ? $scope.paintId : null,
					colorIdList: angular.isDefined($scope.colorId) ? $scope.colorId : null,
					transmissionList: angular.isDefined($scope.transmissionId) ? $scope.transmissionId : null,
					bodyTypeList: angular.isDefined($scope.bodyTypeId) ? $scope.bodyTypeId : null,
					fuelTypeList: angular.isDefined($scope.fuelTypeId) ? $scope.fuelTypeId : null,
					doorsList: angular.isDefined($scope.doorsId) ? $scope.doorsId : null,
					energyLabelList: angular.isDefined($scope.energyLabelId) ? $scope.energyLabelId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,
					countryList: angular.isDefined($scope.countryId) ? $scope.countryId : null,
					co2origin: angular.isDefined($scope.co2originId) ? $scope.co2originId : null,
					vehicleDetailsId: angular.isDefined($scope.vehicleDetailsId) ? $scope.vehicleDetailsId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.crmCarList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCrmCarList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarId = id;
			$('#deleteCrmCarConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtCrmCarViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarId },
				function() {
					$scope.loadAll();
					$('#deleteCrmCarConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateModelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateColorUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTransmissionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBodyTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateFuelTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateDoorsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};
	});
