/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCarConfigurationPackageArticleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationPackageArticleFactory,
		RelationMgmtCarConfigurationPackageArticleListDtoSearchFactory,
		AutocompleteRelationMgmtArticlesByQueryParamsFactory
	) {
		$scope.carConfigurationPackageArticleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfigurationPackageArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfigurationPackageArticle);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfigurationPackageArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-carconfigurationpackagearticle.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationPackageArticleSearchOverview.article)) {
				return $scope.CarConfigurationPackageArticleSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarConfigurationPackageArticleSearchOverview = angular.copy($scope.CarConfigurationPackageArticleSearchOverview);

			$rootScope.pageSizeCarConfigurationPackageArticle = angular.copy($scope.pageSize);

			RelationMgmtCarConfigurationPackageArticleListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationPackageArticleSearchOverview.page - 1,
					size: $scope.pageSize,
					carConfigurationPackageIdList: $scope.CarConfigurationPackageArticleSearchOverview.carConfigurationPackageIdList,
					articleId: getArticleId(),

					sort: [
						$scope.CarConfigurationPackageArticleSearchOverview.predicate +
							',' +
							($scope.CarConfigurationPackageArticleSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationPackageArticleList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationPackageArticleSearchOverviewDirty = function() {
			return !angular.equals(
				$scope.CarConfigurationPackageArticleSearchOverview,
				$scope.originalCarConfigurationPackageArticleSearchOverview
			);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationPackageArticleSearchOverview = {};
			$rootScope.CarConfigurationPackageArticleSearchOverview.page = 1;
			$rootScope.CarConfigurationPackageArticleSearchOverview.predicate = 'id';
			$rootScope.CarConfigurationPackageArticleSearchOverview.ascending = false;

			$scope.CarConfigurationPackageArticleSearchOverview = angular.copy($rootScope.CarConfigurationPackageArticleSearchOverview);

			$scope.originalCarConfigurationPackageArticleSearchOverview = angular.copy($scope.CarConfigurationPackageArticleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.CarConfigurationPackageArticleSearchOverview) ||
				$rootScope.CarConfigurationPackageArticleSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationPackageArticleSearchOverview = angular.copy($rootScope.CarConfigurationPackageArticleSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationPackageArticleSearchOverview.predicate === itemName) {
				if ($scope.CarConfigurationPackageArticleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationPackageUISelect = function(item, model) {
			$scope.search();
		};
	});
