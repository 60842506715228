/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CrmCarMediaDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarMediaDocuments/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCrmCarMediaDocumentViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarMediaDocumentViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarmediadocument/:id',
		{},
		{
			// Factory is only used to delete a RelationMgmtCrmCarMediaDocumentViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCrmCarMediaDocumentListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarMediaDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarmediadocument',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCrmCarMediaDocumentEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarMediaDocumentEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarmediadocument/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCrmCarMediaDocumentEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarMediaDocumentNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcarmediadocument/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of StockMgmtCrmCarMediaDocumentListDto's
 */
angular.module('mmmApp').factory('StockMgmtCrmCarMediaDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcarmediadocument',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCrmCarMediaDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarMediaDocuments/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CrmCarMediaDocuments without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarMediaDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarMediaDocuments/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCrmCarMediaDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/crmCarMediaDocuments/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
