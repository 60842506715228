/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('VehiclePriceInformationFactory', function($resource) {
	return $resource(
		'api/vehiclePriceInformations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewVehiclePriceInformationFactory', function($resource) {
	return $resource(
		'api/vehiclePriceInformations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all VehiclePriceInformations without the use of paging
angular.module('mmmApp').factory('NoPagingVehiclePriceInformationFactory', function($resource) {
	return $resource(
		'api/vehiclePriceInformations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceSourceFactory', function($resource) {
	return $resource(
		'api/vehiclePriceInformation/priceSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllVehiclePriceInformationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/vehiclePriceInformations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
