/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('filteroptionsOverviewMappedByFiltertype', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/filteroption/filteroption-tab.html',
		controller: 'FilterOptionTabController',
		scope: {
			filterTypeId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigFilterOptionMappedByFilterType', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/filteroption/filteroption-list-car-config.tab.html',
		controller: 'CarConfigFilterOptionListDtoTabController',
		scope: {
			filterTypeId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('filterpagesOverviewMappedByFiltertype', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/filterpage/filterpage-tab.html',
		controller: 'FilterPageTabController',
		scope: {
			filterTypeId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carConfigFilterPageMappedByFilterType', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/filterpage/filterpage-list-car-config.tab.html',
		controller: 'CarConfigFilterPageListDtoTabController',
		scope: {
			filterTypeId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
