/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigInterestListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		InterestFactory,
		CarConfigInterestListDtoSearchFactory,
		CarConfigInterestViewDtoFactory
	) {
		$scope.interestList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeInterest)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeInterest);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeInterest = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-interest.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getInterestList = function() {
			CarConfigInterestListDtoSearchFactory.query(
				{
					interestTypeList: angular.isDefined($scope.interestTypeId) ? $scope.interestTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.interestList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getInterestList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedInterestId = id;
			$('#deleteInterestConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigInterestViewDtoFactory.delete(
				{ id: $scope.selectedInterestId },
				function() {
					$scope.loadAll();
					$('#deleteInterestConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteInterestConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:interestUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateInterestTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
