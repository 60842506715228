/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('DealerHexonPortalDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.dealerHexonPortal = entity;
		$scope.objectName = 'DealerHexonPortal';
		$scope.dealerHexonPortalId = $stateParams.dealerHexonPortalId;

		if (angular.isUndefined($rootScope.dealerHexonPortalActiveTab)) {
			//set the default here
			$rootScope.dealerHexonPortalActiveTab = 0;
		}

		$scope.goToEditState = function(dealerHexonPortal) {
			$state.go('dealerhexonportal.detail.edit({dealerHexonPortalId:dealerHexonPortal.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:dealerHexonPortalUpdate', function(event, result) {
			$scope.dealerHexonPortal = result;
		});
	});
