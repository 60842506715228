/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarConfigurationPackageFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackages/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarConfigurationPackageEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationPackageEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationpackage/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarConfigurationPackageEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationPackageNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationpackage/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarConfigurationPackageEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationPackageEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationpackage/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarConfigurationPackageViewDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackage/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarConfigurationPackageListDto's
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackage',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarConfigurationPackageEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackage/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationPackageEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackage/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarConfigurationPackageEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackage/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarConfigurationPackageFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackages/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarConfigurationPackages without the use of paging
angular.module('mmmApp').factory('NoPagingCarConfigurationPackageFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackages/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackage/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackage/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationPackageArticlesByCarConfigurationPackageFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackageArticles/carConfigurationPackage/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('ArticleFromCarConfigurationPackageArticlePicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/carConfigurationPackageArticleList/AsArticleList/carConfigurationPackage/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarConfigurationPackagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carConfigurationPackages/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarConfigurationPackageSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carconfigurationpackages/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
