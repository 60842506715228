/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtConsignmentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ConsignmentFactory,
		StockMgmtConsignmentListDtoSearchFactory,
		StockMgmtConsignmentViewDtoFactory,
		AutocompleteStockMgmtCarStocksByQueryParamsFactory
	) {
		$scope.consignmentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeConsignment)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeConsignment);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeConsignment = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-consignment.new');
		};

		var getAgreementId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ConsignmentSearchOverview.agreement)) {
				return $scope.ConsignmentSearchOverview.agreement.id;
			} else {
				return undefined;
			}
		};

		var getOriginId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ConsignmentSearchOverview.origin)) {
				return $scope.ConsignmentSearchOverview.origin.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ConsignmentSearchOverview = angular.copy($scope.ConsignmentSearchOverview);

			$rootScope.pageSizeConsignment = angular.copy($scope.pageSize);

			StockMgmtConsignmentListDtoSearchFactory.query(
				{
					page: $scope.ConsignmentSearchOverview.page - 1,
					size: $scope.pageSize,
					agreementId: getAgreementId(),
					originId: getOriginId(),

					sort: [$scope.ConsignmentSearchOverview.predicate + ',' + ($scope.ConsignmentSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.consignmentList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isConsignmentSearchOverviewDirty = function() {
			return !angular.equals($scope.ConsignmentSearchOverview, $scope.originalConsignmentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ConsignmentSearchOverview = {};
			$rootScope.ConsignmentSearchOverview.page = 1;
			$rootScope.ConsignmentSearchOverview.predicate = 'id';
			$rootScope.ConsignmentSearchOverview.ascending = false;

			$scope.ConsignmentSearchOverview = angular.copy($rootScope.ConsignmentSearchOverview);

			$scope.originalConsignmentSearchOverview = angular.copy($scope.ConsignmentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ConsignmentSearchOverview) || $rootScope.ConsignmentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ConsignmentSearchOverview = angular.copy($rootScope.ConsignmentSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ConsignmentSearchOverview.predicate === itemName) {
				if ($scope.ConsignmentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
