'use strict';

// CarConfiguratorSimulator has detail layout enabled. Detail layout template url: carconfiguratorsimulator-detail.html
angular.module('mmmApp').config(function($stateProvider) {

	/**
	 * Add all translations for a 'CarConfiguratorSimulator'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carconfiguratorsimulator'); // main translation loaded for carconfiguratorsimulator
		$translatePartialLoader.addPart('tabpage'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference dealerOptions
		$translatePartialLoader.addPart('caroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('filteroption'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carconfiguratorsimulator', {
		/* abstract state for DTO CarConfigCarConfiguratorSimulatorListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract : true,
		parent : 'entity',
		url : '/car/config/carconfiguratorsimulator',
		data : {
			authorities : [ 'ROLE_CARCONFIG', 'ROLE_ADMIN' ],
			pageTitle : 'CarConfiguratorSimulator',
			isModal : false
		},
		params : {
			hiddenParam : 'YES', // hides params from url
			callInput : null
		// this data model is set from the new relation state OR from the save and new logic
		},
		resolve : {
			translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
				return getTranslations($translate, $translatePartialLoader);
			} ]
		}
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarConfiguratorSimulatorListDto */
		/* to access: ui-ref="car-config-carconfiguratorsimulator.list" */
		/* url: http://<hostname>/car/config/carconfiguratorsimulator/list */
		url : '/list',
		views : {
			'content@' : {
				templateUrl : 'app/entities/carconfiguratorsimulator/carconfiguratorsimulator-list-car-config.html',
				controller : 'CarConfigCarConfiguratorSimulatorListDtoController'
			}
		}
	};

	$stateProvider.state('car-config-carconfiguratorsimulator.list', dtoListState);

});
