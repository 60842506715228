'use strict';




angular.module('mmmApp').controller('CarConfigCarGradeDependentPriceListDtoTabController', function ($scope, $rootScope, $state, $stateParams, $q, AlertService, CarGradeDependentPriceFactory, CarConfigCarGradeDependentPriceListDtoSearchFactory, CarConfigCarGradeDependentPriceViewDtoFactory) {
    $scope.carGradeDependentPriceList = [];
    $scope.predicate = 'id';
    $scope.ascending = false;
    $scope.page = 1;

    if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarGradeDependentPrice)) {
    $scope.pageSize = angular.copy($rootScope.pageSizeCarGradeDependentPrice);
    } else {
        $scope.pageSize = 20;

    $rootScope.pageSizeCarGradeDependentPrice = angular.copy($scope.pageSize);
    }

    $scope.goToNewState = function() {

        // this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
        $state.go('car-config-cargradedependentprice.new', {mappedBy:$scope.mappedBy, mappedById:$scope.mappedById, mappedByDisplayValue:$scope.mappedByDisplayValue});
    };






    $scope.getCarGradeDependentPriceList = function(){CarConfigCarGradeDependentPriceListDtoSearchFactory.query({
            articleId: angular.isDefined($scope.articleId) ? $scope.articleId: null,
                carGradeIdList: angular.isDefined($scope.carGradeId) ? $scope.carGradeId: null,
            
            page: $scope.page - 1,
            size: $scope.pageSize,
            sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc' )]
        }, function(result, headers) {
            $scope.totalItems = headers('X-Total-Count');
            $scope.carGradeDependentPriceList = result.items;

        });
    };
    $scope.loadAll = function() {
        $scope.getCarGradeDependentPriceList();
    };


    $scope.loadPage = function () {
        $scope.loadAll();
    };


    $scope.singleDelete = function (id) {
        $scope.selectedCarGradeDependentPriceId = id;
            $('#deleteCarGradeDependentPriceConfirmationInTab').modal('show');
    };

    $scope.confirmSingleDelete = function () {
        CarConfigCarGradeDependentPriceViewDtoFactory.delete({id: $scope.selectedCarGradeDependentPriceId}, function () {
            $scope.loadAll();
            $('#deleteCarGradeDependentPriceConfirmationInTab').modal('hide');
        }, function(error) {
            $('#deleteCarGradeDependentPriceConfirmationInTab').modal('hide');
            AlertService.error(error.data.message, error.data.params);
        });
    };






    $scope.loadPage();



    $scope.getSortIcon = function(itemName) {
        if ($scope.predicate === itemName) {
            if ($scope.ascending) { 
                return "glyphicon-sort-by-attributes";
            } else {
                return "glyphicon-sort-by-attributes-alt";
            }
        } else {
            return "glyphicon-sort";
        }
    };

    $scope.updateCarGradeUISelect = function(item, model) {
        $scope.search();
    }
});
