/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('TranslationFactory', function($resource) {
	return $resource(
		'api/translations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigTranslationViewDto
 */
angular.module('mmmApp').factory('CarConfigTranslationViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/translation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigTranslationListDto's
 */
angular.module('mmmApp').factory('CarConfigTranslationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/translation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigTranslationEditDto
 */
angular.module('mmmApp').factory('CarConfigTranslationEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/translation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewTranslationFactory', function($resource) {
	return $resource(
		'api/translations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Translations without the use of paging
angular.module('mmmApp').factory('NoPagingTranslationFactory', function($resource) {
	return $resource(
		'api/translations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/translation/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllTranslationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/translations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
