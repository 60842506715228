/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('EquivalentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.equivalent = entity;
	$scope.objectName = 'Equivalent';
	$scope.equivalentId = $stateParams.equivalentId;

	if (angular.isUndefined($rootScope.equivalentActiveTab)) {
		//set the default here
		$rootScope.equivalentActiveTab = 0;
	}

	$scope.goToEditState = function(equivalent) {
		$state.go('equivalent.detail.edit({equivalentId:equivalent.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:equivalentUpdate', function(event, result) {
		$scope.equivalent = result;
	});
});
