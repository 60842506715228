/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('DocumentSecuredDetailController', function(
		$scope,
		$rootScope,
		$stateParams,
		$state,
		entity,
		DocumentSecuredDownloadService,
		$location
	) {
		$scope.documentSecured = entity;
		$scope.objectName = 'DocumentSecured';
		$scope.documentSecuredId = $stateParams.documentSecuredId;

		if (angular.isUndefined($rootScope.documentSecuredActiveTab)) {
			//set the default here
			$rootScope.documentSecuredActiveTab = 0;
		}

		$scope.goToEditState = function(documentSecured) {
			$state.go('documentsecured.detail.edit({documentSecuredId:documentSecured.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:documentSecuredUpdate', function(event, result) {
			$scope.documentSecured = result;
		});

		$scope.downloadDocumentSecured = function(id) {
			DocumentSecuredDownloadService(id);
		};
	});
