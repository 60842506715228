'use strict';

angular.module('mmmApp').controller('ArticleApiArticleBulkEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, ArticleApiArticleBulkEditDtoFactory, AlertService) {

			$scope.selectedArticleIds = _.map($stateParams.selectedArticleList, 'id');
			$scope.selectedItemsAmount = $scope.selectedArticleIds.length;
			$scope.article = entity;
			$scope.submitButtonIsActive = true;

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:articleUpdate', result);
				$scope.submitButtonIsActive = true;
				$rootScope.back();
			};

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.updatingItemsInProgress = false;
			};

			$scope.save = function() {
				$scope.updatingItemsInProgress = true;
				ArticleApiArticleBulkEditDtoFactory.update({
					'articleIds' : $scope.selectedArticleIds
				}, $scope.article, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

		});
