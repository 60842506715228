/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigFilterPageListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		FilterPageFactory,
		CarConfigFilterPageListDtoSearchFactory,
		CarConfigFilterPageViewDtoFactory
	) {
		$scope.filterPageList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeFilterPage)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeFilterPage);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeFilterPage = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-filterpage.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.FilterPageSearchOverview = angular.copy($scope.FilterPageSearchOverview);

			$rootScope.pageSizeFilterPage = angular.copy($scope.pageSize);

			CarConfigFilterPageListDtoSearchFactory.query(
				{
					page: $scope.FilterPageSearchOverview.page - 1,
					size: $scope.pageSize,
					filterTypeIdList: $scope.FilterPageSearchOverview.filterTypeIdList,
					tabPageList: $scope.FilterPageSearchOverview.tabPageList,
					placementList: $scope.FilterPageSearchOverview.placementList,

					sort: [$scope.FilterPageSearchOverview.predicate + ',' + ($scope.FilterPageSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.filterPageList = result.items;

					$scope.filterTypeReferenceFilterList = result.filterTypeSearchOptions;

					$scope.tabPageList = result.tabPageSearchOptions;

					$scope.placementList = result.placementSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isFilterPageSearchOverviewDirty = function() {
			return !angular.equals($scope.FilterPageSearchOverview, $scope.originalFilterPageSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.FilterPageSearchOverview = {};
			$rootScope.FilterPageSearchOverview.page = 1;
			$rootScope.FilterPageSearchOverview.predicate = 'id';
			$rootScope.FilterPageSearchOverview.ascending = false;

			$scope.FilterPageSearchOverview = angular.copy($rootScope.FilterPageSearchOverview);

			$scope.originalFilterPageSearchOverview = angular.copy($scope.FilterPageSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.FilterPageSearchOverview) || $rootScope.FilterPageSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.FilterPageSearchOverview = angular.copy($rootScope.FilterPageSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedFilterPageId = id;
			$('#deleteFilterPageConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigFilterPageViewDtoFactory.delete(
				{ id: $scope.selectedFilterPageId },
				function() {
					$scope.search();
					$('#deleteFilterPageConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteFilterPageConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.FilterPageSearchOverview.predicate === itemName) {
				if ($scope.FilterPageSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateFilterTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTabPageUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePlacementUISelect = function(item, model) {
			$scope.search();
		};
	});
