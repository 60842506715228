/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtBusinessListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BusinessFactory,
		RelationMgmtBusinessListDtoSearchFactory,
		RelationMgmtBusinessViewDtoFactory
	) {
		$scope.businessList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBusiness)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBusiness);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeBusiness = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-business.new');
		};

		var getPrimaryPhoneId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.primaryPhone)) {
				return $scope.BusinessSearchOverview.primaryPhone.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryAddressId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.primaryAddress)) {
				return $scope.BusinessSearchOverview.primaryAddress.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryEmailId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.primaryEmail)) {
				return $scope.BusinessSearchOverview.primaryEmail.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.tenant)) {
				return $scope.BusinessSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute optIn of javaType boolean
		// handle attribute optInDate of javaType java.time.LocalDate
		$scope.processOptInDate = function() {
			var optInDateFromDefined = $rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.optInDateFrom);
			var optInDateToDefined = $rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.optInDateTo);

			if (optInDateFromDefined) {
				$scope.BusinessSearchOverview.optInDateFromString = $rootScope.convertDateWithoutZone($scope.BusinessSearchOverview.optInDateFrom);
			} else {
				$scope.BusinessSearchOverview.optInDateFromString = undefined;
			}

			if (optInDateToDefined) {
				$scope.BusinessSearchOverview.optInDateToString = $rootScope.convertDateWithoutZone($scope.BusinessSearchOverview.optInDateTo);
			} else {
				$scope.BusinessSearchOverview.optInDateToString = undefined;
			}
		};

		// handle attribute optOut of javaType boolean
		// handle attribute optOutDate of javaType java.time.LocalDate
		$scope.processOptOutDate = function() {
			var optOutDateFromDefined = $rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.optOutDateFrom);
			var optOutDateToDefined = $rootScope.isDefinedAndNotNull($scope.BusinessSearchOverview.optOutDateTo);

			if (optOutDateFromDefined) {
				$scope.BusinessSearchOverview.optOutDateFromString = $rootScope.convertDateWithoutZone(
					$scope.BusinessSearchOverview.optOutDateFrom
				);
			} else {
				$scope.BusinessSearchOverview.optOutDateFromString = undefined;
			}

			if (optOutDateToDefined) {
				$scope.BusinessSearchOverview.optOutDateToString = $rootScope.convertDateWithoutZone($scope.BusinessSearchOverview.optOutDateTo);
			} else {
				$scope.BusinessSearchOverview.optOutDateToString = undefined;
			}
		};

		// handle attribute dealer of javaType boolean
		// handle attribute lease of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processOptInDate();
			$scope.processOptOutDate();

			$rootScope.BusinessSearchOverview = angular.copy($scope.BusinessSearchOverview);

			$rootScope.pageSizeBusiness = angular.copy($scope.pageSize);

			RelationMgmtBusinessListDtoSearchFactory.query(
				{
					page: $scope.BusinessSearchOverview.page - 1,
					size: $scope.pageSize,
					optIn: $scope.BusinessSearchOverview.optInGroup,
					optInDateFrom: $scope.BusinessSearchOverview.optInDateFromString,
					optInDateTo: $scope.BusinessSearchOverview.optInDateToString,
					optOut: $scope.BusinessSearchOverview.optOutGroup,
					optOutDateFrom: $scope.BusinessSearchOverview.optOutDateFromString,
					optOutDateTo: $scope.BusinessSearchOverview.optOutDateToString,
					name: $scope.BusinessSearchOverview.name,
					chamberOfCommerceNumber: $scope.BusinessSearchOverview.chamberOfCommerceNumber,
					vatNumber: $scope.BusinessSearchOverview.vatNumber,
					website: $scope.BusinessSearchOverview.website,
					dealer: $scope.BusinessSearchOverview.dealerGroup,
					lease: $scope.BusinessSearchOverview.leaseGroup,
					status: $scope.BusinessSearchOverview.status,
					relationType: $scope.BusinessSearchOverview.relationType,
					primaryPhoneId: getPrimaryPhoneId(),
					primaryAddressId: getPrimaryAddressId(),
					primaryEmailId: getPrimaryEmailId(),
					tenantId: getTenantId(),

					sort: [$scope.BusinessSearchOverview.predicate + ',' + ($scope.BusinessSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.businessList = result.items;

					$scope.relationStatusList = result.statusSearchOptions;

					$scope.relationTypeList = result.relationTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBusinessSearchOverviewDirty = function() {
			return !angular.equals($scope.BusinessSearchOverview, $scope.originalBusinessSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BusinessSearchOverview = {};
			$rootScope.BusinessSearchOverview.page = 1;
			$rootScope.BusinessSearchOverview.predicate = 'id';
			$rootScope.BusinessSearchOverview.ascending = false;

			$scope.BusinessSearchOverview = angular.copy($rootScope.BusinessSearchOverview);

			$scope.originalBusinessSearchOverview = angular.copy($scope.BusinessSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BusinessSearchOverview) || $rootScope.BusinessSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BusinessSearchOverview = angular.copy($rootScope.BusinessSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedBusinessId = id;
			$('#deleteBusinessConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtBusinessViewDtoFactory.delete(
				{ id: $scope.selectedBusinessId },
				function() {
					$scope.search();
					$('#deleteBusinessConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteBusinessConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BusinessSearchOverview.predicate === itemName) {
				if ($scope.BusinessSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
