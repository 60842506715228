/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CrmCarDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.crmCar = entity;
	$scope.objectName = 'CrmCar';
	$scope.crmCarId = $stateParams.crmCarId;

	if (angular.isUndefined($rootScope.crmCarActiveTab)) {
		//set the default here
		$rootScope.crmCarActiveTab = 0;
	}

	$scope.goToEditState = function(crmCar) {
		$state.go('crmcar.detail.edit({crmCarId:crmCar.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:crmCarUpdate', function(event, result) {
		$scope.crmCar = result;
	});
});
