/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarBrandFactory', function($resource) {
	return $resource(
		'api/carBrands/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarBrandViewDto
 */
angular.module('mmmApp').factory('CarConfigCarBrandViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbrand/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarBrandListDto's
 */
angular.module('mmmApp').factory('CarConfigCarBrandListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carbrand',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarBrandEditDto
 */
angular.module('mmmApp').factory('CarConfigCarBrandEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbrand/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarBrandEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarBrandNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carbrand/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarBrandFactory', function($resource) {
	return $resource(
		'api/carBrands/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarBrands without the use of paging
angular.module('mmmApp').factory('NoPagingCarBrandFactory', function($resource) {
	return $resource(
		'api/carBrands/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carBrand/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarModelsByCarBrandFactory', function($resource) {
	return $resource(
		'api/carModels/carBrand/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('UserCarStockAccessBoundCarBrandsByCarBrandFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundCarBrands/carBrand/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('UserCarStockAccessBoundFromUserCarStockAccessBoundCarBrandPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/userCarStockAccessBoundCarBrandList/AsUserCarStockAccessBoundList/carBrand/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarBrandsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carBrands/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
