/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CrmCarFactory', function($resource) {
	return $resource(
		'api/cars/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCrmCarViewDto
 */
angular.module('mmmApp').factory('StockMgmtCrmCarViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcar/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCrmCarListDto's
 */
angular.module('mmmApp').factory('StockMgmtCrmCarListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcar',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtCrmCarEditDto
 */
angular.module('mmmApp').factory('StockMgmtCrmCarEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcar/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtCrmCarEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtCrmCarNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/crmcar/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCrmCarViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcar/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCrmCarListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcar',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCrmCarEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcar/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCrmCarEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCrmCarNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/crmcar/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of VehicleApiCrmCarEditDto
 */
angular.module('mmmApp').factory('VehicleApiCrmCarEditDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/crmcar/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of VehicleApiCrmCarEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('VehicleApiCrmCarNewDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/crmcar/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCrmCarFactory', function($resource) {
	return $resource(
		'api/cars/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Cars without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarFactory', function($resource) {
	return $resource(
		'api/cars/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TransmissionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTransmissionTypeFactory', function($resource) {
	return $resource(
		'api/crmCar/transmissionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BodyTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingBodyTypeFactory', function($resource) {
	return $resource(
		'api/crmCar/bodyTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible FuelTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingFuelTypeFactory', function($resource) {
	return $resource(
		'api/crmCar/fuelTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible NumberOfDoors Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/crmCar/numberOfDoors',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible EnergyLabels Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingEnergyLabelFactory', function($resource) {
	return $resource(
		'api/crmCar/energyLabels',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/crmCar/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/crmCar/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Co2Origins Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCo2OriginFactory', function($resource) {
	return $resource(
		'api/crmCar/co2Origins',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationCarRolesByCarFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/car/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarMileagesByCarFactory', function($resource) {
	return $resource(
		'api/carMileages/car/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarValuationsByCarFactory', function($resource) {
	return $resource(
		'api/crmCarValuations/car/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ExternalCarDatasByCrmCarFactory', function($resource) {
	return $resource(
		'api/externalCarDatas/crmCar/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarOptionsByCrmCarFactory', function($resource) {
	return $resource(
		'api/crmCarOptions/crmCar/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarDocumentsByCrmCarFactory', function($resource) {
	return $resource(
		'api/crmCarDocuments/crmCar/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('DocumentFromCrmCarDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/crmCarDocumentList/AsDocumentList/crmCar/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarMediaDocumentsByCrmCarFactory', function($resource) {
	return $resource(
		'api/crmCarMediaDocuments/crmCar/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('DocumentFromHexonImagesPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/crmCarMediaDocumentList/AsDocumentList/crmCar/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/cars/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/cars/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteStockMgmtCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/stockmgmt/autocomplete/cars',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/cars',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteWebsiteApiCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/websiteapi/autocomplete/cars',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteVehicleApiCarsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/vehicleapi/autocomplete/cars',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
