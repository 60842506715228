/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('DealerAuthenticationFactory', function($resource) {
	return $resource(
		'api/dealerAuthentications/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtDealerAuthenticationViewDto
 */
angular.module('mmmApp').factory('StockMgmtDealerAuthenticationViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerauthentication/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtDealerAuthenticationListDto's
 */
angular.module('mmmApp').factory('StockMgmtDealerAuthenticationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerauthentication',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtDealerAuthenticationEditDto
 */
angular.module('mmmApp').factory('StockMgmtDealerAuthenticationEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerauthentication/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDealerAuthenticationEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtDealerAuthenticationNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealerauthentication/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewDealerAuthenticationFactory', function($resource) {
	return $resource(
		'api/dealerAuthentications/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all DealerAuthentications without the use of paging
angular.module('mmmApp').factory('NoPagingDealerAuthenticationFactory', function($resource) {
	return $resource(
		'api/dealerAuthentications/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible AuthenticationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingAuthenticationTypeFactory', function($resource) {
	return $resource(
		'api/dealerAuthentication/authenticationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllDealerAuthenticationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/dealerAuthentications/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
