/** @format */

'use strict';

// Category has edit layout enabled. Edit layout template url: category-dialog.html
// Category has detail layout enabled. Detail layout template url: category-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Category'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('category'); // main translation loaded for category
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-category', {
		/* abstract state for DTO CarConfigCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/category',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Category',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCategoryListDto */
		/* to access: ui-ref="car-config-category.list" */
		/* url: http://<hostname>/car/config/category/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-list-car-config.html',
				controller: 'CarConfigCategoryListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-category.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCategoryViewDto */
		/* to access: ui-sref="car-config-category.detail" */
		/* url: http://<hostname>/car/config/category/detail/:categoryId */
		url: '/detail/:categoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-view-car-config.html',
				controller: 'CategoryDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCategoryViewDtoFactory',
				function($stateParams, CarConfigCategoryViewDtoFactory) {
					return CarConfigCategoryViewDtoFactory.get({ id: $stateParams.categoryId });
				},
			],
		},
	};

	$stateProvider.state('car-config-category.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCategoryEditDto */
		/* to access: ui-sref="car-config-category.edit" */
		/* url: http://<hostname>/car/config/category/edit/:categoryId */
		url: '/edit/:categoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-edit-car-config.html',
				controller: 'CarConfigCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCategoryEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCategoryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCategoryEditDtoFactory.get({ id: $stateParams.categoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-category.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCategoryEditDto */
		/* to access: ui-sref="car-config-category.new" */
		/* url: http://<hostname>/car/config/category/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-edit-car-config.html',
				controller: 'CarConfigCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCategoryNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCategoryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCategoryNewDtoFactory.get({ id: $stateParams.categoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-category.new', dtoCreateState);

	/* Bulk edit state for DTO car.config */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO CarConfigCategoryEditDto */
		/* to access: ui-sref="car-config-category.bulkedit" */
		/* url: http://<hostname>/car/config/category/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-bulkedit-car-config.html',
				controller: 'CarConfigCategoryBulkEditDtoController',
			},
		},
		params: {
			selectedCategoryList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCategoryNewBulkEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCategoryNewBulkEditDtoFactory) {
					return CarConfigCategoryNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return _.merge(result, { type: 'CarConfigCategoryBulkEditDto' });
					});
				},
			],
		},
	};

	$stateProvider.state('car-config-category.bulkedit', dtoBulkEditState);

	$stateProvider.state('article-api-category', {
		/* abstract state for DTO ArticleApiCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/category',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'Category',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCategoryEditDto */
		/* to access: ui-sref="article-api-category.edit" */
		/* url: http://<hostname>/articleapi/category/edit/:categoryId */
		url: '/edit/:categoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-edit-article-api.html',
				controller: 'ArticleApiCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCategoryEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCategoryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCategoryEditDtoFactory.get({ id: $stateParams.categoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-category.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCategoryEditDto */
		/* to access: ui-sref="article-api-category.new" */
		/* url: http://<hostname>/articleapi/category/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/category/category-edit-article-api.html',
				controller: 'ArticleApiCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCategoryNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCategoryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCategoryNewDtoFactory.get({ id: $stateParams.categoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-category.new', dtoCreateState);
});
