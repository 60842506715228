/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('CarConfigCarTransmissionEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigCarTransmissionEditDtoFactory,
		AlertService,
		HistoryService,
		CarConfigCarTransmissionEditDtoSaveAndCreateFactory,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.carTransmission = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.carTransmission) && $rootScope.isDefinedAndNotNull($scope.carTransmission.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('car-config-cartransmission.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carTransmissionUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			CarConfigCarTransmissionEditDtoFactory.update($scope.carTransmission, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.carTransmission.id != null) {
				CarConfigCarTransmissionEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.carTransmission,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				CarConfigCarTransmissionEditDtoSaveAndCreateFactory.createAndNew(
					$scope.carTransmission,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
