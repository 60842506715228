/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('GroupDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.group = entity;
	$scope.objectName = 'Group';
	$scope.groupId = $stateParams.groupId;

	if (angular.isUndefined($rootScope.groupActiveTab)) {
		//set the default here
		$rootScope.groupActiveTab = 0;
	}

	$scope.goToEditState = function(group) {
		$state.go('group.detail.edit({groupId:group.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:groupUpdate', function(event, result) {
		$scope.group = result;
	});
});
