/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtSectionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SectionFactory,
		RelationMgmtSectionListDtoSearchFactory,
		RelationMgmtSectionViewDtoFactory,
		AutocompleteRelationMgmtEmployeesByQueryParamsFactory
	) {
		$scope.sectionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSection)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSection);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSection = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-section.new');
		};

		var getAccountManagerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SectionSearchOverview.accountManager)) {
				return $scope.SectionSearchOverview.accountManager.id;
			} else {
				return undefined;
			}
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SectionSearchOverview.dealer)) {
				return $scope.SectionSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryContactId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SectionSearchOverview.primaryContact)) {
				return $scope.SectionSearchOverview.primaryContact.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SectionSearchOverview.tenant)) {
				return $scope.SectionSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SectionSearchOverview = angular.copy($scope.SectionSearchOverview);

			$rootScope.pageSizeSection = angular.copy($scope.pageSize);

			RelationMgmtSectionListDtoSearchFactory.query(
				{
					page: $scope.SectionSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.SectionSearchOverview.description,
					sectionStatusList: $scope.SectionSearchOverview.sectionStatusList,
					accountManagerId: getAccountManagerId(),
					dealerId: getDealerId(),
					primaryContactId: getPrimaryContactId(),
					tenantId: getTenantId(),

					sort: [$scope.SectionSearchOverview.predicate + ',' + ($scope.SectionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.sectionList = result.items;

					$scope.sectionStatusList = result.sectionStatusSearchOptions;

					$scope.autocompleteEmployees = function(val) {
						var params = {
							employeeTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSectionSearchOverviewDirty = function() {
			return !angular.equals($scope.SectionSearchOverview, $scope.originalSectionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SectionSearchOverview = {};
			$rootScope.SectionSearchOverview.page = 1;
			$rootScope.SectionSearchOverview.predicate = 'id';
			$rootScope.SectionSearchOverview.ascending = false;

			$scope.SectionSearchOverview = angular.copy($rootScope.SectionSearchOverview);

			$scope.originalSectionSearchOverview = angular.copy($scope.SectionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SectionSearchOverview) || $rootScope.SectionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SectionSearchOverview = angular.copy($rootScope.SectionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSectionId = id;
			$('#deleteSectionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtSectionViewDtoFactory.delete(
				{ id: $scope.selectedSectionId },
				function() {
					$scope.search();
					$('#deleteSectionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSectionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SectionSearchOverview.predicate === itemName) {
				if ($scope.SectionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSectionStatusUISelect = function(item, model) {
			$scope.search();
		};
	});
