/** @format */

'use strict';

// CarTransmission has edit layout enabled. Edit layout template url: cartransmission-dialog.html
// CarTransmission has detail layout enabled. Detail layout template url: cartransmission-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarTransmission'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('cartransmission'); // main translation loaded for cartransmission
		$translatePartialLoader.addPart('article'); // main translation loaded for cartransmission
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('transmissiontype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-cartransmission', {
		/* abstract state for DTO CarConfigCarTransmissionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/cartransmission',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarTransmission',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarTransmissionListDto */
		/* to access: ui-ref="car-config-cartransmission.list" */
		/* url: http://<hostname>/car/config/cartransmission/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-list-car-config.html',
				controller: 'CarConfigCarTransmissionListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-cartransmission.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarTransmissionViewDto */
		/* to access: ui-sref="car-config-cartransmission.detail" */
		/* url: http://<hostname>/car/config/cartransmission/detail/:carTransmissionId */
		url: '/detail/:carTransmissionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-view-car-config.html',
				controller: 'CarTransmissionDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarTransmissionViewDtoFactory',
				function($stateParams, CarConfigCarTransmissionViewDtoFactory) {
					return CarConfigCarTransmissionViewDtoFactory.get({ id: $stateParams.carTransmissionId });
				},
			],
		},
	};

	$stateProvider.state('car-config-cartransmission.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarTransmissionEditDto */
		/* to access: ui-sref="car-config-cartransmission.edit" */
		/* url: http://<hostname>/car/config/cartransmission/edit/:carTransmissionId */
		url: '/edit/:carTransmissionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-edit-car-config.html',
				controller: 'CarConfigCarTransmissionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarTransmissionEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarTransmissionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarTransmissionEditDtoFactory.get({ id: $stateParams.carTransmissionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-cartransmission.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarTransmissionEditDto */
		/* to access: ui-sref="car-config-cartransmission.new" */
		/* url: http://<hostname>/car/config/cartransmission/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-edit-car-config.html',
				controller: 'CarConfigCarTransmissionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarTransmissionNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarTransmissionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarTransmissionNewDtoFactory.get({ id: $stateParams.carTransmissionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-cartransmission.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarTransmission and dto property value car.config
	$stateProvider.state('car-config-carconfiguration.new.newCarTransmission', getCreateState('/cartransmission/new'));
	$stateProvider.state('car-config-carconfiguration.edit.newCarTransmission', getCreateState('/cartransmission/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/cartransmission/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('article-api-cartransmission', {
		/* abstract state for DTO ArticleApiCarTransmissionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/cartransmission',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarTransmission',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarTransmissionEditDto */
		/* to access: ui-sref="article-api-cartransmission.edit" */
		/* url: http://<hostname>/articleapi/cartransmission/edit/:carTransmissionId */
		url: '/edit/:carTransmissionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-edit-article-api.html',
				controller: 'ArticleApiCarTransmissionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarTransmissionEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarTransmissionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarTransmissionEditDtoFactory.get({ id: $stateParams.carTransmissionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-cartransmission.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarTransmissionEditDto */
		/* to access: ui-sref="article-api-cartransmission.new" */
		/* url: http://<hostname>/articleapi/cartransmission/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/cartransmission/cartransmission-edit-article-api.html',
				controller: 'ArticleApiCarTransmissionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarTransmissionNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarTransmissionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarTransmissionNewDtoFactory.get({ id: $stateParams.carTransmissionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-cartransmission.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarTransmission and dto property value article.api
	$stateProvider.state('article-api-carconfiguration.new.newCarTransmission', getCreateState('/cartransmission/new'));
	$stateProvider.state('article-api-carconfiguration.edit.newCarTransmission', getCreateState('/cartransmission/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/cartransmission/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
