/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CrmCarValuationDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.crmCarValuation = entity;
		$scope.objectName = 'CrmCarValuation';
		$scope.crmCarValuationId = $stateParams.crmCarValuationId;

		if (angular.isUndefined($rootScope.crmCarValuationActiveTab)) {
			//set the default here
			$rootScope.crmCarValuationActiveTab = 0;
		}

		$scope.goToEditState = function(crmCarValuation) {
			$state.go('crmcarvaluation.detail.edit({crmCarValuationId:crmCarValuation.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:crmCarValuationUpdate', function(event, result) {
			$scope.crmCarValuation = result;
		});
	});
