/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('TenantFactory', function($resource) {
	return $resource(
		'api/tenants/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of AdminTenantViewDto
 */
angular.module('mmmApp').factory('AdminTenantViewDtoFactory', function($resource) {
	return $resource(
		'api/admin/tenant/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of AdminTenantListDto's
 */
angular.module('mmmApp').factory('AdminTenantListDtoSearchFactory', function($resource) {
	return $resource(
		'api/admin/tenant',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of AdminTenantEditDto
 */
angular.module('mmmApp').factory('AdminTenantEditDtoFactory', function($resource) {
	return $resource(
		'api/admin/tenant/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of AdminTenantEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('AdminTenantNewDtoFactory', function($resource) {
	return $resource(
		'api/admin/tenant/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewTenantFactory', function($resource) {
	return $resource(
		'api/tenants/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Tenants without the use of paging
angular.module('mmmApp').factory('NoPagingTenantFactory', function($resource) {
	return $resource(
		'api/tenants/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealersByTenantFactory', function($resource) {
	return $resource(
		'api/dealers/tenant/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('LocationsByTenantFactory', function($resource) {
	return $resource(
		'api/locations/tenant/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('UsersByTenantFactory', function($resource) {
	return $resource(
		'api/users/tenant/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DocumentsByTenantFactory', function($resource) {
	return $resource(
		'api/documents/tenant/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationCarRolesByTenantFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/tenant/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllTenantsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/tenants/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
