/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('EmployeeDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.employee = entity;
	$scope.objectName = 'Employee';
	$scope.employeeId = $stateParams.employeeId;

	if (angular.isUndefined($rootScope.employeeActiveTab)) {
		//set the default here
		$rootScope.employeeActiveTab = 0;
	}

	$scope.goToEditState = function(employee) {
		$state.go('employee.detail.edit({employeeId:employee.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:employeeUpdate', function(event, result) {
		$scope.employee = result;
	});
});
