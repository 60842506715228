/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ApplicationTimingLogFactory', function($resource) {
	return $resource(
		'api/applicationTimingLogs/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of AdminApplicationTimingLogViewDto
 */
angular.module('mmmApp').factory('AdminApplicationTimingLogViewDtoFactory', function($resource) {
	return $resource(
		'api/admin/applicationtiminglog/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of AdminApplicationTimingLogListDto's
 */
angular.module('mmmApp').factory('AdminApplicationTimingLogListDtoSearchFactory', function($resource) {
	return $resource(
		'api/admin/applicationtiminglog',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewApplicationTimingLogFactory', function($resource) {
	return $resource(
		'api/applicationTimingLogs/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ApplicationTimingLogs without the use of paging
angular.module('mmmApp').factory('NoPagingApplicationTimingLogFactory', function($resource) {
	return $resource(
		'api/applicationTimingLogs/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TabPages Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTabPageFactory', function($resource) {
	return $resource(
		'api/applicationTimingLog/tabPages',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllApplicationTimingLogsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/applicationTimingLogs/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
