/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigurationDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carConfiguration = entity;
		$scope.objectName = 'CarConfiguration';
		$scope.carConfigurationId = $stateParams.carConfigurationId;

		if (angular.isUndefined($rootScope.carConfigurationActiveTab)) {
			//set the default here
			$rootScope.carConfigurationActiveTab = 0;
		}

		$scope.goToEditState = function(carConfiguration) {
			$state.go('carconfiguration.detail.edit({carConfigurationId:carConfiguration.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carConfigurationUpdate', function(event, result) {
			$scope.carConfiguration = result;
		});
	});
