/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('OrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtOrderLineViewDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline/:id',
		{},
		{
			// Factory is only used to delete a RelationMgmtOrderLineViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtOrderLineListDto's
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtOrderLineEditDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtOrderLineEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtOrderLineEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of RelationMgmtOrderLineEditDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderline/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewOrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all OrderLines without the use of paging
angular.module('mmmApp').factory('NoPagingOrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/orderLine/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingOrderLineTypeFactory', function($resource) {
	return $resource(
		'api/orderLine/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllOrderLinesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/orderLines/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteOrderLinesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/orderLines/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtOrderLinesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/orderlines',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
