/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtRelationSalesOrderRoleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationSalesOrderRoleFactory,
		RelationMgmtRelationSalesOrderRoleListDtoSearchFactory,
		RelationMgmtRelationSalesOrderRoleViewDtoFactory,
		AutocompleteRelationMgmtRelationsByQueryParamsFactory,
		AutocompleteRelationMgmtSalesOrdersByQueryParamsFactory
	) {
		$scope.relationSalesOrderRoleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelationSalesOrderRole)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelationSalesOrderRole);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelationSalesOrderRole = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-relationsalesorderrole.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSalesOrderRoleSearchOverview.tenant)) {
				return $scope.RelationSalesOrderRoleSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSalesOrderRoleSearchOverview.relation)) {
				return $scope.RelationSalesOrderRoleSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getSalesOrderId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSalesOrderRoleSearchOverview.salesOrder)) {
				return $scope.RelationSalesOrderRoleSearchOverview.salesOrder.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RelationSalesOrderRoleSearchOverview = angular.copy($scope.RelationSalesOrderRoleSearchOverview);

			$rootScope.pageSizeRelationSalesOrderRole = angular.copy($scope.pageSize);

			RelationMgmtRelationSalesOrderRoleListDtoSearchFactory.query(
				{
					page: $scope.RelationSalesOrderRoleSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.RelationSalesOrderRoleSearchOverview.description,
					tenantId: getTenantId(),
					relationId: getRelationId(),
					salesOrderId: getSalesOrderId(),
					connectionTypeList: $scope.RelationSalesOrderRoleSearchOverview.connectionTypeList,

					sort: [
						$scope.RelationSalesOrderRoleSearchOverview.predicate +
							',' +
							($scope.RelationSalesOrderRoleSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationSalesOrderRoleList = result.items;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.autocompleteSalesOrders = function(val) {
						var params = {
							salesOrderTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtSalesOrdersByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.connectionTypeList = result.connectionTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationSalesOrderRoleSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationSalesOrderRoleSearchOverview, $scope.originalRelationSalesOrderRoleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationSalesOrderRoleSearchOverview = {};
			$rootScope.RelationSalesOrderRoleSearchOverview.page = 1;
			$rootScope.RelationSalesOrderRoleSearchOverview.predicate = 'id';
			$rootScope.RelationSalesOrderRoleSearchOverview.ascending = false;

			$scope.RelationSalesOrderRoleSearchOverview = angular.copy($rootScope.RelationSalesOrderRoleSearchOverview);

			$scope.originalRelationSalesOrderRoleSearchOverview = angular.copy($scope.RelationSalesOrderRoleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.RelationSalesOrderRoleSearchOverview) ||
				$rootScope.RelationSalesOrderRoleSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationSalesOrderRoleSearchOverview = angular.copy($rootScope.RelationSalesOrderRoleSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationSalesOrderRoleId = id;
			$('#deleteRelationSalesOrderRoleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtRelationSalesOrderRoleViewDtoFactory.delete(
				{ id: $scope.selectedRelationSalesOrderRoleId },
				function() {
					$scope.search();
					$('#deleteRelationSalesOrderRoleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationSalesOrderRoleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationSalesOrderRoleSearchOverview.predicate === itemName) {
				if ($scope.RelationSalesOrderRoleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateConnectionTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
