/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtTradeInOrderLineListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		TradeInOrderLineFactory,
		RelationMgmtTradeInOrderLineListDtoSearchFactory,
		RelationMgmtTradeInOrderLineViewDtoFactory,
		AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory,
		AutocompleteRelationMgmtCarsByQueryParamsFactory,
		AutocompleteRelationMgmtCrmCarValuationsByQueryParamsFactory,
		AutocompleteRelationMgmtCarMileagesByQueryParamsFactory
	) {
		$scope.tradeInOrderLineList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeTradeInOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeTradeInOrderLine);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeTradeInOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-tradeinorderline.new');
		};

		var getOrderLineGroupId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.orderLineGroup)) {
				return $scope.TradeInOrderLineSearchOverview.orderLineGroup.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.article)) {
				return $scope.TradeInOrderLineSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.tenant)) {
				return $scope.TradeInOrderLineSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.car)) {
				return $scope.TradeInOrderLineSearchOverview.car.id;
			} else {
				return undefined;
			}
		};

		var getValuationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.valuation)) {
				return $scope.TradeInOrderLineSearchOverview.valuation.id;
			} else {
				return undefined;
			}
		};

		var getMileageId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.mileage)) {
				return $scope.TradeInOrderLineSearchOverview.mileage.id;
			} else {
				return undefined;
			}
		};

		// handle attribute tradeInDate of javaType java.time.LocalDate
		$scope.processTradeInDate = function() {
			var tradeInDateFromDefined = $rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.tradeInDateFrom);
			var tradeInDateToDefined = $rootScope.isDefinedAndNotNull($scope.TradeInOrderLineSearchOverview.tradeInDateTo);

			if (tradeInDateFromDefined) {
				$scope.TradeInOrderLineSearchOverview.tradeInDateFromString = $rootScope.convertDateWithoutZone(
					$scope.TradeInOrderLineSearchOverview.tradeInDateFrom
				);
			} else {
				$scope.TradeInOrderLineSearchOverview.tradeInDateFromString = undefined;
			}

			if (tradeInDateToDefined) {
				$scope.TradeInOrderLineSearchOverview.tradeInDateToString = $rootScope.convertDateWithoutZone(
					$scope.TradeInOrderLineSearchOverview.tradeInDateTo
				);
			} else {
				$scope.TradeInOrderLineSearchOverview.tradeInDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processTradeInDate();

			$rootScope.TradeInOrderLineSearchOverview = angular.copy($scope.TradeInOrderLineSearchOverview);

			$rootScope.pageSizeTradeInOrderLine = angular.copy($scope.pageSize);

			RelationMgmtTradeInOrderLineListDtoSearchFactory.query(
				{
					page: $scope.TradeInOrderLineSearchOverview.page - 1,
					size: $scope.pageSize,
					subtotalFrom: $scope.TradeInOrderLineSearchOverview.subtotalFrom,
					subtotalTo: $scope.TradeInOrderLineSearchOverview.subtotalTo,
					baseTotalFrom: $scope.TradeInOrderLineSearchOverview.baseTotalFrom,
					baseTotalTo: $scope.TradeInOrderLineSearchOverview.baseTotalTo,
					baseTotalExVatFrom: $scope.TradeInOrderLineSearchOverview.baseTotalExVatFrom,
					baseTotalExVatTo: $scope.TradeInOrderLineSearchOverview.baseTotalExVatTo,
					tradeInDateFrom: $scope.TradeInOrderLineSearchOverview.tradeInDateFromString,
					tradeInDateTo: $scope.TradeInOrderLineSearchOverview.tradeInDateToString,
					orderLineGroupId: getOrderLineGroupId(),
					articleId: getArticleId(),
					vatType: $scope.TradeInOrderLineSearchOverview.vatType,
					orderLineType: $scope.TradeInOrderLineSearchOverview.orderLineType,
					tenantId: getTenantId(),
					carId: getCarId(),
					valuationId: getValuationId(),
					mileageId: getMileageId(),
					tradeInDestinationList: $scope.TradeInOrderLineSearchOverview.tradeInDestinationList,

					sort: [
						$scope.TradeInOrderLineSearchOverview.predicate + ',' + ($scope.TradeInOrderLineSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.tradeInOrderLineList = result.items;

					$scope.autocompleteOrderLineGroups = function(val) {
						var params = {
							orderLineGroupTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.orderLineTypeList = result.orderLineTypeSearchOptions;

					$scope.tradeInDestinationList = result.tradeInDestinationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isTradeInOrderLineSearchOverviewDirty = function() {
			return !angular.equals($scope.TradeInOrderLineSearchOverview, $scope.originalTradeInOrderLineSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.TradeInOrderLineSearchOverview = {};
			$rootScope.TradeInOrderLineSearchOverview.page = 1;
			$rootScope.TradeInOrderLineSearchOverview.predicate = 'id';
			$rootScope.TradeInOrderLineSearchOverview.ascending = false;

			$scope.TradeInOrderLineSearchOverview = angular.copy($rootScope.TradeInOrderLineSearchOverview);

			$scope.originalTradeInOrderLineSearchOverview = angular.copy($scope.TradeInOrderLineSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.TradeInOrderLineSearchOverview) || $rootScope.TradeInOrderLineSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.TradeInOrderLineSearchOverview = angular.copy($rootScope.TradeInOrderLineSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedTradeInOrderLineId = id;
			$('#deleteTradeInOrderLineConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtTradeInOrderLineViewDtoFactory.delete(
				{ id: $scope.selectedTradeInOrderLineId },
				function() {
					$scope.search();
					$('#deleteTradeInOrderLineConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteTradeInOrderLineConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.TradeInOrderLineSearchOverview.predicate === itemName) {
				if ($scope.TradeInOrderLineSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTradeInDestinationUISelect = function(item, model) {
			$scope.search();
		};
	});
