/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'UserCarStockAccessBoundDealer'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('usercarstockaccessbounddealer'); // main translation loaded for usercarstockaccessbounddealer
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('usercarstockaccessbound'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('usercarstockaccessbound'); // coupling entity reference loaded for usercarstockaccessbound
		$translatePartialLoader.addPart('dealer'); // coupling entity reference loaded for dealer

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-usercarstockaccessbounddealer', {
		/* abstract state for DTO StockMgmtUserCarStockAccessBoundDealerListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/usercarstockaccessbounddealer',
		data: {
			authorities: ['ROLE_DEALER_ADMIN', 'ROLE_ DEALER', 'ROLE_ADMIN'],
			pageTitle: 'UserCarStockAccessBoundDealer',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtUserCarStockAccessBoundDealerListDto */
		/* to access: ui-ref="stock-mgmt-usercarstockaccessbounddealer.list" */
		/* url: http://<hostname>/stockmgmt/usercarstockaccessbounddealer/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/usercarstockaccessbounddealer/usercarstockaccessbounddealer-list-stock-mgmt.html',
				controller: 'StockMgmtUserCarStockAccessBoundDealerListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-usercarstockaccessbounddealer.list', dtoListState);
});
