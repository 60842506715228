'use strict';

angular.module('mmmApp').controller('StockMgmtDealerBulkEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, StockMgmtDealerBulkEditDtoFactory, AlertService) {

			$scope.selectedDealerIds = _.map($stateParams.selectedDealerList, 'id');
			$scope.selectedItemsAmount = $scope.selectedDealerIds.length;
			$scope.dealer = entity;
			$scope.submitButtonIsActive = true;

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:dealerUpdate', result);
				$scope.submitButtonIsActive = true;
				$rootScope.back();
			};

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.updatingItemsInProgress = false;
			};

			$scope.save = function() {
				$scope.updatingItemsInProgress = true;
				StockMgmtDealerBulkEditDtoFactory.update({
					'dealerIds' : $scope.selectedDealerIds
				}, $scope.dealer, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

		});
