/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('DocumentDetailController', function($scope, $rootScope, $stateParams, $state, entity, DocumentDownloadService, $location) {
		$scope.document = entity;
		$scope.objectName = 'Document';
		$scope.documentId = $stateParams.documentId;

		if (angular.isUndefined($rootScope.documentActiveTab)) {
			//set the default here
			$rootScope.documentActiveTab = 0;
		}

		$scope.goToEditState = function(document) {
			$state.go('document.detail.edit({documentId:document.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:documentUpdate', function(event, result) {
			$scope.document = result;
		});

		$scope.downloadDocument = function(id) {
			DocumentDownloadService(id);
		};
	});
