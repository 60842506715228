/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtAddressListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		AddressFactory,
		RelationMgmtAddressListDtoSearchFactory,
		RelationMgmtAddressViewDtoFactory
	) {
		$scope.addressList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeAddress)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeAddress);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeAddress = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-address.new');
		};

		var getCountryId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.AddressSearchOverview.country)) {
				return $scope.AddressSearchOverview.country.id;
			} else {
				return undefined;
			}
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.AddressSearchOverview.relation)) {
				return $scope.AddressSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryAddressOfId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.AddressSearchOverview.primaryAddressOf)) {
				return $scope.AddressSearchOverview.primaryAddressOf.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.AddressSearchOverview.tenant)) {
				return $scope.AddressSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.AddressSearchOverview = angular.copy($scope.AddressSearchOverview);

			$rootScope.pageSizeAddress = angular.copy($scope.pageSize);

			RelationMgmtAddressListDtoSearchFactory.query(
				{
					page: $scope.AddressSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.AddressSearchOverview.description,
					street: $scope.AddressSearchOverview.street,
					houseNumber: $scope.AddressSearchOverview.houseNumber,
					houseNumberAddition: $scope.AddressSearchOverview.houseNumberAddition,
					city: $scope.AddressSearchOverview.city,
					zipCode: $scope.AddressSearchOverview.zipCode,
					countryId: getCountryId(),
					relationId: getRelationId(),
					addressTypeList: $scope.AddressSearchOverview.addressTypeList,
					primaryAddressOfId: getPrimaryAddressOfId(),
					tenantId: getTenantId(),

					sort: [$scope.AddressSearchOverview.predicate + ',' + ($scope.AddressSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.addressList = result.items;

					$scope.countryReferenceFilterList = result.countrySearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.addressTypeList = result.addressTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isAddressSearchOverviewDirty = function() {
			return !angular.equals($scope.AddressSearchOverview, $scope.originalAddressSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.AddressSearchOverview = {};
			$rootScope.AddressSearchOverview.page = 1;
			$rootScope.AddressSearchOverview.predicate = 'id';
			$rootScope.AddressSearchOverview.ascending = false;

			$scope.AddressSearchOverview = angular.copy($rootScope.AddressSearchOverview);

			$scope.originalAddressSearchOverview = angular.copy($scope.AddressSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.AddressSearchOverview) || $rootScope.AddressSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.AddressSearchOverview = angular.copy($rootScope.AddressSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedAddressId = id;
			$('#deleteAddressConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtAddressViewDtoFactory.delete(
				{ id: $scope.selectedAddressId },
				function() {
					$scope.search();
					$('#deleteAddressConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteAddressConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.AddressSearchOverview.predicate === itemName) {
				if ($scope.AddressSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateAddressTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
