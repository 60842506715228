/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('phonesOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/phone/phone-tab.html',
		controller: 'PhoneTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtPhoneMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/phone/phone-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtPhoneListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('addressesOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/address/address-tab.html',
		controller: 'AddressTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtAddressMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/address/address-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtAddressListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('emailsOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/email/email-tab.html',
		controller: 'EmailTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtEmailMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/email/email-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtEmailListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('notesOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/note/note-tab.html',
		controller: 'NoteTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtNoteMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/note/note-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtNoteListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationrolesOverviewMappedByRelationfrom', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationrole/relationrole-tab.html',
		controller: 'RelationRoleTabController',
		scope: {
			relationFromId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationRoleMappedByRelationFrom', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationrole/relationrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationRoleListDtoTabController',
		scope: {
			relationFromId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationrolesOverviewMappedByRelationto', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationrole/relationrole-tab.html',
		controller: 'RelationRoleTabController',
		scope: {
			relationToId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationRoleMappedByRelationTo', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationrole/relationrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationRoleListDtoTabController',
		scope: {
			relationToId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationdocumentsOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationdocument/relationdocument-tab.html',
		controller: 'RelationDocumentTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationDocumentMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationdocument/relationdocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationDocumentListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationsalesorderrolesOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-tab.html',
		controller: 'RelationSalesOrderRoleTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationSalesOrderRoleMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationsalesorderrole/relationsalesorderrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationSalesOrderRoleListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationcarrolesOverviewMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-tab.html',
		controller: 'RelationCarRoleTabController',
		scope: {
			relationId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationCarRoleMappedByRelation', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationCarRoleListDtoTabController',
		scope: {
			relationId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('sectionsOverviewMappedByPrimarycontact', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/section/section-tab.html',
		controller: 'SectionTabController',
		scope: {
			primaryContactId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtSectionMappedByPrimaryContact', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/section/section-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtSectionListDtoTabController',
		scope: {
			primaryContactId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
