/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarModelYearListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarModelYearFactory,
		CarConfigCarModelYearListDtoSearchFactory,
		CarConfigCarModelYearViewDtoFactory
	) {
		$scope.carModelYearList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarModelYear)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarModelYear);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarModelYear = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carmodelyear.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarModelYearSearchOverview.brand)) {
				return $scope.CarModelYearSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		var getCarModelId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarModelYearSearchOverview.carModel)) {
				return $scope.CarModelYearSearchOverview.carModel.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarModelYearSearchOverview = angular.copy($scope.CarModelYearSearchOverview);

			$rootScope.pageSizeCarModelYear = angular.copy($scope.pageSize);

			CarConfigCarModelYearListDtoSearchFactory.query(
				{
					page: $scope.CarModelYearSearchOverview.page - 1,
					size: $scope.pageSize,
					brandId: getBrandId(),
					carModelId: getCarModelId(),

					sort: [$scope.CarModelYearSearchOverview.predicate + ',' + ($scope.CarModelYearSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carModelYearList = result.items;

					$scope.carModelReferenceFilterList = result.carModelSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarModelYearSearchOverviewDirty = function() {
			return !angular.equals($scope.CarModelYearSearchOverview, $scope.originalCarModelYearSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarModelYearSearchOverview = {};
			$rootScope.CarModelYearSearchOverview.page = 1;
			$rootScope.CarModelYearSearchOverview.predicate = 'id';
			$rootScope.CarModelYearSearchOverview.ascending = false;

			$scope.CarModelYearSearchOverview = angular.copy($rootScope.CarModelYearSearchOverview);

			$scope.originalCarModelYearSearchOverview = angular.copy($scope.CarModelYearSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarModelYearSearchOverview) || $rootScope.CarModelYearSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarModelYearSearchOverview = angular.copy($rootScope.CarModelYearSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarModelYearId = id;
			$('#deleteCarModelYearConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarModelYearViewDtoFactory.delete(
				{ id: $scope.selectedCarModelYearId },
				function() {
					$scope.search();
					$('#deleteCarModelYearConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarModelYearConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarModelYearSearchOverview.predicate === itemName) {
				if ($scope.CarModelYearSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
