/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigVatListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		VatFactory,
		CarConfigVatListDtoSearchFactory,
		CarConfigVatViewDtoFactory
	) {
		$scope.vatList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeVat)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeVat);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeVat = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-vat.new');
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.VatSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.VatSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.VatSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone($scope.VatSearchOverview.validFromFrom);
			} else {
				$scope.VatSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.VatSearchOverview.validFromToString = $rootScope.convertDateWithoutZone($scope.VatSearchOverview.validFromTo);
			} else {
				$scope.VatSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.VatSearchOverview = angular.copy($scope.VatSearchOverview);

			$rootScope.pageSizeVat = angular.copy($scope.pageSize);

			CarConfigVatListDtoSearchFactory.query(
				{
					page: $scope.VatSearchOverview.page - 1,
					size: $scope.pageSize,
					percentageFrom: $scope.VatSearchOverview.percentageFrom,
					percentageTo: $scope.VatSearchOverview.percentageTo,
					validFromFrom: $scope.VatSearchOverview.validFromFromString,
					validFromTo: $scope.VatSearchOverview.validFromToString,
					vatTypeList: $scope.VatSearchOverview.vatTypeList,
					countryList: $scope.VatSearchOverview.countryList,

					sort: [$scope.VatSearchOverview.predicate + ',' + ($scope.VatSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.vatList = result.items;

					$scope.vatTypeList = result.vatTypeSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isVatSearchOverviewDirty = function() {
			return !angular.equals($scope.VatSearchOverview, $scope.originalVatSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.VatSearchOverview = {};
			$rootScope.VatSearchOverview.page = 1;
			$rootScope.VatSearchOverview.predicate = 'id';
			$rootScope.VatSearchOverview.ascending = false;

			$scope.VatSearchOverview = angular.copy($rootScope.VatSearchOverview);

			$scope.originalVatSearchOverview = angular.copy($scope.VatSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.VatSearchOverview) || $rootScope.VatSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.VatSearchOverview = angular.copy($rootScope.VatSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedVatId = id;
			$('#deleteVatConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigVatViewDtoFactory.delete(
				{ id: $scope.selectedVatId },
				function() {
					$scope.search();
					$('#deleteVatConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteVatConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.VatSearchOverview.predicate === itemName) {
				if ($scope.VatSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateVatTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};
	});
