/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtDocumentSecuredEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtDocumentSecuredEditDtoFactory,
		AlertService,
		HistoryService,
		FileUploader,
		AuthServerProvider
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.document = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.document['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.document) && $rootScope.isDefinedAndNotNull($scope.document.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:documentSecuredUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			var formData = [];
			$scope.submitButtonIsActive = false;
			if ($scope.isEdit()) {
				formData.push({ id: $scope.document.id });
			}
			if ($rootScope.isDefinedAndNotNull($scope.document.description)) {
				formData.push({ description: $scope.document.description });
			}
			if ($rootScope.isDefinedAndNotNull($scope.document.name)) {
				formData.push({ name: $scope.document.name });
			}
			if (!angular.isUndefined($scope.file.queue[0]) && isUploadReady) {
				$scope.file.queue[0].formData = formData;
				$scope.submitButtonIsActive = false;
				$scope.file.uploadAll(); // triggers the multipart form POST
			} else if ($scope.isEdit()) {
				// no fileupload, only send normal editDto
				StockMgmtDocumentSecuredEditDtoFactory.update($scope.document, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				if (isUploadReady) {
					AlertService.error('error.file.upload.not.selected', {});
				} else {
					AlertService.error(errorMessage, {});
				}
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		var isUploadReady = true;
		var isUploadDisabled = false; // this variable is used in the statement to determine whether the file input should be enabled
		var errorMessage = '';

		var uploader = ($scope.file = new FileUploader({
			url: 'api/stockmgmt/documentsecured/edit',
			autoUpload: false,
			headers: {
				Accept: 'application/json, text/plain, */*',
				Authorization: 'Bearer ' + AuthServerProvider.getToken(),
			},
		}));

		$scope.isDisableFileInput = function() {
			return uploader.queueLimit == uploader.queue.length || isUploadDisabled;
		};

		uploader.onErrorItem = function(fileItem, response, status, headers) {
			// console.log("uploader.onErrorItem");
			isUploadReady = false;
		};

		uploader.onAfterAddingFile = function(fileItem) {
			// console.log("uploader.onAfterAddingFile");
			if (uploader.queue.length == 2) {
				//delete one item on the left side of the array (Jquery function)
				uploader.queue.shift();
			}
			$scope.submitButtonIsActive = true;
		};

		var clearFileInputAndQueue = function() {
			uploader.clearQueue();
			//Clear the file input. Since there is no ng-model binding we can only do this by directly calling the DOM element(s) and set it to null
			angular.element("input[type='file']").val(null);
		};

		uploader.onWhenAddingFileFailed = function(item, filter, options) {
			$scope.submitButtonIsActive = false;
			AlertService.error('error.file.upload.onWhenAddingFileFailed');
			clearFileInputAndQueue();
		};

		uploader.onCompleteItem = function(fileItem, result, status) {
			if (status === 201 || status === 200) {
				onSaveFinishedCallback(result);
			} else {
				// something went wrong, cancel all items in uploader and clear the queue
				uploader.cancelAll();
				uploader.clearQueue();

				// default error message in case none of below statusses match
				errorMessage = 'error.file.upload.general';

				switch (status) {
					case 500: {
						if (result.indexOf('SizeLimitExceededException') != -1) {
							errorMessage = 'error.file.upload.size.exceeded';
						}
					}
					// more cases to come
				}

				AlertService.error(errorMessage);
				$scope.submitButtonIsActive = false;
			}
		};
	});
