/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ArticleFactory', function($resource) {
	return $resource(
		'api/articles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigArticleViewDto
 */
angular.module('mmmApp').factory('CarConfigArticleViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/article/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigArticleListDto's
 */
angular.module('mmmApp').factory('CarConfigArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/article',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigArticleEditDto
 */
angular.module('mmmApp').factory('CarConfigArticleEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/article/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigArticleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigArticleNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/article/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigArticleEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('CarConfigArticleNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/article/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of CarConfigArticleEditDto
 */
angular.module('mmmApp').factory('CarConfigArticleBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/article/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigArticleEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigArticleEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/article/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of ArticleManagementArticleListDto's
 */
angular.module('mmmApp').factory('ArticleManagementArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/articlemanagement/article',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtArticleViewDto
 */
angular.module('mmmApp').factory('RelationMgmtArticleViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/article/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtArticleListDto's
 */
angular.module('mmmApp').factory('RelationMgmtArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/article',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiArticleListDto's
 */
angular.module('mmmApp').factory('WebsiteApiArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/article',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiArticleEditDto
 */
angular.module('mmmApp').factory('ArticleApiArticleEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/article/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiArticleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiArticleNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/article/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiArticleEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiArticleEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/article/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewArticleFactory', function($resource) {
	return $resource(
		'api/articles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Articles without the use of paging
angular.module('mmmApp').factory('NoPagingArticleFactory', function($resource) {
	return $resource(
		'api/articles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/article/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/article/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/article/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/article/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/article/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/article/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/article/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleFilterOptionsByArticleFactory', function($resource) {
	return $resource(
		'api/articleFilterOptions/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('FilterOptionFromArticleFilterOptionPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleFilterOptionList/AsFilterOptionList/article/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCarModelYearsByArticleFactory', function($resource) {
	return $resource(
		'api/articleCarModelYears/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('CarModelYearFromArticleCarModelYearPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleCarModelYearList/AsCarModelYearList/article/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('BillOfMaterialsByParentArticleFactory', function($resource) {
	return $resource(
		'api/billOfMaterials/parentArticle/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('BillOfMaterialsByArticleFactory', function($resource) {
	return $resource(
		'api/billOfMaterials/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ImagesByArticleFactory', function($resource) {
	return $resource(
		'api/images/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCombisByFromArticleFactory', function($resource) {
	return $resource(
		'api/articleCombis/fromArticle/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCombisByToArticleFactory', function($resource) {
	return $resource(
		'api/articleCombis/toArticle/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleTagsByArticleFactory', function($resource) {
	return $resource(
		'api/articleTags/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('TagFromArticleTagsPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleTagList/AsTagList/article/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationPackageArticlesByArticleFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackageArticles/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('CarConfigurationPackageFromCarConfigurationPackagePicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/carConfigurationPackageArticleList/AsCarConfigurationPackageList/article/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCategoriesByArticleFactory', function($resource) {
	return $resource(
		'api/articleCategories/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('CategoryFromArticleCategoryPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleCategoryList/AsCategoryList/article/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('OrderLinesByArticleFactory', function($resource) {
	return $resource(
		'api/orderLines/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticlePricesByArticleFactory', function($resource) {
	return $resource(
		'api/articlePrices/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationDependentPricesByArticleFactory', function($resource) {
	return $resource(
		'api/carConfigurationDependentPrices/article/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarOptionDependentPricesByContextArticleFactory', function($resource) {
	return $resource(
		'api/carOptionDependentPrices/contextArticle/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarOptionDependentPricesByTargetArticleFactory', function($resource) {
	return $resource(
		'api/carOptionDependentPrices/targetArticle/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/articles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarConfigArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/car/config/autocomplete/articles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteArticleManagementArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/articlemanagement/autocomplete/articles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/articles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteWebsiteApiArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/websiteapi/autocomplete/articles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteArticleApiArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/articleapi/autocomplete/articles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

/**
 * Factory to get / update an instance of specified subtype of CarConfigArticleEditDto
 */
angular.module('mmmApp').factory('CarConfigArticleSubTypeEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/:type/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

angular.module('mmmApp').factory('ArticleSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articles/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
