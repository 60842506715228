/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigurationPackageDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carConfigurationPackage = entity;
		$scope.objectName = 'CarConfigurationPackage';
		$scope.carConfigurationPackageId = $stateParams.carConfigurationPackageId;

		if (angular.isUndefined($rootScope.carConfigurationPackageActiveTab)) {
			//set the default here
			$rootScope.carConfigurationPackageActiveTab = 0;
		}

		$scope.goToEditState = function(carConfigurationPackage) {
			$state.go(
				'carconfigurationpackage.detail.edit({carConfigurationPackageId:carConfigurationPackage.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carConfigurationPackageUpdate', function(event, result) {
			$scope.carConfigurationPackage = result;
		});
	});
