/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('ConsignmentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.consignment = entity;
	$scope.objectName = 'Consignment';
	$scope.consignmentId = $stateParams.consignmentId;

	if (angular.isUndefined($rootScope.consignmentActiveTab)) {
		//set the default here
		$rootScope.consignmentActiveTab = 0;
	}

	$scope.goToEditState = function(consignment) {
		$state.go('consignment.detail.edit({consignmentId:consignment.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:consignmentUpdate', function(event, result) {
		$scope.consignment = result;
	});
});
