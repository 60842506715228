/** @format */

'use strict';

// CarPaint has edit layout enabled. Edit layout template url: carpaint-dialog.html
// CarPaint has detail layout enabled. Detail layout template url: carpaint-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarPaint'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carpaint'); // main translation loaded for carpaint
		$translatePartialLoader.addPart('article'); // main translation loaded for carpaint
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('painttype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('color'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carpaint', {
		/* abstract state for DTO CarConfigCarPaintListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carpaint',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarPaint',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarPaintListDto */
		/* to access: ui-ref="car-config-carpaint.list" */
		/* url: http://<hostname>/car/config/carpaint/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-list-car-config.html',
				controller: 'CarConfigCarPaintListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carpaint.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarPaintViewDto */
		/* to access: ui-sref="car-config-carpaint.detail" */
		/* url: http://<hostname>/car/config/carpaint/detail/:carPaintId */
		url: '/detail/:carPaintId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-view-car-config.html',
				controller: 'CarPaintDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarPaintViewDtoFactory',
				function($stateParams, CarConfigCarPaintViewDtoFactory) {
					return CarConfigCarPaintViewDtoFactory.get({ id: $stateParams.carPaintId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carpaint.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarPaintEditDto */
		/* to access: ui-sref="car-config-carpaint.edit" */
		/* url: http://<hostname>/car/config/carpaint/edit/:carPaintId */
		url: '/edit/:carPaintId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-edit-car-config.html',
				controller: 'CarConfigCarPaintEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarPaintEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarPaintEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarPaintEditDtoFactory.get({ id: $stateParams.carPaintId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carpaint.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarPaintEditDto */
		/* to access: ui-sref="car-config-carpaint.new" */
		/* url: http://<hostname>/car/config/carpaint/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-edit-car-config.html',
				controller: 'CarConfigCarPaintEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarPaintNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarPaintNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarPaintNewDtoFactory.get({ id: $stateParams.carPaintId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carpaint.new', dtoCreateState);

	// create state for plus button on CarModelVersion edit / new page for reference CarPaint and dto property value car.config
	$stateProvider.state('car-config-carmodelversion.new.newCarPaint', getCreateState('/carpaint/new'));
	$stateProvider.state('car-config-carmodelversion.edit.newCarPaint', getCreateState('/carpaint/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carpaint/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('website-api-carpaint', {
		/* abstract state for DTO WebsiteApiCarPaintListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/carpaint',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarPaint',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO website.api */
	var dtoListState = {
		/* list state for DTO WebsiteApiCarPaintListDto */
		/* to access: ui-ref="website-api-carpaint.list" */
		/* url: http://<hostname>/websiteapi/carpaint/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-list-website-api.html',
				controller: 'WebsiteApiCarPaintListDtoController',
			},
		},
	};

	$stateProvider.state('website-api-carpaint.list', dtoListState);

	$stateProvider.state('article-api-carpaint', {
		/* abstract state for DTO ArticleApiCarPaintListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carpaint',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarPaint',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarPaintEditDto */
		/* to access: ui-sref="article-api-carpaint.edit" */
		/* url: http://<hostname>/articleapi/carpaint/edit/:carPaintId */
		url: '/edit/:carPaintId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-edit-article-api.html',
				controller: 'ArticleApiCarPaintEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarPaintEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarPaintEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarPaintEditDtoFactory.get({ id: $stateParams.carPaintId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carpaint.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarPaintEditDto */
		/* to access: ui-sref="article-api-carpaint.new" */
		/* url: http://<hostname>/articleapi/carpaint/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpaint/carpaint-edit-article-api.html',
				controller: 'ArticleApiCarPaintEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarPaintNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarPaintNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarPaintNewDtoFactory.get({ id: $stateParams.carPaintId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carpaint.new', dtoCreateState);

	// create state for plus button on CarModelVersion edit / new page for reference CarPaint and dto property value article.api
	$stateProvider.state('article-api-carmodelversion.new.newCarPaint', getCreateState('/carpaint/new'));
	$stateProvider.state('article-api-carmodelversion.edit.newCarPaint', getCreateState('/carpaint/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carpaint/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
