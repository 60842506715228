/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('BusinessFactory', function($resource) {
	return $resource(
		'api/businesses/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtBusinessViewDto
 */
angular.module('mmmApp').factory('RelationMgmtBusinessViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/business/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtBusinessListDto's
 */
angular.module('mmmApp').factory('RelationMgmtBusinessListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/business',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtBusinessEditDto
 */
angular.module('mmmApp').factory('RelationMgmtBusinessEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/business/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtBusinessEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtBusinessNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/business/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewBusinessFactory', function($resource) {
	return $resource(
		'api/businesses/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Businesses without the use of paging
angular.module('mmmApp').factory('NoPagingBusinessFactory', function($resource) {
	return $resource(
		'api/businesses/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationStatusses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationStatusFactory', function($resource) {
	return $resource(
		'api/business/relationStatusses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationTypeFactory', function($resource) {
	return $resource(
		'api/business/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllBusinessesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/businesses/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteBusinessesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/businesses/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtBusinessesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/businesses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
