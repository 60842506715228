/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarBrandDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carBrand = entity;
	$scope.objectName = 'CarBrand';
	$scope.carBrandId = $stateParams.carBrandId;

	if (angular.isUndefined($rootScope.carBrandActiveTab)) {
		//set the default here
		$rootScope.carBrandActiveTab = 0;
	}

	$scope.goToEditState = function(carBrand) {
		$state.go('carbrand.detail.edit({carBrandId:carBrand.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carBrandUpdate', function(event, result) {
		$scope.carBrand = result;
	});
});
