/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('WebsiteApiCarModelVersionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarModelVersionFactory,
		WebsiteApiCarModelVersionListDtoSearchFactory
	) {
		$scope.carModelVersionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarModelVersion)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarModelVersion);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarModelVersion = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('website-api-carmodelversion.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarModelVersionSearchOverview.equivalent)) {
				return $scope.CarModelVersionSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		var getCarModelYearId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarModelVersionSearchOverview.carModelYear)) {
				return $scope.CarModelVersionSearchOverview.carModelYear.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarModelVersionSearchOverview = angular.copy($scope.CarModelVersionSearchOverview);

			$rootScope.pageSizeCarModelVersion = angular.copy($scope.pageSize);

			WebsiteApiCarModelVersionListDtoSearchFactory.query(
				{
					page: $scope.CarModelVersionSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarModelVersionSearchOverview.name,
					productCode: $scope.CarModelVersionSearchOverview.productCode,
					factoryCode: $scope.CarModelVersionSearchOverview.factoryCode,
					description: $scope.CarModelVersionSearchOverview.description,
					canBeOrdered: $scope.CarModelVersionSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarModelVersionSearchOverview.spotlightGroup,
					creator: $scope.CarModelVersionSearchOverview.creator,
					updater: $scope.CarModelVersionSearchOverview.updater,
					lengthFrom: $scope.CarModelVersionSearchOverview.lengthFrom,
					lengthTo: $scope.CarModelVersionSearchOverview.lengthTo,
					widthFrom: $scope.CarModelVersionSearchOverview.widthFrom,
					widthTo: $scope.CarModelVersionSearchOverview.widthTo,
					heightFrom: $scope.CarModelVersionSearchOverview.heightFrom,
					heightTo: $scope.CarModelVersionSearchOverview.heightTo,
					wheelbaseFrom: $scope.CarModelVersionSearchOverview.wheelbaseFrom,
					wheelbaseTo: $scope.CarModelVersionSearchOverview.wheelbaseTo,
					numberOfWheelsFrom: $scope.CarModelVersionSearchOverview.numberOfWheelsFrom,
					numberOfWheelsTo: $scope.CarModelVersionSearchOverview.numberOfWheelsTo,
					statusList: $scope.CarModelVersionSearchOverview.statusList,
					typeList: $scope.CarModelVersionSearchOverview.typeList,
					imageSize: $scope.CarModelVersionSearchOverview.imageSize,
					vatType: $scope.CarModelVersionSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarModelVersionSearchOverview.priceTypeList,
					brandIdList: $scope.CarModelVersionSearchOverview.brandIdList,
					country: $scope.CarModelVersionSearchOverview.country,
					locale: $scope.CarModelVersionSearchOverview.locale,
					carModelYearId: getCarModelYearId(),
					carPaintIdList: $scope.CarModelVersionSearchOverview.carPaintIdList,

					sort: [$scope.CarModelVersionSearchOverview.predicate + ',' + ($scope.CarModelVersionSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carModelVersionList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.carModelYearReferenceFilterList = result.carModelYearSearchOptions;

					$scope.carPaintReferenceFilterList = result.carPaintSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarModelVersionSearchOverviewDirty = function() {
			return !angular.equals($scope.CarModelVersionSearchOverview, $scope.originalCarModelVersionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarModelVersionSearchOverview = {};
			$rootScope.CarModelVersionSearchOverview.page = 1;
			$rootScope.CarModelVersionSearchOverview.predicate = 'id';
			$rootScope.CarModelVersionSearchOverview.ascending = false;

			$scope.CarModelVersionSearchOverview = angular.copy($rootScope.CarModelVersionSearchOverview);

			$scope.originalCarModelVersionSearchOverview = angular.copy($scope.CarModelVersionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarModelVersionSearchOverview) || $rootScope.CarModelVersionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarModelVersionSearchOverview = angular.copy($rootScope.CarModelVersionSearchOverview);

			$scope.search();
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carModelVersionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarModelVersionSearchOverview.predicate === itemName) {
				if ($scope.CarModelVersionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarPaintUISelect = function(item, model) {
			$scope.search();
		};
	});
