'use strict';

angular.module('mmmApp').controller('OrderLineDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.orderLine = entity;
	$scope.objectName = "OrderLine";
	$scope.orderLineId = $stateParams.orderLineId;

	if (angular.isUndefined($rootScope.orderLineActiveTab)) {
		//set the default here
		$rootScope.orderLineActiveTab = 0;
	}

	$scope.goToEditState = function(orderLine) {
		$state.go('orderline.detail.edit({orderLineId:orderLine.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:orderLineUpdate', function(event, result) {
		$scope.orderLine = result;
	});

});
