/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarEngineDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carEngine = entity;
	$scope.objectName = 'CarEngine';
	$scope.carEngineId = $stateParams.carEngineId;

	if (angular.isUndefined($rootScope.carEngineActiveTab)) {
		//set the default here
		$rootScope.carEngineActiveTab = 0;
	}

	$scope.goToEditState = function(carEngine) {
		$state.go('carengine.detail.edit({carEngineId:carEngine.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carEngineUpdate', function(event, result) {
		$scope.carEngine = result;
	});
});
