/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCarBrandDealerListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarBrandDealerFactory,
		StockMgmtCarBrandDealerListDtoSearchFactory
	) {
		$scope.carBrandDealerList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarBrandDealer)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarBrandDealer);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarBrandDealer = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-carbranddealer.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarBrandDealerSearchOverview.tenant)) {
				return $scope.CarBrandDealerSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarBrandDealerSearchOverview.dealer)) {
				return $scope.CarBrandDealerSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarBrandDealerSearchOverview.brand)) {
				return $scope.CarBrandDealerSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarBrandDealerSearchOverview = angular.copy($scope.CarBrandDealerSearchOverview);

			$rootScope.pageSizeCarBrandDealer = angular.copy($scope.pageSize);

			StockMgmtCarBrandDealerListDtoSearchFactory.query(
				{
					page: $scope.CarBrandDealerSearchOverview.page - 1,
					size: $scope.pageSize,
					dealerCode: $scope.CarBrandDealerSearchOverview.dealerCode,
					tenantId: getTenantId(),
					dealerId: getDealerId(),
					brandId: getBrandId(),

					sort: [$scope.CarBrandDealerSearchOverview.predicate + ',' + ($scope.CarBrandDealerSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carBrandDealerList = result.items;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarBrandDealerSearchOverviewDirty = function() {
			return !angular.equals($scope.CarBrandDealerSearchOverview, $scope.originalCarBrandDealerSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarBrandDealerSearchOverview = {};
			$rootScope.CarBrandDealerSearchOverview.page = 1;
			$rootScope.CarBrandDealerSearchOverview.predicate = 'id';
			$rootScope.CarBrandDealerSearchOverview.ascending = false;

			$scope.CarBrandDealerSearchOverview = angular.copy($rootScope.CarBrandDealerSearchOverview);

			$scope.originalCarBrandDealerSearchOverview = angular.copy($scope.CarBrandDealerSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarBrandDealerSearchOverview) || $rootScope.CarBrandDealerSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarBrandDealerSearchOverview = angular.copy($rootScope.CarBrandDealerSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarBrandDealerSearchOverview.predicate === itemName) {
				if ($scope.CarBrandDealerSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
