/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('TradeInOfSalesOrderPlacedInCarStockFactory', function($resource) {
	return $resource(
		'api/tradeInsOfSalesOrderPlacedInCarStock/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewTradeInOfSalesOrderPlacedInCarStockFactory', function($resource) {
	return $resource(
		'api/tradeInsOfSalesOrderPlacedInCarStock/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all TradeInsOfSalesOrderPlacedInCarStock without the use of paging
angular.module('mmmApp').factory('NoPagingTradeInOfSalesOrderPlacedInCarStockFactory', function($resource) {
	return $resource(
		'api/tradeInsOfSalesOrderPlacedInCarStock/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllTradeInsOfSalesOrderPlacedInCarStockByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/tradeInsOfSalesOrderPlacedInCarStock/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
