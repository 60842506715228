/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingFuelTypeFactory', function($resource) {
	return $resource(
		'api/mappingFuelTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingFuelTypeViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingFuelTypeViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfueltype/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingFuelTypeListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingFuelTypeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfueltype',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingFuelTypeEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingFuelTypeEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfueltype/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingFuelTypeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingFuelTypeNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfueltype/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingFuelTypeEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingFuelTypeEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfueltype/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingFuelTypeFactory', function($resource) {
	return $resource(
		'api/mappingFuelTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingFuelTypes without the use of paging
angular.module('mmmApp').factory('NoPagingMappingFuelTypeFactory', function($resource) {
	return $resource(
		'api/mappingFuelTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingFuelType/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible FuelTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingFuelTypeFactory', function($resource) {
	return $resource(
		'api/mappingFuelType/fuelTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingFuelTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingFuelTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingFuelTypeSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingfueltypes/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
