/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'GenericVehicleDetails'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('genericvehicledetails'); // main translation loaded for genericvehicledetails
		$translatePartialLoader.addPart('batteryfastchargerplugtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('batteryplugtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('batterytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('bodytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('emissionclass'); // one to many reference from main entity
		$translatePartialLoader.addPart('energylabel'); // one to many reference from main entity
		$translatePartialLoader.addPart('fueltype'); // one to many reference from main entity
		$translatePartialLoader.addPart('hybridpowertrain'); // one to many reference from main entity
		$translatePartialLoader.addPart('hybridtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('vehicletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity

		return $translate.refresh();
	};
});
