/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarStockDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carStock = entity;
	$scope.objectName = 'CarStock';
	$scope.carStockId = $stateParams.carStockId;

	if (angular.isUndefined($rootScope.carStockActiveTab)) {
		//set the default here
		$rootScope.carStockActiveTab = 0;
	}

	$scope.goToEditState = function(carStock) {
		$state.go('carstock.detail.edit({carStockId:carStock.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carStockUpdate', function(event, result) {
		$scope.carStock = result;
	});
	/**
	 * Navigate to the car configuration in a new window/tab
	 * @param carConfigurationId
	 */
	$scope.gotoCarConfiguration = function(carConfigurationId) {
		if ($rootScope.isDefinedAndNotNull(carConfigurationId)) {
			// TODO why is injecting the constant APP_SETTINGS not working?
			var url = $rootScope.CARCONFIG_URL;
			window.open(url + '/loadConfiguration.php?id=' + carConfigurationId);
		}
	};
});
