// CodeComposer:ignore : Stopped generating because of AMI-11600: Adding 2 indirect columns to angularjs list
/** @format */


'use strict';

// CrmCar has edit layout enabled. Edit layout template url: crmcar-dialog.html
// CrmCar has detail layout enabled. Detail layout template url: crmcar-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CrmCar'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('crmcar'); // main translation loaded for crmcar
		$translatePartialLoader.addPart('transmissiontype'); // one to many reference from main entity
		$translatePartialLoader.addPart('bodytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('fueltype'); // one to many reference from main entity
		$translatePartialLoader.addPart('numberofdoors'); // one to many reference from main entity
		$translatePartialLoader.addPart('energylabel'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('co2origin'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackage'); // one to many reference from main entity
		$translatePartialLoader.addPart('carmodel'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference paint
		$translatePartialLoader.addPart('carpaint'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationcarrole'); // one to many reference from main entity
		$translatePartialLoader.addPart('taskcrmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('rdwcolor'); // one to many reference from main entity
		$translatePartialLoader.addPart('carmileage'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcarvaluation'); // one to many reference from main entity
		$translatePartialLoader.addPart('externalcardata'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcaroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcardocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcarmediadocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('task'); // main translation loaded for parent object Task of reference taskTransport
		$translatePartialLoader.addPart('basetask'); // main translation loaded for parent object BaseTask of reference taskTransport
		$translatePartialLoader.addPart('tenantawarebasemodel'); // main translation loaded for parent object TenantAwareBaseModel of reference taskTransport
		$translatePartialLoader.addPart('basemodel'); // main translation loaded for parent object BaseModel of reference taskTransport
		$translatePartialLoader.addPart('tasktransport'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcaroptionorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('optionorigin'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-crmcar', {
		/* abstract state for DTO StockMgmtCrmCarListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/crmcar',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCar',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCrmCarListDto */
		/* to access: ui-ref="stock-mgmt-crmcar.list" */
		/* url: http://<hostname>/stockmgmt/crmcar/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-list-stock-mgmt.html',
				controller: 'StockMgmtCrmCarListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-crmcar.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtCrmCarViewDto */
		/* to access: ui-sref="stock-mgmt-crmcar.detail" */
		/* url: http://<hostname>/stockmgmt/crmcar/detail/:crmCarId */
		url: '/detail/:crmCarId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-view-stock-mgmt.html',
				controller: 'CrmCarDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtCrmCarViewDtoFactory',
				function($stateParams, StockMgmtCrmCarViewDtoFactory) {
					return StockMgmtCrmCarViewDtoFactory.get({ id: $stateParams.crmCarId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcar.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCrmCarEditDto */
		/* to access: ui-sref="stock-mgmt-crmcar.edit" */
		/* url: http://<hostname>/stockmgmt/crmcar/edit/:crmCarId */
		url: '/edit/:crmCarId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-stock-mgmt.html',
				controller: 'StockMgmtCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCrmCarEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCrmCarEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCrmCarEditDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcar.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtCrmCarEditDto */
		/* to access: ui-sref="stock-mgmt-crmcar.new" */
		/* url: http://<hostname>/stockmgmt/crmcar/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-stock-mgmt.html',
				controller: 'StockMgmtCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCrmCarNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtCrmCarNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCrmCarNewDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-crmcar.new', dtoCreateState);

	// create state for plus button on TaskTransport edit / new page for reference CrmCar and dto property value stock.mgmt
	$stateProvider.state('stock-mgmt-tasktransport.new.newCrmCar', getCreateState('/crmcar/new'));
	$stateProvider.state('stock-mgmt-tasktransport.edit.newCrmCar', getCreateState('/crmcar/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/crmcar/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('relation-mgmt-crmcar', {
		/* abstract state for DTO RelationMgmtCrmCarListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/crmcar',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CrmCar',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCrmCarListDto */
		/* to access: ui-ref="relation-mgmt-crmcar.list" */
		/* url: http://<hostname>/relationmgmt/crmcar/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-list-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-crmcar.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtCrmCarViewDto */
		/* to access: ui-sref="relation-mgmt-crmcar.detail" */
		/* url: http://<hostname>/relationmgmt/crmcar/detail/:crmCarId */
		url: '/detail/:crmCarId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-view-relation-mgmt.html',
				controller: 'CrmCarDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtCrmCarViewDtoFactory',
				function($stateParams, RelationMgmtCrmCarViewDtoFactory) {
					return RelationMgmtCrmCarViewDtoFactory.get({ id: $stateParams.crmCarId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcar.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtCrmCarEditDto */
		/* to access: ui-sref="relation-mgmt-crmcar.edit" */
		/* url: http://<hostname>/relationmgmt/crmcar/edit/:crmCarId */
		url: '/edit/:crmCarId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarEditDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcar.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtCrmCarEditDto */
		/* to access: ui-sref="relation-mgmt-crmcar.new" */
		/* url: http://<hostname>/relationmgmt/crmcar/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-relation-mgmt.html',
				controller: 'RelationMgmtCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtCrmCarNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtCrmCarNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtCrmCarNewDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-crmcar.new', dtoCreateState);

	// create state for plus button on TaskTransport edit / new page for reference CrmCar and dto property value relation.mgmt
	$stateProvider.state('relation-mgmt-tasktransport.new.newCrmCar', getCreateState('/crmcar/new'));
	$stateProvider.state('relation-mgmt-tasktransport.edit.newCrmCar', getCreateState('/crmcar/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/crmcar/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('website-api-crmcar', {
		/* abstract state for DTO WebsiteApiCrmCarListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/crmcar',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'CrmCar',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('vehicle-api-crmcar', {
		/* abstract state for DTO VehicleApiCrmCarListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/vehicleapi/crmcar',
		data: {
			authorities: ['ROLE_VEHICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CrmCar',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO vehicle.api */
	var dtoEditState = {
		/* edit state for DTO VehicleApiCrmCarEditDto */
		/* to access: ui-sref="vehicle-api-crmcar.edit" */
		/* url: http://<hostname>/vehicleapi/crmcar/edit/:crmCarId */
		url: '/edit/:crmCarId',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-vehicle-api.html',
				controller: 'VehicleApiCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'VehicleApiCrmCarEditDtoFactory',
				function($stateParams, $rootScope, VehicleApiCrmCarEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return VehicleApiCrmCarEditDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('vehicle-api-crmcar.edit', dtoEditState);

	/* new (create) state for DTO vehicle.api */
	var dtoCreateState = {
		/* new state for DTO VehicleApiCrmCarEditDto */
		/* to access: ui-sref="vehicle-api-crmcar.new" */
		/* url: http://<hostname>/vehicleapi/crmcar/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/crmcar/crmcar-edit-vehicle-api.html',
				controller: 'VehicleApiCrmCarEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'VehicleApiCrmCarNewDtoFactory',
				function($stateParams, $rootScope, VehicleApiCrmCarNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return VehicleApiCrmCarNewDtoFactory.get({ id: $stateParams.crmCarId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('vehicle-api-crmcar.new', dtoCreateState);

	// create state for plus button on TaskTransport edit / new page for reference CrmCar and dto property value vehicle.api
	$stateProvider.state('vehicle-api-tasktransport.new.newCrmCar', getCreateState('/crmcar/new'));
	$stateProvider.state('vehicle-api-tasktransport.edit.newCrmCar', getCreateState('/crmcar/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/crmcar/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
