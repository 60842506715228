angular.module("mmmApp").component("localizedOutput", {
    templateUrl: "app/shared/localizedoutput/localizedoutput.html",
    bindings: {
        dto: "<",
        entityname: "@",
        propertyname: "@"
    },
    controller: function($scope) {
        /**
         * $ctrl.dto is a reference to an instance of a Resource which might not be resolved yet and thus, does not contain localized property yet.
         * Then component's code must handle cases of missing .localized field.
         * Thus, production of the translated data for UI must be fault-tolerant to absence of the .localized field
         * @type {*[]}
         */
        $scope.translations = getUIData(this);
        $scope.$watchGroup(["$ctrl", "$ctrl.dto.localized"], function(
            arrOfChanges
        ) {
            $scope.translations = getUIData(arrOfChanges[0]);
        });
    }
});

/**
 * Get objects describing translations for the entity-property pair. Ignore supportedLocales
 */
function getUIData(controllerInstance) {
    const res = [];
    const dto = controllerInstance.dto;
    const entityName = controllerInstance.entityname;
    const propertyName = controllerInstance.propertyname;
    // see why it is so in the controller doc
    if (dto && dto.localized && dto.localized.translations) {
        dto.localized.translations.forEach(function(translationDTO) {
            if (
                translationDTO.entityName === entityName &&
                translationDTO.propertyName === propertyName
            ) {
                res.push({
                    locale: translationDTO.locale,
                    text: translationDTO.translationValue
                });
            }
        });
    }
    return res;
}
