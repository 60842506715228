/** @format */

'use strict';

// VehicleOptionCategory has edit layout enabled. Edit layout template url: vehicleoptioncategory-dialog.html
// VehicleOptionCategory has detail layout enabled. Detail layout template url: vehicleoptioncategory-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'VehicleOptionCategory'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('vehicleoptioncategory'); // main translation loaded for vehicleoptioncategory

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-vehicleoptioncategory', {
		/* abstract state for DTO StockMgmtVehicleOptionCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/vehicleoptioncategory',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'VehicleOptionCategory',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtVehicleOptionCategoryListDto */
		/* to access: ui-ref="stock-mgmt-vehicleoptioncategory.list" */
		/* url: http://<hostname>/stockmgmt/vehicleoptioncategory/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/vehicleoptioncategory/vehicleoptioncategory-list-stock-mgmt.html',
				controller: 'StockMgmtVehicleOptionCategoryListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-vehicleoptioncategory.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtVehicleOptionCategoryViewDto */
		/* to access: ui-sref="stock-mgmt-vehicleoptioncategory.detail" */
		/* url: http://<hostname>/stockmgmt/vehicleoptioncategory/detail/:vehicleOptionCategoryId */
		url: '/detail/:vehicleOptionCategoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/vehicleoptioncategory/vehicleoptioncategory-view-stock-mgmt.html',
				controller: 'VehicleOptionCategoryDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtVehicleOptionCategoryViewDtoFactory',
				function($stateParams, StockMgmtVehicleOptionCategoryViewDtoFactory) {
					return StockMgmtVehicleOptionCategoryViewDtoFactory.get({ id: $stateParams.vehicleOptionCategoryId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-vehicleoptioncategory.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtVehicleOptionCategoryEditDto */
		/* to access: ui-sref="stock-mgmt-vehicleoptioncategory.edit" */
		/* url: http://<hostname>/stockmgmt/vehicleoptioncategory/edit/:vehicleOptionCategoryId */
		url: '/edit/:vehicleOptionCategoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/vehicleoptioncategory/vehicleoptioncategory-edit-stock-mgmt.html',
				controller: 'StockMgmtVehicleOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtVehicleOptionCategoryEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtVehicleOptionCategoryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtVehicleOptionCategoryEditDtoFactory.get({ id: $stateParams.vehicleOptionCategoryId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-vehicleoptioncategory.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtVehicleOptionCategoryEditDto */
		/* to access: ui-sref="stock-mgmt-vehicleoptioncategory.new" */
		/* url: http://<hostname>/stockmgmt/vehicleoptioncategory/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/vehicleoptioncategory/vehicleoptioncategory-edit-stock-mgmt.html',
				controller: 'StockMgmtVehicleOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtVehicleOptionCategoryNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtVehicleOptionCategoryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtVehicleOptionCategoryNewDtoFactory.get({ id: $stateParams.vehicleOptionCategoryId }).$promise.then(function(
							result
						) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-vehicleoptioncategory.new', dtoCreateState);
});
