/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarModelListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarModelFactory,
		CarConfigCarModelListDtoSearchFactory,
		CarConfigCarModelViewDtoFactory
	) {
		$scope.carModelList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarModel)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarModel);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarModel = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-carmodel.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getCarModelList = function() {
			CarConfigCarModelListDtoSearchFactory.query(
				{
					carBrandIdList: angular.isDefined($scope.carBrandId) ? $scope.carBrandId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carModelList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarModelList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarModelId = id;
			$('#deleteCarModelConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarModelViewDtoFactory.delete(
				{ id: $scope.selectedCarModelId },
				function() {
					$scope.loadAll();
					$('#deleteCarModelConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarModelConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carModelUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
