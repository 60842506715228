/** @format */

'use strict';

// GroupMember has edit layout enabled. Edit layout template url: groupmember-dialog.html
// GroupMember has detail layout enabled. Detail layout template url: groupmember-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'GroupMember'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('groupmember'); // main translation loaded for groupmember
		$translatePartialLoader.addPart('group'); // one to many reference from main entity
		$translatePartialLoader.addPart('person'); // main translation loaded for parent object Person of reference employee
		$translatePartialLoader.addPart('relation'); // main translation loaded for parent object Relation of reference employee
		$translatePartialLoader.addPart('employee'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-groupmember', {
		/* abstract state for DTO RelationMgmtGroupMemberListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/groupmember',
		data: {
			authorities: ['ROLE_TASK_ADMIN', 'ROLE_ADMIN'],
			pageTitle: 'GroupMember',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtGroupMemberListDto */
		/* to access: ui-ref="relation-mgmt-groupmember.list" */
		/* url: http://<hostname>/relationmgmt/groupmember/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/groupmember/groupmember-list-relation-mgmt.html',
				controller: 'RelationMgmtGroupMemberListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-groupmember.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtGroupMemberViewDto */
		/* to access: ui-sref="relation-mgmt-groupmember.detail" */
		/* url: http://<hostname>/relationmgmt/groupmember/detail/:groupMemberId */
		url: '/detail/:groupMemberId',
		views: {
			'content@': {
				templateUrl: 'app/entities/groupmember/groupmember-view-relation-mgmt.html',
				controller: 'GroupMemberDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtGroupMemberViewDtoFactory',
				function($stateParams, RelationMgmtGroupMemberViewDtoFactory) {
					return RelationMgmtGroupMemberViewDtoFactory.get({ id: $stateParams.groupMemberId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-groupmember.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtGroupMemberEditDto */
		/* to access: ui-sref="relation-mgmt-groupmember.edit" */
		/* url: http://<hostname>/relationmgmt/groupmember/edit/:groupMemberId */
		url: '/edit/:groupMemberId',
		views: {
			'content@': {
				templateUrl: 'app/entities/groupmember/groupmember-edit-relation-mgmt.html',
				controller: 'RelationMgmtGroupMemberEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtGroupMemberEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtGroupMemberEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtGroupMemberEditDtoFactory.get({ id: $stateParams.groupMemberId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-groupmember.edit', dtoEditState);
});
