/** @format */

(function() {
	'use strict';

	var jhiAlertError = {
		template:
			'<div class="alerts" ng-cloak="" role="alert">' +
			'<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
			'<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></uib-alert>' +
			'</div>' +
			'</div>',
		controller: jhiAlertErrorController,
	};

	angular.module('mmmApp').component('jhiAlertError', jhiAlertError);

	jhiAlertErrorController.$inject = ['$scope', 'AlertService', '$rootScope', '$translate'];

	function jhiAlertErrorController($scope, AlertService, $rootScope, $translate) {
		var vm = this;

		vm.alerts = [];

		function addErrorAlert(message, key, data) {
			key = key ? key : message;
			vm.alerts.push(
				AlertService.add(
					{
						type: 'danger',
						msg: key,
						params: data,
						timeout: 5000,
						toast: AlertService.isToast(),
						scoped: true,
					},
					vm.alerts
				)
			);
		}

		var cleanHttpErrorListener = $rootScope.$on('mmmApp.httpError', function(event, httpResponse) {
			var i;
			event.stopPropagation();
			switch (httpResponse.status) {
				// connection refused, server not reachable
				case 0:
					addErrorAlert('Server not reachable', 'error.server.not.reachable');
					break;

				case 400:
					var headers = Object.keys(httpResponse.headers())
						.filter(function(header) {
							return (
								header.indexOf('app-error', header.length - 'app-error'.length) !== -1 ||
								header.indexOf('app-params', header.length - 'app-params'.length) !== -1
							);
						})
						.sort();
					var errorHeader = httpResponse.headers(headers[0]);
					var entityKey = httpResponse.headers(headers[1]);
					if (angular.isString(errorHeader)) {
						var entityName = $translate.instant('global.menu.entities.' + entityKey);
						addErrorAlert(errorHeader, errorHeader, { entityName: entityName });
					} else if (httpResponse.data && httpResponse.data.fieldErrors) {
						for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
							var fieldError = httpResponse.data.fieldErrors[i];
							// convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
							var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
							var fieldName = $translate.instant('mmmApp.' + fieldError.objectName + '.' + convertedField);
							addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, { fieldName: fieldName });
						}
					} else if (httpResponse.data && httpResponse.data.message) {
						addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
					} else {
						addErrorAlert(httpResponse.data);
					}
					break;

				case 404:
					addErrorAlert('Not found', 'error.url.not.found');
					break;

				default:
					if (httpResponse.data && httpResponse.data.message) {
						addErrorAlert(httpResponse.data.message);
					} else {
						addErrorAlert(angular.toJson(httpResponse));
					}
			}
		});

		$scope.$on('$destroy', function() {
			if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
				cleanHttpErrorListener();
				vm.alerts = [];
			}
		});
	}
})();
