/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDocumentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DocumentFactory,
		StockMgmtDocumentListDtoSearchFactory,
		StockMgmtDocumentViewDtoFactory,
		DocumentDownloadService
	) {
		$scope.documentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDocument)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDocument);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDocument = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-document.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DocumentSearchOverview.tenant)) {
				return $scope.DocumentSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DocumentSearchOverview = angular.copy($scope.DocumentSearchOverview);

			$rootScope.pageSizeDocument = angular.copy($scope.pageSize);

			StockMgmtDocumentListDtoSearchFactory.query(
				{
					page: $scope.DocumentSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.DocumentSearchOverview.description,
					name: $scope.DocumentSearchOverview.name,
					fileName: $scope.DocumentSearchOverview.fileName,
					mimeType: $scope.DocumentSearchOverview.mimeType,
					tenantId: getTenantId(),

					sort: [$scope.DocumentSearchOverview.predicate + ',' + ($scope.DocumentSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.documentList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDocumentSearchOverviewDirty = function() {
			return !angular.equals($scope.DocumentSearchOverview, $scope.originalDocumentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DocumentSearchOverview = {};
			$rootScope.DocumentSearchOverview.page = 1;
			$rootScope.DocumentSearchOverview.predicate = 'id';
			$rootScope.DocumentSearchOverview.ascending = false;

			$scope.DocumentSearchOverview = angular.copy($rootScope.DocumentSearchOverview);

			$scope.originalDocumentSearchOverview = angular.copy($scope.DocumentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DocumentSearchOverview) || $rootScope.DocumentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DocumentSearchOverview = angular.copy($rootScope.DocumentSearchOverview);

			$scope.search();
		};

		$scope.downloadDocument = function(id) {
			DocumentDownloadService(id);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DocumentSearchOverview.predicate === itemName) {
				if ($scope.DocumentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
