/** @format */

'use strict';

// RelationCarRole has edit layout enabled. Edit layout template url: relationcarrole-dialog.html
// RelationCarRole has detail layout enabled. Detail layout template url: relationcarrole-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'RelationCarRole'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('relationcarrole'); // main translation loaded for relationcarrole
		$translatePartialLoader.addPart('crmcarrole'); // one to many reference from main entity
		$translatePartialLoader.addPart('relation'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-relationcarrole', {
		/* abstract state for DTO RelationMgmtRelationCarRoleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/relationcarrole',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'RelationCarRole',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtRelationCarRoleListDto */
		/* to access: ui-ref="relation-mgmt-relationcarrole.list" */
		/* url: http://<hostname>/relationmgmt/relationcarrole/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationcarrole/relationcarrole-list-relation-mgmt.html',
				controller: 'RelationMgmtRelationCarRoleListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-relationcarrole.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtRelationCarRoleViewDto */
		/* to access: ui-sref="relation-mgmt-relationcarrole.detail" */
		/* url: http://<hostname>/relationmgmt/relationcarrole/detail/:relationCarRoleId */
		url: '/detail/:relationCarRoleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationcarrole/relationcarrole-view-relation-mgmt.html',
				controller: 'RelationCarRoleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtRelationCarRoleViewDtoFactory',
				function($stateParams, RelationMgmtRelationCarRoleViewDtoFactory) {
					return RelationMgmtRelationCarRoleViewDtoFactory.get({ id: $stateParams.relationCarRoleId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationcarrole.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtRelationCarRoleEditDto */
		/* to access: ui-sref="relation-mgmt-relationcarrole.edit" */
		/* url: http://<hostname>/relationmgmt/relationcarrole/edit/:relationCarRoleId */
		url: '/edit/:relationCarRoleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationcarrole/relationcarrole-edit-relation-mgmt.html',
				controller: 'RelationMgmtRelationCarRoleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtRelationCarRoleEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtRelationCarRoleEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtRelationCarRoleEditDtoFactory.get({ id: $stateParams.relationCarRoleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationcarrole.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtRelationCarRoleEditDto */
		/* to access: ui-sref="relation-mgmt-relationcarrole.new" */
		/* url: http://<hostname>/relationmgmt/relationcarrole/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/relationcarrole/relationcarrole-edit-relation-mgmt.html',
				controller: 'RelationMgmtRelationCarRoleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtRelationCarRoleNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtRelationCarRoleNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtRelationCarRoleNewDtoFactory.get({ id: $stateParams.relationCarRoleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-relationcarrole.new', dtoCreateState);
});
