(function() {
	'use strict';

	angular.module('mmmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('user-management', {
			parent : 'admin',
			url : '/user-management?page&sort',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'userManagement.home.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/user/user-list-admin.html',
					controller : 'AdminUserListDtoController',
					controllerAs : 'vm'
				}
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'id,asc',
					squash : true
				}
			},
			resolve : {
				pagingParams : [ '$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
					return {
						page : PaginationUtil.parsePage($stateParams.page),
						sort : $stateParams.sort,
						predicate : PaginationUtil.parsePredicate($stateParams.sort),
						ascending : PaginationUtil.parseAscending($stateParams.sort)
					};
				} ],
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('user-management');
					$translatePartialLoader.addPart('user'); // main translation loaded for user
					$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
					$translatePartialLoader.addPart('authority'); // one to many reference from main entity
					$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
					return $translate.refresh();
				} ]

			}
		}).state('user-management.new', {
			url : '/new',
			data : {
				authorities : [ 'ROLE_ADMIN' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/admin/user-management/user-management-dialog.html',
					controller : 'UserManagementDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'lg',
					resolve : {
						entity : function() {
							return {
								id : null,
								login : null,
								firstName : null,
								lastName : null,
								email : null,
								activated : true,
								langKey : null,
								locale : null,
								createdBy : null,
								createdDate : null,
								lastModifiedBy : null,
								lastModifiedDate : null,
								resetDate : null,
								resetKey : null,
								authorities : null,
                                userType: null,
                                housePhone: null,
                                mobilePhone: null
							};
						}
					}
				}).result.then(function() {
					$state.go('user-management', null, {
						reload : true
					});
				}, function() {
					$state.go('user-management');
				});
			} ]
		}).state('user-management.edit', {
			url : '/{login}/edit',
			data : {
				authorities : [ 'ROLE_ADMIN' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/admin/user-management/user-management-dialog.html',
					controller : 'UserManagementDialogController',
					controllerAs : 'vm',
					backdrop : 'static',
					size : 'lg',
					resolve : {
						entity : [ 'User', function(User) {
							return User.get({
								login : $stateParams.login
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('user-management', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		}).state('user-management-detail', {
			parent : 'user-management',
			url : '/{login}',
			data : {
				authorities : [ 'ROLE_ADMIN' ],
				pageTitle : 'user-management.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/admin/user-management/user-management-detail.html',
					controller : 'UserManagementDetailController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('user-management');
					return $translate.refresh();
				} ]
			}
		}).state('user-management.delete', {
			url : '/{login}/delete',
			data : {
				authorities : [ 'ROLE_ADMIN' ]
			},
			onEnter : [ '$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl : 'app/admin/user-management/user-management-delete-dialog.html',
					controller : 'UserManagementDeleteController',
					controllerAs : 'vm',
					size : 'md',
					resolve : {
						entity : [ 'User', function(User) {
							return User.get({
								login : $stateParams.login
							}).$promise;
						} ]
					}
				}).result.then(function() {
					$state.go('user-management', null, {
						reload : true
					});
				}, function() {
					$state.go('^');
				});
			} ]
		});
	}
})();
