/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('DocumentFactory', function($resource) {
	return $resource(
		'api/documents/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtDocumentViewDto
 */
angular.module('mmmApp').factory('StockMgmtDocumentViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/document/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to GET a blob attribute from a document-like object.
 */
angular.module('mmmApp').factory('StockMgmtDocumentDownloadDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/document/download/:id',
		{},
		{
			download: {
				method: 'GET',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					console.log(headers);
					return {
						data: data,
						headerData: headers('content-disposition'),
					};
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtDocumentListDto's
 */
angular.module('mmmApp').factory('StockMgmtDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/document',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtDocumentEditDto
 */
angular.module('mmmApp').factory('StockMgmtDocumentEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/document/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDocumentEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtDocumentNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/document/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewDocumentFactory', function($resource) {
	return $resource(
		'api/documents/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Documents without the use of paging
angular.module('mmmApp').factory('NoPagingDocumentFactory', function($resource) {
	return $resource(
		'api/documents/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationDocumentsByDocumentFactory', function($resource) {
	return $resource(
		'api/relationDocuments/document/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('RelationFromRelationDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/relationDocumentList/AsRelationList/document/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('SectionDocumentsByDocumentFactory', function($resource) {
	return $resource(
		'api/sectionDocuments/document/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('TenantFromSectionDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/sectionDocumentList/AsTenantList/document/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarDocumentsByDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarDocuments/document/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('CrmCarFromCrmCarDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/crmCarDocumentList/AsCrmCarList/document/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CrmCarMediaDocumentsByDocumentFactory', function($resource) {
	return $resource(
		'api/crmCarMediaDocuments/document/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('CrmCarFromCrmCarMediaDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/crmCarMediaDocumentList/AsCrmCarList/document/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/documents/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
