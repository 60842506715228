/** @format */

'use strict';

// ExternalCarData has detail layout enabled. Detail layout template url: externalcardata-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ExternalCarData'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('externalcardata'); // main translation loaded for externalcardata
		$translatePartialLoader.addPart('mappingsource'); // one to many reference from main entity
		$translatePartialLoader.addPart('externalmessagetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('externalcardatafield'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-externalcardata', {
		/* abstract state for DTO StockMgmtExternalCarDataListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/externalcardata',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'ExternalCarData',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtExternalCarDataListDto */
		/* to access: ui-ref="stock-mgmt-externalcardata.list" */
		/* url: http://<hostname>/stockmgmt/externalcardata/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/externalcardata/externalcardata-list-stock-mgmt.html',
				controller: 'StockMgmtExternalCarDataListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-externalcardata.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtExternalCarDataViewDto */
		/* to access: ui-sref="stock-mgmt-externalcardata.detail" */
		/* url: http://<hostname>/stockmgmt/externalcardata/detail/:externalCarDataId */
		url: '/detail/:externalCarDataId',
		views: {
			'content@': {
				templateUrl: 'app/entities/externalcardata/externalcardata-view-stock-mgmt.html',
				controller: 'ExternalCarDataDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtExternalCarDataViewDtoFactory',
				function($stateParams, StockMgmtExternalCarDataViewDtoFactory) {
					return StockMgmtExternalCarDataViewDtoFactory.get({ id: $stateParams.externalCarDataId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-externalcardata.detail', dtoDetailState);
});
