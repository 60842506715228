/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('UserDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.user = entity;
	$scope.objectName = 'User';
	$scope.userId = $stateParams.userId;

	if (angular.isUndefined($rootScope.userActiveTab)) {
		//set the default here
		$rootScope.userActiveTab = 0;
	}

	$scope.goToEditState = function(user) {
		$state.go('user.detail.edit({userId:user.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:userUpdate', function(event, result) {
		$scope.user = result;
	});
});
