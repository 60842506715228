/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('RelationMgmtCrmCarOptionEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		RelationMgmtCrmCarOptionEditDtoFactory,
		AlertService,
		AutocompleteRelationMgmtCarsByQueryParamsFactory,
		AutocompleteRelationMgmtCarOptionsByQueryParamsFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.crmCarOption = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.crmCarOption['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.crmCarOption) && $rootScope.isDefinedAndNotNull($scope.crmCarOption.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:crmCarOptionUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			RelationMgmtCrmCarOptionEditDtoFactory.update($scope.crmCarOption, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteCars = function(val) {
			/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
			var params = {
				crmCarTypeAheadSearchString: val,
			};

			return AutocompleteRelationMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteCarOptions = function(val) {
			/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
			var params = {
				carOptionTypeAheadSearchString: val,
			};

			return AutocompleteRelationMgmtCarOptionsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
