/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticleBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigArticleBulkEditDtoFactory,
		AlertService,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);
		$scope.selectedArticleIds = _.map($stateParams.selectedArticleList, 'id');
		$scope.selectedItemsAmount = $scope.selectedArticleIds.length;
		$scope.article = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:articleUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			CarConfigArticleBulkEditDtoFactory.update(
				{ articleIds: $scope.selectedArticleIds },
				$scope.article,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
