/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtDealerImageEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtDealerImageEditDtoFactory,
		AlertService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.dealerImage = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.dealerImage['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.dealerImage) && $rootScope.isDefinedAndNotNull($scope.dealerImage.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:dealerImageUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			StockMgmtDealerImageEditDtoFactory.update($scope.dealerImage, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
