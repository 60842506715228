/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('AdminTenantListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		TenantFactory,
		AdminTenantListDtoSearchFactory,
		AdminTenantViewDtoFactory
	) {
		$scope.tenantList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeTenant)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeTenant);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeTenant = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('admin-tenant.new');
		};

		// handle attribute trimlines of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.TenantSearchOverview = angular.copy($scope.TenantSearchOverview);

			$rootScope.pageSizeTenant = angular.copy($scope.pageSize);

			AdminTenantListDtoSearchFactory.query(
				{
					page: $scope.TenantSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.TenantSearchOverview.name,
					trimlines: $scope.TenantSearchOverview.trimlinesGroup,

					sort: [$scope.TenantSearchOverview.predicate + ',' + ($scope.TenantSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.tenantList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isTenantSearchOverviewDirty = function() {
			return !angular.equals($scope.TenantSearchOverview, $scope.originalTenantSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.TenantSearchOverview = {};
			$rootScope.TenantSearchOverview.page = 1;
			$rootScope.TenantSearchOverview.predicate = 'name';
			$rootScope.TenantSearchOverview.ascending = false;

			$scope.TenantSearchOverview = angular.copy($rootScope.TenantSearchOverview);

			$scope.originalTenantSearchOverview = angular.copy($scope.TenantSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.TenantSearchOverview) || $rootScope.TenantSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.TenantSearchOverview = angular.copy($rootScope.TenantSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedTenantId = id;
			$('#deleteTenantConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			AdminTenantViewDtoFactory.delete(
				{ id: $scope.selectedTenantId },
				function() {
					$scope.search();
					$('#deleteTenantConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteTenantConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.TenantSearchOverview.predicate === itemName) {
				if ($scope.TenantSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
