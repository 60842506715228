/** @format */

'use strict';

// Tag has edit layout enabled. Edit layout template url: tag-dialog.html
// Tag has detail layout enabled. Detail layout template url: tag-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Tag'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('tag'); // main translation loaded for tag
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-tag', {
		/* abstract state for DTO CarConfigTagListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/tag',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Tag',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigTagListDto */
		/* to access: ui-ref="car-config-tag.list" */
		/* url: http://<hostname>/car/config/tag/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/tag/tag-list-car-config.html',
				controller: 'CarConfigTagListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-tag.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigTagViewDto */
		/* to access: ui-sref="car-config-tag.detail" */
		/* url: http://<hostname>/car/config/tag/detail/:tagId */
		url: '/detail/:tagId',
		views: {
			'content@': {
				templateUrl: 'app/entities/tag/tag-view-car-config.html',
				controller: 'TagDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigTagViewDtoFactory',
				function($stateParams, CarConfigTagViewDtoFactory) {
					return CarConfigTagViewDtoFactory.get({ id: $stateParams.tagId });
				},
			],
		},
	};

	$stateProvider.state('car-config-tag.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigTagEditDto */
		/* to access: ui-sref="car-config-tag.edit" */
		/* url: http://<hostname>/car/config/tag/edit/:tagId */
		url: '/edit/:tagId',
		views: {
			'content@': {
				templateUrl: 'app/entities/tag/tag-edit-car-config.html',
				controller: 'CarConfigTagEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigTagEditDtoFactory',
				function($stateParams, $rootScope, CarConfigTagEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigTagEditDtoFactory.get({ id: $stateParams.tagId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-tag.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigTagEditDto */
		/* to access: ui-sref="car-config-tag.new" */
		/* url: http://<hostname>/car/config/tag/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/tag/tag-edit-car-config.html',
				controller: 'CarConfigTagEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigTagNewDtoFactory',
				function($stateParams, $rootScope, CarConfigTagNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigTagNewDtoFactory.get({ id: $stateParams.tagId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-tag.new', dtoCreateState);
});
