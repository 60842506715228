/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('FilterTypeFactory', function($resource) {
	return $resource(
		'api/filterTypes/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigFilterTypeViewDto
 */
angular.module('mmmApp').factory('CarConfigFilterTypeViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filtertype/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigFilterTypeListDto's
 */
angular.module('mmmApp').factory('CarConfigFilterTypeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/filtertype',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigFilterTypeEditDto
 */
angular.module('mmmApp').factory('CarConfigFilterTypeEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filtertype/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigFilterTypeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigFilterTypeNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/filtertype/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewFilterTypeFactory', function($resource) {
	return $resource(
		'api/filterTypes/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all FilterTypes without the use of paging
angular.module('mmmApp').factory('NoPagingFilterTypeFactory', function($resource) {
	return $resource(
		'api/filterTypes/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ConditionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingConditionTypeFactory', function($resource) {
	return $resource(
		'api/filterType/conditionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('FilterOptionsByFilterTypeFactory', function($resource) {
	return $resource(
		'api/filterOptions/filterType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('FilterPagesByFilterTypeFactory', function($resource) {
	return $resource(
		'api/filterPages/filterType/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllFilterTypesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/filterTypes/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
