/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('HexonPortalFactory', function($resource) {
	return $resource(
		'api/hexonPortals/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtHexonPortalViewDto
 */
angular.module('mmmApp').factory('StockMgmtHexonPortalViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonportal/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtHexonPortalListDto's
 */
angular.module('mmmApp').factory('StockMgmtHexonPortalListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonportal',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtHexonPortalEditDto
 */
angular.module('mmmApp').factory('StockMgmtHexonPortalEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonportal/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtHexonPortalEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtHexonPortalNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonportal/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtHexonPortalEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtHexonPortalEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonportal/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewHexonPortalFactory', function($resource) {
	return $resource(
		'api/hexonPortals/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all HexonPortals without the use of paging
angular.module('mmmApp').factory('NoPagingHexonPortalFactory', function($resource) {
	return $resource(
		'api/hexonPortals/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllHexonPortalsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/hexonPortals/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('HexonPortalSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/hexonportals/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
