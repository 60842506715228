/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarModelDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carModel = entity;
	$scope.objectName = 'CarModel';
	$scope.carModelId = $stateParams.carModelId;

	if (angular.isUndefined($rootScope.carModelActiveTab)) {
		//set the default here
		$rootScope.carModelActiveTab = 0;
	}

	$scope.goToEditState = function(carModel) {
		$state.go('carmodel.detail.edit({carModelId:carModel.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carModelUpdate', function(event, result) {
		$scope.carModel = result;
	});
});
