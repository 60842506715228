/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('TranslationDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.translation = entity;
	$scope.objectName = 'Translation';
	$scope.translationId = $stateParams.translationId;

	if (angular.isUndefined($rootScope.translationActiveTab)) {
		//set the default here
		$rootScope.translationActiveTab = 0;
	}

	$scope.goToEditState = function(translation) {
		$state.go('translation.detail.edit({translationId:translation.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:translationUpdate', function(event, result) {
		$scope.translation = result;
	});
});
