/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtRelationDocumentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationDocumentFactory,
		RelationMgmtRelationDocumentListDtoSearchFactory,
		RelationMgmtRelationDocumentViewDtoFactory,
		AutocompleteRelationMgmtRelationsByQueryParamsFactory
	) {
		$scope.relationDocumentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelationDocument)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelationDocument);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelationDocument = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-relationdocument.new');
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationDocumentSearchOverview.relation)) {
				return $scope.RelationDocumentSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getDocumentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationDocumentSearchOverview.document)) {
				return $scope.RelationDocumentSearchOverview.document.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RelationDocumentSearchOverview = angular.copy($scope.RelationDocumentSearchOverview);

			$rootScope.pageSizeRelationDocument = angular.copy($scope.pageSize);

			RelationMgmtRelationDocumentListDtoSearchFactory.query(
				{
					page: $scope.RelationDocumentSearchOverview.page - 1,
					size: $scope.pageSize,
					relationId: getRelationId(),
					documentId: getDocumentId(),

					sort: [
						$scope.RelationDocumentSearchOverview.predicate + ',' + ($scope.RelationDocumentSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationDocumentList = result.items;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.documentReferenceFilterList = result.documentSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationDocumentSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationDocumentSearchOverview, $scope.originalRelationDocumentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationDocumentSearchOverview = {};
			$rootScope.RelationDocumentSearchOverview.page = 1;
			$rootScope.RelationDocumentSearchOverview.predicate = 'id';
			$rootScope.RelationDocumentSearchOverview.ascending = false;

			$scope.RelationDocumentSearchOverview = angular.copy($rootScope.RelationDocumentSearchOverview);

			$scope.originalRelationDocumentSearchOverview = angular.copy($scope.RelationDocumentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationDocumentSearchOverview) || $rootScope.RelationDocumentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationDocumentSearchOverview = angular.copy($rootScope.RelationDocumentSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationDocumentId = id;
			$('#deleteRelationDocumentConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtRelationDocumentViewDtoFactory.delete(
				{ id: $scope.selectedRelationDocumentId },
				function() {
					$scope.search();
					$('#deleteRelationDocumentConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationDocumentConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationDocumentSearchOverview.predicate === itemName) {
				if ($scope.RelationDocumentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
