/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('RelationRoleDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.relationRole = entity;
	$scope.objectName = 'RelationRole';
	$scope.relationRoleId = $stateParams.relationRoleId;

	if (angular.isUndefined($rootScope.relationRoleActiveTab)) {
		//set the default here
		$rootScope.relationRoleActiveTab = 0;
	}

	$scope.goToEditState = function(relationRole) {
		$state.go('relationrole.detail.edit({relationRoleId:relationRole.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:relationRoleUpdate', function(event, result) {
		$scope.relationRole = result;
	});
});
