/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigImageListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ImageFactory,
		CarConfigImageListDtoSearchFactory,
		CarConfigImageViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.imageList = [];
		$scope.selectedImageList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeImage)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeImage);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeImage = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-image.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ImageSearchOverview.article)) {
				return $scope.ImageSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		// handle attribute defaultImage of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ImageSearchOverview = angular.copy($scope.ImageSearchOverview);

			$rootScope.pageSizeImage = angular.copy($scope.pageSize);

			CarConfigImageListDtoSearchFactory.query(
				{
					page: $scope.ImageSearchOverview.page - 1,
					size: $scope.pageSize,
					url: $scope.ImageSearchOverview.url,
					defaultImage: $scope.ImageSearchOverview.defaultImageGroup,
					articleId: getArticleId(),
					colorIdList: $scope.ImageSearchOverview.colorIdList,

					sort: [$scope.ImageSearchOverview.predicate + ',' + ($scope.ImageSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.imageList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.colorReferenceFilterList = result.colorSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isImageSearchOverviewDirty = function() {
			return !angular.equals($scope.ImageSearchOverview, $scope.originalImageSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ImageSearchOverview = {};
			$rootScope.ImageSearchOverview.page = 1;
			$rootScope.ImageSearchOverview.predicate = 'id';
			$rootScope.ImageSearchOverview.ascending = false;

			$scope.ImageSearchOverview = angular.copy($rootScope.ImageSearchOverview);

			$scope.originalImageSearchOverview = angular.copy($scope.ImageSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ImageSearchOverview) || $rootScope.ImageSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ImageSearchOverview = angular.copy($rootScope.ImageSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedImageId = id;
			$('#deleteImageConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigImageViewDtoFactory.delete(
				{ id: $scope.selectedImageId },
				function() {
					$scope.search();
					$('#deleteImageConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteImageConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleImageConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedImageList, function(value, key) {
				promises.push(CarConfigImageViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleImageConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.imageList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedImageList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedImageList = [];

			angular.forEach($scope.imageList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedImageList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:imageUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ImageSearchOverview.predicate === itemName) {
				if ($scope.ImageSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateColorUISelect = function(item, model) {
			$scope.search();
		};
	});
