/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigRdwColorListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RdwColorFactory,
		CarConfigRdwColorListDtoSearchFactory,
		CarConfigRdwColorViewDtoFactory
	) {
		$scope.rdwColorList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRdwColor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRdwColor);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRdwColor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-rdwcolor.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.RdwColorSearchOverview = angular.copy($scope.RdwColorSearchOverview);

			$rootScope.pageSizeRdwColor = angular.copy($scope.pageSize);

			CarConfigRdwColorListDtoSearchFactory.query(
				{
					page: $scope.RdwColorSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.RdwColorSearchOverview.description,
					hexColor: $scope.RdwColorSearchOverview.hexColor,

					sort: [$scope.RdwColorSearchOverview.predicate + ',' + ($scope.RdwColorSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.rdwColorList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRdwColorSearchOverviewDirty = function() {
			return !angular.equals($scope.RdwColorSearchOverview, $scope.originalRdwColorSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RdwColorSearchOverview = {};
			$rootScope.RdwColorSearchOverview.page = 1;
			$rootScope.RdwColorSearchOverview.predicate = 'id';
			$rootScope.RdwColorSearchOverview.ascending = false;

			$scope.RdwColorSearchOverview = angular.copy($rootScope.RdwColorSearchOverview);

			$scope.originalRdwColorSearchOverview = angular.copy($scope.RdwColorSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RdwColorSearchOverview) || $rootScope.RdwColorSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RdwColorSearchOverview = angular.copy($rootScope.RdwColorSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRdwColorId = id;
			$('#deleteRdwColorConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigRdwColorViewDtoFactory.delete(
				{ id: $scope.selectedRdwColorId },
				function() {
					$scope.search();
					$('#deleteRdwColorConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRdwColorConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RdwColorSearchOverview.predicate === itemName) {
				if ($scope.RdwColorSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
