/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('DocumentSecuredFactory', function($resource) {
	return $resource(
		'api/documents/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtDocumentSecuredViewDto
 */
angular.module('mmmApp').factory('StockMgmtDocumentSecuredViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/documentsecured/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to GET a blob attribute from a document-like object.
 */
angular.module('mmmApp').factory('StockMgmtDocumentSecuredDownloadDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/documentsecured/download/:id',
		{},
		{
			download: {
				method: 'GET',
				responseType: 'arraybuffer',
				transformResponse: function(data, headers) {
					console.log(headers);
					return {
						data: data,
						headerData: headers('content-disposition'),
					};
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtDocumentSecuredListDto's
 */
angular.module('mmmApp').factory('StockMgmtDocumentSecuredListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/documentsecured',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtDocumentSecuredEditDto
 */
angular.module('mmmApp').factory('StockMgmtDocumentSecuredEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/documentsecured/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDocumentSecuredEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtDocumentSecuredNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/documentsecured/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewDocumentSecuredFactory', function($resource) {
	return $resource(
		'api/documents/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Documents without the use of paging
angular.module('mmmApp').factory('NoPagingDocumentSecuredFactory', function($resource) {
	return $resource(
		'api/documents/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerAuthenticationsByDocumentFactory', function($resource) {
	return $resource(
		'api/dealerAuthentications/document/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('AuthenticationTypeFromDealerAuthenticationPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/dealerAuthenticationList/AsAuthenticationTypeList/documentSecured/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/documents/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
