/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('WorkflowProcessAnchorDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.workflowProcessAnchor = entity;
		$scope.objectName = 'WorkflowProcessAnchor';
		$scope.workflowProcessAnchorId = $stateParams.workflowProcessAnchorId;

		if (angular.isUndefined($rootScope.workflowProcessAnchorActiveTab)) {
			//set the default here
			$rootScope.workflowProcessAnchorActiveTab = 0;
		}

		$scope.goToEditState = function(workflowProcessAnchor) {
			$state.go(
				'workflowprocessanchor.detail.edit({workflowProcessAnchorId:workflowProcessAnchor.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:workflowProcessAnchorUpdate', function(event, result) {
			$scope.workflowProcessAnchor = result;
		});
	});
