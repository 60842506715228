/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ExternalCarDataFactory', function($resource) {
	return $resource(
		'api/externalCarDatas/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtExternalCarDataViewDto
 */
angular.module('mmmApp').factory('StockMgmtExternalCarDataViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/externalcardata/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtExternalCarDataListDto's
 */
angular.module('mmmApp').factory('StockMgmtExternalCarDataListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/externalcardata',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewExternalCarDataFactory', function($resource) {
	return $resource(
		'api/externalCarDatas/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ExternalCarDatas without the use of paging
angular.module('mmmApp').factory('NoPagingExternalCarDataFactory', function($resource) {
	return $resource(
		'api/externalCarDatas/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/externalCarData/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ExternalMessageTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingExternalMessageTypeFactory', function($resource) {
	return $resource(
		'api/externalCarData/externalMessageTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ExternalCarDataFieldsByExternalCarDataFactory', function($resource) {
	return $resource(
		'api/externalCarDataFields/externalCarData/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllExternalCarDatasByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/externalCarDatas/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
