/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('ApplicationLogDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.applicationLog = entity;
		$scope.objectName = 'ApplicationLog';
		$scope.applicationLogId = $stateParams.applicationLogId;

		if (angular.isUndefined($rootScope.applicationLogActiveTab)) {
			//set the default here
			$rootScope.applicationLogActiveTab = 0;
		}

		$scope.goToEditState = function(applicationLog) {
			$state.go('applicationlog.detail.edit({applicationLogId:applicationLog.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:applicationLogUpdate', function(event, result) {
			$scope.applicationLog = result;
		});
	});
