/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtOrderLineGroupListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineGroupFactory,
		RelationMgmtOrderLineGroupListDtoSearchFactory,
		RelationMgmtOrderLineGroupViewDtoFactory,
		AutocompleteRelationMgmtSalesOrdersByQueryParamsFactory
	) {
		$scope.orderLineGroupList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLineGroup)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLineGroup);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOrderLineGroup = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-orderlinegroup.new');
		};

		var getSalesOrderId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineGroupSearchOverview.salesOrder)) {
				return $scope.OrderLineGroupSearchOverview.salesOrder.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineGroupSearchOverview.tenant)) {
				return $scope.OrderLineGroupSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute tradeIn of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.OrderLineGroupSearchOverview = angular.copy($scope.OrderLineGroupSearchOverview);

			$rootScope.pageSizeOrderLineGroup = angular.copy($scope.pageSize);

			RelationMgmtOrderLineGroupListDtoSearchFactory.query(
				{
					page: $scope.OrderLineGroupSearchOverview.page - 1,
					size: $scope.pageSize,
					sortOrderFrom: $scope.OrderLineGroupSearchOverview.sortOrderFrom,
					sortOrderTo: $scope.OrderLineGroupSearchOverview.sortOrderTo,
					description: $scope.OrderLineGroupSearchOverview.description,
					tradeIn: $scope.OrderLineGroupSearchOverview.tradeInGroup,
					salesOrderId: getSalesOrderId(),
					tenantId: getTenantId(),

					sort: [$scope.OrderLineGroupSearchOverview.predicate + ',' + ($scope.OrderLineGroupSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.orderLineGroupList = result.items;

					$scope.autocompleteSalesOrders = function(val) {
						var params = {
							salesOrderTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtSalesOrdersByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isOrderLineGroupSearchOverviewDirty = function() {
			return !angular.equals($scope.OrderLineGroupSearchOverview, $scope.originalOrderLineGroupSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OrderLineGroupSearchOverview = {};
			$rootScope.OrderLineGroupSearchOverview.page = 1;
			$rootScope.OrderLineGroupSearchOverview.predicate = 'sortOrder';
			$rootScope.OrderLineGroupSearchOverview.ascending = false;

			$scope.OrderLineGroupSearchOverview = angular.copy($rootScope.OrderLineGroupSearchOverview);

			$scope.originalOrderLineGroupSearchOverview = angular.copy($scope.OrderLineGroupSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OrderLineGroupSearchOverview) || $rootScope.OrderLineGroupSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OrderLineGroupSearchOverview = angular.copy($rootScope.OrderLineGroupSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineGroupId = id;
			$('#deleteOrderLineGroupConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtOrderLineGroupViewDtoFactory.delete(
				{ id: $scope.selectedOrderLineGroupId },
				function() {
					$scope.search();
					$('#deleteOrderLineGroupConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineGroupConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OrderLineGroupSearchOverview.predicate === itemName) {
				if ($scope.OrderLineGroupSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
