/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingTransmissionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingTransmissionFactory,
		StockMgmtMappingTransmissionListDtoSearchFactory,
		StockMgmtMappingTransmissionViewDtoFactory
	) {
		$scope.mappingTransmissionList = [];
		$scope.selectedMappingTransmissionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingTransmission)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingTransmission);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingTransmission = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingtransmission.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingTransmissionSearchOverview = angular.copy($scope.MappingTransmissionSearchOverview);

			$rootScope.pageSizeMappingTransmission = angular.copy($scope.pageSize);

			StockMgmtMappingTransmissionListDtoSearchFactory.query(
				{
					page: $scope.MappingTransmissionSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingTransmissionSearchOverview.sourceKey,
					sourceField: $scope.MappingTransmissionSearchOverview.sourceField,
					sourceList: $scope.MappingTransmissionSearchOverview.sourceList,
					transmissionTypeList: $scope.MappingTransmissionSearchOverview.transmissionTypeList,

					sort: [
						$scope.MappingTransmissionSearchOverview.predicate +
							',' +
							($scope.MappingTransmissionSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingTransmissionList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.transmissionTypeList = result.transmissionTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingTransmissionSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingTransmissionSearchOverview, $scope.originalMappingTransmissionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingTransmissionSearchOverview = {};
			$rootScope.MappingTransmissionSearchOverview.page = 1;
			$rootScope.MappingTransmissionSearchOverview.predicate = 'id';
			$rootScope.MappingTransmissionSearchOverview.ascending = false;

			$scope.MappingTransmissionSearchOverview = angular.copy($rootScope.MappingTransmissionSearchOverview);

			$scope.originalMappingTransmissionSearchOverview = angular.copy($scope.MappingTransmissionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingTransmissionSearchOverview) || $rootScope.MappingTransmissionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingTransmissionSearchOverview = angular.copy($rootScope.MappingTransmissionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingTransmissionId = id;
			$('#deleteMappingTransmissionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingTransmissionViewDtoFactory.delete(
				{ id: $scope.selectedMappingTransmissionId },
				function() {
					$scope.search();
					$('#deleteMappingTransmissionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteMappingTransmissionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingTransmissionConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingTransmissionList, function(value, key) {
				promises.push(StockMgmtMappingTransmissionViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingTransmissionConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingTransmissionList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingTransmissionList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingTransmissionList = [];

			angular.forEach($scope.mappingTransmissionList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingTransmissionList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingTransmissionUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingTransmissionSearchOverview.predicate === itemName) {
				if ($scope.MappingTransmissionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTransmissionTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
