/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('VehicleOptionDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.vehicleOption = entity;
	$scope.objectName = 'VehicleOption';
	$scope.vehicleOptionId = $stateParams.vehicleOptionId;

	if (angular.isUndefined($rootScope.vehicleOptionActiveTab)) {
		//set the default here
		$rootScope.vehicleOptionActiveTab = 0;
	}

	$scope.goToEditState = function(vehicleOption) {
		$state.go('vehicleoption.detail.edit({vehicleOptionId:vehicleOption.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:vehicleOptionUpdate', function(event, result) {
		$scope.vehicleOption = result;
	});
});
