/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtWorkflowProcessAnchorListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		WorkflowProcessAnchorFactory,
		RelationMgmtWorkflowProcessAnchorListDtoSearchFactory,
		RelationMgmtWorkflowProcessAnchorViewDtoFactory
	) {
		$scope.workflowProcessAnchorList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeWorkflowProcessAnchor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeWorkflowProcessAnchor);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeWorkflowProcessAnchor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-workflowprocessanchor.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.WorkflowProcessAnchorSearchOverview = angular.copy($scope.WorkflowProcessAnchorSearchOverview);

			$rootScope.pageSizeWorkflowProcessAnchor = angular.copy($scope.pageSize);

			RelationMgmtWorkflowProcessAnchorListDtoSearchFactory.query(
				{
					page: $scope.WorkflowProcessAnchorSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.WorkflowProcessAnchorSearchOverview.name,
					processAnchor: $scope.WorkflowProcessAnchorSearchOverview.processAnchor,
					workflowServiceTypeList: $scope.WorkflowProcessAnchorSearchOverview.workflowServiceTypeList,
					locale: $scope.WorkflowProcessAnchorSearchOverview.locale,

					sort: [
						$scope.WorkflowProcessAnchorSearchOverview.predicate +
							',' +
							($scope.WorkflowProcessAnchorSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.workflowProcessAnchorList = result.items;

					$scope.workflowServiceTypeList = result.workflowServiceTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isWorkflowProcessAnchorSearchOverviewDirty = function() {
			return !angular.equals($scope.WorkflowProcessAnchorSearchOverview, $scope.originalWorkflowProcessAnchorSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.WorkflowProcessAnchorSearchOverview = {};
			$rootScope.WorkflowProcessAnchorSearchOverview.page = 1;
			$rootScope.WorkflowProcessAnchorSearchOverview.predicate = 'id';
			$rootScope.WorkflowProcessAnchorSearchOverview.ascending = false;

			$scope.WorkflowProcessAnchorSearchOverview = angular.copy($rootScope.WorkflowProcessAnchorSearchOverview);

			$scope.originalWorkflowProcessAnchorSearchOverview = angular.copy($scope.WorkflowProcessAnchorSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.WorkflowProcessAnchorSearchOverview) || $rootScope.WorkflowProcessAnchorSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.WorkflowProcessAnchorSearchOverview = angular.copy($rootScope.WorkflowProcessAnchorSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedWorkflowProcessAnchorId = id;
			$('#deleteWorkflowProcessAnchorConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtWorkflowProcessAnchorViewDtoFactory.delete(
				{ id: $scope.selectedWorkflowProcessAnchorId },
				function() {
					$scope.search();
					$('#deleteWorkflowProcessAnchorConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteWorkflowProcessAnchorConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.WorkflowProcessAnchorSearchOverview.predicate === itemName) {
				if ($scope.WorkflowProcessAnchorSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateWorkflowServiceTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
