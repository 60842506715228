/** @format */

'use strict';

// CarBodytype has edit layout enabled. Edit layout template url: carbodytype-dialog.html
// CarBodytype has detail layout enabled. Detail layout template url: carbodytype-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarBodytype'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carbodytype'); // main translation loaded for carbodytype
		$translatePartialLoader.addPart('article'); // main translation loaded for carbodytype
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('bodytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carbodytype', {
		/* abstract state for DTO CarConfigCarBodytypeListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carbodytype',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarBodytype',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarBodytypeListDto */
		/* to access: ui-ref="car-config-carbodytype.list" */
		/* url: http://<hostname>/car/config/carbodytype/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-list-car-config.html',
				controller: 'CarConfigCarBodytypeListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carbodytype.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarBodytypeViewDto */
		/* to access: ui-sref="car-config-carbodytype.detail" */
		/* url: http://<hostname>/car/config/carbodytype/detail/:carBodytypeId */
		url: '/detail/:carBodytypeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-view-car-config.html',
				controller: 'CarBodytypeDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarBodytypeViewDtoFactory',
				function($stateParams, CarConfigCarBodytypeViewDtoFactory) {
					return CarConfigCarBodytypeViewDtoFactory.get({ id: $stateParams.carBodytypeId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carbodytype.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarBodytypeEditDto */
		/* to access: ui-sref="car-config-carbodytype.edit" */
		/* url: http://<hostname>/car/config/carbodytype/edit/:carBodytypeId */
		url: '/edit/:carBodytypeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-edit-car-config.html',
				controller: 'CarConfigCarBodytypeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarBodytypeEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarBodytypeEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarBodytypeEditDtoFactory.get({ id: $stateParams.carBodytypeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carbodytype.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarBodytypeEditDto */
		/* to access: ui-sref="car-config-carbodytype.new" */
		/* url: http://<hostname>/car/config/carbodytype/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-edit-car-config.html',
				controller: 'CarConfigCarBodytypeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarBodytypeNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarBodytypeNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarBodytypeNewDtoFactory.get({ id: $stateParams.carBodytypeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carbodytype.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarBodytype and dto property value car.config
	$stateProvider.state('car-config-carconfiguration.new.newCarBodytype', getCreateState('/carbodytype/new'));
	$stateProvider.state('car-config-carconfiguration.edit.newCarBodytype', getCreateState('/carbodytype/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carbodytype/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('article-api-carbodytype', {
		/* abstract state for DTO ArticleApiCarBodytypeListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carbodytype',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarBodytype',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarBodytypeEditDto */
		/* to access: ui-sref="article-api-carbodytype.edit" */
		/* url: http://<hostname>/articleapi/carbodytype/edit/:carBodytypeId */
		url: '/edit/:carBodytypeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-edit-article-api.html',
				controller: 'ArticleApiCarBodytypeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarBodytypeEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarBodytypeEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarBodytypeEditDtoFactory.get({ id: $stateParams.carBodytypeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carbodytype.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarBodytypeEditDto */
		/* to access: ui-sref="article-api-carbodytype.new" */
		/* url: http://<hostname>/articleapi/carbodytype/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbodytype/carbodytype-edit-article-api.html',
				controller: 'ArticleApiCarBodytypeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarBodytypeNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarBodytypeNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarBodytypeNewDtoFactory.get({ id: $stateParams.carBodytypeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carbodytype.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarBodytype and dto property value article.api
	$stateProvider.state('article-api-carconfiguration.new.newCarBodytype', getCreateState('/carbodytype/new'));
	$stateProvider.state('article-api-carconfiguration.edit.newCarBodytype', getCreateState('/carbodytype/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carbodytype/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
