/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('BaseTaskDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.baseTask = entity;
	$scope.objectName = 'BaseTask';
	$scope.baseTaskId = $stateParams.baseTaskId;

	if (angular.isUndefined($rootScope.baseTaskActiveTab)) {
		//set the default here
		$rootScope.baseTaskActiveTab = 0;
	}

	$scope.goToEditState = function(baseTask) {
		$state.go('basetask.detail.edit({baseTaskId:baseTask.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:baseTaskUpdate', function(event, result) {
		$scope.baseTask = result;
	});
});
