/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigFilterTypeListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		FilterTypeFactory,
		CarConfigFilterTypeListDtoSearchFactory,
		CarConfigFilterTypeViewDtoFactory
	) {
		$scope.filterTypeList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeFilterType)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeFilterType);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeFilterType = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-filtertype.new');
		};

		// handle attribute multiSelect of javaType boolean
		// handle attribute color of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.FilterTypeSearchOverview = angular.copy($scope.FilterTypeSearchOverview);

			$rootScope.pageSizeFilterType = angular.copy($scope.pageSize);

			CarConfigFilterTypeListDtoSearchFactory.query(
				{
					page: $scope.FilterTypeSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.FilterTypeSearchOverview.description,
					multiSelect: $scope.FilterTypeSearchOverview.multiSelectGroup,
					color: $scope.FilterTypeSearchOverview.colorGroup,
					displayOrderFrom: $scope.FilterTypeSearchOverview.displayOrderFrom,
					displayOrderTo: $scope.FilterTypeSearchOverview.displayOrderTo,
					conditionList: $scope.FilterTypeSearchOverview.conditionList,

					sort: [$scope.FilterTypeSearchOverview.predicate + ',' + ($scope.FilterTypeSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.filterTypeList = result.items;

					$scope.conditionTypeList = result.conditionSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isFilterTypeSearchOverviewDirty = function() {
			return !angular.equals($scope.FilterTypeSearchOverview, $scope.originalFilterTypeSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.FilterTypeSearchOverview = {};
			$rootScope.FilterTypeSearchOverview.page = 1;
			$rootScope.FilterTypeSearchOverview.predicate = 'id';
			$rootScope.FilterTypeSearchOverview.ascending = false;

			$scope.FilterTypeSearchOverview = angular.copy($rootScope.FilterTypeSearchOverview);

			$scope.originalFilterTypeSearchOverview = angular.copy($scope.FilterTypeSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.FilterTypeSearchOverview) || $rootScope.FilterTypeSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.FilterTypeSearchOverview = angular.copy($rootScope.FilterTypeSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedFilterTypeId = id;
			$('#deleteFilterTypeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigFilterTypeViewDtoFactory.delete(
				{ id: $scope.selectedFilterTypeId },
				function() {
					$scope.search();
					$('#deleteFilterTypeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteFilterTypeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.FilterTypeSearchOverview.predicate === itemName) {
				if ($scope.FilterTypeSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateConditionUISelect = function(item, model) {
			$scope.search();
		};
	});
