/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('BaseTaskTemplateDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.baseTaskTemplate = entity;
		$scope.objectName = 'BaseTaskTemplate';
		$scope.baseTaskTemplateId = $stateParams.baseTaskTemplateId;

		if (angular.isUndefined($rootScope.baseTaskTemplateActiveTab)) {
			//set the default here
			$rootScope.baseTaskTemplateActiveTab = 0;
		}

		$scope.goToEditState = function(baseTaskTemplate) {
			$state.go('basetasktemplate.detail.edit({baseTaskTemplateId:baseTaskTemplate.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:baseTaskTemplateUpdate', function(event, result) {
			$scope.baseTaskTemplate = result;
		});
	});
