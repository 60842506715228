/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarGradeListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarGradeFactory,
		CarConfigCarGradeListDtoSearchFactory,
		CarConfigCarGradeViewDtoFactory
	) {
		$scope.carGradeList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarGrade)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarGrade);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarGrade = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-cargrade.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarGradeSearchOverview.equivalent)) {
				return $scope.CarGradeSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarGradeSearchOverview = angular.copy($scope.CarGradeSearchOverview);

			$rootScope.pageSizeCarGrade = angular.copy($scope.pageSize);

			CarConfigCarGradeListDtoSearchFactory.query(
				{
					page: $scope.CarGradeSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarGradeSearchOverview.name,
					productCode: $scope.CarGradeSearchOverview.productCode,
					factoryCode: $scope.CarGradeSearchOverview.factoryCode,
					description: $scope.CarGradeSearchOverview.description,
					canBeOrdered: $scope.CarGradeSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarGradeSearchOverview.spotlightGroup,
					creator: $scope.CarGradeSearchOverview.creator,
					updater: $scope.CarGradeSearchOverview.updater,
					statusList: $scope.CarGradeSearchOverview.statusList,
					typeList: $scope.CarGradeSearchOverview.typeList,
					imageSize: $scope.CarGradeSearchOverview.imageSize,
					vatType: $scope.CarGradeSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarGradeSearchOverview.priceTypeList,
					brandIdList: $scope.CarGradeSearchOverview.brandIdList,
					countryList: $scope.CarGradeSearchOverview.countryList,
					locale: $scope.CarGradeSearchOverview.locale,
					carModelYearIdList: $scope.CarGradeSearchOverview.carModelYearIdList,

					sort: [$scope.CarGradeSearchOverview.predicate + ',' + ($scope.CarGradeSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carGradeList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.carModelYearReferenceFilterList = result.carModelYearSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarGradeSearchOverviewDirty = function() {
			return !angular.equals($scope.CarGradeSearchOverview, $scope.originalCarGradeSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarGradeSearchOverview = {};
			$rootScope.CarGradeSearchOverview.page = 1;
			$rootScope.CarGradeSearchOverview.predicate = 'id';
			$rootScope.CarGradeSearchOverview.ascending = false;

			$scope.CarGradeSearchOverview = angular.copy($rootScope.CarGradeSearchOverview);

			$scope.originalCarGradeSearchOverview = angular.copy($scope.CarGradeSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarGradeSearchOverview) || $rootScope.CarGradeSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarGradeSearchOverview = angular.copy($rootScope.CarGradeSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarGradeId = id;
			$('#deleteCarGradeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarGradeViewDtoFactory.delete(
				{ id: $scope.selectedCarGradeId },
				function() {
					$scope.search();
					$('#deleteCarGradeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarGradeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carGradeUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarGradeSearchOverview.predicate === itemName) {
				if ($scope.CarGradeSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarModelYearUISelect = function(item, model) {
			$scope.search();
		};
	});
