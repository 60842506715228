/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('RelationDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.relation = entity;
	$scope.objectName = 'Relation';
	$scope.relationId = $stateParams.relationId;

	if (angular.isUndefined($rootScope.relationActiveTab)) {
		//set the default here
		$rootScope.relationActiveTab = 0;
	}

	$scope.goToEditState = function(relation) {
		$state.go('relation.detail.edit({relationId:relation.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:relationUpdate', function(event, result) {
		$scope.relation = result;
	});
});
