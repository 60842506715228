/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtOrderLineListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineFactory,
		RelationMgmtOrderLineListDtoSearchFactory,
		RelationMgmtOrderLineViewDtoFactory,
		AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory
	) {
		$scope.orderLineList = [];
		$scope.selectedOrderLineList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLine);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-orderline.new');
		};

		var getOrderLineGroupId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineSearchOverview.orderLineGroup)) {
				return $scope.OrderLineSearchOverview.orderLineGroup.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineSearchOverview.article)) {
				return $scope.OrderLineSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineSearchOverview.tenant)) {
				return $scope.OrderLineSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.OrderLineSearchOverview = angular.copy($scope.OrderLineSearchOverview);

			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);

			RelationMgmtOrderLineListDtoSearchFactory.query(
				{
					page: $scope.OrderLineSearchOverview.page - 1,
					size: $scope.pageSize,
					subtotalFrom: $scope.OrderLineSearchOverview.subtotalFrom,
					subtotalTo: $scope.OrderLineSearchOverview.subtotalTo,
					baseTotalFrom: $scope.OrderLineSearchOverview.baseTotalFrom,
					baseTotalTo: $scope.OrderLineSearchOverview.baseTotalTo,
					baseTotalExVatFrom: $scope.OrderLineSearchOverview.baseTotalExVatFrom,
					baseTotalExVatTo: $scope.OrderLineSearchOverview.baseTotalExVatTo,
					orderLineGroupId: getOrderLineGroupId(),
					articleId: getArticleId(),
					vatType: $scope.OrderLineSearchOverview.vatType,
					orderLineType: $scope.OrderLineSearchOverview.orderLineType,
					tenantId: getTenantId(),

					sort: [$scope.OrderLineSearchOverview.predicate + ',' + ($scope.OrderLineSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.orderLineList = result.items;

					$scope.autocompleteOrderLineGroups = function(val) {
						var params = {
							orderLineGroupTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.orderLineTypeList = result.orderLineTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isOrderLineSearchOverviewDirty = function() {
			return !angular.equals($scope.OrderLineSearchOverview, $scope.originalOrderLineSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OrderLineSearchOverview = {};
			$rootScope.OrderLineSearchOverview.page = 1;
			$rootScope.OrderLineSearchOverview.predicate = 'id';
			$rootScope.OrderLineSearchOverview.ascending = false;

			$scope.OrderLineSearchOverview = angular.copy($rootScope.OrderLineSearchOverview);

			$scope.originalOrderLineSearchOverview = angular.copy($scope.OrderLineSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OrderLineSearchOverview) || $rootScope.OrderLineSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OrderLineSearchOverview = angular.copy($rootScope.OrderLineSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineId = id;
			$('#deleteOrderLineConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtOrderLineViewDtoFactory.delete(
				{ id: $scope.selectedOrderLineId },
				function() {
					$scope.search();
					$('#deleteOrderLineConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleUpdate = function() {
			$state.go('relation-mgmt-orderline.bulkedit', { selectedOrderLineList: $scope.selectedOrderLineList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.orderLineList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedOrderLineList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedOrderLineList = [];

			angular.forEach($scope.orderLineList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedOrderLineList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OrderLineSearchOverview.predicate === itemName) {
				if ($scope.OrderLineSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
