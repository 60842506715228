'use strict';

/**
 * Factory to search / retrieve an array of CarConfigCarConfiguratorSimulatorListDto's
 */
angular.module('mmmApp').factory('SimulatorSearchFactory', function($resource) {
	return $resource('api/car/config/carconfiguratorsimulator/simulate', {}, {
		'query' : {
			method : 'GET',
			isArray : false
		}
	});
});

/**
 * Factory to search / retrieve an array of CarConfigCarConfiguratorSimulatorListDto's
 */
angular.module('mmmApp').factory('SimulatorDefaultsFactory', function($resource) {
	return $resource('api/car/config/carconfiguratorsimulator/defaults', {}, {
		'get' : {
			method : 'GET',
			isArray : false
		}
	});
});
