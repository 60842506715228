/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtOrderLineBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		RelationMgmtOrderLineBulkEditDtoFactory,
		AlertService,
		AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory,
		AutocompleteRelationMgmtArticlesByQueryParamsFactory
	) {
		$scope.selectedOrderLineIds = _.map($stateParams.selectedOrderLineList, 'id');
		$scope.selectedItemsAmount = $scope.selectedOrderLineIds.length;
		$scope.orderLine = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:orderLineUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			RelationMgmtOrderLineBulkEditDtoFactory.update(
				{ orderLineIds: $scope.selectedOrderLineIds },
				$scope.orderLine,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteOrderLineGroups = function(val) {
			var params = {
				orderLineGroupTypeAheadSearchString: val,
			};

			return AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
