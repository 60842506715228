/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('MappingCarBrandDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.mappingCarBrand = entity;
		$scope.objectName = 'MappingCarBrand';
		$scope.mappingCarBrandId = $stateParams.mappingCarBrandId;

		if (angular.isUndefined($rootScope.mappingCarBrandActiveTab)) {
			//set the default here
			$rootScope.mappingCarBrandActiveTab = 0;
		}

		$scope.goToEditState = function(mappingCarBrand) {
			$state.go('mappingcarbrand.detail.edit({mappingCarBrandId:mappingCarBrand.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:mappingCarBrandUpdate', function(event, result) {
			$scope.mappingCarBrand = result;
		});
	});
