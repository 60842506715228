/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('locationsOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerlocation/dealerlocation-tab.html',
		controller: 'DealerLocationTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerLocationMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerlocation/dealerlocation-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerLocationListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('openinghoursOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/openinghour/openinghour-tab.html',
		controller: 'OpeningHourTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtOpeningHourMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/openinghour/openinghour-list-stock-mgmt.tab.html',
		controller: 'StockMgmtOpeningHourListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealerhexonportalsOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerhexonportal/dealerhexonportal-tab.html',
		controller: 'DealerHexonPortalTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerHexonPortalMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerhexonportal/dealerhexonportal-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerHexonPortalListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('hexonpublishrulesOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/hexonpublishrule/hexonpublishrule-tab.html',
		controller: 'HexonPublishRuleTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtHexonPublishRuleMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/hexonpublishrule/hexonpublishrule-list-stock-mgmt.tab.html',
		controller: 'StockMgmtHexonPublishRuleListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealercontentsOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealercontent/dealercontent-tab.html',
		controller: 'DealerContentTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerContentMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealercontent/dealercontent-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerContentListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealerimagesOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerimage/dealerimage-tab.html',
		controller: 'DealerImageTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerImageMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerimage/dealerimage-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerImageListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealerfaqsOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerfaq/dealerfaq-tab.html',
		controller: 'DealerFaqTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerFaqMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerfaq/dealerfaq-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerFaqListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealerauthenticationsOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerauthentication/dealerauthentication-tab.html',
		controller: 'DealerAuthenticationTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerAuthenticationMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerauthentication/dealerauthentication-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerAuthenticationListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealersOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carbranddealer/carbranddealer-tab.html',
		controller: 'CarBrandDealerTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCarBrandDealerMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carbranddealer/carbranddealer-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCarBrandDealerListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('usercarstockaccessbounddealersOverviewMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/usercarstockaccessbounddealer/usercarstockaccessbounddealer-tab.html',
		controller: 'UserCarStockAccessBoundDealerTabController',
		scope: {
			dealerId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtUserCarStockAccessBoundDealerMappedByDealer', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/usercarstockaccessbounddealer/usercarstockaccessbounddealer-list-stock-mgmt.tab.html',
		controller: 'StockMgmtUserCarStockAccessBoundDealerListDtoTabController',
		scope: {
			dealerId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
