/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationPriceListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationPriceFactory,
		CarConfigCarConfigurationPriceListDtoSearchFactory,
		CarConfigCarConfigurationPriceViewDtoFactory
	) {
		$scope.carConfigurationPriceList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfigurationPrice)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfigurationPrice);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfigurationPrice = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carconfigurationprice.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationPriceSearchOverview.brand)) {
				return $scope.CarConfigurationPriceSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.CarConfigurationPriceSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.CarConfigurationPriceSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.CarConfigurationPriceSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.CarConfigurationPriceSearchOverview.validFromFrom
				);
			} else {
				$scope.CarConfigurationPriceSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.CarConfigurationPriceSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.CarConfigurationPriceSearchOverview.validFromTo
				);
			} else {
				$scope.CarConfigurationPriceSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.CarConfigurationPriceSearchOverview = angular.copy($scope.CarConfigurationPriceSearchOverview);

			$rootScope.pageSizeCarConfigurationPrice = angular.copy($scope.pageSize);

			CarConfigCarConfigurationPriceListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationPriceSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.CarConfigurationPriceSearchOverview.validFromFromString,
					validFromTo: $scope.CarConfigurationPriceSearchOverview.validFromToString,
					priceFrom: $scope.CarConfigurationPriceSearchOverview.priceFrom,
					priceTo: $scope.CarConfigurationPriceSearchOverview.priceTo,
					bpmFrom: $scope.CarConfigurationPriceSearchOverview.bpmFrom,
					bpmTo: $scope.CarConfigurationPriceSearchOverview.bpmTo,
					cataloguePriceExVatFrom: $scope.CarConfigurationPriceSearchOverview.cataloguePriceExVatFrom,
					cataloguePriceExVatTo: $scope.CarConfigurationPriceSearchOverview.cataloguePriceExVatTo,
					co2MaxFrom: $scope.CarConfigurationPriceSearchOverview.co2MaxFrom,
					co2MaxTo: $scope.CarConfigurationPriceSearchOverview.co2MaxTo,
					co2MinFrom: $scope.CarConfigurationPriceSearchOverview.co2MinFrom,
					co2MinTo: $scope.CarConfigurationPriceSearchOverview.co2MinTo,
					co2BaseFrom: $scope.CarConfigurationPriceSearchOverview.co2BaseFrom,
					co2BaseTo: $scope.CarConfigurationPriceSearchOverview.co2BaseTo,
					fiscalValueFrom: $scope.CarConfigurationPriceSearchOverview.fiscalValueFrom,
					fiscalValueTo: $scope.CarConfigurationPriceSearchOverview.fiscalValueTo,
					additiveTaxPercentageFrom: $scope.CarConfigurationPriceSearchOverview.additiveTaxPercentageFrom,
					additiveTaxPercentageTo: $scope.CarConfigurationPriceSearchOverview.additiveTaxPercentageTo,
					carConfigurationIdList: $scope.CarConfigurationPriceSearchOverview.carConfigurationIdList,
					brandId: getBrandId(),

					sort: [
						$scope.CarConfigurationPriceSearchOverview.predicate +
							',' +
							($scope.CarConfigurationPriceSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationPriceList = result.items;

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationPriceSearchOverviewDirty = function() {
			return !angular.equals($scope.CarConfigurationPriceSearchOverview, $scope.originalCarConfigurationPriceSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationPriceSearchOverview = {};
			$rootScope.CarConfigurationPriceSearchOverview.page = 1;
			$rootScope.CarConfigurationPriceSearchOverview.predicate = 'id';
			$rootScope.CarConfigurationPriceSearchOverview.ascending = false;

			$scope.CarConfigurationPriceSearchOverview = angular.copy($rootScope.CarConfigurationPriceSearchOverview);

			$scope.originalCarConfigurationPriceSearchOverview = angular.copy($scope.CarConfigurationPriceSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarConfigurationPriceSearchOverview) || $rootScope.CarConfigurationPriceSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationPriceSearchOverview = angular.copy($rootScope.CarConfigurationPriceSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarConfigurationPriceId = id;
			$('#deleteCarConfigurationPriceConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarConfigurationPriceViewDtoFactory.delete(
				{ id: $scope.selectedCarConfigurationPriceId },
				function() {
					$scope.search();
					$('#deleteCarConfigurationPriceConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarConfigurationPriceConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationPriceSearchOverview.predicate === itemName) {
				if ($scope.CarConfigurationPriceSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};
	});
