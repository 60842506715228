/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('dealersOverviewMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealer/dealer-tab.html',
		controller: 'DealerTabController',
		scope: {
			tenantId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealer/dealer-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('websiteApiDealerMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealer/dealer-list-website-api.tab.html',
		controller: 'WebsiteApiDealerListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('locationsOverviewMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerlocation/dealerlocation-tab.html',
		controller: 'DealerLocationTabController',
		scope: {
			tenantId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDealerLocationMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/dealerlocation/dealerlocation-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDealerLocationListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('usersOverviewMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/user/user-tab.html',
		controller: 'UserTabController',
		scope: {
			tenantId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('adminUserMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/user/user-list-admin.tab.html',
		controller: 'AdminUserListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('documentsOverviewMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/document/document-tab.html',
		controller: 'DocumentTabController',
		scope: {
			tenantId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtDocumentMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/document/document-list-stock-mgmt.tab.html',
		controller: 'StockMgmtDocumentListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationcarrolesOverviewMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-tab.html',
		controller: 'RelationCarRoleTabController',
		scope: {
			tenantId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationCarRoleMappedByTenant', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationCarRoleListDtoTabController',
		scope: {
			tenantId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
