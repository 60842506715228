/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	$stateProvider.state('entity', {
		abstract: true,
		parent: 'site',
	});
});
