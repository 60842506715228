/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('HexonPortalDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.hexonPortal = entity;
	$scope.objectName = 'HexonPortal';
	$scope.hexonPortalId = $stateParams.hexonPortalId;

	if (angular.isUndefined($rootScope.hexonPortalActiveTab)) {
		//set the default here
		$rootScope.hexonPortalActiveTab = 0;
	}

	$scope.goToEditState = function(hexonPortal) {
		$state.go('hexonportal.detail.edit({hexonPortalId:hexonPortal.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:hexonPortalUpdate', function(event, result) {
		$scope.hexonPortal = result;
	});
});
