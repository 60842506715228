/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigOptionCategoryListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OptionCategoryFactory,
		CarConfigOptionCategoryListDtoSearchFactory,
		CarConfigOptionCategoryViewDtoFactory
	) {
		$scope.optionCategoryList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOptionCategory)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOptionCategory);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOptionCategory = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-optioncategory.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OptionCategorySearchOverview.brand)) {
				return $scope.OptionCategorySearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.OptionCategorySearchOverview = angular.copy($scope.OptionCategorySearchOverview);

			$rootScope.pageSizeOptionCategory = angular.copy($scope.pageSize);

			CarConfigOptionCategoryListDtoSearchFactory.query(
				{
					page: $scope.OptionCategorySearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.OptionCategorySearchOverview.description,
					optionCategoryTypeList: $scope.OptionCategorySearchOverview.optionCategoryTypeList,
					brandId: getBrandId(),
					locale: $scope.OptionCategorySearchOverview.locale,

					sort: [$scope.OptionCategorySearchOverview.predicate + ',' + ($scope.OptionCategorySearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.optionCategoryList = result.items;

					$scope.optionCategoryTypeList = result.optionCategoryTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isOptionCategorySearchOverviewDirty = function() {
			return !angular.equals($scope.OptionCategorySearchOverview, $scope.originalOptionCategorySearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OptionCategorySearchOverview = {};
			$rootScope.OptionCategorySearchOverview.page = 1;
			$rootScope.OptionCategorySearchOverview.predicate = 'id';
			$rootScope.OptionCategorySearchOverview.ascending = false;

			$scope.OptionCategorySearchOverview = angular.copy($rootScope.OptionCategorySearchOverview);

			$scope.originalOptionCategorySearchOverview = angular.copy($scope.OptionCategorySearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OptionCategorySearchOverview) || $rootScope.OptionCategorySearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OptionCategorySearchOverview = angular.copy($rootScope.OptionCategorySearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOptionCategoryId = id;
			$('#deleteOptionCategoryConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigOptionCategoryViewDtoFactory.delete(
				{ id: $scope.selectedOptionCategoryId },
				function() {
					$scope.search();
					$('#deleteOptionCategoryConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteOptionCategoryConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OptionCategorySearchOverview.predicate === itemName) {
				if ($scope.OptionCategorySearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateOptionCategoryTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
