/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarConfigurationPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationPrices/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarConfigurationPriceViewDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPriceViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationprice/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarConfigurationPriceListDto's
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPriceListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationprice',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

// Custom code for CarConfigurationPrice implemented here custom/jhipster/factory/create_custom_edit_factory.ftl

/**
 * Factory to get / update an instance of CarConfigCarConfigurationPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPriceEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarConfigurationPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarConfigurationPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationPrices/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarConfigurationPrices without the use of paging
angular.module('mmmApp').factory('NoPagingCarConfigurationPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationPrices/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarConfigurationPricesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carConfigurationPrices/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
