/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarConfigurationDependentPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationDependentPrices/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarConfigurationDependentPriceViewDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarConfigurationDependentPriceListDto's
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarConfigurationDependentPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationDependentPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationDependentPriceEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of CarConfigCarConfigurationDependentPriceEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationDependentPriceBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationdependentprice/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarConfigurationDependentPriceEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationDependentPriceEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationdependentprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarConfigurationDependentPriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarConfigurationDependentPriceNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carconfigurationdependentprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarConfigurationDependentPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationDependentPrices/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarConfigurationDependentPrices without the use of paging
angular.module('mmmApp').factory('NoPagingCarConfigurationDependentPriceFactory', function($resource) {
	return $resource(
		'api/carConfigurationDependentPrices/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarConfigurationDependentPricesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carConfigurationDependentPrices/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
