/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarMileageFactory', function($resource) {
	return $resource(
		'api/carMileages/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCarMileageViewDto
 */
angular.module('mmmApp').factory('StockMgmtCarMileageViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carmileage/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCarMileageListDto's
 */
angular.module('mmmApp').factory('StockMgmtCarMileageListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carmileage',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtCarMileageEditDto
 */
angular.module('mmmApp').factory('StockMgmtCarMileageEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carmileage/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtCarMileageEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtCarMileageNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carmileage/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtCarMileageViewDto
 */
angular.module('mmmApp').factory('RelationMgmtCarMileageViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carmileage/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtCarMileageListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCarMileageListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carmileage',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtCarMileageEditDto
 */
angular.module('mmmApp').factory('RelationMgmtCarMileageEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carmileage/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtCarMileageEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtCarMileageNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carmileage/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarMileageFactory', function($resource) {
	return $resource(
		'api/carMileages/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarMileages without the use of paging
angular.module('mmmApp').factory('NoPagingCarMileageFactory', function($resource) {
	return $resource(
		'api/carMileages/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RecordedByItems Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRecordedByFactory', function($resource) {
	return $resource(
		'api/carMileage/recordedByItems',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarMileagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carMileages/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarMileagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/carMileages/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteStockMgmtCarMileagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/stockmgmt/autocomplete/carmileages',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtCarMileagesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/carmileages',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
