/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarPowertrainFactory', function($resource) {
	return $resource(
		'api/carPowertrains/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarPowertrainViewDto
 */
angular.module('mmmApp').factory('CarConfigCarPowertrainViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpowertrain/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarPowertrainListDto's
 */
angular.module('mmmApp').factory('CarConfigCarPowertrainListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carpowertrain',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarPowertrainEditDto
 */
angular.module('mmmApp').factory('CarConfigCarPowertrainEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpowertrain/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarPowertrainEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarPowertrainNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpowertrain/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarPowertrainEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarPowertrainEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carpowertrain/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarPowertrainEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarPowertrainEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carpowertrain/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarPowertrainEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarPowertrainNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carpowertrain/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarPowertrainEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarPowertrainEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carpowertrain/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarPowertrainFactory', function($resource) {
	return $resource(
		'api/carPowertrains/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarPowertrains without the use of paging
angular.module('mmmApp').factory('NoPagingCarPowertrainFactory', function($resource) {
	return $resource(
		'api/carPowertrains/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carPowertrain/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carPowertrain/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carPowertrain/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carPowertrain/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carPowertrain/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carPowertrain/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carPowertrain/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationsByCarPowertrainFactory', function($resource) {
	return $resource(
		'api/carConfigurations/carPowertrain/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarPowertrainsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carPowertrains/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarPowertrainSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carpowertrains/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
