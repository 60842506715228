/** @format */

'use strict';

// DealerFaq has edit layout enabled. Edit layout template url: dealerfaq-dialog.html
// DealerFaq has detail layout enabled. Detail layout template url: dealerfaq-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'DealerFaq'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('dealerfaq'); // main translation loaded for dealerfaq
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-dealerfaq', {
		/* abstract state for DTO StockMgmtDealerFaqListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/dealerfaq',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'DealerFaq',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtDealerFaqListDto */
		/* to access: ui-ref="stock-mgmt-dealerfaq.list" */
		/* url: http://<hostname>/stockmgmt/dealerfaq/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/dealerfaq/dealerfaq-list-stock-mgmt.html',
				controller: 'StockMgmtDealerFaqListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-dealerfaq.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtDealerFaqViewDto */
		/* to access: ui-sref="stock-mgmt-dealerfaq.detail" */
		/* url: http://<hostname>/stockmgmt/dealerfaq/detail/:dealerFaqId */
		url: '/detail/:dealerFaqId',
		views: {
			'content@': {
				templateUrl: 'app/entities/dealerfaq/dealerfaq-view-stock-mgmt.html',
				controller: 'DealerFaqDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtDealerFaqViewDtoFactory',
				function($stateParams, StockMgmtDealerFaqViewDtoFactory) {
					return StockMgmtDealerFaqViewDtoFactory.get({ id: $stateParams.dealerFaqId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-dealerfaq.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtDealerFaqEditDto */
		/* to access: ui-sref="stock-mgmt-dealerfaq.edit" */
		/* url: http://<hostname>/stockmgmt/dealerfaq/edit/:dealerFaqId */
		url: '/edit/:dealerFaqId',
		views: {
			'content@': {
				templateUrl: 'app/entities/dealerfaq/dealerfaq-edit-stock-mgmt.html',
				controller: 'StockMgmtDealerFaqEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtDealerFaqEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtDealerFaqEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtDealerFaqEditDtoFactory.get({ id: $stateParams.dealerFaqId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-dealerfaq.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtDealerFaqEditDto */
		/* to access: ui-sref="stock-mgmt-dealerfaq.new" */
		/* url: http://<hostname>/stockmgmt/dealerfaq/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/dealerfaq/dealerfaq-edit-stock-mgmt.html',
				controller: 'StockMgmtDealerFaqEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtDealerFaqNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtDealerFaqNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtDealerFaqNewDtoFactory.get({ id: $stateParams.dealerFaqId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-dealerfaq.new', dtoCreateState);
});
