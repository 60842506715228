/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('RelationMgmtSectionEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		RelationMgmtSectionEditDtoFactory,
		AlertService,
		AutocompleteRelationMgmtEmployeesByQueryParamsFactory,
		AutocompleteRelationMgmtRelationsByQueryParamsFactory,
		HistoryService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.section = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.section['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.section) && $rootScope.isDefinedAndNotNull($scope.section.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:sectionUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			RelationMgmtSectionEditDtoFactory.update($scope.section, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteEmployees = function(val) {
			/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
			var params = {
				employeeTypeAheadSearchString: val,
			};

			return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		$scope.autocompleteRelations = function(val) {
			/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
			var params = {
				relationTypeAheadSearchString: val,
			};

			return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
