/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingCarModelListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingCarModelFactory,
		StockMgmtMappingCarModelListDtoSearchFactory,
		StockMgmtMappingCarModelViewDtoFactory
	) {
		$scope.mappingCarModelList = [];
		$scope.selectedMappingCarModelList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingCarModel)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingCarModel);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingCarModel = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingcarmodel.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingCarModelSearchOverview = angular.copy($scope.MappingCarModelSearchOverview);

			$rootScope.pageSizeMappingCarModel = angular.copy($scope.pageSize);

			StockMgmtMappingCarModelListDtoSearchFactory.query(
				{
					page: $scope.MappingCarModelSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingCarModelSearchOverview.sourceKey,
					sourceField: $scope.MappingCarModelSearchOverview.sourceField,
					sourceList: $scope.MappingCarModelSearchOverview.sourceList,
					carModelIdList: $scope.MappingCarModelSearchOverview.carModelIdList,

					sort: [$scope.MappingCarModelSearchOverview.predicate + ',' + ($scope.MappingCarModelSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingCarModelList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.carModelReferenceFilterList = result.carModelSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingCarModelSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingCarModelSearchOverview, $scope.originalMappingCarModelSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingCarModelSearchOverview = {};
			$rootScope.MappingCarModelSearchOverview.page = 1;
			$rootScope.MappingCarModelSearchOverview.predicate = 'id';
			$rootScope.MappingCarModelSearchOverview.ascending = false;

			$scope.MappingCarModelSearchOverview = angular.copy($rootScope.MappingCarModelSearchOverview);

			$scope.originalMappingCarModelSearchOverview = angular.copy($scope.MappingCarModelSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingCarModelSearchOverview) || $rootScope.MappingCarModelSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingCarModelSearchOverview = angular.copy($rootScope.MappingCarModelSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingCarModelId = id;
			$('#deleteMappingCarModelConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingCarModelViewDtoFactory.delete(
				{ id: $scope.selectedMappingCarModelId },
				function() {
					$scope.search();
					$('#deleteMappingCarModelConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteMappingCarModelConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingCarModelConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingCarModelList, function(value, key) {
				promises.push(StockMgmtMappingCarModelViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingCarModelConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingCarModelList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingCarModelList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingCarModelList = [];

			angular.forEach($scope.mappingCarModelList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingCarModelList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingCarModelUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingCarModelSearchOverview.predicate === itemName) {
				if ($scope.MappingCarModelSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarModelUISelect = function(item, model) {
			$scope.search();
		};
	});
