/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'BatchVehicleRemoveAdvertisementPerform'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('batchvehicleremoveadvertisementperform'); // main translation loaded for batchvehicleremoveadvertisementperform
		$translatePartialLoader.addPart('batchstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstock'); // one to many reference from main entity

		return $translate.refresh();
	};
});
