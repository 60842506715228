/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('WebsiteApiCarStockListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarStockFactory,
		WebsiteApiCarStockListDtoSearchFactory,
		$uibModal,
		Upload,
		$timeout,
		QuoteFactory
	) {
		$scope.carStockList = [];
		$scope.predicate = 'publishDate';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarStock)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarStock);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarStock = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('website-api-carstock.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getCarStockList = function() {
			WebsiteApiCarStockListDtoSearchFactory.query(
				{
					priceStatus: angular.isDefined($scope.priceStatusId) ? $scope.priceStatusId : null,
					vehicleId: angular.isDefined($scope.vehicleId) ? $scope.vehicleId : null,
					stockStatus: angular.isDefined($scope.stockStatusId) ? $scope.stockStatusId : null,
					typeList: angular.isDefined($scope.typeId) ? $scope.typeId : null,
					sellingStatus: angular.isDefined($scope.sellingStatusId) ? $scope.sellingStatusId : null,
					pipelineStatus: angular.isDefined($scope.pipelineStatusId) ? $scope.pipelineStatusId : null,
					locationIdList: angular.isDefined($scope.locationId) ? $scope.locationId : null,
					sellerIdList: angular.isDefined($scope.sellerId) ? $scope.sellerId : null,
					financialOwnerId: angular.isDefined($scope.financialOwnerId) ? $scope.financialOwnerId : null,
					dealerSwappedFromId: angular.isDefined($scope.dealerSwappedFromId) ? $scope.dealerSwappedFromId : null,
					transferStatus: angular.isDefined($scope.transferStatusId) ? $scope.transferStatusId : null,
					publishSource: angular.isDefined($scope.publishSourceId) ? $scope.publishSourceId : null,
					carStockOrigin: angular.isDefined($scope.carStockOriginId) ? $scope.carStockOriginId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,
					guaranteeIdList: angular.isDefined($scope.guaranteeId) ? $scope.guaranteeId : null,
					carStockDiscountOrigin: angular.isDefined($scope.carStockDiscountOriginId) ? $scope.carStockDiscountOriginId : null,
					imaginStudioImageSourceStatus: angular.isDefined($scope.imaginStudioImageSourceStatusId)
						? $scope.imaginStudioImageSourceStatusId
						: null,
					carSpecificImageSourceStatus: angular.isDefined($scope.carSpecificImageSourceStatusId)
						? $scope.carSpecificImageSourceStatusId
						: null,
					brandImageSourceStatus: angular.isDefined($scope.brandImageSourceStatusId) ? $scope.brandImageSourceStatusId : null,
					communityImageSourceStatus: angular.isDefined($scope.communityImageSourceStatusId) ? $scope.communityImageSourceStatusId : null,
					matchingCarImageSourceStatus: angular.isDefined($scope.matchingCarImageSourceStatusId)
						? $scope.matchingCarImageSourceStatusId
						: null,
					imageSetImageSourceStatus: angular.isDefined($scope.imageSetImageSourceStatusId) ? $scope.imageSetImageSourceStatusId : null,
					activeImageSource: angular.isDefined($scope.activeImageSourceId) ? $scope.activeImageSourceId : null,
					carBrandDealerId: angular.isDefined($scope.carBrandDealerId) ? $scope.carBrandDealerId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,
					consignmentId: angular.isDefined($scope.consignmentId) ? $scope.consignmentId : null,
					preferredPriceSource: angular.isDefined($scope.preferredPriceSourceId) ? $scope.preferredPriceSourceId : null,
					preferredDiscountSource: angular.isDefined($scope.preferredDiscountSourceId) ? $scope.preferredDiscountSourceId : null,
					genericVehiclePriceInformationId: angular.isDefined($scope.genericVehiclePriceInformationId)
						? $scope.genericVehiclePriceInformationId
						: null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carStockList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCarStockList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.showUpload = function(size, parentSelector) {
			var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
			$scope.modalInstance = $uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'uploadEMotiveFile.html',
				// controller: 'ModalStatusController',
				scope: $scope,
				resolve: {},
				// size: size,
				appendTo: parentElem,
			});
		};

		$scope.gotoCarConfiguration = function(carConfigurationId) {
			if ($rootScope.isDefinedAndNotNull(carConfigurationId)) {
				// TODO why is injecting the constant APP_SETTINGS not working?
				var url = $rootScope.CARCONFIG_URL;
				window.open(url + '/loadConfiguration.php?id=' + carConfigurationId);
			}
		};

		/**
		 * Gets the quote as PDF. Clicking the element that fires this method show a "save as" dialog to save the PDF after
		 * the XHR request retrieved all bytes (a byte[]) from the REST endpoint.
		 *
		 * // FIXME add the carpaint to the call
		 *
		 * @param carConfigurationId
		 */
		$scope.getQuotePdf = function(carConfigurationId) {
			QuoteFactory.pdf({
				quoteCarConfigurationId: carConfigurationId,
				filterOptions: [],
				filterArticles: [],
				dealerOptions: [],
				currentTabPage: 'TAB_QUOTE',
				financeType: 'PRIVATE_ONCE',
				financeDuration: null,
				carPaintId: null,
			})
				.$promise.then(function(data) {
					// var file = new Blob([data], {type: 'application/pdf'});
					// var fileURL = URL.createObjectURL(file);
					// result is PDF, we created an url to it which will be opened in new tab/window
					//for browser compatibility
					var ieEDGE = navigator.userAgent.match(/Edge/g);
					var ie = navigator.userAgent.match(/.NET/g); // IE 11+
					var oldIE = navigator.userAgent.match(/MSIE/g);
					var name = 'quote-' + carConfigurationId;
					var blob = new window.Blob([data.data], { type: 'application/pdf' });

					if (ie || oldIE || ieEDGE) {
						// IE browsers
						var fileName = name + '.pdf';
						window.navigator.msSaveBlob(blob, fileName); // shows the "save as" dialog in browser
					} else {
						// non IE browsers
						var file = new Blob([data.data], {
							type: 'application/pdf',
						});
						var fileURL = URL.createObjectURL(file);
						console.log('Quote PDF was found and will download with ' + fileURL);

						var a = document.createElement('a');
						a.href = fileURL;
						a.target = '_blank';
						a.download = name + '.pdf';
						document.body.appendChild(a);
						a.click(); // shows the "save as" dialog in browser
					}
				})
				.catch(function(error) {
					// something went wrong...
					console.error('Quote PDF was not found or something else went wrong', error);
					AlertService.error('Failed to download quote pdf');
				});
		};

		$scope.uploadExcel = function(file) {
			file.upload = Upload.upload({
				url: '/api/emotive/upload',
				data: {
					file: file,
					selectedModelVersion: $scope.selectedModelVersion,
				},
			});

			file.upload
				.then(function(response) {
					$timeout(function() {
						var data = (file.result = response.data);
					});
					AlertService.success('excelimport.messages.import.success', { excelFileName: file.name });
					$timeout(function() {
						$scope.showProgress = false;
						$scope.loadPage();
					}, 2000);
				})
				.catch(function(error) {
					AlertService.error(error.data.detail);
					$timeout(function() {
						$scope.showProgress = false;
						$scope.excelFile = undefined;
						$scope.loadPage();
					}, 2000);
				})
				.finally(function(evt) {
					$scope.closeModal();
					// Math.min is to fix IE which reports 200% sometimes
					file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
				});
		};

		$scope.clearForm = function() {
			$scope.excelFile = undefined;
		};

		$scope.closeModal = function() {
			$scope.modalInstance.close();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateLocationUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateSellerUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateGuaranteeUISelect = function(item, model) {
			$scope.search();
		};
	});
