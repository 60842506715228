/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('GroupFactory', function($resource) {
	return $resource(
		'api/groups/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtGroupViewDto
 */
angular.module('mmmApp').factory('RelationMgmtGroupViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/group/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtGroupListDto's
 */
angular.module('mmmApp').factory('RelationMgmtGroupListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/group',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtGroupEditDto
 */
angular.module('mmmApp').factory('RelationMgmtGroupEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/group/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtGroupEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtGroupNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/group/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewGroupFactory', function($resource) {
	return $resource(
		'api/groups/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Groups without the use of paging
angular.module('mmmApp').factory('NoPagingGroupFactory', function($resource) {
	return $resource(
		'api/groups/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('GroupMembersByGroupFactory', function($resource) {
	return $resource(
		'api/groupMembers/group/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllGroupsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/groups/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
