/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('ArticleManagementArticleListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		ArticleManagementArticleListDtoSearchFactory
	) {
		$scope.articleList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('article-management-article.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getArticleList = function() {
			ArticleManagementArticleListDtoSearchFactory.query(
				{
					statusList: angular.isDefined($scope.statusId) ? $scope.statusId : null,
					typeList: angular.isDefined($scope.typeId) ? $scope.typeId : null,
					imageSize: angular.isDefined($scope.imageSizeId) ? $scope.imageSizeId : null,
					vatType: angular.isDefined($scope.vatTypeId) ? $scope.vatTypeId : null,
					equivalentId: angular.isDefined($scope.equivalentId) ? $scope.equivalentId : null,
					priceTypeList: angular.isDefined($scope.priceTypeId) ? $scope.priceTypeId : null,
					brandIdList: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					country: angular.isDefined($scope.countryId) ? $scope.countryId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// Go to the new state of objects that inherit from article.
		// Custom code from custom/jhipster/controller/overview/additional-actions.ftl

		$rootScope.$watch('selectedWorkingCarBrand.id', function() {
			$scope.loadPage();
		});

		$scope.goToNewStateChild = function(modelObjectName) {
			$state.go('car-config-' + modelObjectName + '.new');
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
