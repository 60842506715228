/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingFailedLogFactory', function($resource) {
	return $resource(
		'api/mappingFailedLogs/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingFailedLogViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingFailedLogViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfailedlog/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingFailedLogListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingFailedLogListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfailedlog',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingFailedLogEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingFailedLogEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfailedlog/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingFailedLogEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingFailedLogNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingfailedlog/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingFailedLogFactory', function($resource) {
	return $resource(
		'api/mappingFailedLogs/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingFailedLogs without the use of paging
angular.module('mmmApp').factory('NoPagingMappingFailedLogFactory', function($resource) {
	return $resource(
		'api/mappingFailedLogs/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingFailedLog/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingTypeFactory', function($resource) {
	return $resource(
		'api/mappingFailedLog/mappingTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingFailedLogsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingFailedLogs/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
