/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('sectiondocumentsOverviewMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/sectiondocument/sectiondocument-tab.html',
		controller: 'SectionDocumentTabController',
		scope: {
			sectionId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtSectionDocumentMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/sectiondocument/sectiondocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtSectionDocumentListDtoTabController',
		scope: {
			sectionId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('salesordersOverviewMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesorder/salesorder-tab.html',
		controller: 'SalesOrderTabController',
		scope: {
			sectionId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtSalesOrderMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/salesorder/salesorder-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtSalesOrderListDtoTabController',
		scope: {
			sectionId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('notesOverviewMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/note/note-tab.html',
		controller: 'NoteTabController',
		scope: {
			sectionId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtNoteMappedBySection', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/note/note-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtNoteListDtoTabController',
		scope: {
			sectionId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
