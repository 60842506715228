/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarTransmissionFactory', function($resource) {
	return $resource(
		'api/carTransmissions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarTransmissionViewDto
 */
angular.module('mmmApp').factory('CarConfigCarTransmissionViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cartransmission/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarTransmissionListDto's
 */
angular.module('mmmApp').factory('CarConfigCarTransmissionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/cartransmission',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarTransmissionEditDto
 */
angular.module('mmmApp').factory('CarConfigCarTransmissionEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cartransmission/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarTransmissionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarTransmissionNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cartransmission/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarTransmissionEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarTransmissionEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/cartransmission/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarTransmissionEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarTransmissionEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/cartransmission/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarTransmissionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarTransmissionNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/cartransmission/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarTransmissionEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarTransmissionEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/cartransmission/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarTransmissionFactory', function($resource) {
	return $resource(
		'api/carTransmissions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarTransmissions without the use of paging
angular.module('mmmApp').factory('NoPagingCarTransmissionFactory', function($resource) {
	return $resource(
		'api/carTransmissions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carTransmission/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carTransmission/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carTransmission/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carTransmission/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carTransmission/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carTransmission/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carTransmission/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TransmissionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTransmissionTypeFactory', function($resource) {
	return $resource(
		'api/carTransmission/transmissionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationsByCarTransmissionFactory', function($resource) {
	return $resource(
		'api/carConfigurations/carTransmission/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarTransmissionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carTransmissions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarTransmissionSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/cartransmissions/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
