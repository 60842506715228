/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtHexonPublishRuleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		HexonPublishRuleFactory,
		StockMgmtHexonPublishRuleListDtoSearchFactory,
		StockMgmtHexonPublishRuleViewDtoFactory
	) {
		$scope.hexonPublishRuleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeHexonPublishRule)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeHexonPublishRule);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeHexonPublishRule = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-hexonpublishrule.new');
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.HexonPublishRuleSearchOverview.dealer)) {
				return $scope.HexonPublishRuleSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.HexonPublishRuleSearchOverview.tenant)) {
				return $scope.HexonPublishRuleSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.HexonPublishRuleSearchOverview = angular.copy($scope.HexonPublishRuleSearchOverview);

			$rootScope.pageSizeHexonPublishRule = angular.copy($scope.pageSize);

			StockMgmtHexonPublishRuleListDtoSearchFactory.query(
				{
					page: $scope.HexonPublishRuleSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.HexonPublishRuleSearchOverview.description,
					filterResult: $scope.HexonPublishRuleSearchOverview.filterResult,
					dealerId: getDealerId(),
					hexonPublishRuleTypeList: $scope.HexonPublishRuleSearchOverview.hexonPublishRuleTypeList,
					tenantId: getTenantId(),

					sort: [
						$scope.HexonPublishRuleSearchOverview.predicate + ',' + ($scope.HexonPublishRuleSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.hexonPublishRuleList = result.items;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$scope.hexonPublishRuleTypeList = result.hexonPublishRuleTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isHexonPublishRuleSearchOverviewDirty = function() {
			return !angular.equals($scope.HexonPublishRuleSearchOverview, $scope.originalHexonPublishRuleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.HexonPublishRuleSearchOverview = {};
			$rootScope.HexonPublishRuleSearchOverview.page = 1;
			$rootScope.HexonPublishRuleSearchOverview.predicate = 'sortOrder';
			$rootScope.HexonPublishRuleSearchOverview.ascending = true;

			$scope.HexonPublishRuleSearchOverview = angular.copy($rootScope.HexonPublishRuleSearchOverview);

			$scope.originalHexonPublishRuleSearchOverview = angular.copy($scope.HexonPublishRuleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.HexonPublishRuleSearchOverview) || $rootScope.HexonPublishRuleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.HexonPublishRuleSearchOverview = angular.copy($rootScope.HexonPublishRuleSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedHexonPublishRuleId = id;
			$('#deleteHexonPublishRuleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtHexonPublishRuleViewDtoFactory.delete(
				{ id: $scope.selectedHexonPublishRuleId },
				function() {
					$scope.search();
					$('#deleteHexonPublishRuleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteHexonPublishRuleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.HexonPublishRuleSearchOverview.predicate === itemName) {
				if ($scope.HexonPublishRuleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateHexonPublishRuleTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
