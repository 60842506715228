/** @format */

'use strict';

// Guarantee has edit layout enabled. Edit layout template url: guarantee-dialog.html
// Guarantee has detail layout enabled. Detail layout template url: guarantee-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Guarantee'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('guarantee'); // main translation loaded for guarantee
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-guarantee', {
		/* abstract state for DTO RelationMgmtGuaranteeListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/guarantee',
		data: {
			authorities: ['ROLE_DEALER_ADMIN', 'ROLE_ADMIN'],
			pageTitle: 'Guarantee',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtGuaranteeListDto */
		/* to access: ui-ref="relation-mgmt-guarantee.list" */
		/* url: http://<hostname>/relationmgmt/guarantee/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/guarantee/guarantee-list-relation-mgmt.html',
				controller: 'RelationMgmtGuaranteeListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-guarantee.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtGuaranteeViewDto */
		/* to access: ui-sref="relation-mgmt-guarantee.detail" */
		/* url: http://<hostname>/relationmgmt/guarantee/detail/:guaranteeId */
		url: '/detail/:guaranteeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/guarantee/guarantee-view-relation-mgmt.html',
				controller: 'GuaranteeDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtGuaranteeViewDtoFactory',
				function($stateParams, RelationMgmtGuaranteeViewDtoFactory) {
					return RelationMgmtGuaranteeViewDtoFactory.get({ id: $stateParams.guaranteeId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-guarantee.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtGuaranteeEditDto */
		/* to access: ui-sref="relation-mgmt-guarantee.edit" */
		/* url: http://<hostname>/relationmgmt/guarantee/edit/:guaranteeId */
		url: '/edit/:guaranteeId',
		views: {
			'content@': {
				templateUrl: 'app/entities/guarantee/guarantee-edit-relation-mgmt.html',
				controller: 'RelationMgmtGuaranteeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtGuaranteeEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtGuaranteeEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtGuaranteeEditDtoFactory.get({ id: $stateParams.guaranteeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-guarantee.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtGuaranteeEditDto */
		/* to access: ui-sref="relation-mgmt-guarantee.new" */
		/* url: http://<hostname>/relationmgmt/guarantee/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/guarantee/guarantee-edit-relation-mgmt.html',
				controller: 'RelationMgmtGuaranteeEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtGuaranteeNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtGuaranteeNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtGuaranteeNewDtoFactory.get({ id: $stateParams.guaranteeId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-guarantee.new', dtoCreateState);
});
