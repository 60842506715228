/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarConfigurationPackageArticleFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackageArticles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarConfigurationPackageArticleViewDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageArticleViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackagearticle/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarConfigurationPackageArticleListDto's
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackagearticle',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarConfigurationPackageArticleEditDto
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageArticleEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackagearticle/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarConfigurationPackageArticleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarConfigurationPackageArticleNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carconfigurationpackagearticle/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of RelationMgmtCarConfigurationPackageArticleListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCarConfigurationPackageArticleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carconfigurationpackagearticle',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarConfigurationPackageArticleFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackageArticles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarConfigurationPackageArticles without the use of paging
angular.module('mmmApp').factory('NoPagingCarConfigurationPackageArticleFactory', function($resource) {
	return $resource(
		'api/carConfigurationPackageArticles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarConfigurationPackageArticlesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carConfigurationPackageArticles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
