/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('SectionDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.section = entity;
	$scope.objectName = 'Section';
	$scope.sectionId = $stateParams.sectionId;

	if (angular.isUndefined($rootScope.sectionActiveTab)) {
		//set the default here
		$rootScope.sectionActiveTab = 0;
	}

	$scope.goToEditState = function(section) {
		$state.go('section.detail.edit({sectionId:section.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:sectionUpdate', function(event, result) {
		$scope.section = result;
	});
});
