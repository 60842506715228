/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationcarrolesOverviewMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-tab.html',
		controller: 'RelationCarRoleTabController',
		scope: {
			carId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtRelationCarRoleMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/relationcarrole/relationcarrole-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtRelationCarRoleListDtoTabController',
		scope: {
			carId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('carmileagesOverviewMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carmileage/carmileage-tab.html',
		controller: 'CarMileageTabController',
		scope: {
			carId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCarMileageMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carmileage/carmileage-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCarMileageListDtoTabController',
		scope: {
			carId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCarMileageMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/carmileage/carmileage-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCarMileageListDtoTabController',
		scope: {
			carId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcarvaluationsOverviewMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-tab.html',
		controller: 'CrmCarValuationTabController',
		scope: {
			carId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarValuationMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarValuationListDtoTabController',
		scope: {
			carId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarValuationMappedByCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarvaluation/crmcarvaluation-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarValuationListDtoTabController',
		scope: {
			carId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('externalcardatasOverviewMappedByCrmcar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/externalcardata/externalcardata-tab.html',
		controller: 'ExternalCarDataTabController',
		scope: {
			crmCarId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtExternalCarDataMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/externalcardata/externalcardata-list-stock-mgmt.tab.html',
		controller: 'StockMgmtExternalCarDataListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcaroptionsOverviewMappedByCrmcar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcaroption/crmcaroption-tab.html',
		controller: 'CrmCarOptionTabController',
		scope: {
			crmCarId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarOptionMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcaroption/crmcaroption-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarOptionListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarOptionMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcaroption/crmcaroption-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarOptionListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcardocumentsOverviewMappedByCrmcar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-tab.html',
		controller: 'CrmCarDocumentTabController',
		scope: {
			crmCarId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarDocumentMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarDocumentListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarDocumentMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcardocument/crmcardocument-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarDocumentListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('crmcarmediadocumentsOverviewMappedByCrmcar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-tab.html',
		controller: 'CrmCarMediaDocumentTabController',
		scope: {
			crmCarId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('relationMgmtCrmCarMediaDocumentMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-relation-mgmt.tab.html',
		controller: 'RelationMgmtCrmCarMediaDocumentListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
//DTO version of directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('stockMgmtCrmCarMediaDocumentMappedByCrmCar', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/crmcarmediadocument/crmcarmediadocument-list-stock-mgmt.tab.html',
		controller: 'StockMgmtCrmCarMediaDocumentListDtoTabController',
		scope: {
			crmCarId: '=mappedById',
			showCreateButtonTab: '=showCreateButtonTab',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
			mappedByDisplayValue: '=mappedByDisplayValue',
		},
	};
});
