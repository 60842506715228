/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingRdwColorFactory', function($resource) {
	return $resource(
		'api/mappingRdwColors/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingRdwColorViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingRdwColorViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingrdwcolor/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingRdwColorListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingRdwColorListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingrdwcolor',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingRdwColorEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingRdwColorEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingrdwcolor/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingRdwColorEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingRdwColorNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingrdwcolor/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingRdwColorEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingRdwColorEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingrdwcolor/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingRdwColorFactory', function($resource) {
	return $resource(
		'api/mappingRdwColors/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingRdwColors without the use of paging
angular.module('mmmApp').factory('NoPagingMappingRdwColorFactory', function($resource) {
	return $resource(
		'api/mappingRdwColors/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingRdwColor/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingRdwColorsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingRdwColors/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingRdwColorSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingrdwcolors/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
