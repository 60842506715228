/** @format */

'use strict';

// Email has edit layout enabled. Edit layout template url: email-dialog.html
// Email has detail layout enabled. Detail layout template url: email-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Email'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('email'); // main translation loaded for email
		$translatePartialLoader.addPart('emailtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('relation'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-email', {
		/* abstract state for DTO RelationMgmtEmailListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/email',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Email',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtEmailListDto */
		/* to access: ui-ref="relation-mgmt-email.list" */
		/* url: http://<hostname>/relationmgmt/email/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/email/email-list-relation-mgmt.html',
				controller: 'RelationMgmtEmailListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-email.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtEmailViewDto */
		/* to access: ui-sref="relation-mgmt-email.detail" */
		/* url: http://<hostname>/relationmgmt/email/detail/:emailId */
		url: '/detail/:emailId',
		views: {
			'content@': {
				templateUrl: 'app/entities/email/email-view-relation-mgmt.html',
				controller: 'EmailDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtEmailViewDtoFactory',
				function($stateParams, RelationMgmtEmailViewDtoFactory) {
					return RelationMgmtEmailViewDtoFactory.get({ id: $stateParams.emailId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-email.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtEmailEditDto */
		/* to access: ui-sref="relation-mgmt-email.edit" */
		/* url: http://<hostname>/relationmgmt/email/edit/:emailId */
		url: '/edit/:emailId',
		views: {
			'content@': {
				templateUrl: 'app/entities/email/email-edit-relation-mgmt.html',
				controller: 'RelationMgmtEmailEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtEmailEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtEmailEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtEmailEditDtoFactory.get({ id: $stateParams.emailId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-email.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtEmailEditDto */
		/* to access: ui-sref="relation-mgmt-email.new" */
		/* url: http://<hostname>/relationmgmt/email/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/email/email-edit-relation-mgmt.html',
				controller: 'RelationMgmtEmailEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtEmailNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtEmailNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtEmailNewDtoFactory.get({ id: $stateParams.emailId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-email.new', dtoCreateState);
});
