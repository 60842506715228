/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerLocationListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerLocationFactory,
		StockMgmtDealerLocationListDtoSearchFactory,
		StockMgmtDealerLocationViewDtoFactory
	) {
		$scope.dealerLocationList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealerLocation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealerLocation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealerLocation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-dealerlocation.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getDealerLocationList = function() {
			StockMgmtDealerLocationListDtoSearchFactory.query(
				{
					dealerId: angular.isDefined($scope.dealerId) ? $scope.dealerId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerLocationList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getDealerLocationList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDealerLocationId = id;
			$('#deleteDealerLocationConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtDealerLocationViewDtoFactory.delete(
				{ id: $scope.selectedDealerLocationId },
				function() {
					$scope.loadAll();
					$('#deleteDealerLocationConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteDealerLocationConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
