/** @format */

'use strict';

// CarConfiguration has edit layout enabled. Edit layout template url: carconfiguration-dialog.html
// CarConfiguration has detail layout enabled. Detail layout template url: carconfiguration-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarConfiguration'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carconfiguration'); // main translation loaded for carconfiguration
		$translatePartialLoader.addPart('article'); // main translation loaded for carconfiguration
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('vehicletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('energylabel'); // one to many reference from main entity
		$translatePartialLoader.addPart('numberofdoors'); // one to many reference from main entity
		$translatePartialLoader.addPart('numberofseats'); // one to many reference from main entity
		$translatePartialLoader.addPart('emissionclass'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carModelVersion
		$translatePartialLoader.addPart('carmodelversion'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carBodytype
		$translatePartialLoader.addPart('carbodytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carEngine
		$translatePartialLoader.addPart('carengine'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carGrade
		$translatePartialLoader.addPart('cargrade'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carTransmission
		$translatePartialLoader.addPart('cartransmission'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carPowertrain
		$translatePartialLoader.addPart('carpowertrain'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstockdiscount'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('article-management-carconfiguration', {
		/* abstract state for DTO ArticleManagementCarConfigurationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articlemanagement/carconfiguration',
		data: {
			authorities: ['ROLE_ARTICLE_MANAGEMENT', 'ROLE_ADMIN'],
			pageTitle: 'CarConfiguration',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO article.management */
	var dtoListState = {
		/* list state for DTO ArticleManagementCarConfigurationListDto */
		/* to access: ui-ref="article-management-carconfiguration.list" */
		/* url: http://<hostname>/articlemanagement/carconfiguration/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-list-article-management.html',
				controller: 'ArticleManagementCarConfigurationListDtoController',
			},
		},
	};

	$stateProvider.state('article-management-carconfiguration.list', dtoListState);

	$stateProvider.state('car-config-carconfiguration', {
		/* abstract state for DTO CarConfigCarConfigurationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carconfiguration',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarConfiguration',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarConfigurationListDto */
		/* to access: ui-ref="car-config-carconfiguration.list" */
		/* url: http://<hostname>/car/config/carconfiguration/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-list-car-config.html',
				controller: 'CarConfigCarConfigurationListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carconfiguration.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarConfigurationViewDto */
		/* to access: ui-sref="car-config-carconfiguration.detail" */
		/* url: http://<hostname>/car/config/carconfiguration/detail/:carConfigurationId */
		url: '/detail/:carConfigurationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-view-car-config.html',
				controller: 'CarConfigurationDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarConfigurationViewDtoFactory',
				function($stateParams, CarConfigCarConfigurationViewDtoFactory) {
					return CarConfigCarConfigurationViewDtoFactory.get({ id: $stateParams.carConfigurationId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfiguration.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarConfigurationEditDto */
		/* to access: ui-sref="car-config-carconfiguration.edit" */
		/* url: http://<hostname>/car/config/carconfiguration/edit/:carConfigurationId */
		url: '/edit/:carConfigurationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-edit-car-config.html',
				controller: 'CarConfigCarConfigurationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carModelVersion has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carModelVersion)) {
							// push the created carModelVersion to the list with selectable carModelVersion items
							$stateParams.dataModel.carModelVersionList.push($stateParams.dataModel.carModelVersion);

							// set the created carModelVersion as selected carModelVersion
							$stateParams.dataModel.selectedCarModelVersion = $stateParams.dataModel.carModelVersion;
						}
						// as carBodytype has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBodytype)) {
							// push the created carBodytype to the list with selectable carBodytype items
							$stateParams.dataModel.carBodytypeList.push($stateParams.dataModel.carBodytype);

							// set the created carBodytype as selected carBodytype
							$stateParams.dataModel.selectedCarBodytype = $stateParams.dataModel.carBodytype;
						}
						// as carEngine has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carEngine)) {
							// push the created carEngine to the list with selectable carEngine items
							$stateParams.dataModel.carEngineList.push($stateParams.dataModel.carEngine);

							// set the created carEngine as selected carEngine
							$stateParams.dataModel.selectedCarEngine = $stateParams.dataModel.carEngine;
						}
						// as carGrade has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carGrade)) {
							// push the created carGrade to the list with selectable carGrade items
							$stateParams.dataModel.carGradeList.push($stateParams.dataModel.carGrade);

							// set the created carGrade as selected carGrade
							$stateParams.dataModel.selectedCarGrade = $stateParams.dataModel.carGrade;
						}
						// as carTransmission has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carTransmission)) {
							// push the created carTransmission to the list with selectable carTransmission items
							$stateParams.dataModel.carTransmissionList.push($stateParams.dataModel.carTransmission);

							// set the created carTransmission as selected carTransmission
							$stateParams.dataModel.selectedCarTransmission = $stateParams.dataModel.carTransmission;
						}
						// as carPowertrain has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carPowertrain)) {
							// push the created carPowertrain to the list with selectable carPowertrain items
							$stateParams.dataModel.carPowertrainList.push($stateParams.dataModel.carPowertrain);

							// set the created carPowertrain as selected carPowertrain
							$stateParams.dataModel.selectedCarPowertrain = $stateParams.dataModel.carPowertrain;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationEditDtoFactory.get({ id: $stateParams.carConfigurationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfiguration.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarConfigurationEditDto */
		/* to access: ui-sref="car-config-carconfiguration.new" */
		/* url: http://<hostname>/car/config/carconfiguration/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-edit-car-config.html',
				controller: 'CarConfigCarConfigurationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carModelVersion has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carModelVersion)) {
							// push the created carModelVersion to the list with selectable carModelVersion items
							$stateParams.dataModel.carModelVersionList.push($stateParams.dataModel.carModelVersion);

							// set the created carModelVersion as selected carModelVersion
							$stateParams.dataModel.selectedCarModelVersion = $stateParams.dataModel.carModelVersion;
						}
						// as carBodytype has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBodytype)) {
							// push the created carBodytype to the list with selectable carBodytype items
							$stateParams.dataModel.carBodytypeList.push($stateParams.dataModel.carBodytype);

							// set the created carBodytype as selected carBodytype
							$stateParams.dataModel.selectedCarBodytype = $stateParams.dataModel.carBodytype;
						}
						// as carEngine has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carEngine)) {
							// push the created carEngine to the list with selectable carEngine items
							$stateParams.dataModel.carEngineList.push($stateParams.dataModel.carEngine);

							// set the created carEngine as selected carEngine
							$stateParams.dataModel.selectedCarEngine = $stateParams.dataModel.carEngine;
						}
						// as carGrade has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carGrade)) {
							// push the created carGrade to the list with selectable carGrade items
							$stateParams.dataModel.carGradeList.push($stateParams.dataModel.carGrade);

							// set the created carGrade as selected carGrade
							$stateParams.dataModel.selectedCarGrade = $stateParams.dataModel.carGrade;
						}
						// as carTransmission has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carTransmission)) {
							// push the created carTransmission to the list with selectable carTransmission items
							$stateParams.dataModel.carTransmissionList.push($stateParams.dataModel.carTransmission);

							// set the created carTransmission as selected carTransmission
							$stateParams.dataModel.selectedCarTransmission = $stateParams.dataModel.carTransmission;
						}
						// as carPowertrain has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carPowertrain)) {
							// push the created carPowertrain to the list with selectable carPowertrain items
							$stateParams.dataModel.carPowertrainList.push($stateParams.dataModel.carPowertrain);

							// set the created carPowertrain as selected carPowertrain
							$stateParams.dataModel.selectedCarPowertrain = $stateParams.dataModel.carPowertrain;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationNewDtoFactory.get({ id: $stateParams.carConfigurationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfiguration.new', dtoCreateState);

	$stateProvider.state('website-api-carconfiguration', {
		/* abstract state for DTO WebsiteApiCarConfigurationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/carconfiguration',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarConfiguration',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO website.api */
	var dtoListState = {
		/* list state for DTO WebsiteApiCarConfigurationListDto */
		/* to access: ui-ref="website-api-carconfiguration.list" */
		/* url: http://<hostname>/websiteapi/carconfiguration/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-list-website-api.html',
				controller: 'WebsiteApiCarConfigurationListDtoController',
			},
		},
	};

	$stateProvider.state('website-api-carconfiguration.list', dtoListState);

	$stateProvider.state('article-api-carconfiguration', {
		/* abstract state for DTO ArticleApiCarConfigurationListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carconfiguration',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarConfiguration',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarConfigurationEditDto */
		/* to access: ui-sref="article-api-carconfiguration.edit" */
		/* url: http://<hostname>/articleapi/carconfiguration/edit/:carConfigurationId */
		url: '/edit/:carConfigurationId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carModelVersion has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carModelVersion)) {
							// push the created carModelVersion to the list with selectable carModelVersion items
							$stateParams.dataModel.carModelVersionList.push($stateParams.dataModel.carModelVersion);

							// set the created carModelVersion as selected carModelVersion
							$stateParams.dataModel.selectedCarModelVersion = $stateParams.dataModel.carModelVersion;
						}
						// as carBodytype has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBodytype)) {
							// push the created carBodytype to the list with selectable carBodytype items
							$stateParams.dataModel.carBodytypeList.push($stateParams.dataModel.carBodytype);

							// set the created carBodytype as selected carBodytype
							$stateParams.dataModel.selectedCarBodytype = $stateParams.dataModel.carBodytype;
						}
						// as carEngine has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carEngine)) {
							// push the created carEngine to the list with selectable carEngine items
							$stateParams.dataModel.carEngineList.push($stateParams.dataModel.carEngine);

							// set the created carEngine as selected carEngine
							$stateParams.dataModel.selectedCarEngine = $stateParams.dataModel.carEngine;
						}
						// as carGrade has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carGrade)) {
							// push the created carGrade to the list with selectable carGrade items
							$stateParams.dataModel.carGradeList.push($stateParams.dataModel.carGrade);

							// set the created carGrade as selected carGrade
							$stateParams.dataModel.selectedCarGrade = $stateParams.dataModel.carGrade;
						}
						// as carTransmission has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carTransmission)) {
							// push the created carTransmission to the list with selectable carTransmission items
							$stateParams.dataModel.carTransmissionList.push($stateParams.dataModel.carTransmission);

							// set the created carTransmission as selected carTransmission
							$stateParams.dataModel.selectedCarTransmission = $stateParams.dataModel.carTransmission;
						}
						// as carPowertrain has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carPowertrain)) {
							// push the created carPowertrain to the list with selectable carPowertrain items
							$stateParams.dataModel.carPowertrainList.push($stateParams.dataModel.carPowertrain);

							// set the created carPowertrain as selected carPowertrain
							$stateParams.dataModel.selectedCarPowertrain = $stateParams.dataModel.carPowertrain;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationEditDtoFactory.get({ id: $stateParams.carConfigurationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfiguration.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarConfigurationEditDto */
		/* to access: ui-sref="article-api-carconfiguration.new" */
		/* url: http://<hostname>/articleapi/carconfiguration/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfiguration/carconfiguration-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carModelVersion has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carModelVersion)) {
							// push the created carModelVersion to the list with selectable carModelVersion items
							$stateParams.dataModel.carModelVersionList.push($stateParams.dataModel.carModelVersion);

							// set the created carModelVersion as selected carModelVersion
							$stateParams.dataModel.selectedCarModelVersion = $stateParams.dataModel.carModelVersion;
						}
						// as carBodytype has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBodytype)) {
							// push the created carBodytype to the list with selectable carBodytype items
							$stateParams.dataModel.carBodytypeList.push($stateParams.dataModel.carBodytype);

							// set the created carBodytype as selected carBodytype
							$stateParams.dataModel.selectedCarBodytype = $stateParams.dataModel.carBodytype;
						}
						// as carEngine has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carEngine)) {
							// push the created carEngine to the list with selectable carEngine items
							$stateParams.dataModel.carEngineList.push($stateParams.dataModel.carEngine);

							// set the created carEngine as selected carEngine
							$stateParams.dataModel.selectedCarEngine = $stateParams.dataModel.carEngine;
						}
						// as carGrade has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carGrade)) {
							// push the created carGrade to the list with selectable carGrade items
							$stateParams.dataModel.carGradeList.push($stateParams.dataModel.carGrade);

							// set the created carGrade as selected carGrade
							$stateParams.dataModel.selectedCarGrade = $stateParams.dataModel.carGrade;
						}
						// as carTransmission has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carTransmission)) {
							// push the created carTransmission to the list with selectable carTransmission items
							$stateParams.dataModel.carTransmissionList.push($stateParams.dataModel.carTransmission);

							// set the created carTransmission as selected carTransmission
							$stateParams.dataModel.selectedCarTransmission = $stateParams.dataModel.carTransmission;
						}
						// as carPowertrain has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carPowertrain)) {
							// push the created carPowertrain to the list with selectable carPowertrain items
							$stateParams.dataModel.carPowertrainList.push($stateParams.dataModel.carPowertrain);

							// set the created carPowertrain as selected carPowertrain
							$stateParams.dataModel.selectedCarPowertrain = $stateParams.dataModel.carPowertrain;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationNewDtoFactory.get({ id: $stateParams.carConfigurationId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfiguration.new', dtoCreateState);
});
