/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('UserCarStockAccessBoundCarBrandFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundCarBrands/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to search / retrieve an array of StockMgmtUserCarStockAccessBoundCarBrandListDto's
 */
angular.module('mmmApp').factory('StockMgmtUserCarStockAccessBoundCarBrandListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/usercarstockaccessboundcarbrand',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewUserCarStockAccessBoundCarBrandFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundCarBrands/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all UserCarStockAccessBoundCarBrands without the use of paging
angular.module('mmmApp').factory('NoPagingUserCarStockAccessBoundCarBrandFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundCarBrands/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllUserCarStockAccessBoundCarBrandsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/userCarStockAccessBoundCarBrands/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
