/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('GuaranteeFactory', function($resource) {
	return $resource(
		'api/guarantees/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtGuaranteeViewDto
 */
angular.module('mmmApp').factory('RelationMgmtGuaranteeViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/guarantee/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtGuaranteeListDto's
 */
angular.module('mmmApp').factory('RelationMgmtGuaranteeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/guarantee',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtGuaranteeEditDto
 */
angular.module('mmmApp').factory('RelationMgmtGuaranteeEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/guarantee/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtGuaranteeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtGuaranteeNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/guarantee/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewGuaranteeFactory', function($resource) {
	return $resource(
		'api/guarantees/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Guarantees without the use of paging
angular.module('mmmApp').factory('NoPagingGuaranteeFactory', function($resource) {
	return $resource(
		'api/guarantees/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllGuaranteesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/guarantees/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
