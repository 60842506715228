/** @format */

'use strict';

// ApplicationLog has detail layout enabled. Detail layout template url: applicationlog-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ApplicationLog'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('applicationlog'); // main translation loaded for applicationlog
		$translatePartialLoader.addPart('applicationtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('applicationlogstatus'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('admin-applicationlog', {
		/* abstract state for DTO AdminApplicationLogListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/admin/applicationlog',
		data: {
			authorities: ['ROLE_ADMIN'],
			pageTitle: 'ApplicationLog',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO admin */
	var dtoListState = {
		/* list state for DTO AdminApplicationLogListDto */
		/* to access: ui-ref="admin-applicationlog.list" */
		/* url: http://<hostname>/admin/applicationlog/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/applicationlog/applicationlog-list-admin.html',
				controller: 'AdminApplicationLogListDtoController',
			},
		},
	};

	$stateProvider.state('admin-applicationlog.list', dtoListState);

	/* detail state for DTO admin */
	var dtoDetailState = {
		/* detail state for DTO AdminApplicationLogViewDto */
		/* to access: ui-sref="admin-applicationlog.detail" */
		/* url: http://<hostname>/admin/applicationlog/detail/:applicationLogId */
		url: '/detail/:applicationLogId',
		views: {
			'content@': {
				templateUrl: 'app/entities/applicationlog/applicationlog-view-admin.html',
				controller: 'ApplicationLogDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'AdminApplicationLogViewDtoFactory',
				function($stateParams, AdminApplicationLogViewDtoFactory) {
					return AdminApplicationLogViewDtoFactory.get({ id: $stateParams.applicationLogId });
				},
			],
		},
	};

	$stateProvider.state('admin-applicationlog.detail', dtoDetailState);
});
