/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('ArticleApiCarConfigurationEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		ArticleApiCarConfigurationEditDtoFactory,
		AlertService,
		HistoryService,
		ArticleApiCarConfigurationEditDtoSaveAndCreateFactory,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.carConfiguration = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.carConfiguration['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.carConfiguration) && $rootScope.isDefinedAndNotNull($scope.carConfiguration.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('article-api-carconfiguration.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carConfigurationUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			ArticleApiCarConfigurationEditDtoFactory.update($scope.carConfiguration, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.carConfiguration.id != null) {
				ArticleApiCarConfigurationEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.carConfiguration,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				ArticleApiCarConfigurationEditDtoSaveAndCreateFactory.createAndNew(
					$scope.carConfiguration,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// triggered when click a plus button, navigates to creating a new carModelVersion of type CarModelVersion
		// once CarModelVersion is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarModelVersion = function() {
			var referredBy = {
				refName: 'carModelVersion',
				data: $scope.carConfiguration,
			};

			// navigating to new carModelVersion will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarModelVersion', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new carBodytype of type CarBodytype
		// once CarBodytype is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarBodytype = function() {
			var referredBy = {
				refName: 'carBodytype',
				data: $scope.carConfiguration,
			};

			// navigating to new carBodytype will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarBodytype', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new carEngine of type CarEngine
		// once CarEngine is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarEngine = function() {
			var referredBy = {
				refName: 'carEngine',
				data: $scope.carConfiguration,
			};

			// navigating to new carEngine will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarEngine', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new carGrade of type CarGrade
		// once CarGrade is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarGrade = function() {
			var referredBy = {
				refName: 'carGrade',
				data: $scope.carConfiguration,
			};

			// navigating to new carGrade will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarGrade', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new carTransmission of type CarTransmission
		// once CarTransmission is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarTransmission = function() {
			var referredBy = {
				refName: 'carTransmission',
				data: $scope.carConfiguration,
			};

			// navigating to new carTransmission will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarTransmission', { referredBy: referredBy }, { notify: true });
		};

		// triggered when click a plus button, navigates to creating a new carPowertrain of type CarPowertrain
		// once CarPowertrain is saved, history is updated and the user automatically navigates back to this edit page for CarConfiguration
		$scope.createCarPowertrain = function() {
			var referredBy = {
				refName: 'carPowertrain',
				data: $scope.carConfiguration,
			};

			// navigating to new carPowertrain will add this new carconfiguration state to the history stack
			$state.go($state.current.name + '.newCarPowertrain', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
