/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('CarModelYearDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.carModelYear = entity;
	$scope.objectName = 'CarModelYear';
	$scope.carModelYearId = $stateParams.carModelYearId;

	if (angular.isUndefined($rootScope.carModelYearActiveTab)) {
		//set the default here
		$rootScope.carModelYearActiveTab = 0;
	}

	$scope.goToEditState = function(carModelYear) {
		$state.go('carmodelyear.detail.edit({carModelYearId:carModelYear.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:carModelYearUpdate', function(event, result) {
		$scope.carModelYear = result;
	});
});
