(function() {
	'use strict';

	angular.module('mmmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('pdfimport', {
			parent : 'app',
			url : '/pdfimport',
			data : {
				authorities : []
			},
			views : {
				'content@' : {
					templateUrl : 'app/pdfimporter/pdfimport.html',
					controller : 'PdfImportController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('global');
					$translatePartialLoader.addPart('pdfimport');
					$translatePartialLoader.addPart('carconfiguration');
					$translatePartialLoader.addPart('carengine');
					$translatePartialLoader.addPart('carpowertrain');
					$translatePartialLoader.addPart('cartransmission');
					$translatePartialLoader.addPart('cargrade');
					$translatePartialLoader.addPart('caroption');
					$translatePartialLoader.addPart('optionorigin');
					return $translate.refresh();
				} ]
			}
		});
	}
})();
