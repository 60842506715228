/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('CarConfigArticleCombiEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigArticleCombiEditDtoFactory,
		AlertService,
		AutocompleteCarConfigArticlesByQueryParamsFactory,
		HistoryService,
		CarConfigArticleCombiEditDtoSaveAndCreateFactory
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.articleCombi = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.articleCombi['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.articleCombi) && $rootScope.isDefinedAndNotNull($scope.articleCombi.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('car-config-articlecombi.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:articleCombiUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			CarConfigArticleCombiEditDtoFactory.update($scope.articleCombi, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.articleCombi.id != null) {
				CarConfigArticleCombiEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.articleCombi,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				CarConfigArticleCombiEditDtoSaveAndCreateFactory.createAndNew(
					$scope.articleCombi,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteArticles = function(val) {
			/* A custom hook can be implemented here custom/frontend/angular/entity/controller/dto-edit/autocomplete_extra_parameters.ftl */
			var params = {
				articleTypeAheadSearchString: val,
			};

			return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
