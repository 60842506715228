/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('ArticleApiCarPaintEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		ArticleApiCarPaintEditDtoFactory,
		AlertService,
		HistoryService,
		ArticleApiCarPaintEditDtoSaveAndCreateFactory,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.carPaint = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.carPaint['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.carPaint) && $rootScope.isDefinedAndNotNull($scope.carPaint.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('article-api-carpaint.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carPaintUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			ArticleApiCarPaintEditDtoFactory.update($scope.carPaint, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.carPaint.id != null) {
				ArticleApiCarPaintEditDtoSaveAndCreateFactory.updateAndNew(
					$scope.carPaint,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			} else {
				ArticleApiCarPaintEditDtoSaveAndCreateFactory.createAndNew(
					$scope.carPaint,
					onSaveAndNewFinishedCallback,
					saveOrUpdateErrorCallback
				);
			}
		};

		// triggered when click a plus button, navigates to creating a new color of type Color
		// once Color is saved, history is updated and the user automatically navigates back to this edit page for CarPaint
		$scope.createColor = function() {
			var referredBy = {
				refName: 'color',
				data: $scope.carPaint,
			};

			// navigating to new color will add this new carpaint state to the history stack
			$state.go($state.current.name + '.newColor', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
