/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCarOrderLineListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarOrderLineFactory,
		RelationMgmtCarOrderLineListDtoSearchFactory,
		RelationMgmtCarOrderLineViewDtoFactory,
		AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory,
		AutocompleteRelationMgmtRelationsByQueryParamsFactory,
		AutocompleteRelationMgmtCarStocksByQueryParamsFactory
	) {
		$scope.carOrderLineList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarOrderLine);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-carorderline.new');
		};

		var getOrderLineGroupId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.orderLineGroup)) {
				return $scope.CarOrderLineSearchOverview.orderLineGroup.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.article)) {
				return $scope.CarOrderLineSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.tenant)) {
				return $scope.CarOrderLineSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getDriverId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.driver)) {
				return $scope.CarOrderLineSearchOverview.driver.id;
			} else {
				return undefined;
			}
		};

		var getCarStockId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.carStock)) {
				return $scope.CarOrderLineSearchOverview.carStock.id;
			} else {
				return undefined;
			}
		};

		var getQuoteId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.quote)) {
				return $scope.CarOrderLineSearchOverview.quote.id;
			} else {
				return undefined;
			}
		};

		// handle attribute licenceDate of javaType java.time.LocalDate
		$scope.processLicenceDate = function() {
			var licenceDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.licenceDateFrom);
			var licenceDateToDefined = $rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.licenceDateTo);

			if (licenceDateFromDefined) {
				$scope.CarOrderLineSearchOverview.licenceDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CarOrderLineSearchOverview.licenceDateFrom
				);
			} else {
				$scope.CarOrderLineSearchOverview.licenceDateFromString = undefined;
			}

			if (licenceDateToDefined) {
				$scope.CarOrderLineSearchOverview.licenceDateToString = $rootScope.convertDateWithoutZone(
					$scope.CarOrderLineSearchOverview.licenceDateTo
				);
			} else {
				$scope.CarOrderLineSearchOverview.licenceDateToString = undefined;
			}
		};

		// handle attribute deliveryDate of javaType java.time.LocalDate
		$scope.processDeliveryDate = function() {
			var deliveryDateFromDefined = $rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.deliveryDateFrom);
			var deliveryDateToDefined = $rootScope.isDefinedAndNotNull($scope.CarOrderLineSearchOverview.deliveryDateTo);

			if (deliveryDateFromDefined) {
				$scope.CarOrderLineSearchOverview.deliveryDateFromString = $rootScope.convertDateWithoutZone(
					$scope.CarOrderLineSearchOverview.deliveryDateFrom
				);
			} else {
				$scope.CarOrderLineSearchOverview.deliveryDateFromString = undefined;
			}

			if (deliveryDateToDefined) {
				$scope.CarOrderLineSearchOverview.deliveryDateToString = $rootScope.convertDateWithoutZone(
					$scope.CarOrderLineSearchOverview.deliveryDateTo
				);
			} else {
				$scope.CarOrderLineSearchOverview.deliveryDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processLicenceDate();
			$scope.processDeliveryDate();

			$rootScope.CarOrderLineSearchOverview = angular.copy($scope.CarOrderLineSearchOverview);

			$rootScope.pageSizeCarOrderLine = angular.copy($scope.pageSize);

			RelationMgmtCarOrderLineListDtoSearchFactory.query(
				{
					page: $scope.CarOrderLineSearchOverview.page - 1,
					size: $scope.pageSize,
					subtotalFrom: $scope.CarOrderLineSearchOverview.subtotalFrom,
					subtotalTo: $scope.CarOrderLineSearchOverview.subtotalTo,
					baseTotalFrom: $scope.CarOrderLineSearchOverview.baseTotalFrom,
					baseTotalTo: $scope.CarOrderLineSearchOverview.baseTotalTo,
					baseTotalExVatFrom: $scope.CarOrderLineSearchOverview.baseTotalExVatFrom,
					baseTotalExVatTo: $scope.CarOrderLineSearchOverview.baseTotalExVatTo,
					licenceDateFrom: $scope.CarOrderLineSearchOverview.licenceDateFromString,
					licenceDateTo: $scope.CarOrderLineSearchOverview.licenceDateToString,
					deliveryDateFrom: $scope.CarOrderLineSearchOverview.deliveryDateFromString,
					deliveryDateTo: $scope.CarOrderLineSearchOverview.deliveryDateToString,
					residualValueFrom: $scope.CarOrderLineSearchOverview.residualValueFrom,
					residualValueTo: $scope.CarOrderLineSearchOverview.residualValueTo,
					orderLineGroupId: getOrderLineGroupId(),
					articleId: getArticleId(),
					vatType: $scope.CarOrderLineSearchOverview.vatType,
					orderLineType: $scope.CarOrderLineSearchOverview.orderLineType,
					tenantId: getTenantId(),
					driverId: getDriverId(),
					carStockId: getCarStockId(),
					quoteId: getQuoteId(),

					sort: [$scope.CarOrderLineSearchOverview.predicate + ',' + ($scope.CarOrderLineSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carOrderLineList = result.items;

					$scope.autocompleteOrderLineGroups = function(val) {
						var params = {
							orderLineGroupTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtOrderLineGroupsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.orderLineTypeList = result.orderLineTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarOrderLineSearchOverviewDirty = function() {
			return !angular.equals($scope.CarOrderLineSearchOverview, $scope.originalCarOrderLineSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarOrderLineSearchOverview = {};
			$rootScope.CarOrderLineSearchOverview.page = 1;
			$rootScope.CarOrderLineSearchOverview.predicate = 'id';
			$rootScope.CarOrderLineSearchOverview.ascending = false;

			$scope.CarOrderLineSearchOverview = angular.copy($rootScope.CarOrderLineSearchOverview);

			$scope.originalCarOrderLineSearchOverview = angular.copy($scope.CarOrderLineSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarOrderLineSearchOverview) || $rootScope.CarOrderLineSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarOrderLineSearchOverview = angular.copy($rootScope.CarOrderLineSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarOrderLineId = id;
			$('#deleteCarOrderLineConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtCarOrderLineViewDtoFactory.delete(
				{ id: $scope.selectedCarOrderLineId },
				function() {
					$scope.search();
					$('#deleteCarOrderLineConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarOrderLineConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarOrderLineSearchOverview.predicate === itemName) {
				if ($scope.CarOrderLineSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
