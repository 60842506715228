/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('VatFactory', function($resource) {
	return $resource(
		'api/vats/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigVatViewDto
 */
angular.module('mmmApp').factory('CarConfigVatViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/vat/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigVatListDto's
 */
angular.module('mmmApp').factory('CarConfigVatListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/vat',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigVatEditDto
 */
angular.module('mmmApp').factory('CarConfigVatEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/vat/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigVatEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigVatNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/vat/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewVatFactory', function($resource) {
	return $resource(
		'api/vats/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Vats without the use of paging
angular.module('mmmApp').factory('NoPagingVatFactory', function($resource) {
	return $resource(
		'api/vats/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/vat/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/vat/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllVatsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/vats/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
