(function() {
	'use strict';

	angular.module('mmmApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider.state('settings', {
			parent : 'account',
			url : '/settings',
			data : {
				authorities : [ 'ROLE_USER' ],
				pageTitle : 'global.menu.account.settings'
			},
			views : {
				'content@' : {
					templateUrl : 'app/account/settings/settings.html',
					controller : 'SettingsController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('settings');
					$translatePartialLoader.addPart('supportedlocale');
                    $translatePartialLoader.addPart('usertype');
					return $translate.refresh();
				} ]
			}
		});
	}
})();
