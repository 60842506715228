/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('CarConfigCarModelEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigCarModelEditDtoFactory,
		AlertService,
		HistoryService,
		CarConfigCarModelEditDtoSaveAndCreateFactory,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.carModel = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.carModel['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.carModel) && $rootScope.isDefinedAndNotNull($scope.carModel.id);
		};

		var onSaveAndNewFinishedCallback = function(result) {
			// navigate to new state with result (a new entity, but with the copied values from the previous)
			$state.go('car-config-carmodel.new', {
				dataModel: result,
				mappedBy: $stateParams.mappedBy,
				mappedById: $stateParams.mappedById,
			});
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carModelUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			CarConfigCarModelEditDtoFactory.update($scope.carModel, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		$scope.saveAndNew = function() {
			$scope.submitButtonIsActive = false;

			// To prevent navigating back to this current view, remove it from history.
			HistoryService.removeHistory();

			if ($scope.carModel.id != null) {
				CarConfigCarModelEditDtoSaveAndCreateFactory.updateAndNew($scope.carModel, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			} else {
				CarConfigCarModelEditDtoSaveAndCreateFactory.createAndNew($scope.carModel, onSaveAndNewFinishedCallback, saveOrUpdateErrorCallback);
			}
		};

		// triggered when click a plus button, navigates to creating a new carBrand of type CarBrand
		// once CarBrand is saved, history is updated and the user automatically navigates back to this edit page for CarModel
		$scope.createCarBrand = function() {
			var referredBy = {
				refName: 'carBrand',
				data: $scope.carModel,
			};

			// navigating to new carBrand will add this new carmodel state to the history stack
			$state.go($state.current.name + '.newCarBrand', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
