/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ArticleTagFactory', function($resource) {
	return $resource(
		'api/articleTags/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigArticleTagViewDto
 */
angular.module('mmmApp').factory('CarConfigArticleTagViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articletag/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigArticleTagListDto's
 */
angular.module('mmmApp').factory('CarConfigArticleTagListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/articletag',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigArticleTagEditDto
 */
angular.module('mmmApp').factory('CarConfigArticleTagEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articletag/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigArticleTagEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigArticleTagNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articletag/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewArticleTagFactory', function($resource) {
	return $resource(
		'api/articleTags/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ArticleTags without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTagFactory', function($resource) {
	return $resource(
		'api/articleTags/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllArticleTagsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articleTags/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
