/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingTransmissionFactory', function($resource) {
	return $resource(
		'api/mappingTransmissions/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingTransmissionViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingTransmissionViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingtransmission/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingTransmissionListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingTransmissionListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingtransmission',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingTransmissionEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingTransmissionEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingtransmission/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingTransmissionEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingTransmissionNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingtransmission/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingTransmissionEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingTransmissionEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingtransmission/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingTransmissionFactory', function($resource) {
	return $resource(
		'api/mappingTransmissions/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingTransmissions without the use of paging
angular.module('mmmApp').factory('NoPagingMappingTransmissionFactory', function($resource) {
	return $resource(
		'api/mappingTransmissions/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingTransmission/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible TransmissionTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTransmissionTypeFactory', function($resource) {
	return $resource(
		'api/mappingTransmission/transmissionTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingTransmissionsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingTransmissions/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingTransmissionSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingtransmissions/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
