'use strict';




angular.module('mmmApp').controller('CarConfigCarGradeDependentPriceListDtoController', function ($scope, $rootScope, $state, $stateParams, $q, AlertService, CarGradeDependentPriceFactory, CarConfigCarGradeDependentPriceListDtoSearchFactory, CarConfigCarGradeDependentPriceViewDtoFactory, AutocompleteCarConfigArticlesByQueryParamsFactory) {
    $scope.carGradeDependentPriceList = [];
    $scope.showSearchForm = true;

    if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarGradeDependentPrice)) {
    $scope.pageSize = angular.copy($rootScope.pageSizeCarGradeDependentPrice);
    } else {
        $scope.pageSize = 20;

    $rootScope.pageSizeCarGradeDependentPrice = angular.copy($scope.pageSize);
    }

    $scope.goToNewState = function() {

        // this overview is NOT mapped, it is the main overview
        $state.go('car-config-cargradedependentprice.new');
    };


      var getArticleId = function() {
        if ($rootScope.isDefinedAndNotNull($scope.CarGradeDependentPriceSearchOverview.article)) {
          return $scope.CarGradeDependentPriceSearchOverview.article.id;
        } else {
          return undefined;
        }
      };



        // handle attribute validFrom of javaType java.time.LocalDate
    $scope.processValidFrom = function () {
        var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.CarGradeDependentPriceSearchOverview.validFromFrom);
        var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.CarGradeDependentPriceSearchOverview.validFromTo);

        if (validFromFromDefined) {
            $scope.CarGradeDependentPriceSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone($scope.CarGradeDependentPriceSearchOverview.validFromFrom);
        } else {
            $scope.CarGradeDependentPriceSearchOverview.validFromFromString = undefined;
        }

        if (validFromToDefined) {
            $scope.CarGradeDependentPriceSearchOverview.validFromToString = $rootScope.convertDateWithoutZone($scope.CarGradeDependentPriceSearchOverview.validFromTo);
        } else {
            $scope.CarGradeDependentPriceSearchOverview.validFromToString = undefined;
        }
    };




    $scope.search = function () {
        $("#search-button").addClass("glyphicon-refresh-animate");

        // Call the javascript functions to handle search parameters
        $scope.processValidFrom();

        $rootScope.CarGradeDependentPriceSearchOverview = angular.copy($scope.CarGradeDependentPriceSearchOverview);

        $rootScope.pageSizeCarGradeDependentPrice = angular.copy($scope.pageSize);

    

        CarConfigCarGradeDependentPriceListDtoSearchFactory.query({
            page: $scope.CarGradeDependentPriceSearchOverview.page - 1,
            size: $scope.pageSize,
            validFromFrom: $scope.CarGradeDependentPriceSearchOverview.validFromFromString,
            validFromTo: $scope.CarGradeDependentPriceSearchOverview.validFromToString,
            priceFrom: $scope.CarGradeDependentPriceSearchOverview.priceFrom,
            priceTo: $scope.CarGradeDependentPriceSearchOverview.priceTo,
            fiscalValueFrom: $scope.CarGradeDependentPriceSearchOverview.fiscalValueFrom,
            fiscalValueTo: $scope.CarGradeDependentPriceSearchOverview.fiscalValueTo,
            bpmFrom: $scope.CarGradeDependentPriceSearchOverview.bpmFrom,
            bpmTo: $scope.CarGradeDependentPriceSearchOverview.bpmTo,
            cataloguePriceExVatFrom: $scope.CarGradeDependentPriceSearchOverview.cataloguePriceExVatFrom,
            cataloguePriceExVatTo: $scope.CarGradeDependentPriceSearchOverview.cataloguePriceExVatTo,
            articleId: getArticleId(),
            carGradeIdList: $scope.CarGradeDependentPriceSearchOverview.carGradeIdList,
            
            sort: [$scope.CarGradeDependentPriceSearchOverview.predicate + ',' + ($scope.CarGradeDependentPriceSearchOverview.ascending ? 'asc' : 'desc')]

        }, function(result, headers) {
            $scope.totalItems = headers('X-Total-Count');
            $scope.carGradeDependentPriceList = result.items;




                    $scope.carGradeReferenceFilterList = result.carGradeSearchOptions;

            

            $("#search-button").removeClass("glyphicon-refresh-animate");
        });
        
    };


    $scope.isCarGradeDependentPriceSearchOverviewDirty = function () {
        return !angular.equals($scope.CarGradeDependentPriceSearchOverview, $scope.originalCarGradeDependentPriceSearchOverview);
    };

    $scope.loadDefaultSearchParams = function() {

        $rootScope.CarGradeDependentPriceSearchOverview = {};
        $rootScope.CarGradeDependentPriceSearchOverview.page = 1;
        $rootScope.CarGradeDependentPriceSearchOverview.predicate = 'id';
        $rootScope.CarGradeDependentPriceSearchOverview.ascending = false;

        

        $scope.CarGradeDependentPriceSearchOverview = angular.copy($rootScope.CarGradeDependentPriceSearchOverview);

        $scope.originalCarGradeDependentPriceSearchOverview = angular.copy($scope.CarGradeDependentPriceSearchOverview);
    };

    $scope.reset = function() {
        $scope.loadDefaultSearchParams();
        if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
            // we check the availability of search form as it is still used in search forms that are not inlined in the table
            $scope.searchForm.$setPristine();
        }
        $scope.search();
    };


    $scope.loadPage = function () {
        if (angular.isUndefined($rootScope.CarGradeDependentPriceSearchOverview) || $rootScope.CarGradeDependentPriceSearchOverview === null) {
            $scope.loadDefaultSearchParams(); // load defaults
        }

        $scope.CarGradeDependentPriceSearchOverview = angular.copy($rootScope.CarGradeDependentPriceSearchOverview);
        $scope.search();
    };


    $scope.singleDelete = function (id) {
        $scope.selectedCarGradeDependentPriceId = id;
            $('#deleteCarGradeDependentPriceConfirmation').modal('show');
    };

    $scope.confirmSingleDelete = function () {
        CarConfigCarGradeDependentPriceViewDtoFactory.delete({id: $scope.selectedCarGradeDependentPriceId}, function () {
            $scope.search();
            $('#deleteCarGradeDependentPriceConfirmation').modal('hide');
        }, function(error) {
            $('#deleteCarGradeDependentPriceConfirmation').modal('hide');
            AlertService.error(error.data.message, error.data.params);
        });
    };






    $scope.loadPage();



    $scope.getSortIcon = function(itemName) {
        if ($scope.CarGradeDependentPriceSearchOverview.predicate === itemName) {
            if ($scope.CarGradeDependentPriceSearchOverview.ascending) { 
                return "glyphicon-sort-by-attributes";
            } else {
                return "glyphicon-sort-by-attributes-alt";
            }
        } else {
            return "glyphicon-sort";
        }
    };

    $scope.updateCarGradeUISelect = function(item, model) {
        $scope.search();
    }
});
