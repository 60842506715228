/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCountryListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CountryFactory,
		RelationMgmtCountryListDtoSearchFactory,
		RelationMgmtCountryViewDtoFactory
	) {
		$scope.countryList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCountry)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCountry);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCountry = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-country.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getCountryList = function() {
			RelationMgmtCountryListDtoSearchFactory.query(
				{
					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.countryList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCountryList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCountryId = id;
			$('#deleteCountryConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtCountryViewDtoFactory.delete(
				{ id: $scope.selectedCountryId },
				function() {
					$scope.loadAll();
					$('#deleteCountryConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCountryConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
