/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigOptionCategoryListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OptionCategoryFactory,
		CarConfigOptionCategoryListDtoSearchFactory,
		CarConfigOptionCategoryViewDtoFactory
	) {
		$scope.optionCategoryList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOptionCategory)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOptionCategory);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOptionCategory = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-optioncategory.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getOptionCategoryList = function() {
			CarConfigOptionCategoryListDtoSearchFactory.query(
				{
					optionCategoryTypeList: angular.isDefined($scope.optionCategoryTypeId) ? $scope.optionCategoryTypeId : null,
					brandId: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.optionCategoryList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getOptionCategoryList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOptionCategoryId = id;
			$('#deleteOptionCategoryConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigOptionCategoryViewDtoFactory.delete(
				{ id: $scope.selectedOptionCategoryId },
				function() {
					$scope.loadAll();
					$('#deleteOptionCategoryConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteOptionCategoryConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateOptionCategoryTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
