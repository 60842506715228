/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticleCategoryListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleCategoryFactory,
		CarConfigArticleCategoryListDtoSearchFactory
	) {
		$scope.articleCategoryList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticleCategory)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticleCategory);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticleCategory = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-articlecategory.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getArticleCategoryList = function() {
			CarConfigArticleCategoryListDtoSearchFactory.query(
				{
					articleId: angular.isDefined($scope.articleId) ? $scope.articleId : null,
					categoryId: angular.isDefined($scope.categoryId) ? $scope.categoryId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleCategoryList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getArticleCategoryList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
