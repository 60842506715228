/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('RelationDocumentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.relationDocument = entity;
		$scope.objectName = 'RelationDocument';
		$scope.relationDocumentId = $stateParams.relationDocumentId;

		if (angular.isUndefined($rootScope.relationDocumentActiveTab)) {
			//set the default here
			$rootScope.relationDocumentActiveTab = 0;
		}

		$scope.goToEditState = function(relationDocument) {
			$state.go('relationdocument.detail.edit({relationDocumentId:relationDocument.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:relationDocumentUpdate', function(event, result) {
			$scope.relationDocument = result;
		});
	});
