/** @format */

'use strict';

// CarEngine has edit layout enabled. Edit layout template url: carengine-dialog.html
// CarEngine has detail layout enabled. Detail layout template url: carengine-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarEngine'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carengine'); // main translation loaded for carengine
		$translatePartialLoader.addPart('article'); // main translation loaded for carengine
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('fueltype'); // one to many reference from main entity
		$translatePartialLoader.addPart('hybridtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('hybridpowertrain'); // one to many reference from main entity
		$translatePartialLoader.addPart('batterytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('batteryplugtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('batteryfastchargerplugtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carengine', {
		/* abstract state for DTO CarConfigCarEngineListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carengine',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarEngine',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarEngineListDto */
		/* to access: ui-ref="car-config-carengine.list" */
		/* url: http://<hostname>/car/config/carengine/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-list-car-config.html',
				controller: 'CarConfigCarEngineListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carengine.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarEngineViewDto */
		/* to access: ui-sref="car-config-carengine.detail" */
		/* url: http://<hostname>/car/config/carengine/detail/:carEngineId */
		url: '/detail/:carEngineId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-view-car-config.html',
				controller: 'CarEngineDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarEngineViewDtoFactory',
				function($stateParams, CarConfigCarEngineViewDtoFactory) {
					return CarConfigCarEngineViewDtoFactory.get({ id: $stateParams.carEngineId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carengine.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarEngineEditDto */
		/* to access: ui-sref="car-config-carengine.edit" */
		/* url: http://<hostname>/car/config/carengine/edit/:carEngineId */
		url: '/edit/:carEngineId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-edit-car-config.html',
				controller: 'CarConfigCarEngineEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarEngineEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarEngineEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarEngineEditDtoFactory.get({ id: $stateParams.carEngineId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carengine.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarEngineEditDto */
		/* to access: ui-sref="car-config-carengine.new" */
		/* url: http://<hostname>/car/config/carengine/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-edit-car-config.html',
				controller: 'CarConfigCarEngineEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarEngineNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarEngineNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarEngineNewDtoFactory.get({ id: $stateParams.carEngineId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carengine.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarEngine and dto property value car.config
	$stateProvider.state('car-config-carconfiguration.new.newCarEngine', getCreateState('/carengine/new'));
	$stateProvider.state('car-config-carconfiguration.edit.newCarEngine', getCreateState('/carengine/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carengine/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('article-api-carengine', {
		/* abstract state for DTO ArticleApiCarEngineListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carengine',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarEngine',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarEngineEditDto */
		/* to access: ui-sref="article-api-carengine.edit" */
		/* url: http://<hostname>/articleapi/carengine/edit/:carEngineId */
		url: '/edit/:carEngineId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-edit-article-api.html',
				controller: 'ArticleApiCarEngineEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarEngineEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarEngineEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarEngineEditDtoFactory.get({ id: $stateParams.carEngineId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carengine.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarEngineEditDto */
		/* to access: ui-sref="article-api-carengine.new" */
		/* url: http://<hostname>/articleapi/carengine/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carengine/carengine-edit-article-api.html',
				controller: 'ArticleApiCarEngineEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarEngineNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarEngineNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarEngineNewDtoFactory.get({ id: $stateParams.carEngineId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carengine.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarEngine and dto property value article.api
	$stateProvider.state('article-api-carconfiguration.new.newCarEngine', getCreateState('/carengine/new'));
	$stateProvider.state('article-api-carconfiguration.edit.newCarEngine', getCreateState('/carengine/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carengine/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
