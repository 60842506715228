/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarGradeFactory', function($resource) {
	return $resource(
		'api/carGrades/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarGradeViewDto
 */
angular.module('mmmApp').factory('CarConfigCarGradeViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cargrade/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarGradeListDto's
 */
angular.module('mmmApp').factory('CarConfigCarGradeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/cargrade',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarGradeEditDto
 */
angular.module('mmmApp').factory('CarConfigCarGradeEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cargrade/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarGradeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarGradeNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/cargrade/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarGradeEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarGradeEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/cargrade/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiCarGradeListDto's
 */
angular.module('mmmApp').factory('WebsiteApiCarGradeListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/cargrade',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarGradeEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarGradeEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/cargrade/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarGradeEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarGradeNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/cargrade/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarGradeEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarGradeEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/cargrade/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarGradeFactory', function($resource) {
	return $resource(
		'api/carGrades/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarGrades without the use of paging
angular.module('mmmApp').factory('NoPagingCarGradeFactory', function($resource) {
	return $resource(
		'api/carGrades/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carGrade/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carGrade/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carGrade/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carGrade/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carGrade/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carGrade/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carGrade/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationsByCarGradeFactory', function($resource) {
	return $resource(
		'api/carConfigurations/carGrade/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarGradesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carGrades/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarGradeSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/cargrades/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
