/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtRelationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationFactory,
		RelationMgmtRelationListDtoSearchFactory,
		RelationMgmtRelationViewDtoFactory
	) {
		$scope.relationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-relation.new');
		};

		var getPrimaryPhoneId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.primaryPhone)) {
				return $scope.RelationSearchOverview.primaryPhone.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryAddressId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.primaryAddress)) {
				return $scope.RelationSearchOverview.primaryAddress.id;
			} else {
				return undefined;
			}
		};

		var getPrimaryEmailId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.primaryEmail)) {
				return $scope.RelationSearchOverview.primaryEmail.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.tenant)) {
				return $scope.RelationSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute optIn of javaType boolean
		// handle attribute optInDate of javaType java.time.LocalDate
		$scope.processOptInDate = function() {
			var optInDateFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.optInDateFrom);
			var optInDateToDefined = $rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.optInDateTo);

			if (optInDateFromDefined) {
				$scope.RelationSearchOverview.optInDateFromString = $rootScope.convertDateWithoutZone($scope.RelationSearchOverview.optInDateFrom);
			} else {
				$scope.RelationSearchOverview.optInDateFromString = undefined;
			}

			if (optInDateToDefined) {
				$scope.RelationSearchOverview.optInDateToString = $rootScope.convertDateWithoutZone($scope.RelationSearchOverview.optInDateTo);
			} else {
				$scope.RelationSearchOverview.optInDateToString = undefined;
			}
		};

		// handle attribute optOut of javaType boolean
		// handle attribute optOutDate of javaType java.time.LocalDate
		$scope.processOptOutDate = function() {
			var optOutDateFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.optOutDateFrom);
			var optOutDateToDefined = $rootScope.isDefinedAndNotNull($scope.RelationSearchOverview.optOutDateTo);

			if (optOutDateFromDefined) {
				$scope.RelationSearchOverview.optOutDateFromString = $rootScope.convertDateWithoutZone(
					$scope.RelationSearchOverview.optOutDateFrom
				);
			} else {
				$scope.RelationSearchOverview.optOutDateFromString = undefined;
			}

			if (optOutDateToDefined) {
				$scope.RelationSearchOverview.optOutDateToString = $rootScope.convertDateWithoutZone($scope.RelationSearchOverview.optOutDateTo);
			} else {
				$scope.RelationSearchOverview.optOutDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processOptInDate();
			$scope.processOptOutDate();

			$rootScope.RelationSearchOverview = angular.copy($scope.RelationSearchOverview);

			$rootScope.pageSizeRelation = angular.copy($scope.pageSize);

			RelationMgmtRelationListDtoSearchFactory.query(
				{
					page: $scope.RelationSearchOverview.page - 1,
					size: $scope.pageSize,
					optIn: $scope.RelationSearchOverview.optInGroup,
					optInDateFrom: $scope.RelationSearchOverview.optInDateFromString,
					optInDateTo: $scope.RelationSearchOverview.optInDateToString,
					optOut: $scope.RelationSearchOverview.optOutGroup,
					optOutDateFrom: $scope.RelationSearchOverview.optOutDateFromString,
					optOutDateTo: $scope.RelationSearchOverview.optOutDateToString,
					status: $scope.RelationSearchOverview.status,
					relationType: $scope.RelationSearchOverview.relationType,
					primaryPhoneId: getPrimaryPhoneId(),
					primaryAddressId: getPrimaryAddressId(),
					primaryEmailId: getPrimaryEmailId(),
					tenantId: getTenantId(),

					sort: [$scope.RelationSearchOverview.predicate + ',' + ($scope.RelationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationList = result.items;

					$scope.relationStatusList = result.statusSearchOptions;

					$scope.relationTypeList = result.relationTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationSearchOverview, $scope.originalRelationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationSearchOverview = {};
			$rootScope.RelationSearchOverview.page = 1;
			$rootScope.RelationSearchOverview.predicate = 'id';
			$rootScope.RelationSearchOverview.ascending = false;

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);

			$scope.originalRelationSearchOverview = angular.copy($scope.RelationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationSearchOverview) || $rootScope.RelationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationSearchOverview = angular.copy($rootScope.RelationSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationSearchOverview.predicate === itemName) {
				if ($scope.RelationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
