/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtExternalCarDataListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ExternalCarDataFactory,
		StockMgmtExternalCarDataListDtoSearchFactory,
		AutocompleteStockMgmtCarsByQueryParamsFactory
	) {
		$scope.externalCarDataList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeExternalCarData)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeExternalCarData);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeExternalCarData = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-externalcardata.new');
		};

		var getCrmCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ExternalCarDataSearchOverview.crmCar)) {
				return $scope.ExternalCarDataSearchOverview.crmCar.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ExternalCarDataSearchOverview = angular.copy($scope.ExternalCarDataSearchOverview);

			$rootScope.pageSizeExternalCarData = angular.copy($scope.pageSize);

			StockMgmtExternalCarDataListDtoSearchFactory.query(
				{
					page: $scope.ExternalCarDataSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceList: $scope.ExternalCarDataSearchOverview.sourceList,
					messageTypeList: $scope.ExternalCarDataSearchOverview.messageTypeList,
					crmCarId: getCrmCarId(),

					sort: [$scope.ExternalCarDataSearchOverview.predicate + ',' + ($scope.ExternalCarDataSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.externalCarDataList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.externalMessageTypeList = result.messageTypeSearchOptions;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteStockMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isExternalCarDataSearchOverviewDirty = function() {
			return !angular.equals($scope.ExternalCarDataSearchOverview, $scope.originalExternalCarDataSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ExternalCarDataSearchOverview = {};
			$rootScope.ExternalCarDataSearchOverview.page = 1;
			$rootScope.ExternalCarDataSearchOverview.predicate = 'id';
			$rootScope.ExternalCarDataSearchOverview.ascending = false;

			$scope.ExternalCarDataSearchOverview = angular.copy($rootScope.ExternalCarDataSearchOverview);

			$scope.originalExternalCarDataSearchOverview = angular.copy($scope.ExternalCarDataSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ExternalCarDataSearchOverview) || $rootScope.ExternalCarDataSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ExternalCarDataSearchOverview = angular.copy($rootScope.ExternalCarDataSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ExternalCarDataSearchOverview.predicate === itemName) {
				if ($scope.ExternalCarDataSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateMessageTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
