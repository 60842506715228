(function() {
    'use strict';

    // we want jQuery as we want the .closest() function on an element which is not available in jqLite which is available from angular.element
    // var jQuery = $.noConflict();

    angular.module('mmmApp').controller('PdfImportController', PdfImportController);

    PdfImportController.$inject = [ '$scope', '$state', 'Upload', '$timeout', '$translate', 'TaskProgressService',
        'PdfImportGradeEditDtoFactory', 'PdfImportArticleEditDtoFactory', 'AlertService', 'CarConfigArticleListDtoSearchFactory', 'CarConfigArticleSubTypeEditDtoFactory' ];

    function PdfImportController( $scope, $state, Upload, $timeout, $translate, TaskProgressService, PdfImportGradeEditDtoFactory,PdfImportArticleEditDtoFactory,
                                 AlertService, CarConfigArticleListDtoSearchFactory, CarConfigArticleSubTypeEditDtoFactory) {

        $scope.oneAtATime = true;

        // form model variables
        $scope.selectedModelVersion = "";
        $scope.validFromDate = "";

        $scope.uploadPdf = function(file) {
            hideCandidatesPanel();

            $scope.showProgress = true;
            $scope.showData = false;

            // register a new progress task
            TaskProgressService.registerTask("process-pdf", $scope).then(
                function(result) {
                    file.upload = Upload.upload({
                        url : '/pdf/import',
                        data : {
                            file : file,
                            taskId : result.taskId,
                            selectedModelVersion : $scope.selectedModelVersion,
                            validFromDate: $scope.validFromDate
                        }
                    });

                    file.upload.then(function(response) {
                        $timeout(function() {
                            var data = file.result = response.data;

								$scope.groups = [];
                                _.forEach(_.groupBy(data.carConfigurations, 'group'), function(carConfigurations) {
                                    var group = {
                                        "index" : $scope.groups.length,
                                        "title" : $translate.instant('carConfiguration.menu.item.text') + " " + (carConfigurations[0].group || ""),
                                        "name" : "carConfigurations",
                                        "content" : carConfigurations
                                    };
                                    $scope.groups.push(group);
                                });
                                $scope.groups.push({
									"title" : $translate.instant('carEngine.menu.item.text'),
									"name" : "carEngines",
									"content" : wrapEditDtos(data.carEngines)
								});
                                $scope.groups.push({
									"title" : $translate.instant('carPowertrain.menu.item.text'),
									"name" : "carPowertrains",
									"content" : wrapEditDtos(data.carPowertrains)
								});
                                $scope.groups.push({
									"title" : $translate.instant('carTransmission.menu.item.text'),
									"name" : "carTransmissions",
									"content" : wrapEditDtos(data.carTransmissions)
								});
								_.forEach(data.carGrades, function(importgrade) {
								    var grade = importgrade.carConfigCarGradeEditDto;
									var group = {
										"importgrade" : importgrade,
										"index" : $scope.groups.length,
										"gradename" : grade.name,
										"title" : $translate.instant('global.menu.entities.carGrade') + " " + grade.name,
										"name" : "carGrades",
										"content" : getRelevantPortionOfEditDtos(importgrade.carOptions)
									};
									$scope.groups.push(group);
								});
								$scope.groups.push({
									"title" : $translate.instant('OptionOrigin.FACTORY') + " "
											+ $translate.instant('carOption.menu.item.text'),
									"name" : "carOptions-factory",
									"content" : data.options
								});
								$scope.groups.push({
									"title" : $translate.instant('OptionOrigin.DEALER') + " "
											+ $translate.instant('carOption.menu.item.text'),
									"name" : "carOptions-dealer",
									"content" : data.dealeroptions
								});
								$scope.showData = true;
							});

                        AlertService.success("pdfimport.messages.import.success", {"pdfFileName": $scope.pdfFile.name});
                        $timeout(function(){
                            $scope.showProgress = false;
                        }, 2000);
                    }).catch(function(error) {
                        AlertService.error(error.data.detail);
                        $timeout(function(){
                            $scope.showProgress = false;
                            $scope.pdfFile = undefined;
                        }, 2000);
                    }).finally(function(evt) {
                        // Math.min is to fix IE which reports 200% sometimes
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    });
                });
        };

        $scope.saveGrade = function(grade,groupindex) {
            // we need both selected modelversion and validfrom date in backend
            grade.selectedModelVersion = $scope.selectedModelVersion;
            grade.validFrom = $scope.validFromDate;

            PdfImportGradeEditDtoFactory.save(grade).$promise.then(function(result) {
                AlertService.success("pdfimport.messages.save.grade.success", {
                    generatedDescription : result.generatedDescription
                });

                hideCandidatesPanel();

                // update page info
                var grade = result.carConfigCarGradeEditDto;
                _.forEach($scope.groups, function(group) {
                    if (group.name === 'carGrades' && group.index === groupindex) {
                        console.log("grade found", grade.name, "groupindex", group.index);
                        group.title += " " + grade.description||"";
                        group.content = getRelevantPortionOfEditDtos(result.carOptions);
                    }
                });
            });
        };

        /**
         *
         * @param item object with structure like ImportCarConfigurationDto
         * @param groupname name of the group the article was in, used for looking up the item in the model
         */
        $scope.saveObject = function(item, groupname, $event) {
            console.log("saving the object: ", item.article.name);

            item['selectedModelVersion'] = $scope.selectedModelVersion;
            item['validFrom'] = $scope.validFromDate;

            PdfImportArticleEditDtoFactory.save(item).$promise.then(function(result) {
                var articlename = result.article.name;
                // FIXME find correct key, this resolves to plural e.g. 'Car Configurations', and sometimes no key at all. e.g. dealerOption
                var displayType = $translate.instant(result.article.type+'.menu.item.text');

                hideCandidatesPanel($event);

                AlertService.success("pdfimport.messages.save.success", {
                    type : displayType,
                    name : articlename
                });

                // update page info
                var group =_.find($scope.groups, {'name' : groupname});
                group.content[articlename]=result;
            });

        };

        $scope.canBeSaved = function(groupname) {
            switch (groupname) {
                case "carConfigurations":
                case "carEngines":
                case "carTransmissions":
                case "carPowertrains":
                case "carOptions-factory":
                case "carOptions-dealer":
                    return true;
                default:
                    return false;
            }
        };

        /**
         * Changes each editDto in list to only contain the fields needed
         * @param dtolist list with editDtos
         * @param extrafields
         * @returns {Array} dtolist with cleaned up editDtos
         */
        function getRelevantPortionOfEditDtos(dtolist, extrafields) {
            var fields = [ 'name', 'id', 'status' ];
            fields = fields.concat(extrafields||[]);
            return  _.map(dtolist, function(o) {
                // pick only the fields we're interesting in
                var result = _.pick(o, fields);
                // status is only useful when entity is persisted, i.e. has an id
                result.status = (result.id)?result.status:"";
                return result;
            });
        }
        /**
         * Wraps each editDto in list to match ImportCarConfigurationDto structure
         * @param dtolist list with editDtos
         * @returns {Object} dtolist with cleaned up editDtos
         */
        function wrapEditDtos(dtolist) {
            return  _.mapValues(dtolist, function(articleEditDto) {
                // status is only useful when entity is persisted, i.e. has an id
                articleEditDto.status = (articleEditDto.id)?articleEditDto.status:null;
                // wrap in ImportCarConfigurationDto-like object
                return {
                    article: articleEditDto
                };
            });
        }
		$scope.clearForm = function() {
			$scope.pdfFile = undefined;
			$scope.showProgress = false;
			$scope.showData = false;
			$scope.validFromDate = undefined;
			$scope.selectedModelVersion = undefined;
		};

        /*
         * ------------------------------------------ CANDIDATES LOGIC -------------------------------------------------
         */

        $scope.showCandidatesPanel = false;

        $scope.showCandidates = function(item, groupname, $event, groupIdx) {
            $scope.candidatesPanelData = $scope.candidatesPanelData||{};
            $scope.candidatesPanelData.description = item.name||item.article.name;
            $scope.candidatesPanelData.groupname = groupname;
            $scope.candidatesPanelData.importedPriceList = item.priceList;
            $scope.candidatesPanelData.type = item.type||item.article.type;
            $scope.candidatesPanelData.forIndex = groupIdx;

            // mark row as selected for candidate
            var buttonElm = jQuery($event.currentTarget);
            buttonElm.closest("table").find("tr").removeClass("info");
            buttonElm.closest("tr").addClass("info");

            console.log("Show candidates for description of type", $scope.candidatesPanelData.description, $scope.candidatesPanelData.type);

            var overviewElm = angular.element("#overview");
            overviewElm.addClass("col-sm-7");
            $scope.showCandidatesPanel = true;

            $scope.searchCandidates($scope.candidatesPanelData.description, $scope.candidatesPanelData.type);
        };

        /**
         * Search for candidates whose name matches the description. Finding by name uses word_similarity instead of ilike.
         * As the description from PDF is used to build the represented description on this page the represented description
         * is used to look for similarities in the backend.
         *
         * @param description A fabricated description based on PDF output and form input.
         * @param type The article type, written in camelcase. In backend this is expected as upper snake case.
         */
        $scope.searchCandidates = function(description, type) {
            console.log("Searching articles for description", description);
            CarConfigArticleListDtoSearchFactory.query({
                name: description,
                typeId: _.toUpper(_.snakeCase(type)),
                sort: ['name,asc']
            }, function(result) {
                $scope.candidatesList = result.items;
                console.log("Found articles for description", result.items.length);
            });
        };

        $scope.chooseCandidate = function(candidateId) {
            console.log("Candidate ID choosen:", candidateId);
            var endpointtype = _.toLower($scope.candidatesPanelData.type);
            // construct an item with possible price, retrieve the edit variant of the candidate (which is list item) by its id
            var item = ($scope.candidatesPanelData.importedPriceList)?{priceList : $scope.candidatesPanelData.importedPriceList}:{};
            CarConfigArticleSubTypeEditDtoFactory.get({id : candidateId, type: endpointtype}).$promise.then(function(result){
                // populate item's article type object with the correct retrieved edit dto
                item.article = result;
                // send the item to the save object method where it will be enriched and saved or updated
                $scope.saveObject(item, $scope.candidatesPanelData.groupname);

                var trElm = jQuery("#" + $scope.candidatesPanelData.groupname + "-" + $scope.candidatesPanelData.forIndex);
                trElm.find("button").prop( "disabled", true );
                trElm.removeClass("info");
                trElm.addClass("warning");
            });
        };

        function hideCandidatesPanel($event) {
            var overviewElm = angular.element("#overview");

            if ($event) {
                // if click event is available, clear all TR classes
                var buttonElm = $event.currentTarget;
                jQuery(buttonElm).closest("table").find("tr").removeClass("info");
            }

            $timeout(function(){
                delete $scope.candidatesPanelData;
                $scope.showCandidatesPanel = false;
                overviewElm.removeClass("col-sm-7");
            }, 1000);
        }

        // TODO this method is currently not called as a state.go to article detail from the current state is not allowed
        $scope.goToDetails = function(item) {
            var id = (item.id||item.article.id);
            var type = item.type||item.article.type;

            // var stateName = "car-config-[type].detail({[typeId]:[id]})";
            var stateName = "pdfimport.car-config-[type]-detail({[typeId]:[id]})";

            stateName = stateName.replace("[type]",_.toLower(type));
            stateName = stateName.replace("[typeId]", type+"Id");
            stateName = stateName.replace("[id]", id);

            console.log("Current state:", $state.current.name);
            console.log("Navigate to detail state", stateName);

            $state.go(stateName);
        };
	}

})();
