/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('DealerImageDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.dealerImage = entity;
	$scope.objectName = 'DealerImage';
	$scope.dealerImageId = $stateParams.dealerImageId;

	if (angular.isUndefined($rootScope.dealerImageActiveTab)) {
		//set the default here
		$rootScope.dealerImageActiveTab = 0;
	}

	$scope.goToEditState = function(dealerImage) {
		$state.go('dealerimage.detail.edit({dealerImageId:dealerImage.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:dealerImageUpdate', function(event, result) {
		$scope.dealerImage = result;
	});
});
