/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationDependentPriceListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationDependentPriceFactory,
		CarConfigCarConfigurationDependentPriceListDtoSearchFactory,
		CarConfigCarConfigurationDependentPriceViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.carConfigurationDependentPriceList = [];
		$scope.selectedCarConfigurationDependentPriceList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfigurationDependentPrice)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfigurationDependentPrice);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfigurationDependentPrice = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carconfigurationdependentprice.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationDependentPriceSearchOverview.brand)) {
				return $scope.CarConfigurationDependentPriceSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationDependentPriceSearchOverview.article)) {
				return $scope.CarConfigurationDependentPriceSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.CarConfigurationDependentPriceSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.CarConfigurationDependentPriceSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.CarConfigurationDependentPriceSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.CarConfigurationDependentPriceSearchOverview.validFromFrom
				);
			} else {
				$scope.CarConfigurationDependentPriceSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.CarConfigurationDependentPriceSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.CarConfigurationDependentPriceSearchOverview.validFromTo
				);
			} else {
				$scope.CarConfigurationDependentPriceSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.CarConfigurationDependentPriceSearchOverview = angular.copy($scope.CarConfigurationDependentPriceSearchOverview);

			$rootScope.pageSizeCarConfigurationDependentPrice = angular.copy($scope.pageSize);

			CarConfigCarConfigurationDependentPriceListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationDependentPriceSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.CarConfigurationDependentPriceSearchOverview.validFromFromString,
					validFromTo: $scope.CarConfigurationDependentPriceSearchOverview.validFromToString,
					priceFrom: $scope.CarConfigurationDependentPriceSearchOverview.priceFrom,
					priceTo: $scope.CarConfigurationDependentPriceSearchOverview.priceTo,
					bpmFrom: $scope.CarConfigurationDependentPriceSearchOverview.bpmFrom,
					bpmTo: $scope.CarConfigurationDependentPriceSearchOverview.bpmTo,
					cataloguePriceExVatFrom: $scope.CarConfigurationDependentPriceSearchOverview.cataloguePriceExVatFrom,
					cataloguePriceExVatTo: $scope.CarConfigurationDependentPriceSearchOverview.cataloguePriceExVatTo,
					co2From: $scope.CarConfigurationDependentPriceSearchOverview.co2From,
					co2To: $scope.CarConfigurationDependentPriceSearchOverview.co2To,
					brandId: getBrandId(),
					articleId: getArticleId(),
					carConfigurationIdList: $scope.CarConfigurationDependentPriceSearchOverview.carConfigurationIdList,

					sort: [
						$scope.CarConfigurationDependentPriceSearchOverview.predicate +
							',' +
							($scope.CarConfigurationDependentPriceSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationDependentPriceList = result.items;

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationDependentPriceSearchOverviewDirty = function() {
			return !angular.equals(
				$scope.CarConfigurationDependentPriceSearchOverview,
				$scope.originalCarConfigurationDependentPriceSearchOverview
			);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationDependentPriceSearchOverview = {};
			$rootScope.CarConfigurationDependentPriceSearchOverview.page = 1;
			$rootScope.CarConfigurationDependentPriceSearchOverview.predicate = 'id';
			$rootScope.CarConfigurationDependentPriceSearchOverview.ascending = false;

			$scope.CarConfigurationDependentPriceSearchOverview = angular.copy($rootScope.CarConfigurationDependentPriceSearchOverview);

			$scope.originalCarConfigurationDependentPriceSearchOverview = angular.copy($scope.CarConfigurationDependentPriceSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.CarConfigurationDependentPriceSearchOverview) ||
				$rootScope.CarConfigurationDependentPriceSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationDependentPriceSearchOverview = angular.copy($rootScope.CarConfigurationDependentPriceSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarConfigurationDependentPriceId = id;
			$('#deleteCarConfigurationDependentPriceConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarConfigurationDependentPriceViewDtoFactory.delete(
				{ id: $scope.selectedCarConfigurationDependentPriceId },
				function() {
					$scope.search();
					$('#deleteCarConfigurationDependentPriceConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarConfigurationDependentPriceConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleCarConfigurationDependentPriceConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedCarConfigurationDependentPriceList, function(value, key) {
				promises.push(CarConfigCarConfigurationDependentPriceViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleCarConfigurationDependentPriceConfirmation').modal('hide');
				});
		};
		$scope.multipleUpdate = function() {
			$state.go('car-config-carconfigurationdependentprice.bulkedit', {
				selectedCarConfigurationDependentPriceList: $scope.selectedCarConfigurationDependentPriceList,
			});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.carConfigurationDependentPriceList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedCarConfigurationDependentPriceList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedCarConfigurationDependentPriceList = [];

			angular.forEach($scope.carConfigurationDependentPriceList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedCarConfigurationDependentPriceList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationDependentPriceSearchOverview.predicate === itemName) {
				if ($scope.CarConfigurationDependentPriceSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};
	});
