'use strict';

angular
		.module('mmmApp')
		.controller(
				'TradeInOrderLineDetailController',
				function($scope, $rootScope, $stateParams, $state, entity, $location) {
					$scope.tradeInOrderLine = entity;
					$scope.objectName = "TradeInOrderLine";
					$scope.tradeInOrderLineId = $stateParams.tradeInOrderLineId;

					if (angular.isUndefined($rootScope.tradeInOrderLineActiveTab)) {
						//set the default here
						$rootScope.tradeInOrderLineActiveTab = 0;
					}

					$scope.goToEditState = function(tradeInOrderLine) {
						$state
								.go('tradeinorderline.detail.edit({tradeInOrderLineId:tradeInOrderLine.id, mappedBy:mappedBy, mappedById:mappedById})');
					};

					// by broadcasting this event from an edit dialog the model is refreshed
					$rootScope.$on('mmmApp:tradeInOrderLineUpdate', function(event, result) {
						$scope.tradeInOrderLine = result;
					});

				});
