/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('MappingTransmissionDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.mappingTransmission = entity;
		$scope.objectName = 'MappingTransmission';
		$scope.mappingTransmissionId = $stateParams.mappingTransmissionId;

		if (angular.isUndefined($rootScope.mappingTransmissionActiveTab)) {
			//set the default here
			$rootScope.mappingTransmissionActiveTab = 0;
		}

		$scope.goToEditState = function(mappingTransmission) {
			$state.go(
				'mappingtransmission.detail.edit({mappingTransmissionId:mappingTransmission.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:mappingTransmissionUpdate', function(event, result) {
			$scope.mappingTransmission = result;
		});
	});
