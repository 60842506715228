/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('EmailFactory', function($resource) {
	return $resource(
		'api/emails/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtEmailViewDto
 */
angular.module('mmmApp').factory('RelationMgmtEmailViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/email/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtEmailListDto's
 */
angular.module('mmmApp').factory('RelationMgmtEmailListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/email',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtEmailEditDto
 */
angular.module('mmmApp').factory('RelationMgmtEmailEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/email/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtEmailEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtEmailNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/email/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewEmailFactory', function($resource) {
	return $resource(
		'api/emails/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Emails without the use of paging
angular.module('mmmApp').factory('NoPagingEmailFactory', function($resource) {
	return $resource(
		'api/emails/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible EmailTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingEmailTypeFactory', function($resource) {
	return $resource(
		'api/email/emailTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllEmailsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/emails/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
