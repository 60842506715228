/** @format */

'use strict';

// Equivalent has edit layout enabled. Edit layout template url: equivalent-dialog.html
// Equivalent has detail layout enabled. Detail layout template url: equivalent-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Equivalent'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('equivalent'); // main translation loaded for equivalent
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-equivalent', {
		/* abstract state for DTO CarConfigEquivalentListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/equivalent',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Equivalent',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigEquivalentListDto */
		/* to access: ui-ref="car-config-equivalent.list" */
		/* url: http://<hostname>/car/config/equivalent/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/equivalent/equivalent-list-car-config.html',
				controller: 'CarConfigEquivalentListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-equivalent.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigEquivalentViewDto */
		/* to access: ui-sref="car-config-equivalent.detail" */
		/* url: http://<hostname>/car/config/equivalent/detail/:equivalentId */
		url: '/detail/:equivalentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/equivalent/equivalent-view-car-config.html',
				controller: 'EquivalentDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigEquivalentViewDtoFactory',
				function($stateParams, CarConfigEquivalentViewDtoFactory) {
					return CarConfigEquivalentViewDtoFactory.get({ id: $stateParams.equivalentId });
				},
			],
		},
	};

	$stateProvider.state('car-config-equivalent.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigEquivalentEditDto */
		/* to access: ui-sref="car-config-equivalent.edit" */
		/* url: http://<hostname>/car/config/equivalent/edit/:equivalentId */
		url: '/edit/:equivalentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/equivalent/equivalent-edit-car-config.html',
				controller: 'CarConfigEquivalentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigEquivalentEditDtoFactory',
				function($stateParams, $rootScope, CarConfigEquivalentEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigEquivalentEditDtoFactory.get({ id: $stateParams.equivalentId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-equivalent.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigEquivalentEditDto */
		/* to access: ui-sref="car-config-equivalent.new" */
		/* url: http://<hostname>/car/config/equivalent/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/equivalent/equivalent-edit-car-config.html',
				controller: 'CarConfigEquivalentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigEquivalentNewDtoFactory',
				function($stateParams, $rootScope, CarConfigEquivalentNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigEquivalentNewDtoFactory.get({ id: $stateParams.equivalentId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-equivalent.new', dtoCreateState);
});
