/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('FilterOptionDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.filterOption = entity;
	$scope.objectName = 'FilterOption';
	$scope.filterOptionId = $stateParams.filterOptionId;

	if (angular.isUndefined($rootScope.filterOptionActiveTab)) {
		//set the default here
		$rootScope.filterOptionActiveTab = 0;
	}

	$scope.goToEditState = function(filterOption) {
		$state.go('filteroption.detail.edit({filterOptionId:filterOption.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:filterOptionUpdate', function(event, result) {
		$scope.filterOption = result;
	});
});
