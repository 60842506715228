/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtSubTaskTemplateListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SubTaskTemplateFactory,
		RelationMgmtSubTaskTemplateListDtoSearchFactory,
		RelationMgmtSubTaskTemplateViewDtoFactory
	) {
		$scope.subTaskTemplateList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSubTaskTemplate)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSubTaskTemplate);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSubTaskTemplate = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-subtasktemplate.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubTaskTemplateSearchOverview.tenant)) {
				return $scope.SubTaskTemplateSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getTaskTemplateId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SubTaskTemplateSearchOverview.taskTemplate)) {
				return $scope.SubTaskTemplateSearchOverview.taskTemplate.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.SubTaskTemplateSearchOverview = angular.copy($scope.SubTaskTemplateSearchOverview);

			$rootScope.pageSizeSubTaskTemplate = angular.copy($scope.pageSize);

			RelationMgmtSubTaskTemplateListDtoSearchFactory.query(
				{
					page: $scope.SubTaskTemplateSearchOverview.page - 1,
					size: $scope.pageSize,
					sortOrderFrom: $scope.SubTaskTemplateSearchOverview.sortOrderFrom,
					sortOrderTo: $scope.SubTaskTemplateSearchOverview.sortOrderTo,
					title: $scope.SubTaskTemplateSearchOverview.title,
					description: $scope.SubTaskTemplateSearchOverview.description,
					tenantId: getTenantId(),
					taskTemplateId: getTaskTemplateId(),

					sort: [$scope.SubTaskTemplateSearchOverview.predicate + ',' + ($scope.SubTaskTemplateSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.subTaskTemplateList = result.items;

					$scope.taskTemplateReferenceFilterList = result.taskTemplateSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSubTaskTemplateSearchOverviewDirty = function() {
			return !angular.equals($scope.SubTaskTemplateSearchOverview, $scope.originalSubTaskTemplateSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SubTaskTemplateSearchOverview = {};
			$rootScope.SubTaskTemplateSearchOverview.page = 1;
			$rootScope.SubTaskTemplateSearchOverview.predicate = 'id';
			$rootScope.SubTaskTemplateSearchOverview.ascending = false;

			$scope.SubTaskTemplateSearchOverview = angular.copy($rootScope.SubTaskTemplateSearchOverview);

			$scope.originalSubTaskTemplateSearchOverview = angular.copy($scope.SubTaskTemplateSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SubTaskTemplateSearchOverview) || $rootScope.SubTaskTemplateSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SubTaskTemplateSearchOverview = angular.copy($rootScope.SubTaskTemplateSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSubTaskTemplateId = id;
			$('#deleteSubTaskTemplateConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtSubTaskTemplateViewDtoFactory.delete(
				{ id: $scope.selectedSubTaskTemplateId },
				function() {
					$scope.search();
					$('#deleteSubTaskTemplateConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSubTaskTemplateConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SubTaskTemplateSearchOverview.predicate === itemName) {
				if ($scope.SubTaskTemplateSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
