/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('HexonPublishRuleDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.hexonPublishRule = entity;
		$scope.objectName = 'HexonPublishRule';
		$scope.hexonPublishRuleId = $stateParams.hexonPublishRuleId;

		if (angular.isUndefined($rootScope.hexonPublishRuleActiveTab)) {
			//set the default here
			$rootScope.hexonPublishRuleActiveTab = 0;
		}

		$scope.goToEditState = function(hexonPublishRule) {
			$state.go('hexonpublishrule.detail.edit({hexonPublishRuleId:hexonPublishRule.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:hexonPublishRuleUpdate', function(event, result) {
			$scope.hexonPublishRule = result;
		});
	});
