'use strict';

angular
		.module('mmmApp')
		.controller(
				'CarGradeDependentPriceDetailController',
				function($scope, $rootScope, $stateParams, $state, entity, $location) {
					$scope.carGradeDependentPrice = entity;
					$scope.objectName = "CarGradeDependentPrice";
					$scope.carGradeDependentPriceId = $stateParams.carGradeDependentPriceId;

					if (angular.isUndefined($rootScope.carGradeDependentPriceActiveTab)) {
						//set the default here
						$rootScope.carGradeDependentPriceActiveTab = 0;
					}

					$scope.goToEditState = function(carGradeDependentPrice) {
						$state
								.go('cargradedependentprice.detail.edit({carGradeDependentPriceId:carGradeDependentPrice.id, mappedBy:mappedBy, mappedById:mappedById})');
					};

					// by broadcasting this event from an edit dialog the model is refreshed
					$rootScope.$on('mmmApp:carGradeDependentPriceUpdate', function(event, result) {
						$scope.carGradeDependentPrice = result;
					});

				});
