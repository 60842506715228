/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('UserFactory', function($resource) {
	return $resource(
		'api/users/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of AdminUserViewDto
 */
angular.module('mmmApp').factory('AdminUserViewDtoFactory', function($resource) {
	return $resource(
		'api/admin/user/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of AdminUserListDto's
 */
angular.module('mmmApp').factory('AdminUserListDtoSearchFactory', function($resource) {
	return $resource(
		'api/admin/user',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of AdminUserEditDto
 */
angular.module('mmmApp').factory('AdminUserEditDtoFactory', function($resource) {
	return $resource(
		'api/admin/user/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of AdminUserEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('AdminUserNewDtoFactory', function($resource) {
	return $resource(
		'api/admin/user/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewUserFactory', function($resource) {
	return $resource(
		'api/users/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Users without the use of paging
angular.module('mmmApp').factory('NoPagingUserFactory', function($resource) {
	return $resource(
		'api/users/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/user/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('AuthoritiesByUserFactory', function($resource) {
	return $resource(
		'api/authorities/user/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllUsersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/users/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
