/** @format */

'use strict';

// Section has edit layout enabled. Edit layout template url: section-dialog.html
// Section has detail layout enabled. Detail layout template url: section-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Section'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('section'); // main translation loaded for section
		$translatePartialLoader.addPart('sectionstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('person'); // main translation loaded for parent object Person of reference accountManager
		$translatePartialLoader.addPart('relation'); // main translation loaded for parent object Relation of reference accountManager
		$translatePartialLoader.addPart('employee'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('sectiondocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('salesorder'); // one to many reference from main entity
		$translatePartialLoader.addPart('note'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-section', {
		/* abstract state for DTO RelationMgmtSectionListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/section',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Section',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtSectionListDto */
		/* to access: ui-ref="relation-mgmt-section.list" */
		/* url: http://<hostname>/relationmgmt/section/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/section/section-list-relation-mgmt.html',
				controller: 'RelationMgmtSectionListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-section.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtSectionViewDto */
		/* to access: ui-sref="relation-mgmt-section.detail" */
		/* url: http://<hostname>/relationmgmt/section/detail/:sectionId */
		url: '/detail/:sectionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/section/section-view-relation-mgmt.html',
				controller: 'SectionDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtSectionViewDtoFactory',
				function($stateParams, RelationMgmtSectionViewDtoFactory) {
					return RelationMgmtSectionViewDtoFactory.get({ id: $stateParams.sectionId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-section.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtSectionEditDto */
		/* to access: ui-sref="relation-mgmt-section.edit" */
		/* url: http://<hostname>/relationmgmt/section/edit/:sectionId */
		url: '/edit/:sectionId',
		views: {
			'content@': {
				templateUrl: 'app/entities/section/section-edit-relation-mgmt.html',
				controller: 'RelationMgmtSectionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtSectionEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtSectionEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtSectionEditDtoFactory.get({ id: $stateParams.sectionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-section.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtSectionEditDto */
		/* to access: ui-sref="relation-mgmt-section.new" */
		/* url: http://<hostname>/relationmgmt/section/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/section/section-edit-relation-mgmt.html',
				controller: 'RelationMgmtSectionEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtSectionNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtSectionNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtSectionNewDtoFactory.get({ id: $stateParams.sectionId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-section.new', dtoCreateState);
});
