/** @format */

'use strict';

// Document has edit layout enabled. Edit layout template url: document-dialog.html
// Document has detail layout enabled. Detail layout template url: document-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Document'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('document'); // main translation loaded for document
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('relationdocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('sectiondocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcardocument'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcarmediadocument'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-document', {
		/* abstract state for DTO StockMgmtDocumentListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/document',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'Document',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtDocumentListDto */
		/* to access: ui-ref="stock-mgmt-document.list" */
		/* url: http://<hostname>/stockmgmt/document/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/document/document-list-stock-mgmt.html',
				controller: 'StockMgmtDocumentListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-document.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtDocumentViewDto */
		/* to access: ui-sref="stock-mgmt-document.detail" */
		/* url: http://<hostname>/stockmgmt/document/detail/:documentId */
		url: '/detail/:documentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/document/document-view-stock-mgmt.html',
				controller: 'DocumentDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtDocumentViewDtoFactory',
				function($stateParams, StockMgmtDocumentViewDtoFactory) {
					return StockMgmtDocumentViewDtoFactory.get({ id: $stateParams.documentId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-document.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtDocumentEditDto */
		/* to access: ui-sref="stock-mgmt-document.edit" */
		/* url: http://<hostname>/stockmgmt/document/edit/:documentId */
		url: '/edit/:documentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/document/document-edit-stock-mgmt.html',
				controller: 'StockMgmtDocumentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtDocumentEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtDocumentEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtDocumentEditDtoFactory.get({ id: $stateParams.documentId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-document.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtDocumentEditDto */
		/* to access: ui-sref="stock-mgmt-document.new" */
		/* url: http://<hostname>/stockmgmt/document/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/document/document-edit-stock-mgmt.html',
				controller: 'StockMgmtDocumentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtDocumentNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtDocumentNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtDocumentNewDtoFactory.get({ id: $stateParams.documentId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-document.new', dtoCreateState);

	// create state for plus button on CrmCarMediaDocument edit / new page for reference Document and dto property value stock.mgmt
	$stateProvider.state('stock-mgmt-crmcarmediadocument.new.newDocument', getCreateState('/document/new'));
	$stateProvider.state('stock-mgmt-crmcarmediadocument.edit.newDocument', getCreateState('/document/new'));
	// create state for plus button on RelationDocument edit / new page for reference Document and dto property value stock.mgmt
	$stateProvider.state('stock-mgmt-relationdocument.new.newDocument', getCreateState('/document/new'));
	$stateProvider.state('stock-mgmt-relationdocument.edit.newDocument', getCreateState('/document/new'));
	// create state for plus button on SectionDocument edit / new page for reference Document and dto property value stock.mgmt
	$stateProvider.state('stock-mgmt-sectiondocument.new.newDocument', getCreateState('/document/new'));
	$stateProvider.state('stock-mgmt-sectiondocument.edit.newDocument', getCreateState('/document/new'));
	// create state for plus button on CrmCarDocument edit / new page for reference Document and dto property value stock.mgmt
	$stateProvider.state('stock-mgmt-crmcardocument.new.newDocument', getCreateState('/document/new'));
	$stateProvider.state('stock-mgmt-crmcardocument.edit.newDocument', getCreateState('/document/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/document/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
