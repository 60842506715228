/** @format */

'use strict';

// CarStock has edit layout enabled. Edit layout template url: carstock-dialog.html
// CarStock has detail layout enabled. Detail layout template url: carstock-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarStock'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carstock'); // main translation loaded for carstock
		$translatePartialLoader.addPart('carstockpricestatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('stockstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstocktype'); // one to many reference from main entity
		$translatePartialLoader.addPart('sellingstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('pipelinestatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('transferstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('publishsourcetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstockorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstockdiscountorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesourcestatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesource'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricesource'); // one to many reference from main entity
		$translatePartialLoader.addPart('discountsource'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealerlocation'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
		$translatePartialLoader.addPart('guarantee'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbranddealer'); // one to many reference from main entity
		$translatePartialLoader.addPart('consignment'); // one to many reference from main entity
		$translatePartialLoader.addPart('genericvehiclepriceinformation'); // one to many reference from main entity
		// custom translations added by snippets/custom/jhipster/controller/overview/additional-translations.ftl
		$translatePartialLoader.addPart('excelimport'); // translations for import dialog
		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-carstock', {
		/* abstract state for DTO StockMgmtCarStockListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/carstock',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'CarStock',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtCarStockListDto */
		/* to access: ui-ref="stock-mgmt-carstock.list" */
		/* url: http://<hostname>/stockmgmt/carstock/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-list-stock-mgmt.html',
				controller: 'StockMgmtCarStockListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-carstock.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtCarStockViewDto */
		/* to access: ui-sref="stock-mgmt-carstock.detail" */
		/* url: http://<hostname>/stockmgmt/carstock/detail/:carStockId */
		url: '/detail/:carStockId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-view-stock-mgmt.html',
				controller: 'CarStockDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtCarStockViewDtoFactory',
				function($stateParams, StockMgmtCarStockViewDtoFactory) {
					return StockMgmtCarStockViewDtoFactory.get({ id: $stateParams.carStockId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carstock.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtCarStockEditDto */
		/* to access: ui-sref="stock-mgmt-carstock.edit" */
		/* url: http://<hostname>/stockmgmt/carstock/edit/:carStockId */
		url: '/edit/:carStockId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-edit-stock-mgmt.html',
				controller: 'StockMgmtCarStockEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarStockEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarStockEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarStockEditDtoFactory.get({ id: $stateParams.carStockId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carstock.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtCarStockEditDto */
		/* to access: ui-sref="stock-mgmt-carstock.new" */
		/* url: http://<hostname>/stockmgmt/carstock/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-edit-stock-mgmt.html',
				controller: 'StockMgmtCarStockEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarStockNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarStockNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtCarStockNewDtoFactory.get({ id: $stateParams.carStockId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carstock.new', dtoCreateState);

	/* Bulk edit state for DTO stock.mgmt */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO StockMgmtCarStockEditDto */
		/* to access: ui-sref="stock-mgmt-carstock.bulkedit" */
		/* url: http://<hostname>/stockmgmt/carstock/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-bulkedit-stock-mgmt.html',
				controller: 'StockMgmtCarStockBulkEditDtoController',
			},
		},
		params: {
			selectedCarStockList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtCarStockNewBulkEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtCarStockNewBulkEditDtoFactory) {
					return StockMgmtCarStockNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return _.merge(result, { type: 'StockMgmtCarStockBulkEditDto' });
					});
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-carstock.bulkedit', dtoBulkEditState);

	$stateProvider.state('relation-mgmt-carstock', {
		/* abstract state for DTO RelationMgmtCarStockListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/carstock',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'CarStock',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtCarStockListDto */
		/* to access: ui-ref="relation-mgmt-carstock.list" */
		/* url: http://<hostname>/relationmgmt/carstock/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-list-relation-mgmt.html',
				controller: 'RelationMgmtCarStockListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-carstock.list', dtoListState);

	$stateProvider.state('website-api-carstock', {
		/* abstract state for DTO WebsiteApiCarStockListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/carstock',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarStock',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO website.api */
	var dtoListState = {
		/* list state for DTO WebsiteApiCarStockListDto */
		/* to access: ui-ref="website-api-carstock.list" */
		/* url: http://<hostname>/websiteapi/carstock/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-list-website-api.html',
				controller: 'WebsiteApiCarStockListDtoController',
			},
		},
	};

	$stateProvider.state('website-api-carstock.list', dtoListState);

	$stateProvider.state('vehicle-api-carstock', {
		/* abstract state for DTO VehicleApiCarStockListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/vehicleapi/carstock',
		data: {
			authorities: ['ROLE_VEHICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarStock',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO vehicle.api */
	var dtoEditState = {
		/* edit state for DTO VehicleApiCarStockEditDto */
		/* to access: ui-sref="vehicle-api-carstock.edit" */
		/* url: http://<hostname>/vehicleapi/carstock/edit/:carStockId */
		url: '/edit/:carStockId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-edit-vehicle-api.html',
				controller: 'VehicleApiCarStockEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'VehicleApiCarStockEditDtoFactory',
				function($stateParams, $rootScope, VehicleApiCarStockEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return VehicleApiCarStockEditDtoFactory.get({ id: $stateParams.carStockId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('vehicle-api-carstock.edit', dtoEditState);

	/* new (create) state for DTO vehicle.api */
	var dtoCreateState = {
		/* new state for DTO VehicleApiCarStockEditDto */
		/* to access: ui-sref="vehicle-api-carstock.new" */
		/* url: http://<hostname>/vehicleapi/carstock/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-edit-vehicle-api.html',
				controller: 'VehicleApiCarStockEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'VehicleApiCarStockNewDtoFactory',
				function($stateParams, $rootScope, VehicleApiCarStockNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return VehicleApiCarStockNewDtoFactory.get({ id: $stateParams.carStockId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('vehicle-api-carstock.new', dtoCreateState);

	/* Bulk edit state for DTO vehicle.api */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO VehicleApiCarStockEditDto */
		/* to access: ui-sref="vehicle-api-carstock.bulkedit" */
		/* url: http://<hostname>/vehicleapi/carstock/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/carstock/carstock-bulkedit-vehicle-api.html',
				controller: 'VehicleApiCarStockBulkEditDtoController',
			},
		},
		params: {
			selectedCarStockList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'VehicleApiCarStockNewBulkEditDtoFactory',
				function($stateParams, $rootScope, VehicleApiCarStockNewBulkEditDtoFactory) {
					return VehicleApiCarStockNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return _.merge(result, { type: 'VehicleApiCarStockBulkEditDto' });
					});
				},
			],
		},
	};

	$stateProvider.state('vehicle-api-carstock.bulkedit', dtoBulkEditState);
});
