/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingCarBrandListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingCarBrandFactory,
		StockMgmtMappingCarBrandListDtoSearchFactory,
		StockMgmtMappingCarBrandViewDtoFactory
	) {
		$scope.mappingCarBrandList = [];
		$scope.selectedMappingCarBrandList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingCarBrand)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingCarBrand);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingCarBrand = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingcarbrand.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingCarBrandSearchOverview = angular.copy($scope.MappingCarBrandSearchOverview);

			$rootScope.pageSizeMappingCarBrand = angular.copy($scope.pageSize);

			StockMgmtMappingCarBrandListDtoSearchFactory.query(
				{
					page: $scope.MappingCarBrandSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingCarBrandSearchOverview.sourceKey,
					sourceField: $scope.MappingCarBrandSearchOverview.sourceField,
					sourceList: $scope.MappingCarBrandSearchOverview.sourceList,
					carBrandIdList: $scope.MappingCarBrandSearchOverview.carBrandIdList,

					sort: [$scope.MappingCarBrandSearchOverview.predicate + ',' + ($scope.MappingCarBrandSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingCarBrandList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.carBrandReferenceFilterList = result.carBrandSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingCarBrandSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingCarBrandSearchOverview, $scope.originalMappingCarBrandSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingCarBrandSearchOverview = {};
			$rootScope.MappingCarBrandSearchOverview.page = 1;
			$rootScope.MappingCarBrandSearchOverview.predicate = 'id';
			$rootScope.MappingCarBrandSearchOverview.ascending = false;

			$scope.MappingCarBrandSearchOverview = angular.copy($rootScope.MappingCarBrandSearchOverview);

			$scope.originalMappingCarBrandSearchOverview = angular.copy($scope.MappingCarBrandSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingCarBrandSearchOverview) || $rootScope.MappingCarBrandSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingCarBrandSearchOverview = angular.copy($rootScope.MappingCarBrandSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingCarBrandId = id;
			$('#deleteMappingCarBrandConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingCarBrandViewDtoFactory.delete(
				{ id: $scope.selectedMappingCarBrandId },
				function() {
					$scope.search();
					$('#deleteMappingCarBrandConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteMappingCarBrandConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingCarBrandConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingCarBrandList, function(value, key) {
				promises.push(StockMgmtMappingCarBrandViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingCarBrandConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingCarBrandList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingCarBrandList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingCarBrandList = [];

			angular.forEach($scope.mappingCarBrandList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingCarBrandList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingCarBrandUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingCarBrandSearchOverview.predicate === itemName) {
				if ($scope.MappingCarBrandSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
