/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('SalesOrderFactory', function($resource) {
	return $resource(
		'api/salesOrders/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtSalesOrderViewDto
 */
angular.module('mmmApp').factory('RelationMgmtSalesOrderViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/salesorder/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtSalesOrderListDto's
 */
angular.module('mmmApp').factory('RelationMgmtSalesOrderListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/salesorder',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtSalesOrderEditDto
 */
angular.module('mmmApp').factory('RelationMgmtSalesOrderEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/salesorder/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtSalesOrderEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtSalesOrderNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/salesorder/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewSalesOrderFactory', function($resource) {
	return $resource(
		'api/salesOrders/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all SalesOrders without the use of paging
angular.module('mmmApp').factory('NoPagingSalesOrderFactory', function($resource) {
	return $resource(
		'api/salesOrders/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible OrderStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingOrderStatusFactory', function($resource) {
	return $resource(
		'api/salesOrder/orderStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible FinanceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingFinanceTypeFactory', function($resource) {
	return $resource(
		'api/salesOrder/financeTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('OrderLineGroupsBySalesOrderFactory', function($resource) {
	return $resource(
		'api/orderLineGroups/salesOrder/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationSalesOrderRolesBySalesOrderFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRoles/salesOrder/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllSalesOrdersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/salesOrders/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteSalesOrdersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/salesOrders/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtSalesOrdersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/salesorders',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
