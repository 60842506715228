'use strict';

angular.module('mmmApp').controller(
		'StockMgmtUserCarStockAccessBoundBulkEditDtoController',
		function($scope, $rootScope, $timeout, $state, $stateParams, $parse, _, entity, StockMgmtUserCarStockAccessBoundBulkEditDtoFactory,
				AlertService) {

			$scope.selectedUserCarStockAccessBoundIds = _.map($stateParams.selectedUserCarStockAccessBoundList, 'id');
			$scope.selectedItemsAmount = $scope.selectedUserCarStockAccessBoundIds.length;
			$scope.userCarStockAccessBound = entity;
			$scope.submitButtonIsActive = true;

			var onSaveFinishedCallback = function(result) {
				$scope.$emit('mmmApp:userCarStockAccessBoundUpdate', result);
				$scope.submitButtonIsActive = true;
				$rootScope.back();
			};

			var saveOrUpdateErrorCallback = function(error) {
				AlertService.error(error.data);
				$scope.updatingItemsInProgress = false;
			};

			$scope.save = function() {
				$scope.updatingItemsInProgress = true;
				StockMgmtUserCarStockAccessBoundBulkEditDtoFactory.update({
					'userCarStockAccessBoundIds' : $scope.selectedUserCarStockAccessBoundIds
				}, $scope.userCarStockAccessBound, onSaveFinishedCallback, saveOrUpdateErrorCallback);
			};

			// called from cancel button in dialog
			$scope.clear = function() {
				$rootScope.back();
			};

		});
