/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ArticlePriceFactory', function($resource) {
	return $resource(
		'api/articlePrices/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigArticlePriceViewDto
 */
angular.module('mmmApp').factory('CarConfigArticlePriceViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articleprice/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigArticlePriceListDto's
 */
angular.module('mmmApp').factory('CarConfigArticlePriceListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/articleprice',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigArticlePriceEditDto
 */
angular.module('mmmApp').factory('CarConfigArticlePriceEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articleprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigArticlePriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigArticlePriceNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/articleprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiArticlePriceEditDto
 */
angular.module('mmmApp').factory('ArticleApiArticlePriceEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/articleprice/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiArticlePriceEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiArticlePriceNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/articleprice/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewArticlePriceFactory', function($resource) {
	return $resource(
		'api/articlePrices/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ArticlePrices without the use of paging
angular.module('mmmApp').factory('NoPagingArticlePriceFactory', function($resource) {
	return $resource(
		'api/articlePrices/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllArticlePricesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/articlePrices/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
