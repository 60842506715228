// CodeComposer:ignore : Stopped generating because of AMI-11600: Adding 2 indirect columns to angularjs list
/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCrmCarOptionListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarOptionFactory,
		StockMgmtCrmCarOptionListDtoSearchFactory,
		StockMgmtCrmCarOptionViewDtoFactory
	) {
		$scope.crmCarOptionList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.page = 1;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCarOption)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCarOption);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCarOption = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-crmcaroption.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getCrmCarOptionList = function() {
			StockMgmtCrmCarOptionListDtoSearchFactory.query(
				{
					crmCarId: angular.isDefined($scope.crmCarId) ? $scope.crmCarId : null,
					carOptionId: angular.isDefined($scope.carOptionId) ? $scope.carOptionId : null,
					crmCarOptionOriginList: angular.isDefined($scope.crmCarOptionOriginId) ? $scope.crmCarOptionOriginId : null,

					page: $scope.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.crmCarOptionList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getCrmCarOptionList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCrmCarOptionId = id;
			$('#deleteCrmCarOptionConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtCrmCarOptionViewDtoFactory.delete(
				{ id: $scope.selectedCrmCarOptionId },
				function() {
					$scope.loadAll();
					$('#deleteCrmCarOptionConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCrmCarOptionConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCrmCarOptionOriginUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateOptionOriginUISelect = function(item, model) {
			$scope.search();
		};
	});
