/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('SubTaskTemplateDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.subTaskTemplate = entity;
		$scope.objectName = 'SubTaskTemplate';
		$scope.subTaskTemplateId = $stateParams.subTaskTemplateId;

		if (angular.isUndefined($rootScope.subTaskTemplateActiveTab)) {
			//set the default here
			$rootScope.subTaskTemplateActiveTab = 0;
		}

		$scope.goToEditState = function(subTaskTemplate) {
			$state.go('subtasktemplate.detail.edit({subTaskTemplateId:subTaskTemplate.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:subTaskTemplateUpdate', function(event, result) {
			$scope.subTaskTemplate = result;
		});
	});
