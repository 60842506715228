/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('FilterPageDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.filterPage = entity;
	$scope.objectName = 'FilterPage';
	$scope.filterPageId = $stateParams.filterPageId;

	if (angular.isUndefined($rootScope.filterPageActiveTab)) {
		//set the default here
		$rootScope.filterPageActiveTab = 0;
	}

	$scope.goToEditState = function(filterPage) {
		$state.go('filterpage.detail.edit({filterPageId:filterPage.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:filterPageUpdate', function(event, result) {
		$scope.filterPage = result;
	});
});
