/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigurationPackageArticleDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carConfigurationPackageArticle = entity;
		$scope.objectName = 'CarConfigurationPackageArticle';
		$scope.carConfigurationPackageArticleId = $stateParams.carConfigurationPackageArticleId;

		if (angular.isUndefined($rootScope.carConfigurationPackageArticleActiveTab)) {
			//set the default here
			$rootScope.carConfigurationPackageArticleActiveTab = 0;
		}

		$scope.goToEditState = function(carConfigurationPackageArticle) {
			$state.go(
				'carconfigurationpackagearticle.detail.edit({carConfigurationPackageArticleId:carConfigurationPackageArticle.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carConfigurationPackageArticleUpdate', function(event, result) {
			$scope.carConfigurationPackageArticle = result;
		});
	});
