/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarOptionDependentPriceBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigCarOptionDependentPriceBulkEditDtoFactory,
		AlertService,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.selectedCarOptionDependentPriceIds = _.map($stateParams.selectedCarOptionDependentPriceList, 'id');
		$scope.selectedItemsAmount = $scope.selectedCarOptionDependentPriceIds.length;
		$scope.carOptionDependentPrice = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carOptionDependentPriceUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			CarConfigCarOptionDependentPriceBulkEditDtoFactory.update(
				{ carOptionDependentPriceIds: $scope.selectedCarOptionDependentPriceIds },
				$scope.carOptionDependentPrice,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteArticles = function(val) {
			var params = {
				articleTypeAheadSearchString: val,
			};

			return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
