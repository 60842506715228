/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RelationRoleFactory', function($resource) {
	return $resource(
		'api/relationRoles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtRelationRoleViewDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationRoleViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationrole/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtRelationRoleListDto's
 */
angular.module('mmmApp').factory('RelationMgmtRelationRoleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationrole',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtRelationRoleEditDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationRoleEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationrole/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtRelationRoleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtRelationRoleNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationrole/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRelationRoleFactory', function($resource) {
	return $resource(
		'api/relationRoles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RelationRoles without the use of paging
angular.module('mmmApp').factory('NoPagingRelationRoleFactory', function($resource) {
	return $resource(
		'api/relationRoles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible relationRoleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationRoleTypeFactory', function($resource) {
	return $resource(
		'api/relationRole/relationRoleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRelationRolesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relationRoles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
