/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarPaintFactory', function($resource) {
	return $resource(
		'api/carPaints/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarPaintViewDto
 */
angular.module('mmmApp').factory('CarConfigCarPaintViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpaint/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarPaintListDto's
 */
angular.module('mmmApp').factory('CarConfigCarPaintListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carpaint',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarPaintEditDto
 */
angular.module('mmmApp').factory('CarConfigCarPaintEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpaint/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarPaintEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarPaintNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carpaint/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarPaintEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarPaintEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carpaint/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiCarPaintListDto's
 */
angular.module('mmmApp').factory('WebsiteApiCarPaintListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/carpaint',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarPaintEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarPaintEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carpaint/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarPaintEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarPaintNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carpaint/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarPaintEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarPaintEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carpaint/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarPaintFactory', function($resource) {
	return $resource(
		'api/carPaints/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarPaints without the use of paging
angular.module('mmmApp').factory('NoPagingCarPaintFactory', function($resource) {
	return $resource(
		'api/carPaints/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carPaint/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carPaint/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carPaint/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carPaint/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carPaint/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carPaint/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carPaint/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PaintTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPaintTypeFactory', function($resource) {
	return $resource(
		'api/carPaint/paintTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarPaintsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carPaints/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarPaintSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carpaints/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
