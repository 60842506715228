/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('RelationSalesOrderRoleDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.relationSalesOrderRole = entity;
		$scope.objectName = 'RelationSalesOrderRole';
		$scope.relationSalesOrderRoleId = $stateParams.relationSalesOrderRoleId;

		if (angular.isUndefined($rootScope.relationSalesOrderRoleActiveTab)) {
			//set the default here
			$rootScope.relationSalesOrderRoleActiveTab = 0;
		}

		$scope.goToEditState = function(relationSalesOrderRole) {
			$state.go(
				'relationsalesorderrole.detail.edit({relationSalesOrderRoleId:relationSalesOrderRole.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:relationSalesOrderRoleUpdate', function(event, result) {
			$scope.relationSalesOrderRole = result;
		});
	});
