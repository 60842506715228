/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtSalesOrderListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		SalesOrderFactory,
		RelationMgmtSalesOrderListDtoSearchFactory,
		RelationMgmtSalesOrderViewDtoFactory
	) {
		$scope.salesOrderList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeSalesOrder)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeSalesOrder);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeSalesOrder = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-salesorder.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.SalesOrderSearchOverview.tenant)) {
				return $scope.SalesOrderSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute orderDate of javaType java.time.LocalDate
		$scope.processOrderDate = function() {
			var orderDateFromDefined = $rootScope.isDefinedAndNotNull($scope.SalesOrderSearchOverview.orderDateFrom);
			var orderDateToDefined = $rootScope.isDefinedAndNotNull($scope.SalesOrderSearchOverview.orderDateTo);

			if (orderDateFromDefined) {
				$scope.SalesOrderSearchOverview.orderDateFromString = $rootScope.convertDateWithoutZone(
					$scope.SalesOrderSearchOverview.orderDateFrom
				);
			} else {
				$scope.SalesOrderSearchOverview.orderDateFromString = undefined;
			}

			if (orderDateToDefined) {
				$scope.SalesOrderSearchOverview.orderDateToString = $rootScope.convertDateWithoutZone($scope.SalesOrderSearchOverview.orderDateTo);
			} else {
				$scope.SalesOrderSearchOverview.orderDateToString = undefined;
			}
		};

		// handle attribute validThrough of javaType java.time.LocalDate
		$scope.processValidThrough = function() {
			var validThroughFromDefined = $rootScope.isDefinedAndNotNull($scope.SalesOrderSearchOverview.validThroughFrom);
			var validThroughToDefined = $rootScope.isDefinedAndNotNull($scope.SalesOrderSearchOverview.validThroughTo);

			if (validThroughFromDefined) {
				$scope.SalesOrderSearchOverview.validThroughFromString = $rootScope.convertDateWithoutZone(
					$scope.SalesOrderSearchOverview.validThroughFrom
				);
			} else {
				$scope.SalesOrderSearchOverview.validThroughFromString = undefined;
			}

			if (validThroughToDefined) {
				$scope.SalesOrderSearchOverview.validThroughToString = $rootScope.convertDateWithoutZone(
					$scope.SalesOrderSearchOverview.validThroughTo
				);
			} else {
				$scope.SalesOrderSearchOverview.validThroughToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processOrderDate();
			$scope.processValidThrough();

			$rootScope.SalesOrderSearchOverview = angular.copy($scope.SalesOrderSearchOverview);

			$rootScope.pageSizeSalesOrder = angular.copy($scope.pageSize);

			RelationMgmtSalesOrderListDtoSearchFactory.query(
				{
					page: $scope.SalesOrderSearchOverview.page - 1,
					size: $scope.pageSize,
					orderNumber: $scope.SalesOrderSearchOverview.orderNumber,
					orderVersionFrom: $scope.SalesOrderSearchOverview.orderVersionFrom,
					orderVersionTo: $scope.SalesOrderSearchOverview.orderVersionTo,
					financeDurationFrom: $scope.SalesOrderSearchOverview.financeDurationFrom,
					financeDurationTo: $scope.SalesOrderSearchOverview.financeDurationTo,
					cashPaymentFrom: $scope.SalesOrderSearchOverview.cashPaymentFrom,
					cashPaymentTo: $scope.SalesOrderSearchOverview.cashPaymentTo,
					cashPaymentTotalFrom: $scope.SalesOrderSearchOverview.cashPaymentTotalFrom,
					cashPaymentTotalTo: $scope.SalesOrderSearchOverview.cashPaymentTotalTo,
					outstandingBalanceFrom: $scope.SalesOrderSearchOverview.outstandingBalanceFrom,
					outstandingBalanceTo: $scope.SalesOrderSearchOverview.outstandingBalanceTo,
					outstandingBalanceTotalFrom: $scope.SalesOrderSearchOverview.outstandingBalanceTotalFrom,
					outstandingBalanceTotalTo: $scope.SalesOrderSearchOverview.outstandingBalanceTotalTo,
					finalTermFrom: $scope.SalesOrderSearchOverview.finalTermFrom,
					finalTermTo: $scope.SalesOrderSearchOverview.finalTermTo,
					finalTermTotalFrom: $scope.SalesOrderSearchOverview.finalTermTotalFrom,
					finalTermTotalTo: $scope.SalesOrderSearchOverview.finalTermTotalTo,
					orderDateFrom: $scope.SalesOrderSearchOverview.orderDateFromString,
					orderDateTo: $scope.SalesOrderSearchOverview.orderDateToString,
					validThroughFrom: $scope.SalesOrderSearchOverview.validThroughFromString,
					validThroughTo: $scope.SalesOrderSearchOverview.validThroughToString,
					sectionIdList: $scope.SalesOrderSearchOverview.sectionIdList,
					orderStatusList: $scope.SalesOrderSearchOverview.orderStatusList,
					financeTypeList: $scope.SalesOrderSearchOverview.financeTypeList,
					tenantId: getTenantId(),

					sort: [$scope.SalesOrderSearchOverview.predicate + ',' + ($scope.SalesOrderSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.salesOrderList = result.items;

					$scope.sectionReferenceFilterList = result.sectionSearchOptions;

					$scope.orderStatusList = result.orderStatusSearchOptions;

					$scope.financeTypeList = result.financeTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isSalesOrderSearchOverviewDirty = function() {
			return !angular.equals($scope.SalesOrderSearchOverview, $scope.originalSalesOrderSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.SalesOrderSearchOverview = {};
			$rootScope.SalesOrderSearchOverview.page = 1;
			$rootScope.SalesOrderSearchOverview.predicate = 'id';
			$rootScope.SalesOrderSearchOverview.ascending = false;

			$scope.SalesOrderSearchOverview = angular.copy($rootScope.SalesOrderSearchOverview);

			$scope.originalSalesOrderSearchOverview = angular.copy($scope.SalesOrderSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.SalesOrderSearchOverview) || $rootScope.SalesOrderSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.SalesOrderSearchOverview = angular.copy($rootScope.SalesOrderSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedSalesOrderId = id;
			$('#deleteSalesOrderConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtSalesOrderViewDtoFactory.delete(
				{ id: $scope.selectedSalesOrderId },
				function() {
					$scope.search();
					$('#deleteSalesOrderConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteSalesOrderConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.SalesOrderSearchOverview.predicate === itemName) {
				if ($scope.SalesOrderSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSectionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateOrderStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateFinanceTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
