/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingBaseFactory', function($resource) {
	return $resource(
		'api/mappingBases/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingBaseViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingBaseViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingbase/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingBaseListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingBaseListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingbase',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingBaseEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingBaseEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingbase/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingBaseEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingBaseNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingbase/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingBaseEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingBaseEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingbase/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingBaseFactory', function($resource) {
	return $resource(
		'api/mappingBases/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingBases without the use of paging
angular.module('mmmApp').factory('NoPagingMappingBaseFactory', function($resource) {
	return $resource(
		'api/mappingBases/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingBase/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingBasesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingBases/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingBaseSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingbases/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
