/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('PhoneFactory', function($resource) {
	return $resource(
		'api/phones/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtPhoneViewDto
 */
angular.module('mmmApp').factory('RelationMgmtPhoneViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/phone/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtPhoneListDto's
 */
angular.module('mmmApp').factory('RelationMgmtPhoneListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/phone',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtPhoneEditDto
 */
angular.module('mmmApp').factory('RelationMgmtPhoneEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/phone/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtPhoneEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtPhoneNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/phone/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewPhoneFactory', function($resource) {
	return $resource(
		'api/phones/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Phones without the use of paging
angular.module('mmmApp').factory('NoPagingPhoneFactory', function($resource) {
	return $resource(
		'api/phones/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PhoneTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPhoneTypeFactory', function($resource) {
	return $resource(
		'api/phone/phoneTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllPhonesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/phones/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
