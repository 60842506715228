/** @format */

'use strict';

// Article has edit layout enabled. Edit layout template url: article-dialog.html
// Article has detail layout enabled. Detail layout template url: article-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Article'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('article'); // main translation loaded for article
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-article', {
		/* abstract state for DTO CarConfigArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/article',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigArticleListDto */
		/* to access: ui-ref="car-config-article.list" */
		/* url: http://<hostname>/car/config/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-list-car-config.html',
				controller: 'CarConfigArticleListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-article.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigArticleViewDto */
		/* to access: ui-sref="car-config-article.detail" */
		/* url: http://<hostname>/car/config/article/detail/:articleId */
		url: '/detail/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-view-car-config.html',
				controller: 'ArticleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigArticleViewDtoFactory',
				function($stateParams, CarConfigArticleViewDtoFactory) {
					return CarConfigArticleViewDtoFactory.get({ id: $stateParams.articleId });
				},
			],
		},
	};

	$stateProvider.state('car-config-article.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigArticleEditDto */
		/* to access: ui-sref="car-config-article.edit" */
		/* url: http://<hostname>/car/config/article/edit/:articleId */
		url: '/edit/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-car-config.html',
				controller: 'CarConfigArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigArticleEditDtoFactory',
				function($stateParams, $rootScope, CarConfigArticleEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigArticleEditDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-article.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigArticleEditDto */
		/* to access: ui-sref="car-config-article.new" */
		/* url: http://<hostname>/car/config/article/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-car-config.html',
				controller: 'CarConfigArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigArticleNewDtoFactory',
				function($stateParams, $rootScope, CarConfigArticleNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigArticleNewDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-article.new', dtoCreateState);

	/* Bulk edit state for DTO car.config */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO CarConfigArticleEditDto */
		/* to access: ui-sref="car-config-article.bulkedit" */
		/* url: http://<hostname>/car/config/article/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-bulkedit-car-config.html',
				controller: 'CarConfigArticleBulkEditDtoController',
			},
		},
		params: {
			selectedArticleList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigArticleNewBulkEditDtoFactory',
				function($stateParams, $rootScope, CarConfigArticleNewBulkEditDtoFactory) {
					return CarConfigArticleNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return _.merge(result, { type: 'CarConfigArticleBulkEditDto' });
					});
				},
			],
		},
	};

	$stateProvider.state('car-config-article.bulkedit', dtoBulkEditState);

	$stateProvider.state('article-management-article', {
		/* abstract state for DTO ArticleManagementArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articlemanagement/article',
		data: {
			authorities: ['ROLE_ARTICLE_MANAGEMENT', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO article.management */
	var dtoListState = {
		/* list state for DTO ArticleManagementArticleListDto */
		/* to access: ui-ref="article-management-article.list" */
		/* url: http://<hostname>/articlemanagement/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-list-article-management.html',
				controller: 'ArticleManagementArticleListDtoController',
			},
		},
	};

	$stateProvider.state('article-management-article.list', dtoListState);

	$stateProvider.state('relation-mgmt-article', {
		/* abstract state for DTO RelationMgmtArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/article',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtArticleListDto */
		/* to access: ui-ref="relation-mgmt-article.list" */
		/* url: http://<hostname>/relationmgmt/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-list-relation-mgmt.html',
				controller: 'RelationMgmtArticleListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-article.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtArticleViewDto */
		/* to access: ui-sref="relation-mgmt-article.detail" */
		/* url: http://<hostname>/relationmgmt/article/detail/:articleId */
		url: '/detail/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-view-relation-mgmt.html',
				controller: 'ArticleDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtArticleViewDtoFactory',
				function($stateParams, RelationMgmtArticleViewDtoFactory) {
					return RelationMgmtArticleViewDtoFactory.get({ id: $stateParams.articleId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-article.detail', dtoDetailState);

	$stateProvider.state('website-api-article', {
		/* abstract state for DTO WebsiteApiArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/websiteapi/article',
		data: {
			authorities: ['ROLE_WEBSITE_API', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO website.api */
	var dtoListState = {
		/* list state for DTO WebsiteApiArticleListDto */
		/* to access: ui-ref="website-api-article.list" */
		/* url: http://<hostname>/websiteapi/article/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-list-website-api.html',
				controller: 'WebsiteApiArticleListDtoController',
			},
		},
	};

	$stateProvider.state('website-api-article.list', dtoListState);

	$stateProvider.state('article-api-article', {
		/* abstract state for DTO ArticleApiArticleListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/article',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'Article',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiArticleEditDto */
		/* to access: ui-sref="article-api-article.edit" */
		/* url: http://<hostname>/articleapi/article/edit/:articleId */
		url: '/edit/:articleId',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-article-api.html',
				controller: 'ArticleApiArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiArticleEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiArticleEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiArticleEditDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-article.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiArticleEditDto */
		/* to access: ui-sref="article-api-article.new" */
		/* url: http://<hostname>/articleapi/article/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/article/article-edit-article-api.html',
				controller: 'ArticleApiArticleEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiArticleNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiArticleNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiArticleNewDtoFactory.get({ id: $stateParams.articleId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-article.new', dtoCreateState);
});
