/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtOpeningHourListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OpeningHourFactory,
		StockMgmtOpeningHourListDtoSearchFactory,
		StockMgmtOpeningHourViewDtoFactory
	) {
		$scope.openingHourList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOpeningHour)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOpeningHour);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOpeningHour = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-openinghour.new');
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OpeningHourSearchOverview.dealer)) {
				return $scope.OpeningHourSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.OpeningHourSearchOverview = angular.copy($scope.OpeningHourSearchOverview);

			$rootScope.pageSizeOpeningHour = angular.copy($scope.pageSize);

			StockMgmtOpeningHourListDtoSearchFactory.query(
				{
					page: $scope.OpeningHourSearchOverview.page - 1,
					size: $scope.pageSize,
					dayOfWeekList: $scope.OpeningHourSearchOverview.dayOfWeekList,
					dealerId: getDealerId(),

					sort: [$scope.OpeningHourSearchOverview.predicate + ',' + ($scope.OpeningHourSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.openingHourList = result.items;

					$scope.dayOfWeekList = result.dayOfWeekSearchOptions;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isOpeningHourSearchOverviewDirty = function() {
			return !angular.equals($scope.OpeningHourSearchOverview, $scope.originalOpeningHourSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OpeningHourSearchOverview = {};
			$rootScope.OpeningHourSearchOverview.page = 1;
			$rootScope.OpeningHourSearchOverview.predicate = 'dayOfWeek';
			$rootScope.OpeningHourSearchOverview.ascending = true;

			$scope.OpeningHourSearchOverview = angular.copy($rootScope.OpeningHourSearchOverview);

			$scope.originalOpeningHourSearchOverview = angular.copy($scope.OpeningHourSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OpeningHourSearchOverview) || $rootScope.OpeningHourSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OpeningHourSearchOverview = angular.copy($rootScope.OpeningHourSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OpeningHourSearchOverview.predicate === itemName) {
				if ($scope.OpeningHourSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateDayOfWeekUISelect = function(item, model) {
			$scope.search();
		};
	});
