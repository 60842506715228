/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationFactory,
		CarConfigCarConfigurationListDtoSearchFactory,
		CarConfigCarConfigurationViewDtoFactory
	) {
		$scope.carConfigurationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfiguration)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfiguration);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfiguration = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-carconfiguration.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.equivalentId)) {
				return $scope.equivalentId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.equivalent)) {
				return $scope.CarConfigurationSearchTab.equivalent.id;
			} else {
				return undefined;
			}
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.brandId)) {
				return $scope.brandId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.brandIdList)) {
				return $scope.CarConfigurationSearchTab.brandIdList;
			} else {
				return undefined;
			}
		};

		var getCarModelVersionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carModelVersionId)) {
				return $scope.carModelVersionId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carModelVersion)) {
				return $scope.CarConfigurationSearchTab.carModelVersion.id;
			} else {
				return undefined;
			}
		};

		var getCarBodytypeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carBodytypeId)) {
				return $scope.carBodytypeId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carBodytypeIdList)) {
				return $scope.CarConfigurationSearchTab.carBodytypeIdList;
			} else {
				return undefined;
			}
		};

		var getCarEngineId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carEngineId)) {
				return $scope.carEngineId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carEngineIdList)) {
				return $scope.CarConfigurationSearchTab.carEngineIdList;
			} else {
				return undefined;
			}
		};

		var getCarGradeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carGradeId)) {
				return $scope.carGradeId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carGradeIdList)) {
				return $scope.CarConfigurationSearchTab.carGradeIdList;
			} else {
				return undefined;
			}
		};

		var getCarTransmissionId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carTransmissionId)) {
				return $scope.carTransmissionId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carTransmissionIdList)) {
				return $scope.CarConfigurationSearchTab.carTransmissionIdList;
			} else {
				return undefined;
			}
		};

		var getCarPowertrainId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.carPowertrainId)) {
				return $scope.carPowertrainId;
			} else if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationSearchTab.carPowertrainIdList)) {
				return $scope.CarConfigurationSearchTab.carPowertrainIdList;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarConfigurationSearchTab = angular.copy($scope.CarConfigurationSearchTab);

			$rootScope.pageSizeCarConfiguration = angular.copy($scope.pageSize);

			CarConfigCarConfigurationListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationSearchTab.page - 1,
					size: $scope.pageSize,
					name: $scope.CarConfigurationSearchTab.name,
					productCode: $scope.CarConfigurationSearchTab.productCode,
					factoryCode: $scope.CarConfigurationSearchTab.factoryCode,
					description: $scope.CarConfigurationSearchTab.description,
					canBeOrdered: $scope.CarConfigurationSearchTab.canBeOrderedGroup,
					spotlight: $scope.CarConfigurationSearchTab.spotlightGroup,
					creator: $scope.CarConfigurationSearchTab.creator,
					updater: $scope.CarConfigurationSearchTab.updater,
					weightEmptyVehicleFrom: $scope.CarConfigurationSearchTab.weightEmptyVehicleFrom,
					weightEmptyVehicleTo: $scope.CarConfigurationSearchTab.weightEmptyVehicleTo,
					accelerationFrom: $scope.CarConfigurationSearchTab.accelerationFrom,
					accelerationTo: $scope.CarConfigurationSearchTab.accelerationTo,
					fuelConsumptionFrom: $scope.CarConfigurationSearchTab.fuelConsumptionFrom,
					fuelConsumptionTo: $scope.CarConfigurationSearchTab.fuelConsumptionTo,
					batteryConsumptionFrom: $scope.CarConfigurationSearchTab.batteryConsumptionFrom,
					batteryConsumptionTo: $scope.CarConfigurationSearchTab.batteryConsumptionTo,
					fuelConsumptionCityFrom: $scope.CarConfigurationSearchTab.fuelConsumptionCityFrom,
					fuelConsumptionCityTo: $scope.CarConfigurationSearchTab.fuelConsumptionCityTo,
					fuelConsumptionHighWayFrom: $scope.CarConfigurationSearchTab.fuelConsumptionHighWayFrom,
					fuelConsumptionHighWayTo: $scope.CarConfigurationSearchTab.fuelConsumptionHighWayTo,
					weightReadyForUseFrom: $scope.CarConfigurationSearchTab.weightReadyForUseFrom,
					weightReadyForUseTo: $scope.CarConfigurationSearchTab.weightReadyForUseTo,
					trailerWeightBrakesFrom: $scope.CarConfigurationSearchTab.trailerWeightBrakesFrom,
					trailerWeightBrakesTo: $scope.CarConfigurationSearchTab.trailerWeightBrakesTo,
					unbrakedTrailerWeightFrom: $scope.CarConfigurationSearchTab.unbrakedTrailerWeightFrom,
					unbrakedTrailerWeightTo: $scope.CarConfigurationSearchTab.unbrakedTrailerWeightTo,
					maxCargoCapacityFrom: $scope.CarConfigurationSearchTab.maxCargoCapacityFrom,
					maxCargoCapacityTo: $scope.CarConfigurationSearchTab.maxCargoCapacityTo,
					tankCapacityFrom: $scope.CarConfigurationSearchTab.tankCapacityFrom,
					tankCapacityTo: $scope.CarConfigurationSearchTab.tankCapacityTo,
					loadCapacityFrom: $scope.CarConfigurationSearchTab.loadCapacityFrom,
					loadCapacityTo: $scope.CarConfigurationSearchTab.loadCapacityTo,
					maxWeightFrontAxleFrom: $scope.CarConfigurationSearchTab.maxWeightFrontAxleFrom,
					maxWeightFrontAxleTo: $scope.CarConfigurationSearchTab.maxWeightFrontAxleTo,
					maxWeightBackAxleFrom: $scope.CarConfigurationSearchTab.maxWeightBackAxleFrom,
					maxWeightBackAxleTo: $scope.CarConfigurationSearchTab.maxWeightBackAxleTo,
					cargoSpaceLengthFrom: $scope.CarConfigurationSearchTab.cargoSpaceLengthFrom,
					cargoSpaceLengthTo: $scope.CarConfigurationSearchTab.cargoSpaceLengthTo,
					cargoSpaceWidthFrom: $scope.CarConfigurationSearchTab.cargoSpaceWidthFrom,
					cargoSpaceWidthTo: $scope.CarConfigurationSearchTab.cargoSpaceWidthTo,
					cargoSpaceHeightFrom: $scope.CarConfigurationSearchTab.cargoSpaceHeightFrom,
					cargoSpaceHeightTo: $scope.CarConfigurationSearchTab.cargoSpaceHeightTo,
					cargoSpaceWidthOfWheelFrom: $scope.CarConfigurationSearchTab.cargoSpaceWidthOfWheelFrom,
					cargoSpaceWidthOfWheelTo: $scope.CarConfigurationSearchTab.cargoSpaceWidthOfWheelTo,
					cargoSpaceLengthFloorFrom: $scope.CarConfigurationSearchTab.cargoSpaceLengthFloorFrom,
					cargoSpaceLengthFloorTo: $scope.CarConfigurationSearchTab.cargoSpaceLengthFloorTo,
					turningCircleFrom: $scope.CarConfigurationSearchTab.turningCircleFrom,
					turningCircleTo: $scope.CarConfigurationSearchTab.turningCircleTo,
					statusList: $scope.CarConfigurationSearchTab.statusList,
					typeList: $scope.CarConfigurationSearchTab.typeList,
					imageSize: $scope.CarConfigurationSearchTab.imageSize,
					vatType: $scope.CarConfigurationSearchTab.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarConfigurationSearchTab.priceTypeList,
					brandIdList: getBrandId(),
					countryList: $scope.CarConfigurationSearchTab.countryList,
					locale: $scope.CarConfigurationSearchTab.locale,
					vehicleTypeList: $scope.CarConfigurationSearchTab.vehicleTypeList,
					carModelVersionId: getCarModelVersionId(),
					carBodytypeIdList: getCarBodytypeId(),
					carEngineIdList: getCarEngineId(),
					carGradeIdList: getCarGradeId(),
					carTransmissionIdList: getCarTransmissionId(),
					carPowertrainIdList: getCarPowertrainId(),
					energyLabelList: $scope.CarConfigurationSearchTab.energyLabelList,
					numberOfDoorsList: $scope.CarConfigurationSearchTab.numberOfDoorsList,
					numberOfSeatsList: $scope.CarConfigurationSearchTab.numberOfSeatsList,
					emissionClassList: $scope.CarConfigurationSearchTab.emissionClassList,

					sort: [$scope.CarConfigurationSearchTab.predicate + ',' + ($scope.CarConfigurationSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.vehicleTypeList = result.vehicleTypeSearchOptions;

					$scope.carBodytypeReferenceFilterList = result.carBodytypeSearchOptions;

					$scope.carEngineReferenceFilterList = result.carEngineSearchOptions;

					$scope.carGradeReferenceFilterList = result.carGradeSearchOptions;

					$scope.carTransmissionReferenceFilterList = result.carTransmissionSearchOptions;

					$scope.carPowertrainReferenceFilterList = result.carPowertrainSearchOptions;

					$scope.energyLabelList = result.energyLabelSearchOptions;

					$scope.numberOfDoorsList = result.numberOfDoorsSearchOptions;

					$scope.numberOfSeatsList = result.numberOfSeatsSearchOptions;

					$scope.emissionClassList = result.emissionClassSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationSearchTabDirty = function() {
			return !angular.equals($scope.CarConfigurationSearchTab, $scope.originalCarConfigurationSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationSearchTab = {};
			$rootScope.CarConfigurationSearchTab.page = 1;
			$rootScope.CarConfigurationSearchTab.predicate = 'id';
			$rootScope.CarConfigurationSearchTab.ascending = false;

			$scope.CarConfigurationSearchTab = angular.copy($rootScope.CarConfigurationSearchTab);

			$scope.originalCarConfigurationSearchTab = angular.copy($scope.CarConfigurationSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarConfigurationSearchTab) || $rootScope.CarConfigurationSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationSearchTab = angular.copy($rootScope.CarConfigurationSearchTab);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarConfigurationId = id;
			$('#deleteCarConfigurationConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarConfigurationViewDtoFactory.delete(
				{ id: $scope.selectedCarConfigurationId },
				function() {
					$scope.search();
					$('#deleteCarConfigurationConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteCarConfigurationConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carConfigurationUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationSearchTab.predicate === itemName) {
				if ($scope.CarConfigurationSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateVehicleTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarBodytypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarEngineUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarGradeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarTransmissionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarPowertrainUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateNumberOfDoorsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateNumberOfSeatsUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEmissionClassUISelect = function(item, model) {
			$scope.search();
		};
	});
