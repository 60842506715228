/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticlePriceListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticlePriceFactory,
		CarConfigArticlePriceListDtoSearchFactory,
		CarConfigArticlePriceViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.articlePriceList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticlePrice)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticlePrice);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticlePrice = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-articleprice.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticlePriceSearchOverview.article)) {
				return $scope.ArticlePriceSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticlePriceSearchOverview.brand)) {
				return $scope.ArticlePriceSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.ArticlePriceSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.ArticlePriceSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.ArticlePriceSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.ArticlePriceSearchOverview.validFromFrom
				);
			} else {
				$scope.ArticlePriceSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.ArticlePriceSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.ArticlePriceSearchOverview.validFromTo
				);
			} else {
				$scope.ArticlePriceSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.ArticlePriceSearchOverview = angular.copy($scope.ArticlePriceSearchOverview);

			$rootScope.pageSizeArticlePrice = angular.copy($scope.pageSize);

			CarConfigArticlePriceListDtoSearchFactory.query(
				{
					page: $scope.ArticlePriceSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.ArticlePriceSearchOverview.validFromFromString,
					validFromTo: $scope.ArticlePriceSearchOverview.validFromToString,
					priceFrom: $scope.ArticlePriceSearchOverview.priceFrom,
					priceTo: $scope.ArticlePriceSearchOverview.priceTo,
					co2From: $scope.ArticlePriceSearchOverview.co2From,
					co2To: $scope.ArticlePriceSearchOverview.co2To,
					bpmFrom: $scope.ArticlePriceSearchOverview.bpmFrom,
					bpmTo: $scope.ArticlePriceSearchOverview.bpmTo,
					cataloguePriceExVatFrom: $scope.ArticlePriceSearchOverview.cataloguePriceExVatFrom,
					cataloguePriceExVatTo: $scope.ArticlePriceSearchOverview.cataloguePriceExVatTo,
					articleId: getArticleId(),
					brandId: getBrandId(),

					sort: [$scope.ArticlePriceSearchOverview.predicate + ',' + ($scope.ArticlePriceSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articlePriceList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticlePriceSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticlePriceSearchOverview, $scope.originalArticlePriceSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticlePriceSearchOverview = {};
			$rootScope.ArticlePriceSearchOverview.page = 1;
			$rootScope.ArticlePriceSearchOverview.predicate = 'id';
			$rootScope.ArticlePriceSearchOverview.ascending = false;

			$scope.ArticlePriceSearchOverview = angular.copy($rootScope.ArticlePriceSearchOverview);

			$scope.originalArticlePriceSearchOverview = angular.copy($scope.ArticlePriceSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticlePriceSearchOverview) || $rootScope.ArticlePriceSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticlePriceSearchOverview = angular.copy($rootScope.ArticlePriceSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticlePriceId = id;
			$('#deleteArticlePriceConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigArticlePriceViewDtoFactory.delete(
				{ id: $scope.selectedArticlePriceId },
				function() {
					$scope.search();
					$('#deleteArticlePriceConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticlePriceConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticlePriceSearchOverview.predicate === itemName) {
				if ($scope.ArticlePriceSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
