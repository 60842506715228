/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtOpeningHourEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtOpeningHourEditDtoFactory,
		AlertService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.openingHour = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.openingHour['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.openingHour) && $rootScope.isDefinedAndNotNull($scope.openingHour.id);
		};

		$scope.openTimepickerObject = $rootScope.processTimeArrayIntoJavascriptDate($scope.openingHour.open);
		if ($scope.openingHour.open === null) {
			$scope.openTimepickerObject = $rootScope.processTimeArrayIntoJavascriptDate([12, 0, 0]);
		}
		$scope.closedTimepickerObject = $rootScope.processTimeArrayIntoJavascriptDate($scope.openingHour.closed);
		if ($scope.openingHour.closed === null) {
			$scope.closedTimepickerObject = $rootScope.processTimeArrayIntoJavascriptDate([12, 0, 0]);
		}
		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:openingHourUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			$scope.openingHour.open = $rootScope.processJavascriptDateIntoTimeArray($scope.openTimepickerObject);
			$scope.openingHour.closed = $rootScope.processJavascriptDateIntoTimeArray($scope.closedTimepickerObject);

			StockMgmtOpeningHourEditDtoFactory.update($scope.openingHour, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
