/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtQuoteDataListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		QuoteDataFactory,
		RelationMgmtQuoteDataListDtoSearchFactory
	) {
		$scope.quoteDataList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeQuoteData)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeQuoteData);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeQuoteData = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-quotedata.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.QuoteDataSearchOverview = angular.copy($scope.QuoteDataSearchOverview);

			$rootScope.pageSizeQuoteData = angular.copy($scope.pageSize);

			RelationMgmtQuoteDataListDtoSearchFactory.query(
				{
					page: $scope.QuoteDataSearchOverview.page - 1,
					size: $scope.pageSize,
					quoteId: $scope.QuoteDataSearchOverview.quoteId,
					quoteRequestData: $scope.QuoteDataSearchOverview.quoteRequestData,
					carConfigurationIdList: $scope.QuoteDataSearchOverview.carConfigurationIdList,
					carPaintIdList: $scope.QuoteDataSearchOverview.carPaintIdList,

					sort: [$scope.QuoteDataSearchOverview.predicate + ',' + ($scope.QuoteDataSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.quoteDataList = result.items;

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$scope.carPaintReferenceFilterList = result.carPaintSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isQuoteDataSearchOverviewDirty = function() {
			return !angular.equals($scope.QuoteDataSearchOverview, $scope.originalQuoteDataSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.QuoteDataSearchOverview = {};
			$rootScope.QuoteDataSearchOverview.page = 1;
			$rootScope.QuoteDataSearchOverview.predicate = 'id';
			$rootScope.QuoteDataSearchOverview.ascending = false;

			$scope.QuoteDataSearchOverview = angular.copy($rootScope.QuoteDataSearchOverview);

			$scope.originalQuoteDataSearchOverview = angular.copy($scope.QuoteDataSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.QuoteDataSearchOverview) || $rootScope.QuoteDataSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.QuoteDataSearchOverview = angular.copy($rootScope.QuoteDataSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.QuoteDataSearchOverview.predicate === itemName) {
				if ($scope.QuoteDataSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCarPaintUISelect = function(item, model) {
			$scope.search();
		};
	});
