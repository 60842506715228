/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('InterestDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.interest = entity;
	$scope.objectName = 'Interest';
	$scope.interestId = $stateParams.interestId;

	if (angular.isUndefined($rootScope.interestActiveTab)) {
		//set the default here
		$rootScope.interestActiveTab = 0;
	}

	$scope.goToEditState = function(interest) {
		$state.go('interest.detail.edit({interestId:interest.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:interestUpdate', function(event, result) {
		$scope.interest = result;
	});
});
