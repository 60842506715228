/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('MappingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/mappingNumberOfDoors/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtMappingNumberOfDoorsViewDto
 */
angular.module('mmmApp').factory('StockMgmtMappingNumberOfDoorsViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingnumberofdoors/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtMappingNumberOfDoorsListDto's
 */
angular.module('mmmApp').factory('StockMgmtMappingNumberOfDoorsListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingnumberofdoors',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtMappingNumberOfDoorsEditDto
 */
angular.module('mmmApp').factory('StockMgmtMappingNumberOfDoorsEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingnumberofdoors/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtMappingNumberOfDoorsEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtMappingNumberOfDoorsNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingnumberofdoors/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of StockMgmtMappingNumberOfDoorsEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('StockMgmtMappingNumberOfDoorsEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/stockmgmt/mappingnumberofdoors/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewMappingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/mappingNumberOfDoors/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all MappingNumberOfDoors without the use of paging
angular.module('mmmApp').factory('NoPagingMappingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/mappingNumberOfDoors/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible MappingSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingMappingSourceFactory', function($resource) {
	return $resource(
		'api/mappingNumberOfDoors/mappingSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible NumberOfDoors Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingNumberOfDoorsFactory', function($resource) {
	return $resource(
		'api/mappingNumberOfDoors/numberOfDoors',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllMappingNumberOfDoorsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/mappingNumberOfDoors/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('MappingNumberOfDoorsSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/mappingnumberofdoors/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
