/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CrmCarDocumentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.crmCarDocument = entity;
		$scope.objectName = 'CrmCarDocument';
		$scope.crmCarDocumentId = $stateParams.crmCarDocumentId;

		if (angular.isUndefined($rootScope.crmCarDocumentActiveTab)) {
			//set the default here
			$rootScope.crmCarDocumentActiveTab = 0;
		}

		$scope.goToEditState = function(crmCarDocument) {
			$state.go('crmcardocument.detail.edit({crmCarDocumentId:crmCarDocument.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:crmCarDocumentUpdate', function(event, result) {
			$scope.crmCarDocument = result;
		});
	});
