/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('ArticleApiColorEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		ArticleApiColorEditDtoFactory,
		AlertService,
		HistoryService,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.color = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.color['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.color) && $rootScope.isDefinedAndNotNull($scope.color.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:colorUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			ArticleApiColorEditDtoFactory.update($scope.color, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
