'use strict';

angular
		.module('mmmApp')
		.controller(
				'CrmCarMediaDocumentDetailController',
				function($scope, $rootScope, $stateParams, $state, entity, $location) {
					$scope.crmCarMediaDocument = entity;
					$scope.objectName = "CrmCarMediaDocument";
					$scope.crmCarMediaDocumentId = $stateParams.crmCarMediaDocumentId;

					if (angular.isUndefined($rootScope.crmCarMediaDocumentActiveTab)) {
						//set the default here
						$rootScope.crmCarMediaDocumentActiveTab = 0;
					}

					$scope.goToEditState = function(crmCarMediaDocument) {
						$state
								.go('crmcarmediadocument.detail.edit({crmCarMediaDocumentId:crmCarMediaDocument.id, mappedBy:mappedBy, mappedById:mappedById})');
					};

					// by broadcasting this event from an edit dialog the model is refreshed
					$rootScope.$on('mmmApp:crmCarMediaDocumentUpdate', function(event, result) {
						$scope.crmCarMediaDocument = result;
					});

				});
