/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('OrderLineTemplateFactory', function($resource) {
	return $resource(
		'api/orderLineTemplates/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtOrderLineTemplateViewDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineTemplateViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinetemplate/:id',
		{},
		{
			// Factory is only used to delete a RelationMgmtOrderLineTemplateViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtOrderLineTemplateListDto's
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineTemplateListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinetemplate',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtOrderLineTemplateEditDto
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineTemplateEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinetemplate/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtOrderLineTemplateEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtOrderLineTemplateNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/orderlinetemplate/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewOrderLineTemplateFactory', function($resource) {
	return $resource(
		'api/orderLineTemplates/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all OrderLineTemplates without the use of paging
angular.module('mmmApp').factory('NoPagingOrderLineTemplateFactory', function($resource) {
	return $resource(
		'api/orderLineTemplates/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllOrderLineTemplatesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/orderLineTemplates/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
