/** @format */

'use strict';

//Directive for each tabbed pane for n-relations, using 1 tab for each referenceName that is used on the other side (0..1 and 1..1 multiplicity) of the n-relation
//For example: Relation might have a reference to car, but from the other side car has a relation to Relation in two ways: as owner and as reseller. Both need a tab.
angular.module('mmmApp').directive('authoritiesOverviewMappedByUser', function() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/entities/authority/authority-tab.html',
		controller: 'AuthorityTabController',
		scope: {
			userId: '=mappedById',
			mappedBy: '=mappedBy',
			mappedByType: '=mappedByType',
			mappedById: '=mappedById',
		},
	};
});
