/** @format */

'use strict';

// ArticleCombi has edit layout enabled. Edit layout template url: articlecombi-dialog.html
// ArticleCombi has detail layout enabled. Detail layout template url: articlecombi-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ArticleCombi'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('articlecombi'); // main translation loaded for articlecombi
		$translatePartialLoader.addPart('combitype'); // one to many reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-articlecombi', {
		/* abstract state for DTO CarConfigArticleCombiListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/articlecombi',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'ArticleCombi',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigArticleCombiListDto */
		/* to access: ui-ref="car-config-articlecombi.list" */
		/* url: http://<hostname>/car/config/articlecombi/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/articlecombi/articlecombi-list-car-config.html',
				controller: 'CarConfigArticleCombiListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-articlecombi.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigArticleCombiViewDto */
		/* to access: ui-sref="car-config-articlecombi.detail" */
		/* url: http://<hostname>/car/config/articlecombi/detail/:articleCombiId */
		url: '/detail/:articleCombiId',
		views: {
			'content@': {
				templateUrl: 'app/entities/articlecombi/articlecombi-view-car-config.html',
				controller: 'ArticleCombiDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigArticleCombiViewDtoFactory',
				function($stateParams, CarConfigArticleCombiViewDtoFactory) {
					return CarConfigArticleCombiViewDtoFactory.get({ id: $stateParams.articleCombiId });
				},
			],
		},
	};

	$stateProvider.state('car-config-articlecombi.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigArticleCombiEditDto */
		/* to access: ui-sref="car-config-articlecombi.edit" */
		/* url: http://<hostname>/car/config/articlecombi/edit/:articleCombiId */
		url: '/edit/:articleCombiId',
		views: {
			'content@': {
				templateUrl: 'app/entities/articlecombi/articlecombi-edit-car-config.html',
				controller: 'CarConfigArticleCombiEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigArticleCombiEditDtoFactory',
				function($stateParams, $rootScope, CarConfigArticleCombiEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigArticleCombiEditDtoFactory.get({ id: $stateParams.articleCombiId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-articlecombi.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigArticleCombiEditDto */
		/* to access: ui-sref="car-config-articlecombi.new" */
		/* url: http://<hostname>/car/config/articlecombi/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/articlecombi/articlecombi-edit-car-config.html',
				controller: 'CarConfigArticleCombiEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigArticleCombiNewDtoFactory',
				function($stateParams, $rootScope, CarConfigArticleCombiNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigArticleCombiNewDtoFactory.get({ id: $stateParams.articleCombiId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-articlecombi.new', dtoCreateState);
});
