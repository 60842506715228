/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('CarOptionDependentPriceDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.carOptionDependentPrice = entity;
		$scope.objectName = 'CarOptionDependentPrice';
		$scope.carOptionDependentPriceId = $stateParams.carOptionDependentPriceId;

		if (angular.isUndefined($rootScope.carOptionDependentPriceActiveTab)) {
			//set the default here
			$rootScope.carOptionDependentPriceActiveTab = 0;
		}

		$scope.goToEditState = function(carOptionDependentPrice) {
			$state.go(
				'caroptiondependentprice.detail.edit({carOptionDependentPriceId:carOptionDependentPrice.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:carOptionDependentPriceUpdate', function(event, result) {
			$scope.carOptionDependentPrice = result;
		});
	});
