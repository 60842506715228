/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarBodytypeListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarBodytypeFactory,
		CarConfigCarBodytypeListDtoSearchFactory,
		CarConfigCarBodytypeViewDtoFactory
	) {
		$scope.carBodytypeList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarBodytype)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarBodytype);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarBodytype = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carbodytype.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarBodytypeSearchOverview.equivalent)) {
				return $scope.CarBodytypeSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarBodytypeSearchOverview = angular.copy($scope.CarBodytypeSearchOverview);

			$rootScope.pageSizeCarBodytype = angular.copy($scope.pageSize);

			CarConfigCarBodytypeListDtoSearchFactory.query(
				{
					page: $scope.CarBodytypeSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarBodytypeSearchOverview.name,
					productCode: $scope.CarBodytypeSearchOverview.productCode,
					factoryCode: $scope.CarBodytypeSearchOverview.factoryCode,
					description: $scope.CarBodytypeSearchOverview.description,
					canBeOrdered: $scope.CarBodytypeSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarBodytypeSearchOverview.spotlightGroup,
					creator: $scope.CarBodytypeSearchOverview.creator,
					updater: $scope.CarBodytypeSearchOverview.updater,
					statusList: $scope.CarBodytypeSearchOverview.statusList,
					typeList: $scope.CarBodytypeSearchOverview.typeList,
					imageSize: $scope.CarBodytypeSearchOverview.imageSize,
					vatType: $scope.CarBodytypeSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarBodytypeSearchOverview.priceTypeList,
					brandIdList: $scope.CarBodytypeSearchOverview.brandIdList,
					countryList: $scope.CarBodytypeSearchOverview.countryList,
					locale: $scope.CarBodytypeSearchOverview.locale,
					bodyTypeList: $scope.CarBodytypeSearchOverview.bodyTypeList,

					sort: [$scope.CarBodytypeSearchOverview.predicate + ',' + ($scope.CarBodytypeSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carBodytypeList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.bodyTypeList = result.bodyTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarBodytypeSearchOverviewDirty = function() {
			return !angular.equals($scope.CarBodytypeSearchOverview, $scope.originalCarBodytypeSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarBodytypeSearchOverview = {};
			$rootScope.CarBodytypeSearchOverview.page = 1;
			$rootScope.CarBodytypeSearchOverview.predicate = 'id';
			$rootScope.CarBodytypeSearchOverview.ascending = false;

			$scope.CarBodytypeSearchOverview = angular.copy($rootScope.CarBodytypeSearchOverview);

			$scope.originalCarBodytypeSearchOverview = angular.copy($scope.CarBodytypeSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarBodytypeSearchOverview) || $rootScope.CarBodytypeSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarBodytypeSearchOverview = angular.copy($rootScope.CarBodytypeSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarBodytypeId = id;
			$('#deleteCarBodytypeConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarBodytypeViewDtoFactory.delete(
				{ id: $scope.selectedCarBodytypeId },
				function() {
					$scope.search();
					$('#deleteCarBodytypeConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarBodytypeConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carBodytypeUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarBodytypeSearchOverview.predicate === itemName) {
				if ($scope.CarBodytypeSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBodyTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
