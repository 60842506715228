/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RelationDocumentFactory', function($resource) {
	return $resource(
		'api/relationDocuments/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtRelationDocumentViewDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationDocumentViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationdocument/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtRelationDocumentListDto's
 */
angular.module('mmmApp').factory('RelationMgmtRelationDocumentListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationdocument',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtRelationDocumentEditDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationDocumentEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationdocument/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtRelationDocumentEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtRelationDocumentNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationdocument/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRelationDocumentFactory', function($resource) {
	return $resource(
		'api/relationDocuments/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RelationDocuments without the use of paging
angular.module('mmmApp').factory('NoPagingRelationDocumentFactory', function($resource) {
	return $resource(
		'api/relationDocuments/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRelationDocumentsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relationDocuments/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
