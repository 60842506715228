/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('AdminTenantListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		TenantFactory,
		AdminTenantListDtoSearchFactory,
		AdminTenantViewDtoFactory
	) {
		$scope.tenantList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeTenant)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeTenant);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeTenant = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('admin-tenant.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		// handle attribute trimlines of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.TenantSearchTab = angular.copy($scope.TenantSearchTab);

			$rootScope.pageSizeTenant = angular.copy($scope.pageSize);

			AdminTenantListDtoSearchFactory.query(
				{
					page: $scope.TenantSearchTab.page - 1,
					size: $scope.pageSize,
					name: $scope.TenantSearchTab.name,
					trimlines: $scope.TenantSearchTab.trimlinesGroup,

					sort: [$scope.TenantSearchTab.predicate + ',' + ($scope.TenantSearchTab.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.tenantList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isTenantSearchTabDirty = function() {
			return !angular.equals($scope.TenantSearchTab, $scope.originalTenantSearchTab);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.TenantSearchTab = {};
			$rootScope.TenantSearchTab.page = 1;
			$rootScope.TenantSearchTab.predicate = 'name';
			$rootScope.TenantSearchTab.ascending = false;

			$scope.TenantSearchTab = angular.copy($rootScope.TenantSearchTab);

			$scope.originalTenantSearchTab = angular.copy($scope.TenantSearchTab);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.TenantSearchTab) || $rootScope.TenantSearchTab === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.TenantSearchTab = angular.copy($rootScope.TenantSearchTab);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedTenantId = id;
			$('#deleteTenantConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			AdminTenantViewDtoFactory.delete(
				{ id: $scope.selectedTenantId },
				function() {
					$scope.search();
					$('#deleteTenantConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteTenantConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.TenantSearchTab.predicate === itemName) {
				if ($scope.TenantSearchTab.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
