/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCarStockDiscountListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarStockDiscountFactory,
		StockMgmtCarStockDiscountListDtoSearchFactory,
		StockMgmtCarStockDiscountViewDtoFactory
	) {
		$scope.carStockDiscountList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarStockDiscount)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarStockDiscount);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarStockDiscount = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-carstockdiscount.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockDiscountSearchOverview.tenant)) {
				return $scope.CarStockDiscountSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getCarConfigurationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarStockDiscountSearchOverview.carConfiguration)) {
				return $scope.CarStockDiscountSearchOverview.carConfiguration.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.CarStockDiscountSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.CarStockDiscountSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.CarStockDiscountSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.CarStockDiscountSearchOverview.validFromFrom
				);
			} else {
				$scope.CarStockDiscountSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.CarStockDiscountSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.CarStockDiscountSearchOverview.validFromTo
				);
			} else {
				$scope.CarStockDiscountSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.CarStockDiscountSearchOverview = angular.copy($scope.CarStockDiscountSearchOverview);

			$rootScope.pageSizeCarStockDiscount = angular.copy($scope.pageSize);

			StockMgmtCarStockDiscountListDtoSearchFactory.query(
				{
					page: $scope.CarStockDiscountSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.CarStockDiscountSearchOverview.validFromFromString,
					validFromTo: $scope.CarStockDiscountSearchOverview.validFromToString,
					valuePercentageFrom: $scope.CarStockDiscountSearchOverview.valuePercentageFrom,
					valuePercentageTo: $scope.CarStockDiscountSearchOverview.valuePercentageTo,
					valueAmountFrom: $scope.CarStockDiscountSearchOverview.valueAmountFrom,
					valueAmountTo: $scope.CarStockDiscountSearchOverview.valueAmountTo,
					tenantId: getTenantId(),
					carConfigurationId: getCarConfigurationId(),

					sort: [
						$scope.CarStockDiscountSearchOverview.predicate + ',' + ($scope.CarStockDiscountSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carStockDiscountList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarStockDiscountSearchOverviewDirty = function() {
			return !angular.equals($scope.CarStockDiscountSearchOverview, $scope.originalCarStockDiscountSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarStockDiscountSearchOverview = {};
			$rootScope.CarStockDiscountSearchOverview.page = 1;
			$rootScope.CarStockDiscountSearchOverview.predicate = 'id';
			$rootScope.CarStockDiscountSearchOverview.ascending = false;

			$scope.CarStockDiscountSearchOverview = angular.copy($rootScope.CarStockDiscountSearchOverview);

			$scope.originalCarStockDiscountSearchOverview = angular.copy($scope.CarStockDiscountSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarStockDiscountSearchOverview) || $rootScope.CarStockDiscountSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarStockDiscountSearchOverview = angular.copy($rootScope.CarStockDiscountSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarStockDiscountSearchOverview.predicate === itemName) {
				if ($scope.CarStockDiscountSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
