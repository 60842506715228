/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('AdminApplicationTimingLogListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ApplicationTimingLogFactory,
		AdminApplicationTimingLogListDtoSearchFactory
	) {
		$scope.applicationTimingLogList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeApplicationTimingLog)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeApplicationTimingLog);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeApplicationTimingLog = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('admin-applicationtiminglog.new');
		};

		// handle attribute timestamp of javaType java.time.ZonedDateTime
		$scope.processTimestamp = function() {
			var timestampFromDefined = $rootScope.isDefinedAndNotNull($scope.ApplicationTimingLogSearchOverview.timestampFrom);
			var timestampToDefined = $rootScope.isDefinedAndNotNull($scope.ApplicationTimingLogSearchOverview.timestampTo);

			// TODO either we assign a value, or undefined, plus we check for undefined, hence we could assign the variable, period

			if (timestampFromDefined) {
				$scope.ApplicationTimingLogSearchOverview.timestampFromString = $rootScope.convertDateWithoutZone(
					$scope.ApplicationTimingLogSearchOverview.timestampFrom
				);
			} else {
				$scope.ApplicationTimingLogSearchOverview.timestampFromString = undefined;
			}

			if (timestampToDefined) {
				$scope.ApplicationTimingLogSearchOverview.timestampToString = $rootScope.convertDateWithoutZone(
					$scope.ApplicationTimingLogSearchOverview.timestampTo
				);
			} else {
				$scope.ApplicationTimingLogSearchOverview.timestampToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processTimestamp();

			$rootScope.ApplicationTimingLogSearchOverview = angular.copy($scope.ApplicationTimingLogSearchOverview);

			$rootScope.pageSizeApplicationTimingLog = angular.copy($scope.pageSize);

			AdminApplicationTimingLogListDtoSearchFactory.query(
				{
					page: $scope.ApplicationTimingLogSearchOverview.page - 1,
					size: $scope.pageSize,
					timestampFrom: $scope.ApplicationTimingLogSearchOverview.timestampFromString,
					timestampTo: $scope.ApplicationTimingLogSearchOverview.timestampToString,
					description: $scope.ApplicationTimingLogSearchOverview.description,
					callInput: $scope.ApplicationTimingLogSearchOverview.callInput,
					durationFrom: $scope.ApplicationTimingLogSearchOverview.durationFrom,
					durationTo: $scope.ApplicationTimingLogSearchOverview.durationTo,
					responseSizeFrom: $scope.ApplicationTimingLogSearchOverview.responseSizeFrom,
					responseSizeTo: $scope.ApplicationTimingLogSearchOverview.responseSizeTo,
					ipAddress: $scope.ApplicationTimingLogSearchOverview.ipAddress,
					userName: $scope.ApplicationTimingLogSearchOverview.userName,
					additionalInfo: $scope.ApplicationTimingLogSearchOverview.additionalInfo,
					tabPageList: $scope.ApplicationTimingLogSearchOverview.tabPageList,

					sort: [
						$scope.ApplicationTimingLogSearchOverview.predicate +
							',' +
							($scope.ApplicationTimingLogSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.applicationTimingLogList = result.items;

					$scope.tabPageList = result.tabPageSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isApplicationTimingLogSearchOverviewDirty = function() {
			return !angular.equals($scope.ApplicationTimingLogSearchOverview, $scope.originalApplicationTimingLogSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ApplicationTimingLogSearchOverview = {};
			$rootScope.ApplicationTimingLogSearchOverview.page = 1;
			$rootScope.ApplicationTimingLogSearchOverview.predicate = 'id';
			$rootScope.ApplicationTimingLogSearchOverview.ascending = false;

			$scope.ApplicationTimingLogSearchOverview = angular.copy($rootScope.ApplicationTimingLogSearchOverview);

			$scope.originalApplicationTimingLogSearchOverview = angular.copy($scope.ApplicationTimingLogSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ApplicationTimingLogSearchOverview) || $rootScope.ApplicationTimingLogSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ApplicationTimingLogSearchOverview = angular.copy($rootScope.ApplicationTimingLogSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ApplicationTimingLogSearchOverview.predicate === itemName) {
				if ($scope.ApplicationTimingLogSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateTabPageUISelect = function(item, model) {
			$scope.search();
		};
	});
