/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('PersonFactory', function($resource) {
	return $resource(
		'api/persons/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtPersonViewDto
 */
angular.module('mmmApp').factory('RelationMgmtPersonViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/person/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtPersonListDto's
 */
angular.module('mmmApp').factory('RelationMgmtPersonListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/person',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtPersonEditDto
 */
angular.module('mmmApp').factory('RelationMgmtPersonEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/person/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtPersonEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtPersonNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/person/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewPersonFactory', function($resource) {
	return $resource(
		'api/persons/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Persons without the use of paging
angular.module('mmmApp').factory('NoPagingPersonFactory', function($resource) {
	return $resource(
		'api/persons/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationStatusses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationStatusFactory', function($resource) {
	return $resource(
		'api/person/relationStatusses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationTypeFactory', function($resource) {
	return $resource(
		'api/person/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible GenderTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingGenderTypeFactory', function($resource) {
	return $resource(
		'api/person/genderTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllPersonsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/persons/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompletePersonsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/persons/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtPersonsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/persons',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteOwnerPersonsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/owner/autocomplete/persons',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
