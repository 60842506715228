/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerLocationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerLocationFactory,
		StockMgmtDealerLocationListDtoSearchFactory,
		StockMgmtDealerLocationViewDtoFactory
	) {
		$scope.dealerLocationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealerLocation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealerLocation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealerLocation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-dealerlocation.new');
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerLocationSearchOverview.dealer)) {
				return $scope.DealerLocationSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerLocationSearchOverview.tenant)) {
				return $scope.DealerLocationSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DealerLocationSearchOverview = angular.copy($scope.DealerLocationSearchOverview);

			$rootScope.pageSizeDealerLocation = angular.copy($scope.pageSize);

			StockMgmtDealerLocationListDtoSearchFactory.query(
				{
					page: $scope.DealerLocationSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.DealerLocationSearchOverview.name,
					description: $scope.DealerLocationSearchOverview.description,
					sortOrderFrom: $scope.DealerLocationSearchOverview.sortOrderFrom,
					sortOrderTo: $scope.DealerLocationSearchOverview.sortOrderTo,
					externalLocationId: $scope.DealerLocationSearchOverview.externalLocationId,
					dealerId: getDealerId(),
					tenantId: getTenantId(),

					sort: [$scope.DealerLocationSearchOverview.predicate + ',' + ($scope.DealerLocationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerLocationList = result.items;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDealerLocationSearchOverviewDirty = function() {
			return !angular.equals($scope.DealerLocationSearchOverview, $scope.originalDealerLocationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DealerLocationSearchOverview = {};
			$rootScope.DealerLocationSearchOverview.page = 1;
			$rootScope.DealerLocationSearchOverview.predicate = 'id';
			$rootScope.DealerLocationSearchOverview.ascending = false;

			$scope.DealerLocationSearchOverview = angular.copy($rootScope.DealerLocationSearchOverview);

			$scope.originalDealerLocationSearchOverview = angular.copy($scope.DealerLocationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DealerLocationSearchOverview) || $rootScope.DealerLocationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DealerLocationSearchOverview = angular.copy($rootScope.DealerLocationSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDealerLocationId = id;
			$('#deleteDealerLocationConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtDealerLocationViewDtoFactory.delete(
				{ id: $scope.selectedDealerLocationId },
				function() {
					$scope.search();
					$('#deleteDealerLocationConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteDealerLocationConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DealerLocationSearchOverview.predicate === itemName) {
				if ($scope.DealerLocationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
