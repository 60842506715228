/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingRdwColorListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingRdwColorFactory,
		StockMgmtMappingRdwColorListDtoSearchFactory,
		StockMgmtMappingRdwColorViewDtoFactory
	) {
		$scope.mappingRdwColorList = [];
		$scope.selectedMappingRdwColorList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingRdwColor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingRdwColor);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingRdwColor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-mappingrdwcolor.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getMappingRdwColorList = function() {
			StockMgmtMappingRdwColorListDtoSearchFactory.query(
				{
					sourceList: angular.isDefined($scope.sourceId) ? $scope.sourceId : null,
					rdwColorIdList: angular.isDefined($scope.rdwColorId) ? $scope.rdwColorId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingRdwColorList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getMappingRdwColorList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingRdwColorId = id;
			$('#deleteMappingRdwColorConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingRdwColorViewDtoFactory.delete(
				{ id: $scope.selectedMappingRdwColorId },
				function() {
					$scope.loadAll();
					$('#deleteMappingRdwColorConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteMappingRdwColorConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingRdwColorConfirmationInTab').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingRdwColorList, function(value, key) {
				promises.push(StockMgmtMappingRdwColorViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.loadAll();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingRdwColorConfirmationInTab').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingRdwColorList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingRdwColorList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingRdwColorList = [];

			angular.forEach($scope.mappingRdwColorList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingRdwColorList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingRdwColorUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateRdwColorUISelect = function(item, model) {
			$scope.search();
		};
	});
