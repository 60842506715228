/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarOptionDependentPriceListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarOptionDependentPriceFactory,
		CarConfigCarOptionDependentPriceListDtoSearchFactory,
		CarConfigCarOptionDependentPriceViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.carOptionDependentPriceList = [];
		$scope.selectedCarOptionDependentPriceList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarOptionDependentPrice)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarOptionDependentPrice);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarOptionDependentPrice = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-caroptiondependentprice.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOptionDependentPriceSearchOverview.brand)) {
				return $scope.CarOptionDependentPriceSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		var getContextArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOptionDependentPriceSearchOverview.contextArticle)) {
				return $scope.CarOptionDependentPriceSearchOverview.contextArticle.id;
			} else {
				return undefined;
			}
		};

		var getTargetArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarOptionDependentPriceSearchOverview.targetArticle)) {
				return $scope.CarOptionDependentPriceSearchOverview.targetArticle.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.CarOptionDependentPriceSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.CarOptionDependentPriceSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.CarOptionDependentPriceSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.CarOptionDependentPriceSearchOverview.validFromFrom
				);
			} else {
				$scope.CarOptionDependentPriceSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.CarOptionDependentPriceSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.CarOptionDependentPriceSearchOverview.validFromTo
				);
			} else {
				$scope.CarOptionDependentPriceSearchOverview.validFromToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();

			$rootScope.CarOptionDependentPriceSearchOverview = angular.copy($scope.CarOptionDependentPriceSearchOverview);

			$rootScope.pageSizeCarOptionDependentPrice = angular.copy($scope.pageSize);

			CarConfigCarOptionDependentPriceListDtoSearchFactory.query(
				{
					page: $scope.CarOptionDependentPriceSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.CarOptionDependentPriceSearchOverview.validFromFromString,
					validFromTo: $scope.CarOptionDependentPriceSearchOverview.validFromToString,
					priceFrom: $scope.CarOptionDependentPriceSearchOverview.priceFrom,
					priceTo: $scope.CarOptionDependentPriceSearchOverview.priceTo,
					bpmFrom: $scope.CarOptionDependentPriceSearchOverview.bpmFrom,
					bpmTo: $scope.CarOptionDependentPriceSearchOverview.bpmTo,
					cataloguePriceExVatFrom: $scope.CarOptionDependentPriceSearchOverview.cataloguePriceExVatFrom,
					cataloguePriceExVatTo: $scope.CarOptionDependentPriceSearchOverview.cataloguePriceExVatTo,
					co2From: $scope.CarOptionDependentPriceSearchOverview.co2From,
					co2To: $scope.CarOptionDependentPriceSearchOverview.co2To,
					brandId: getBrandId(),
					contextArticleId: getContextArticleId(),
					targetArticleId: getTargetArticleId(),
					carConfigurationIdList: $scope.CarOptionDependentPriceSearchOverview.carConfigurationIdList,

					sort: [
						$scope.CarOptionDependentPriceSearchOverview.predicate +
							',' +
							($scope.CarOptionDependentPriceSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carOptionDependentPriceList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarOptionDependentPriceSearchOverviewDirty = function() {
			return !angular.equals($scope.CarOptionDependentPriceSearchOverview, $scope.originalCarOptionDependentPriceSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarOptionDependentPriceSearchOverview = {};
			$rootScope.CarOptionDependentPriceSearchOverview.page = 1;
			$rootScope.CarOptionDependentPriceSearchOverview.predicate = 'id';
			$rootScope.CarOptionDependentPriceSearchOverview.ascending = false;

			$scope.CarOptionDependentPriceSearchOverview = angular.copy($rootScope.CarOptionDependentPriceSearchOverview);

			$scope.originalCarOptionDependentPriceSearchOverview = angular.copy($scope.CarOptionDependentPriceSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.CarOptionDependentPriceSearchOverview) ||
				$rootScope.CarOptionDependentPriceSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarOptionDependentPriceSearchOverview = angular.copy($rootScope.CarOptionDependentPriceSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarOptionDependentPriceId = id;
			$('#deleteCarOptionDependentPriceConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarOptionDependentPriceViewDtoFactory.delete(
				{ id: $scope.selectedCarOptionDependentPriceId },
				function() {
					$scope.search();
					$('#deleteCarOptionDependentPriceConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarOptionDependentPriceConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleCarOptionDependentPriceConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedCarOptionDependentPriceList, function(value, key) {
				promises.push(CarConfigCarOptionDependentPriceViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleCarOptionDependentPriceConfirmation').modal('hide');
				});
		};
		$scope.multipleUpdate = function() {
			$state.go('car-config-caroptiondependentprice.bulkedit', {
				selectedCarOptionDependentPriceList: $scope.selectedCarOptionDependentPriceList,
			});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.carOptionDependentPriceList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedCarOptionDependentPriceList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedCarOptionDependentPriceList = [];

			angular.forEach($scope.carOptionDependentPriceList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedCarOptionDependentPriceList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarOptionDependentPriceSearchOverview.predicate === itemName) {
				if ($scope.CarOptionDependentPriceSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};
	});
