/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('SubTaskDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.subTask = entity;
	$scope.objectName = 'SubTask';
	$scope.subTaskId = $stateParams.subTaskId;

	if (angular.isUndefined($rootScope.subTaskActiveTab)) {
		//set the default here
		$rootScope.subTaskActiveTab = 0;
	}

	$scope.goToEditState = function(subTask) {
		$state.go('subtask.detail.edit({subTaskId:subTask.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:subTaskUpdate', function(event, result) {
		$scope.subTask = result;
	});
});
