/** @format */

'use strict';

// OptionCategory has edit layout enabled. Edit layout template url: optioncategory-dialog.html
// OptionCategory has detail layout enabled. Detail layout template url: optioncategory-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'OptionCategory'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('optioncategory'); // main translation loaded for optioncategory
		$translatePartialLoader.addPart('optioncategorytype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carOption
		$translatePartialLoader.addPart('caroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-optioncategory', {
		/* abstract state for DTO CarConfigOptionCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/optioncategory',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'OptionCategory',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigOptionCategoryListDto */
		/* to access: ui-ref="car-config-optioncategory.list" */
		/* url: http://<hostname>/car/config/optioncategory/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-list-car-config.html',
				controller: 'CarConfigOptionCategoryListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-optioncategory.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigOptionCategoryViewDto */
		/* to access: ui-sref="car-config-optioncategory.detail" */
		/* url: http://<hostname>/car/config/optioncategory/detail/:optionCategoryId */
		url: '/detail/:optionCategoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-view-car-config.html',
				controller: 'OptionCategoryDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigOptionCategoryViewDtoFactory',
				function($stateParams, CarConfigOptionCategoryViewDtoFactory) {
					return CarConfigOptionCategoryViewDtoFactory.get({ id: $stateParams.optionCategoryId });
				},
			],
		},
	};

	$stateProvider.state('car-config-optioncategory.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigOptionCategoryEditDto */
		/* to access: ui-sref="car-config-optioncategory.edit" */
		/* url: http://<hostname>/car/config/optioncategory/edit/:optionCategoryId */
		url: '/edit/:optionCategoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-edit-car-config.html',
				controller: 'CarConfigOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigOptionCategoryEditDtoFactory',
				function($stateParams, $rootScope, CarConfigOptionCategoryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigOptionCategoryEditDtoFactory.get({ id: $stateParams.optionCategoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-optioncategory.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigOptionCategoryEditDto */
		/* to access: ui-sref="car-config-optioncategory.new" */
		/* url: http://<hostname>/car/config/optioncategory/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-edit-car-config.html',
				controller: 'CarConfigOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigOptionCategoryNewDtoFactory',
				function($stateParams, $rootScope, CarConfigOptionCategoryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigOptionCategoryNewDtoFactory.get({ id: $stateParams.optionCategoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-optioncategory.new', dtoCreateState);

	// create state for plus button on CarOption edit / new page for reference OptionCategory and dto property value car.config
	$stateProvider.state('car-config-caroption.new.newOptionCategory', getCreateState('/optioncategory/new'));
	$stateProvider.state('car-config-caroption.edit.newOptionCategory', getCreateState('/optioncategory/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/optioncategory/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('article-api-optioncategory', {
		/* abstract state for DTO ArticleApiOptionCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/optioncategory',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'OptionCategory',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiOptionCategoryEditDto */
		/* to access: ui-sref="article-api-optioncategory.edit" */
		/* url: http://<hostname>/articleapi/optioncategory/edit/:optionCategoryId */
		url: '/edit/:optionCategoryId',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-edit-article-api.html',
				controller: 'ArticleApiOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiOptionCategoryEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiOptionCategoryEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiOptionCategoryEditDtoFactory.get({ id: $stateParams.optionCategoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-optioncategory.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiOptionCategoryEditDto */
		/* to access: ui-sref="article-api-optioncategory.new" */
		/* url: http://<hostname>/articleapi/optioncategory/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/optioncategory/optioncategory-edit-article-api.html',
				controller: 'ArticleApiOptionCategoryEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiOptionCategoryNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiOptionCategoryNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiOptionCategoryNewDtoFactory.get({ id: $stateParams.optionCategoryId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-optioncategory.new', dtoCreateState);

	// create state for plus button on CarOption edit / new page for reference OptionCategory and dto property value article.api
	$stateProvider.state('article-api-caroption.new.newOptionCategory', getCreateState('/optioncategory/new'));
	$stateProvider.state('article-api-caroption.edit.newOptionCategory', getCreateState('/optioncategory/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/optioncategory/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
