/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtEmailListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		EmailFactory,
		RelationMgmtEmailListDtoSearchFactory,
		RelationMgmtEmailViewDtoFactory
	) {
		$scope.emailList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeEmail)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeEmail);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeEmail = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-email.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getEmailList = function() {
			RelationMgmtEmailListDtoSearchFactory.query(
				{
					emailTypeList: angular.isDefined($scope.emailTypeId) ? $scope.emailTypeId : null,
					relationId: angular.isDefined($scope.relationId) ? $scope.relationId : null,
					primaryEmailOfId: angular.isDefined($scope.primaryEmailOfId) ? $scope.primaryEmailOfId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.emailList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getEmailList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedEmailId = id;
			$('#deleteEmailConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtEmailViewDtoFactory.delete(
				{ id: $scope.selectedEmailId },
				function() {
					$scope.loadAll();
					$('#deleteEmailConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteEmailConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateEmailTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
