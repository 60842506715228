/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingFailedLogListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingFailedLogFactory,
		StockMgmtMappingFailedLogListDtoSearchFactory,
		StockMgmtMappingFailedLogViewDtoFactory
	) {
		$scope.mappingFailedLogList = [];
		$scope.selectedMappingFailedLogList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingFailedLog)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingFailedLog);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingFailedLog = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingfailedlog.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingFailedLogSearchOverview = angular.copy($scope.MappingFailedLogSearchOverview);

			$rootScope.pageSizeMappingFailedLog = angular.copy($scope.pageSize);

			StockMgmtMappingFailedLogListDtoSearchFactory.query(
				{
					page: $scope.MappingFailedLogSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingFailedLogSearchOverview.sourceKey,
					sourceField: $scope.MappingFailedLogSearchOverview.sourceField,
					sourceList: $scope.MappingFailedLogSearchOverview.sourceList,
					mappingTypeList: $scope.MappingFailedLogSearchOverview.mappingTypeList,

					sort: [
						$scope.MappingFailedLogSearchOverview.predicate + ',' + ($scope.MappingFailedLogSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingFailedLogList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$scope.mappingTypeList = result.mappingTypeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingFailedLogSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingFailedLogSearchOverview, $scope.originalMappingFailedLogSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingFailedLogSearchOverview = {};
			$rootScope.MappingFailedLogSearchOverview.page = 1;
			$rootScope.MappingFailedLogSearchOverview.predicate = 'id';
			$rootScope.MappingFailedLogSearchOverview.ascending = false;

			$scope.MappingFailedLogSearchOverview = angular.copy($rootScope.MappingFailedLogSearchOverview);

			$scope.originalMappingFailedLogSearchOverview = angular.copy($scope.MappingFailedLogSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingFailedLogSearchOverview) || $rootScope.MappingFailedLogSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingFailedLogSearchOverview = angular.copy($rootScope.MappingFailedLogSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingFailedLogId = id;
			$('#deleteMappingFailedLogConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingFailedLogViewDtoFactory.delete(
				{ id: $scope.selectedMappingFailedLogId },
				function() {
					$scope.search();
					$('#deleteMappingFailedLogConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteMappingFailedLogConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingFailedLogConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingFailedLogList, function(value, key) {
				promises.push(StockMgmtMappingFailedLogViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingFailedLogConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingFailedLogList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingFailedLogList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingFailedLogList = [];

			angular.forEach($scope.mappingFailedLogList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingFailedLogList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingFailedLogSearchOverview.predicate === itemName) {
				if ($scope.MappingFailedLogSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateMappingTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
