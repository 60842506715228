/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtGroupMemberListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		GroupMemberFactory,
		RelationMgmtGroupMemberListDtoSearchFactory,
		RelationMgmtGroupMemberViewDtoFactory,
		AutocompleteRelationMgmtEmployeesByQueryParamsFactory
	) {
		$scope.groupMemberList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeGroupMember)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeGroupMember);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeGroupMember = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-groupmember.new');
		};

		var getEmployeeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.GroupMemberSearchOverview.employee)) {
				return $scope.GroupMemberSearchOverview.employee.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.GroupMemberSearchOverview = angular.copy($scope.GroupMemberSearchOverview);

			$rootScope.pageSizeGroupMember = angular.copy($scope.pageSize);

			RelationMgmtGroupMemberListDtoSearchFactory.query(
				{
					page: $scope.GroupMemberSearchOverview.page - 1,
					size: $scope.pageSize,
					groupIdList: $scope.GroupMemberSearchOverview.groupIdList,
					employeeId: getEmployeeId(),

					sort: [$scope.GroupMemberSearchOverview.predicate + ',' + ($scope.GroupMemberSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.groupMemberList = result.items;

					$scope.groupReferenceFilterList = result.groupSearchOptions;

					$scope.autocompleteEmployees = function(val) {
						var params = {
							employeeTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isGroupMemberSearchOverviewDirty = function() {
			return !angular.equals($scope.GroupMemberSearchOverview, $scope.originalGroupMemberSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.GroupMemberSearchOverview = {};
			$rootScope.GroupMemberSearchOverview.page = 1;
			$rootScope.GroupMemberSearchOverview.predicate = 'id';
			$rootScope.GroupMemberSearchOverview.ascending = false;

			$scope.GroupMemberSearchOverview = angular.copy($rootScope.GroupMemberSearchOverview);

			$scope.originalGroupMemberSearchOverview = angular.copy($scope.GroupMemberSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.GroupMemberSearchOverview) || $rootScope.GroupMemberSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.GroupMemberSearchOverview = angular.copy($rootScope.GroupMemberSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.GroupMemberSearchOverview.predicate === itemName) {
				if ($scope.GroupMemberSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateGroupUISelect = function(item, model) {
			$scope.search();
		};
	});
