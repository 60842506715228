/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarStockDiscountFactory', function($resource) {
	return $resource(
		'api/carStockDiscounts/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCarStockDiscountViewDto
 */
angular.module('mmmApp').factory('StockMgmtCarStockDiscountViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstockdiscount/:id',
		{},
		{
			// Factory is only used to delete a StockMgmtCarStockDiscountViewDto
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCarStockDiscountListDto's
 */
angular.module('mmmApp').factory('StockMgmtCarStockDiscountListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstockdiscount',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtCarStockDiscountEditDto
 */
angular.module('mmmApp').factory('StockMgmtCarStockDiscountEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstockdiscount/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarStockDiscountFactory', function($resource) {
	return $resource(
		'api/carStockDiscounts/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarStockDiscounts without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockDiscountFactory', function($resource) {
	return $resource(
		'api/carStockDiscounts/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarStockDiscountsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carStockDiscounts/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
