/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtBaseTaskListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BaseTaskFactory,
		RelationMgmtBaseTaskListDtoSearchFactory,
		AutocompleteRelationMgmtEmployeesByQueryParamsFactory
	) {
		$scope.baseTaskList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBaseTask)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBaseTask);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeBaseTask = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-basetask.new');
		};

		var getAssignedEmployeeId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BaseTaskSearchOverview.assignedEmployee)) {
				return $scope.BaseTaskSearchOverview.assignedEmployee.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BaseTaskSearchOverview.tenant)) {
				return $scope.BaseTaskSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.BaseTaskSearchOverview = angular.copy($scope.BaseTaskSearchOverview);

			$rootScope.pageSizeBaseTask = angular.copy($scope.pageSize);

			RelationMgmtBaseTaskListDtoSearchFactory.query(
				{
					page: $scope.BaseTaskSearchOverview.page - 1,
					size: $scope.pageSize,
					title: $scope.BaseTaskSearchOverview.title,
					description: $scope.BaseTaskSearchOverview.description,
					statusList: $scope.BaseTaskSearchOverview.statusList,
					assignedGroupIdList: $scope.BaseTaskSearchOverview.assignedGroupIdList,
					assignedEmployeeId: getAssignedEmployeeId(),
					tenantId: getTenantId(),

					sort: [$scope.BaseTaskSearchOverview.predicate + ',' + ($scope.BaseTaskSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.baseTaskList = result.items;

					$scope.taskStatusList = result.statusSearchOptions;

					$scope.assignedGroupReferenceFilterList = result.assignedGroupSearchOptions;

					$scope.autocompleteEmployees = function(val) {
						var params = {
							employeeTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtEmployeesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBaseTaskSearchOverviewDirty = function() {
			return !angular.equals($scope.BaseTaskSearchOverview, $scope.originalBaseTaskSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BaseTaskSearchOverview = {};
			$rootScope.BaseTaskSearchOverview.page = 1;
			$rootScope.BaseTaskSearchOverview.predicate = 'id';
			$rootScope.BaseTaskSearchOverview.ascending = false;

			$scope.BaseTaskSearchOverview = angular.copy($rootScope.BaseTaskSearchOverview);

			$scope.originalBaseTaskSearchOverview = angular.copy($scope.BaseTaskSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BaseTaskSearchOverview) || $rootScope.BaseTaskSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BaseTaskSearchOverview = angular.copy($rootScope.BaseTaskSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BaseTaskSearchOverview.predicate === itemName) {
				if ($scope.BaseTaskSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateAssignedGroupUISelect = function(item, model) {
			$scope.search();
		};
	});
