/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtRelationRoleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationRoleFactory,
		RelationMgmtRelationRoleListDtoSearchFactory,
		RelationMgmtRelationRoleViewDtoFactory,
		AutocompleteRelationMgmtRelationsByQueryParamsFactory
	) {
		$scope.relationRoleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelationRole)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelationRole);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelationRole = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-relationrole.new');
		};

		var getRelationFromId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.relationFrom)) {
				return $scope.RelationRoleSearchOverview.relationFrom.id;
			} else {
				return undefined;
			}
		};

		var getRelationToId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.relationTo)) {
				return $scope.RelationRoleSearchOverview.relationTo.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.tenant)) {
				return $scope.RelationRoleSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute dateFrom of javaType java.time.LocalDate
		$scope.processDateFrom = function() {
			var dateFromFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.dateFromFrom);
			var dateFromToDefined = $rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.dateFromTo);

			if (dateFromFromDefined) {
				$scope.RelationRoleSearchOverview.dateFromFromString = $rootScope.convertDateWithoutZone(
					$scope.RelationRoleSearchOverview.dateFromFrom
				);
			} else {
				$scope.RelationRoleSearchOverview.dateFromFromString = undefined;
			}

			if (dateFromToDefined) {
				$scope.RelationRoleSearchOverview.dateFromToString = $rootScope.convertDateWithoutZone(
					$scope.RelationRoleSearchOverview.dateFromTo
				);
			} else {
				$scope.RelationRoleSearchOverview.dateFromToString = undefined;
			}
		};

		// handle attribute dateUntil of javaType java.time.LocalDate
		$scope.processDateUntil = function() {
			var dateUntilFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.dateUntilFrom);
			var dateUntilToDefined = $rootScope.isDefinedAndNotNull($scope.RelationRoleSearchOverview.dateUntilTo);

			if (dateUntilFromDefined) {
				$scope.RelationRoleSearchOverview.dateUntilFromString = $rootScope.convertDateWithoutZone(
					$scope.RelationRoleSearchOverview.dateUntilFrom
				);
			} else {
				$scope.RelationRoleSearchOverview.dateUntilFromString = undefined;
			}

			if (dateUntilToDefined) {
				$scope.RelationRoleSearchOverview.dateUntilToString = $rootScope.convertDateWithoutZone(
					$scope.RelationRoleSearchOverview.dateUntilTo
				);
			} else {
				$scope.RelationRoleSearchOverview.dateUntilToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processDateFrom();
			$scope.processDateUntil();

			$rootScope.RelationRoleSearchOverview = angular.copy($scope.RelationRoleSearchOverview);

			$rootScope.pageSizeRelationRole = angular.copy($scope.pageSize);

			RelationMgmtRelationRoleListDtoSearchFactory.query(
				{
					page: $scope.RelationRoleSearchOverview.page - 1,
					size: $scope.pageSize,
					dateFromFrom: $scope.RelationRoleSearchOverview.dateFromFromString,
					dateFromTo: $scope.RelationRoleSearchOverview.dateFromToString,
					dateUntilFrom: $scope.RelationRoleSearchOverview.dateUntilFromString,
					dateUntilTo: $scope.RelationRoleSearchOverview.dateUntilToString,
					relationFromId: getRelationFromId(),
					relationRoleTypeFromToList: $scope.RelationRoleSearchOverview.relationRoleTypeFromToList,
					relationToId: getRelationToId(),
					tenantId: getTenantId(),

					sort: [$scope.RelationRoleSearchOverview.predicate + ',' + ($scope.RelationRoleSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationRoleList = result.items;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.relationRoleTypeList = result.relationRoleTypeFromToSearchOptions;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationRoleSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationRoleSearchOverview, $scope.originalRelationRoleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationRoleSearchOverview = {};
			$rootScope.RelationRoleSearchOverview.page = 1;
			$rootScope.RelationRoleSearchOverview.predicate = 'id';
			$rootScope.RelationRoleSearchOverview.ascending = false;

			$scope.RelationRoleSearchOverview = angular.copy($rootScope.RelationRoleSearchOverview);

			$scope.originalRelationRoleSearchOverview = angular.copy($scope.RelationRoleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationRoleSearchOverview) || $rootScope.RelationRoleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationRoleSearchOverview = angular.copy($rootScope.RelationRoleSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationRoleId = id;
			$('#deleteRelationRoleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtRelationRoleViewDtoFactory.delete(
				{ id: $scope.selectedRelationRoleId },
				function() {
					$scope.search();
					$('#deleteRelationRoleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationRoleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationRoleSearchOverview.predicate === itemName) {
				if ($scope.RelationRoleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateRelationRoleTypeFromToUISelect = function(item, model) {
			$scope.search();
		};
	});
