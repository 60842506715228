/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarEngineFactory', function($resource) {
	return $resource(
		'api/carEngines/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarEngineViewDto
 */
angular.module('mmmApp').factory('CarConfigCarEngineViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carengine/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarEngineListDto's
 */
angular.module('mmmApp').factory('CarConfigCarEngineListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carengine',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarEngineEditDto
 */
angular.module('mmmApp').factory('CarConfigCarEngineEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carengine/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarEngineEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarEngineNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carengine/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarEngineEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarEngineEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carengine/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarEngineEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarEngineEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carengine/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarEngineEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarEngineNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carengine/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarEngineEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarEngineEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carengine/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarEngineFactory', function($resource) {
	return $resource(
		'api/carEngines/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarEngines without the use of paging
angular.module('mmmApp').factory('NoPagingCarEngineFactory', function($resource) {
	return $resource(
		'api/carEngines/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible Statuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStatusFactory', function($resource) {
	return $resource(
		'api/carEngine/statuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ArticleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingArticleTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/articleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSizes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSizeFactory', function($resource) {
	return $resource(
		'api/carEngine/imageSizes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible VatTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingVatTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/vatTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/priceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/carEngine/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carEngine/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible FuelTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingFuelTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/fuelTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible HybridTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingHybridTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/hybridTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible HybridPowertrains Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingHybridPowertrainFactory', function($resource) {
	return $resource(
		'api/carEngine/hybridPowertrains',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BatteryTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingBatteryTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/batteryTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BatteryTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingBatteryPlugTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/batteryTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible BatteryFastChargerPlugTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingBatteryFastChargerPlugTypeFactory', function($resource) {
	return $resource(
		'api/carEngine/batteryFastChargerPlugTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarConfigurationsByCarEngineFactory', function($resource) {
	return $resource(
		'api/carConfigurations/carEngine/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarEnginesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carEngines/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarEngineSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carengines/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
