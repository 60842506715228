/** @format */

'use strict';

// Consignment has edit layout enabled. Edit layout template url: consignment-dialog.html
// Consignment has detail layout enabled. Detail layout template url: consignment-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Consignment'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('consignment'); // main translation loaded for consignment
		$translatePartialLoader.addPart('consignmentagreement'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstock'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-consignment', {
		/* abstract state for DTO StockMgmtConsignmentListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/consignment',
		data: {
			authorities: ['ROLE_ADMIN'],
			pageTitle: 'Consignment',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtConsignmentListDto */
		/* to access: ui-ref="stock-mgmt-consignment.list" */
		/* url: http://<hostname>/stockmgmt/consignment/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/consignment/consignment-list-stock-mgmt.html',
				controller: 'StockMgmtConsignmentListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-consignment.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtConsignmentViewDto */
		/* to access: ui-sref="stock-mgmt-consignment.detail" */
		/* url: http://<hostname>/stockmgmt/consignment/detail/:consignmentId */
		url: '/detail/:consignmentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/consignment/consignment-view-stock-mgmt.html',
				controller: 'ConsignmentDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtConsignmentViewDtoFactory',
				function($stateParams, StockMgmtConsignmentViewDtoFactory) {
					return StockMgmtConsignmentViewDtoFactory.get({ id: $stateParams.consignmentId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-consignment.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtConsignmentEditDto */
		/* to access: ui-sref="stock-mgmt-consignment.edit" */
		/* url: http://<hostname>/stockmgmt/consignment/edit/:consignmentId */
		url: '/edit/:consignmentId',
		views: {
			'content@': {
				templateUrl: 'app/entities/consignment/consignment-edit-stock-mgmt.html',
				controller: 'StockMgmtConsignmentEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtConsignmentEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtConsignmentEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtConsignmentEditDtoFactory.get({ id: $stateParams.consignmentId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-consignment.edit', dtoEditState);
});
