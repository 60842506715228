/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCrmCarDocumentListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CrmCarDocumentFactory,
		StockMgmtCrmCarDocumentListDtoSearchFactory,
		AutocompleteStockMgmtCarsByQueryParamsFactory
	) {
		$scope.crmCarDocumentList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCrmCarDocument)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCrmCarDocument);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCrmCarDocument = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-crmcardocument.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarDocumentSearchOverview.tenant)) {
				return $scope.CrmCarDocumentSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		var getCrmCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarDocumentSearchOverview.crmCar)) {
				return $scope.CrmCarDocumentSearchOverview.crmCar.id;
			} else {
				return undefined;
			}
		};

		var getDocumentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CrmCarDocumentSearchOverview.document)) {
				return $scope.CrmCarDocumentSearchOverview.document.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CrmCarDocumentSearchOverview = angular.copy($scope.CrmCarDocumentSearchOverview);

			$rootScope.pageSizeCrmCarDocument = angular.copy($scope.pageSize);

			StockMgmtCrmCarDocumentListDtoSearchFactory.query(
				{
					page: $scope.CrmCarDocumentSearchOverview.page - 1,
					size: $scope.pageSize,
					tenantId: getTenantId(),
					crmCarId: getCrmCarId(),
					documentId: getDocumentId(),

					sort: [$scope.CrmCarDocumentSearchOverview.predicate + ',' + ($scope.CrmCarDocumentSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.crmCarDocumentList = result.items;

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteStockMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.documentReferenceFilterList = result.documentSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCrmCarDocumentSearchOverviewDirty = function() {
			return !angular.equals($scope.CrmCarDocumentSearchOverview, $scope.originalCrmCarDocumentSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CrmCarDocumentSearchOverview = {};
			$rootScope.CrmCarDocumentSearchOverview.page = 1;
			$rootScope.CrmCarDocumentSearchOverview.predicate = 'id';
			$rootScope.CrmCarDocumentSearchOverview.ascending = false;

			$scope.CrmCarDocumentSearchOverview = angular.copy($rootScope.CrmCarDocumentSearchOverview);

			$scope.originalCrmCarDocumentSearchOverview = angular.copy($scope.CrmCarDocumentSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CrmCarDocumentSearchOverview) || $rootScope.CrmCarDocumentSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CrmCarDocumentSearchOverview = angular.copy($rootScope.CrmCarDocumentSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CrmCarDocumentSearchOverview.predicate === itemName) {
				if ($scope.CrmCarDocumentSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
