/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'VehiclePriceInformation'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('vehiclepriceinformation'); // main translation loaded for vehiclepriceinformation
		$translatePartialLoader.addPart('pricesource'); // one to many reference from main entity
		$translatePartialLoader.addPart('crmcar'); // one to many reference from main entity

		return $translate.refresh();
	};
});
