/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('SalesOrderDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.salesOrder = entity;
	$scope.objectName = 'SalesOrder';
	$scope.salesOrderId = $stateParams.salesOrderId;

	if (angular.isUndefined($rootScope.salesOrderActiveTab)) {
		//set the default here
		$rootScope.salesOrderActiveTab = 0;
	}

	$scope.goToEditState = function(salesOrder) {
		$state.go('salesorder.detail.edit({salesOrderId:salesOrder.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:salesOrderUpdate', function(event, result) {
		$scope.salesOrder = result;
	});
});
