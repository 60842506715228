/** @format */

// Custom js based on jhipster4_ajs - jhipster4_ajs/admin/user-management/user-management-dialog_controller.ftl
(function() {
	'use strict';

	angular.module('mmmApp').controller('UserManagementDialogController', UserManagementDialogController);

	UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

	function UserManagementDialogController($stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
		var vm = this;

		vm.authorities = [
			'ROLE_USER',
			'ROLE_ADMIN',
			'ROLE_CARCONFIG',
			'ROLE_CARCONFIG_FRONTEND',
			'ROLE_CIC_API',
			'ROLE_DEALER',
			'ROLE_DEALER_PREVIEW',
			'ROLE_RELATION',
			'ROLE_RELATION_PREVIEW',
			'ROLE_DEALER_ADMIN',
			'ROLE_DEALER_ADMIN_PREVIEW',
			'ROLE_ONLINE_ADVICE',
			'ROLE_VEHICLE_API',
			'ROLE_GENERIC_VEHICLE_API',
			'ROLE_ARTICLE_MANAGEMENT',
			'ROLE_ARTICLE_MANAGEMENT_PREVIEW',
			'ROLE_WEBSITE_API',
			'ROLE_ARTICLE_API',
			'ROLE_TASK_ADMIN',
			'ROLE_TASK',
            'ROLE_CRM',
            'ROLE_SALES',
            'ROLE_INSIGHTS',
            'ROLE_USED',
            'ROLE_LEADS',
            'ROLE_DEMO',
            'ROLE_AMI_LITE'
		];

        vm.userTypes = ['NONE', 'VEHICLE_API', 'GENERIC_VEHICLE_API', 'ARTICLE_API', 'MANUAL']

		vm.locales = ['nl_NL', 'nl_BE', 'fr_BE'];

		vm.clear = clear;
		vm.languages = null;
		vm.save = save;
		vm.user = entity;

		JhiLanguageService.getAll().then(function(languages) {
			vm.languages = languages;
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function onSaveSuccess(result) {
			vm.isSaving = false;
			$uibModalInstance.close(result);
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		function save() {
			vm.isSaving = true;
			if (vm.user.id !== null) {
				User.update(vm.user, onSaveSuccess, onSaveError);
			} else {
				User.save(vm.user, onSaveSuccess, onSaveError);
			}
		}
	}
})();
