/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('TagFactory', function($resource) {
	return $resource(
		'api/tags/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigTagViewDto
 */
angular.module('mmmApp').factory('CarConfigTagViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/tag/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigTagListDto's
 */
angular.module('mmmApp').factory('CarConfigTagListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/tag',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigTagEditDto
 */
angular.module('mmmApp').factory('CarConfigTagEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/tag/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigTagEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigTagNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/tag/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewTagFactory', function($resource) {
	return $resource(
		'api/tags/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Tags without the use of paging
angular.module('mmmApp').factory('NoPagingTagFactory', function($resource) {
	return $resource(
		'api/tags/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleTagsByTagFactory', function($resource) {
	return $resource(
		'api/articleTags/tag/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('ArticleFromArticleTagPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleTagList/AsArticleList/tag/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllTagsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/tags/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
