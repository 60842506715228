/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerFactory,
		StockMgmtDealerListDtoSearchFactory
	) {
		$scope.dealerList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealer)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealer);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealer = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-dealer.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerSearchOverview.tenant)) {
				return $scope.DealerSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute hexonDealer of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DealerSearchOverview = angular.copy($scope.DealerSearchOverview);

			$rootScope.pageSizeDealer = angular.copy($scope.pageSize);

			StockMgmtDealerListDtoSearchFactory.query(
				{
					page: $scope.DealerSearchOverview.page - 1,
					size: $scope.pageSize,
					hexonAdvertiserIdFrom: $scope.DealerSearchOverview.hexonAdvertiserIdFrom,
					hexonAdvertiserIdTo: $scope.DealerSearchOverview.hexonAdvertiserIdTo,
					name: $scope.DealerSearchOverview.name,
					address: $scope.DealerSearchOverview.address,
					zipCode: $scope.DealerSearchOverview.zipCode,
					city: $scope.DealerSearchOverview.city,
					contact: $scope.DealerSearchOverview.contact,
					twitter: $scope.DealerSearchOverview.twitter,
					whatsApp: $scope.DealerSearchOverview.whatsApp,
					facebook: $scope.DealerSearchOverview.facebook,
					hexonDealer: $scope.DealerSearchOverview.hexonDealerGroup,
					tenantId: getTenantId(),
					dutchRegionList: $scope.DealerSearchOverview.dutchRegionList,
					eSignServiceList: $scope.DealerSearchOverview.eSignServiceList,
					countryList: $scope.DealerSearchOverview.countryList,
					localeList: $scope.DealerSearchOverview.localeList,

					sort: [$scope.DealerSearchOverview.predicate + ',' + ($scope.DealerSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerList = result.items;

					$scope.regionDutchList = result.dutchRegionSearchOptions;

					$scope.eSignServiceList = result.eSignServiceSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.supportedLocaleList = result.localeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDealerSearchOverviewDirty = function() {
			return !angular.equals($scope.DealerSearchOverview, $scope.originalDealerSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DealerSearchOverview = {};
			$rootScope.DealerSearchOverview.page = 1;
			$rootScope.DealerSearchOverview.predicate = 'id';
			$rootScope.DealerSearchOverview.ascending = false;

			$scope.DealerSearchOverview = angular.copy($rootScope.DealerSearchOverview);

			$scope.originalDealerSearchOverview = angular.copy($scope.DealerSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DealerSearchOverview) || $rootScope.DealerSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DealerSearchOverview = angular.copy($rootScope.DealerSearchOverview);

			$scope.search();
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DealerSearchOverview.predicate === itemName) {
				if ($scope.DealerSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateDutchRegionUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateESignServiceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateLocaleUISelect = function(item, model) {
			$scope.search();
		};
	});
