/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('WebsiteApiArticleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFactory,
		WebsiteApiArticleListDtoSearchFactory
	) {
		$scope.articleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticle)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticle);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('website-api-article.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticleSearchOverview.equivalent)) {
				return $scope.ArticleSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);

			$rootScope.pageSizeArticle = angular.copy($scope.pageSize);

			if ($rootScope.isDefinedAndNotNull($scope.ArticleSearchOverview.name)) {
				$scope.ArticleSearchOverview.predicate = 'name';
			}
			if ($rootScope.isDefinedAndNotNull($scope.ArticleSearchOverview.description)) {
				$scope.ArticleSearchOverview.predicate = 'description';
			}

			WebsiteApiArticleListDtoSearchFactory.query(
				{
					page: $scope.ArticleSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.ArticleSearchOverview.name,
					productCode: $scope.ArticleSearchOverview.productCode,
					factoryCode: $scope.ArticleSearchOverview.factoryCode,
					description: $scope.ArticleSearchOverview.description,
					canBeOrdered: $scope.ArticleSearchOverview.canBeOrderedGroup,
					spotlight: $scope.ArticleSearchOverview.spotlightGroup,
					creator: $scope.ArticleSearchOverview.creator,
					updater: $scope.ArticleSearchOverview.updater,
					statusList: $scope.ArticleSearchOverview.statusList,
					typeList: $scope.ArticleSearchOverview.typeList,
					imageSize: $scope.ArticleSearchOverview.imageSize,
					vatType: $scope.ArticleSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.ArticleSearchOverview.priceTypeList,
					brandIdList: $scope.ArticleSearchOverview.brandIdList,
					country: $scope.ArticleSearchOverview.country,
					locale: $scope.ArticleSearchOverview.locale,

					sort: [$scope.ArticleSearchOverview.predicate + ',' + ($scope.ArticleSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticleSearchOverview, $scope.originalArticleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleSearchOverview = {};
			$rootScope.ArticleSearchOverview.page = 1;
			$rootScope.ArticleSearchOverview.predicate = 'id';
			$rootScope.ArticleSearchOverview.ascending = false;

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);

			$scope.originalArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleSearchOverview) || $rootScope.ArticleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleSearchOverview = angular.copy($rootScope.ArticleSearchOverview);
			// generated from hook src/snippets/custom/jhipster/controller/overview/additional-actions-load.ftl
			if (angular.isDefined($rootScope.selectedWorkingCarBrand)) {
				$scope.selectedBrandIdList = [];
				if ($rootScope.selectedWorkingCarBrand.id != 0) {
					$scope.selectedBrandIdList = [];
					$scope.selectedBrandIdList.push($rootScope.selectedWorkingCarBrand.id);
				}
				$scope.ArticleSearchOverview.brandIdList = angular.copy($scope.selectedBrandIdList);
				$rootScope.ArticleSearchOverview = angular.copy($scope.ArticleSearchOverview);
			}
			if ($rootScope.selectedWorkingCountry && $rootScope.selectedWorkingCountry !== '') {
				$scope.ArticleSearchOverview.countryList = [$rootScope.selectedWorkingCountry];
			} else {
				$scope.ArticleSearchOverview.countryList = [];
			}
			$scope.search();
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// Go to the new state of objects that inherit from article.
		// Custom code from custom/jhipster/controller/overview/additional-actions.ftl

		$rootScope.$watch('selectedWorkingCarBrand.id', function() {
			$scope.loadPage();
		});

		$scope.goToNewStateChild = function(modelObjectName) {
			$state.go('car-config-' + modelObjectName + '.new');
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:articleUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleSearchOverview.predicate === itemName) {
				if ($scope.ArticleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
