/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'ArticleCategory'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('articlecategory'); // main translation loaded for articlecategory
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('category'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // coupling entity reference loaded for article
		$translatePartialLoader.addPart('category'); // coupling entity reference loaded for category

		return $translate.refresh();
	};

	$stateProvider.state('car-config-articlecategory', {
		/* abstract state for DTO CarConfigArticleCategoryListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/articlecategory',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'ArticleCategory',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigArticleCategoryListDto */
		/* to access: ui-ref="car-config-articlecategory.list" */
		/* url: http://<hostname>/car/config/articlecategory/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/articlecategory/articlecategory-list-car-config.html',
				controller: 'CarConfigArticleCategoryListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-articlecategory.list', dtoListState);
});
