/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingBaseListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingBaseFactory,
		StockMgmtMappingBaseListDtoSearchFactory
	) {
		$scope.mappingBaseList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingBase)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingBase);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingBase = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-mappingbase.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.MappingBaseSearchOverview = angular.copy($scope.MappingBaseSearchOverview);

			$rootScope.pageSizeMappingBase = angular.copy($scope.pageSize);

			StockMgmtMappingBaseListDtoSearchFactory.query(
				{
					page: $scope.MappingBaseSearchOverview.page - 1,
					size: $scope.pageSize,
					sourceKey: $scope.MappingBaseSearchOverview.sourceKey,
					sourceField: $scope.MappingBaseSearchOverview.sourceField,
					sourceList: $scope.MappingBaseSearchOverview.sourceList,

					sort: [$scope.MappingBaseSearchOverview.predicate + ',' + ($scope.MappingBaseSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingBaseList = result.items;

					$scope.mappingSourceList = result.sourceSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isMappingBaseSearchOverviewDirty = function() {
			return !angular.equals($scope.MappingBaseSearchOverview, $scope.originalMappingBaseSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.MappingBaseSearchOverview = {};
			$rootScope.MappingBaseSearchOverview.page = 1;
			$rootScope.MappingBaseSearchOverview.predicate = 'id';
			$rootScope.MappingBaseSearchOverview.ascending = false;

			$scope.MappingBaseSearchOverview = angular.copy($rootScope.MappingBaseSearchOverview);

			$scope.originalMappingBaseSearchOverview = angular.copy($scope.MappingBaseSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.MappingBaseSearchOverview) || $rootScope.MappingBaseSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.MappingBaseSearchOverview = angular.copy($rootScope.MappingBaseSearchOverview);

			$scope.search();
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingBaseUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.MappingBaseSearchOverview.predicate === itemName) {
				if ($scope.MappingBaseSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};
	});
