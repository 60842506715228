/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('DealerFactory', function($resource) {
	return $resource(
		'api/dealers/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtDealerViewDto
 */
angular.module('mmmApp').factory('StockMgmtDealerViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtDealerListDto's
 */
angular.module('mmmApp').factory('StockMgmtDealerListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtDealerEditDto
 */
angular.module('mmmApp').factory('StockMgmtDealerEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDealerEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtDealerNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtDealerEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('StockMgmtDealerNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of StockMgmtDealerEditDto
 */
angular.module('mmmApp').factory('StockMgmtDealerBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/dealer/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiDealerListDto's
 */
angular.module('mmmApp').factory('WebsiteApiDealerListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/dealer',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewDealerFactory', function($resource) {
	return $resource(
		'api/dealers/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Dealers without the use of paging
angular.module('mmmApp').factory('NoPagingDealerFactory', function($resource) {
	return $resource(
		'api/dealers/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RegionDutchs Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRegionDutchFactory', function($resource) {
	return $resource(
		'api/dealer/regionDutchs',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ESignServices Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingESignServiceFactory', function($resource) {
	return $resource(
		'api/dealer/eSignServices',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedCountries Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedCountryFactory', function($resource) {
	return $resource(
		'api/dealer/supportedCountries',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/dealer/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('OpeningHoursByDealerFactory', function($resource) {
	return $resource(
		'api/openingHours/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerHexonPortalsByDealerFactory', function($resource) {
	return $resource(
		'api/dealerHexonPortals/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('HexonPublishRulesByDealerFactory', function($resource) {
	return $resource(
		'api/hexonPublishRules/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerContentsByDealerFactory', function($resource) {
	return $resource(
		'api/dealerContents/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerImagesByDealerFactory', function($resource) {
	return $resource(
		'api/dealerImages/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerFaqsByDealerFactory', function($resource) {
	return $resource(
		'api/dealerFaqs/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealerAuthenticationsByDealerFactory', function($resource) {
	return $resource(
		'api/dealerAuthentications/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('AuthenticationTypeFromDealerAuthenticationPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/dealerAuthenticationList/AsAuthenticationTypeList/dealer/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('UserCarStockAccessBoundDealersByDealerFactory', function($resource) {
	return $resource(
		'api/userCarStockAccessBoundDealers/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('UserCarStockAccessBoundFromUserCarStockAccessBoundDealerPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/userCarStockAccessBoundDealerList/AsUserCarStockAccessBoundList/dealer/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('LocationsByDealerFactory', function($resource) {
	return $resource(
		'api/locations/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('DealersByDealerFactory', function($resource) {
	return $resource(
		'api/dealers/dealer/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllDealersByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/dealers/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
