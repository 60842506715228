(function() {
	'use strict';

	angular.module('mmmApp').factory('settingsHandler', settingsHandler);

	settingsHandler.$inject = [ '$rootScope', '$http' ];

	function settingsHandler($rootScope, $http) {
		return {
			initialize : initialize
		};

		function initialize() {
			return $http.get('api/settings/env').then(function(response) {
				$rootScope.CARCONFIG_URL = response.data['carconfiguratorUrl'];
				angular.module('mmmApp').constant('APP_SETTINGS', response.data);
			});
		}
	}
})();
