/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtHexonPortalListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		HexonPortalFactory,
		StockMgmtHexonPortalListDtoSearchFactory,
		StockMgmtHexonPortalViewDtoFactory
	) {
		$scope.hexonPortalList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeHexonPortal)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeHexonPortal);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeHexonPortal = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-hexonportal.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.HexonPortalSearchOverview = angular.copy($scope.HexonPortalSearchOverview);

			$rootScope.pageSizeHexonPortal = angular.copy($scope.pageSize);

			StockMgmtHexonPortalListDtoSearchFactory.query(
				{
					page: $scope.HexonPortalSearchOverview.page - 1,
					size: $scope.pageSize,
					code: $scope.HexonPortalSearchOverview.code,
					name: $scope.HexonPortalSearchOverview.name,
					customName: $scope.HexonPortalSearchOverview.customName,

					sort: [$scope.HexonPortalSearchOverview.predicate + ',' + ($scope.HexonPortalSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.hexonPortalList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isHexonPortalSearchOverviewDirty = function() {
			return !angular.equals($scope.HexonPortalSearchOverview, $scope.originalHexonPortalSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.HexonPortalSearchOverview = {};
			$rootScope.HexonPortalSearchOverview.page = 1;
			$rootScope.HexonPortalSearchOverview.predicate = 'id';
			$rootScope.HexonPortalSearchOverview.ascending = false;

			$scope.HexonPortalSearchOverview = angular.copy($rootScope.HexonPortalSearchOverview);

			$scope.originalHexonPortalSearchOverview = angular.copy($scope.HexonPortalSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.HexonPortalSearchOverview) || $rootScope.HexonPortalSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.HexonPortalSearchOverview = angular.copy($rootScope.HexonPortalSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedHexonPortalId = id;
			$('#deleteHexonPortalConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtHexonPortalViewDtoFactory.delete(
				{ id: $scope.selectedHexonPortalId },
				function() {
					$scope.search();
					$('#deleteHexonPortalConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteHexonPortalConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:hexonPortalUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.HexonPortalSearchOverview.predicate === itemName) {
				if ($scope.HexonPortalSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
