/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtCountryListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CountryFactory,
		RelationMgmtCountryListDtoSearchFactory,
		RelationMgmtCountryViewDtoFactory
	) {
		$scope.countryList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCountry)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCountry);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCountry = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-country.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CountrySearchOverview = angular.copy($scope.CountrySearchOverview);

			$rootScope.pageSizeCountry = angular.copy($scope.pageSize);

			RelationMgmtCountryListDtoSearchFactory.query(
				{
					page: $scope.CountrySearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CountrySearchOverview.name,
					code: $scope.CountrySearchOverview.code,

					sort: [$scope.CountrySearchOverview.predicate + ',' + ($scope.CountrySearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.countryList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCountrySearchOverviewDirty = function() {
			return !angular.equals($scope.CountrySearchOverview, $scope.originalCountrySearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CountrySearchOverview = {};
			$rootScope.CountrySearchOverview.page = 1;
			$rootScope.CountrySearchOverview.predicate = 'id';
			$rootScope.CountrySearchOverview.ascending = false;

			$scope.CountrySearchOverview = angular.copy($rootScope.CountrySearchOverview);

			$scope.originalCountrySearchOverview = angular.copy($scope.CountrySearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CountrySearchOverview) || $rootScope.CountrySearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CountrySearchOverview = angular.copy($rootScope.CountrySearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCountryId = id;
			$('#deleteCountryConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtCountryViewDtoFactory.delete(
				{ id: $scope.selectedCountryId },
				function() {
					$scope.search();
					$('#deleteCountryConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCountryConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CountrySearchOverview.predicate === itemName) {
				if ($scope.CountrySearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
