/** @format */

'use strict';

// CarConfigurationDependentPrice has edit layout enabled. Edit layout template url: carconfigurationdependentprice-dialog.html
// CarConfigurationDependentPrice has detail layout enabled. Detail layout template url: carconfigurationdependentprice-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarConfigurationDependentPrice'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // main translation loaded for carconfigurationdependentprice
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carconfigurationdependentprice', {
		/* abstract state for DTO CarConfigCarConfigurationDependentPriceListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carconfigurationdependentprice',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarConfigurationDependentPrice',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarConfigurationDependentPriceListDto */
		/* to access: ui-ref="car-config-carconfigurationdependentprice.list" */
		/* url: http://<hostname>/car/config/carconfigurationdependentprice/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-list-car-config.html',
				controller: 'CarConfigCarConfigurationDependentPriceListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carconfigurationdependentprice.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarConfigurationDependentPriceViewDto */
		/* to access: ui-sref="car-config-carconfigurationdependentprice.detail" */
		/* url: http://<hostname>/car/config/carconfigurationdependentprice/detail/:carConfigurationDependentPriceId */
		url: '/detail/:carConfigurationDependentPriceId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-view-car-config.html',
				controller: 'CarConfigurationDependentPriceDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarConfigurationDependentPriceViewDtoFactory',
				function($stateParams, CarConfigCarConfigurationDependentPriceViewDtoFactory) {
					return CarConfigCarConfigurationDependentPriceViewDtoFactory.get({ id: $stateParams.carConfigurationDependentPriceId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationdependentprice.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarConfigurationDependentPriceEditDto */
		/* to access: ui-sref="car-config-carconfigurationdependentprice.edit" */
		/* url: http://<hostname>/car/config/carconfigurationdependentprice/edit/:carConfigurationDependentPriceId */
		url: '/edit/:carConfigurationDependentPriceId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-edit-car-config.html',
				controller: 'CarConfigCarConfigurationDependentPriceEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationDependentPriceEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationDependentPriceEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationDependentPriceEditDtoFactory.get({
							id: $stateParams.carConfigurationDependentPriceId,
						}).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationdependentprice.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarConfigurationDependentPriceEditDto */
		/* to access: ui-sref="car-config-carconfigurationdependentprice.new" */
		/* url: http://<hostname>/car/config/carconfigurationdependentprice/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-edit-car-config.html',
				controller: 'CarConfigCarConfigurationDependentPriceEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationDependentPriceNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationDependentPriceNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarConfigurationDependentPriceNewDtoFactory.get({
							id: $stateParams.carConfigurationDependentPriceId,
						}).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationdependentprice.new', dtoCreateState);

	/* Bulk edit state for DTO car.config */
	var dtoBulkEditState = {
		/* Bulk edit state for DTO CarConfigCarConfigurationDependentPriceEditDto */
		/* to access: ui-sref="car-config-carconfigurationdependentprice.bulkedit" */
		/* url: http://<hostname>/car/config/carconfigurationdependentprice/bulkedit */
		url: '/bulkedit',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-bulkedit-car-config.html',
				controller: 'CarConfigCarConfigurationDependentPriceBulkEditDtoController',
			},
		},
		params: {
			selectedCarConfigurationDependentPriceList: [''],
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarConfigurationDependentPriceNewBulkEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarConfigurationDependentPriceNewBulkEditDtoFactory) {
					return CarConfigCarConfigurationDependentPriceNewBulkEditDtoFactory.get().$promise.then(function(result) {
						return result;
					});
				},
			],
		},
	};

	$stateProvider.state('car-config-carconfigurationdependentprice.bulkedit', dtoBulkEditState);

	$stateProvider.state('article-api-carconfigurationdependentprice', {
		/* abstract state for DTO ArticleApiCarConfigurationDependentPriceListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carconfigurationdependentprice',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarConfigurationDependentPrice',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarConfigurationDependentPriceEditDto */
		/* to access: ui-sref="article-api-carconfigurationdependentprice.edit" */
		/* url: http://<hostname>/articleapi/carconfigurationdependentprice/edit/:carConfigurationDependentPriceId */
		url: '/edit/:carConfigurationDependentPriceId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationDependentPriceEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationDependentPriceEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationDependentPriceEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationDependentPriceEditDtoFactory.get({
							id: $stateParams.carConfigurationDependentPriceId,
						}).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfigurationdependentprice.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarConfigurationDependentPriceEditDto */
		/* to access: ui-sref="article-api-carconfigurationdependentprice.new" */
		/* url: http://<hostname>/articleapi/carconfigurationdependentprice/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carconfigurationdependentprice/carconfigurationdependentprice-edit-article-api.html',
				controller: 'ArticleApiCarConfigurationDependentPriceEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarConfigurationDependentPriceNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarConfigurationDependentPriceNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarConfigurationDependentPriceNewDtoFactory.get({
							id: $stateParams.carConfigurationDependentPriceId,
						}).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carconfigurationdependentprice.new', dtoCreateState);
});
