/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtOrderLineTemplateListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineTemplateFactory,
		RelationMgmtOrderLineTemplateListDtoSearchFactory,
		RelationMgmtOrderLineTemplateViewDtoFactory,
		AutocompleteRelationMgmtArticlesByQueryParamsFactory
	) {
		$scope.orderLineTemplateList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLineTemplate)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLineTemplate);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOrderLineTemplate = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-orderlinetemplate.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.article)) {
				return $scope.OrderLineTemplateSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.tenant)) {
				return $scope.OrderLineTemplateSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute validFrom of javaType java.time.LocalDate
		$scope.processValidFrom = function() {
			var validFromFromDefined = $rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.validFromFrom);
			var validFromToDefined = $rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.validFromTo);

			if (validFromFromDefined) {
				$scope.OrderLineTemplateSearchOverview.validFromFromString = $rootScope.convertDateWithoutZone(
					$scope.OrderLineTemplateSearchOverview.validFromFrom
				);
			} else {
				$scope.OrderLineTemplateSearchOverview.validFromFromString = undefined;
			}

			if (validFromToDefined) {
				$scope.OrderLineTemplateSearchOverview.validFromToString = $rootScope.convertDateWithoutZone(
					$scope.OrderLineTemplateSearchOverview.validFromTo
				);
			} else {
				$scope.OrderLineTemplateSearchOverview.validFromToString = undefined;
			}
		};

		// handle attribute validThru of javaType java.time.LocalDate
		$scope.processValidThru = function() {
			var validThruFromDefined = $rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.validThruFrom);
			var validThruToDefined = $rootScope.isDefinedAndNotNull($scope.OrderLineTemplateSearchOverview.validThruTo);

			if (validThruFromDefined) {
				$scope.OrderLineTemplateSearchOverview.validThruFromString = $rootScope.convertDateWithoutZone(
					$scope.OrderLineTemplateSearchOverview.validThruFrom
				);
			} else {
				$scope.OrderLineTemplateSearchOverview.validThruFromString = undefined;
			}

			if (validThruToDefined) {
				$scope.OrderLineTemplateSearchOverview.validThruToString = $rootScope.convertDateWithoutZone(
					$scope.OrderLineTemplateSearchOverview.validThruTo
				);
			} else {
				$scope.OrderLineTemplateSearchOverview.validThruToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processValidFrom();
			$scope.processValidThru();

			$rootScope.OrderLineTemplateSearchOverview = angular.copy($scope.OrderLineTemplateSearchOverview);

			$rootScope.pageSizeOrderLineTemplate = angular.copy($scope.pageSize);

			RelationMgmtOrderLineTemplateListDtoSearchFactory.query(
				{
					page: $scope.OrderLineTemplateSearchOverview.page - 1,
					size: $scope.pageSize,
					validFromFrom: $scope.OrderLineTemplateSearchOverview.validFromFromString,
					validFromTo: $scope.OrderLineTemplateSearchOverview.validFromToString,
					validThruFrom: $scope.OrderLineTemplateSearchOverview.validThruFromString,
					validThruTo: $scope.OrderLineTemplateSearchOverview.validThruToString,
					description: $scope.OrderLineTemplateSearchOverview.description,
					quantityFrom: $scope.OrderLineTemplateSearchOverview.quantityFrom,
					quantityTo: $scope.OrderLineTemplateSearchOverview.quantityTo,
					unitPriceFrom: $scope.OrderLineTemplateSearchOverview.unitPriceFrom,
					unitPriceTo: $scope.OrderLineTemplateSearchOverview.unitPriceTo,
					articleId: getArticleId(),
					tenantId: getTenantId(),

					sort: [
						$scope.OrderLineTemplateSearchOverview.predicate + ',' + ($scope.OrderLineTemplateSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.orderLineTemplateList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isOrderLineTemplateSearchOverviewDirty = function() {
			return !angular.equals($scope.OrderLineTemplateSearchOverview, $scope.originalOrderLineTemplateSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OrderLineTemplateSearchOverview = {};
			$rootScope.OrderLineTemplateSearchOverview.page = 1;
			$rootScope.OrderLineTemplateSearchOverview.predicate = 'id';
			$rootScope.OrderLineTemplateSearchOverview.ascending = false;

			$scope.OrderLineTemplateSearchOverview = angular.copy($rootScope.OrderLineTemplateSearchOverview);

			$scope.originalOrderLineTemplateSearchOverview = angular.copy($scope.OrderLineTemplateSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OrderLineTemplateSearchOverview) || $rootScope.OrderLineTemplateSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OrderLineTemplateSearchOverview = angular.copy($rootScope.OrderLineTemplateSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineTemplateId = id;
			$('#deleteOrderLineTemplateConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtOrderLineTemplateViewDtoFactory.delete(
				{ id: $scope.selectedOrderLineTemplateId },
				function() {
					$scope.search();
					$('#deleteOrderLineTemplateConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineTemplateConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OrderLineTemplateSearchOverview.predicate === itemName) {
				if ($scope.OrderLineTemplateSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
