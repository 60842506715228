/** @format */

'use strict';

// CarModelYear has edit layout enabled. Edit layout template url: carmodelyear-dialog.html
// CarModelYear has detail layout enabled. Detail layout template url: carmodelyear-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarModelYear'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carmodelyear'); // main translation loaded for carmodelyear
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('carmodel'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carModelVersion
		$translatePartialLoader.addPart('carmodelversion'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('article-api-carmodelyear', {
		/* abstract state for DTO ArticleApiCarModelYearListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carmodelyear',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarModelYear',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarModelYearEditDto */
		/* to access: ui-sref="article-api-carmodelyear.edit" */
		/* url: http://<hostname>/articleapi/carmodelyear/edit/:carModelYearId */
		url: '/edit/:carModelYearId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-edit-article-api.html',
				controller: 'ArticleApiCarModelYearEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarModelYearEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarModelYearEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarModelYearEditDtoFactory.get({ id: $stateParams.carModelYearId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carmodelyear.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarModelYearEditDto */
		/* to access: ui-sref="article-api-carmodelyear.new" */
		/* url: http://<hostname>/articleapi/carmodelyear/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-edit-article-api.html',
				controller: 'ArticleApiCarModelYearEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarModelYearNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarModelYearNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarModelYearNewDtoFactory.get({ id: $stateParams.carModelYearId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carmodelyear.new', dtoCreateState);

	// create state for plus button on CarModelVersion edit / new page for reference CarModelYear and dto property value article.api
	$stateProvider.state('article-api-carmodelversion.new.newCarModelYear', getCreateState('/carmodelyear/new'));
	$stateProvider.state('article-api-carmodelversion.edit.newCarModelYear', getCreateState('/carmodelyear/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carmodelyear/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('car-config-carmodelyear', {
		/* abstract state for DTO CarConfigCarModelYearListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carmodelyear',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarModelYear',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarModelYearListDto */
		/* to access: ui-ref="car-config-carmodelyear.list" */
		/* url: http://<hostname>/car/config/carmodelyear/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-list-car-config.html',
				controller: 'CarConfigCarModelYearListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carmodelyear.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarModelYearViewDto */
		/* to access: ui-sref="car-config-carmodelyear.detail" */
		/* url: http://<hostname>/car/config/carmodelyear/detail/:carModelYearId */
		url: '/detail/:carModelYearId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-view-car-config.html',
				controller: 'CarModelYearDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarModelYearViewDtoFactory',
				function($stateParams, CarConfigCarModelYearViewDtoFactory) {
					return CarConfigCarModelYearViewDtoFactory.get({ id: $stateParams.carModelYearId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodelyear.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarModelYearEditDto */
		/* to access: ui-sref="car-config-carmodelyear.edit" */
		/* url: http://<hostname>/car/config/carmodelyear/edit/:carModelYearId */
		url: '/edit/:carModelYearId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-edit-car-config.html',
				controller: 'CarConfigCarModelYearEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarModelYearEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarModelYearEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarModelYearEditDtoFactory.get({ id: $stateParams.carModelYearId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodelyear.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarModelYearEditDto */
		/* to access: ui-sref="car-config-carmodelyear.new" */
		/* url: http://<hostname>/car/config/carmodelyear/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodelyear/carmodelyear-edit-car-config.html',
				controller: 'CarConfigCarModelYearEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarModelYearNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarModelYearNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarModelYearNewDtoFactory.get({ id: $stateParams.carModelYearId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodelyear.new', dtoCreateState);

	// create state for plus button on CarModelVersion edit / new page for reference CarModelYear and dto property value car.config
	$stateProvider.state('car-config-carmodelversion.new.newCarModelYear', getCreateState('/carmodelyear/new'));
	$stateProvider.state('car-config-carmodelversion.edit.newCarModelYear', getCreateState('/carmodelyear/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carmodelyear/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
