/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationDependentPriceBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		CarConfigCarConfigurationDependentPriceBulkEditDtoFactory,
		AlertService,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.selectedCarConfigurationDependentPriceIds = _.map($stateParams.selectedCarConfigurationDependentPriceList, 'id');
		$scope.selectedItemsAmount = $scope.selectedCarConfigurationDependentPriceIds.length;
		$scope.carConfigurationDependentPrice = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carConfigurationDependentPriceUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			CarConfigCarConfigurationDependentPriceBulkEditDtoFactory.update(
				{ carConfigurationDependentPriceIds: $scope.selectedCarConfigurationDependentPriceIds },
				$scope.carConfigurationDependentPrice,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteArticles = function(val) {
			var params = {
				articleTypeAheadSearchString: val,
			};

			return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
