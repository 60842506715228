/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('ArticlePriceDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.articlePrice = entity;
	$scope.objectName = 'ArticlePrice';
	$scope.articlePriceId = $stateParams.articlePriceId;

	if (angular.isUndefined($rootScope.articlePriceActiveTab)) {
		//set the default here
		$rootScope.articlePriceActiveTab = 0;
	}

	$scope.goToEditState = function(articlePrice) {
		$state.go('articleprice.detail.edit({articlePriceId:articlePrice.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:articlePriceUpdate', function(event, result) {
		$scope.articlePrice = result;
	});
});
