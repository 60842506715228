/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtUserCarStockAccessBoundDealerListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		UserCarStockAccessBoundDealerFactory,
		StockMgmtUserCarStockAccessBoundDealerListDtoSearchFactory
	) {
		$scope.userCarStockAccessBoundDealerList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeUserCarStockAccessBoundDealer)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeUserCarStockAccessBoundDealer);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeUserCarStockAccessBoundDealer = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-usercarstockaccessbounddealer.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.UserCarStockAccessBoundDealerSearchOverview.tenant)) {
				return $scope.UserCarStockAccessBoundDealerSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.UserCarStockAccessBoundDealerSearchOverview = angular.copy($scope.UserCarStockAccessBoundDealerSearchOverview);

			$rootScope.pageSizeUserCarStockAccessBoundDealer = angular.copy($scope.pageSize);

			StockMgmtUserCarStockAccessBoundDealerListDtoSearchFactory.query(
				{
					page: $scope.UserCarStockAccessBoundDealerSearchOverview.page - 1,
					size: $scope.pageSize,
					tenantId: getTenantId(),
					userCarStockAccessBoundIdList: $scope.UserCarStockAccessBoundDealerSearchOverview.userCarStockAccessBoundIdList,
					dealerIdList: $scope.UserCarStockAccessBoundDealerSearchOverview.dealerIdList,

					sort: [
						$scope.UserCarStockAccessBoundDealerSearchOverview.predicate +
							',' +
							($scope.UserCarStockAccessBoundDealerSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.userCarStockAccessBoundDealerList = result.items;

					$scope.userCarStockAccessBoundReferenceFilterList = result.userCarStockAccessBoundSearchOptions;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isUserCarStockAccessBoundDealerSearchOverviewDirty = function() {
			return !angular.equals(
				$scope.UserCarStockAccessBoundDealerSearchOverview,
				$scope.originalUserCarStockAccessBoundDealerSearchOverview
			);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.UserCarStockAccessBoundDealerSearchOverview = {};
			$rootScope.UserCarStockAccessBoundDealerSearchOverview.page = 1;
			$rootScope.UserCarStockAccessBoundDealerSearchOverview.predicate = 'id';
			$rootScope.UserCarStockAccessBoundDealerSearchOverview.ascending = false;

			$scope.UserCarStockAccessBoundDealerSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundDealerSearchOverview);

			$scope.originalUserCarStockAccessBoundDealerSearchOverview = angular.copy($scope.UserCarStockAccessBoundDealerSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.UserCarStockAccessBoundDealerSearchOverview) ||
				$rootScope.UserCarStockAccessBoundDealerSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.UserCarStockAccessBoundDealerSearchOverview = angular.copy($rootScope.UserCarStockAccessBoundDealerSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.UserCarStockAccessBoundDealerSearchOverview.predicate === itemName) {
				if ($scope.UserCarStockAccessBoundDealerSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateUserCarStockAccessBoundUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateDealerUISelect = function(item, model) {
			$scope.search();
		};
	});
