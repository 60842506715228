/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('RelationMgmtWorkflowProcessAnchorEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		RelationMgmtWorkflowProcessAnchorEditDtoFactory,
		AlertService,
		HistoryService,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.workflowProcessAnchor = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return (
				$rootScope.isDefinedAndNotNull($scope.workflowProcessAnchor) && $rootScope.isDefinedAndNotNull($scope.workflowProcessAnchor.id)
			);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:workflowProcessAnchorUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			RelationMgmtWorkflowProcessAnchorEditDtoFactory.update(
				$scope.workflowProcessAnchor,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
