/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigColorListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ColorFactory,
		CarConfigColorListDtoSearchFactory,
		CarConfigColorViewDtoFactory
	) {
		$scope.colorList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeColor)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeColor);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeColor = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('car-config-color.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getColorList = function() {
			CarConfigColorListDtoSearchFactory.query(
				{
					rdwColorIdList: angular.isDefined($scope.rdwColorId) ? $scope.rdwColorId : null,
					brandId: angular.isDefined($scope.brandId) ? $scope.brandId : null,
					locale: angular.isDefined($scope.localeId) ? $scope.localeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.colorList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getColorList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedColorId = id;
			$('#deleteColorConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigColorViewDtoFactory.delete(
				{ id: $scope.selectedColorId },
				function() {
					$scope.loadAll();
					$('#deleteColorConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteColorConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateRdwColorUISelect = function(item, model) {
			$scope.search();
		};
	});
