/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtCarStockBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtCarStockBulkEditDtoFactory,
		AlertService,
		AutocompleteStockMgmtCarsByQueryParamsFactory,
		LocalizedInputService
	) {
		$scope.getLocalizedFormFieldStatus = LocalizedInputService.getLocalizedFormFieldStatus.bind(LocalizedInputService);
		$scope.selectedCarStockIds = _.map($stateParams.selectedCarStockList, 'id');
		$scope.selectedItemsAmount = $scope.selectedCarStockIds.length;
		$scope.carStock = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:carStockUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			StockMgmtCarStockBulkEditDtoFactory.update(
				{ carStockIds: $scope.selectedCarStockIds },
				$scope.carStock,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		$scope.autocompleteCars = function(val) {
			var params = {
				crmCarTypeAheadSearchString: val,
			};

			return AutocompleteStockMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};
	});
