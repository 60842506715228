/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingEnergyLabelListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingEnergyLabelFactory,
		StockMgmtMappingEnergyLabelListDtoSearchFactory,
		StockMgmtMappingEnergyLabelViewDtoFactory
	) {
		$scope.mappingEnergyLabelList = [];
		$scope.selectedMappingEnergyLabelList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingEnergyLabel)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingEnergyLabel);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingEnergyLabel = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-mappingenergylabel.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getMappingEnergyLabelList = function() {
			StockMgmtMappingEnergyLabelListDtoSearchFactory.query(
				{
					sourceList: angular.isDefined($scope.sourceId) ? $scope.sourceId : null,
					energyLabelList: angular.isDefined($scope.energyLabelId) ? $scope.energyLabelId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingEnergyLabelList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getMappingEnergyLabelList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingEnergyLabelId = id;
			$('#deleteMappingEnergyLabelConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingEnergyLabelViewDtoFactory.delete(
				{ id: $scope.selectedMappingEnergyLabelId },
				function() {
					$scope.loadAll();
					$('#deleteMappingEnergyLabelConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteMappingEnergyLabelConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingEnergyLabelConfirmationInTab').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingEnergyLabelList, function(value, key) {
				promises.push(StockMgmtMappingEnergyLabelViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.loadAll();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingEnergyLabelConfirmationInTab').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingEnergyLabelList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingEnergyLabelList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingEnergyLabelList = [];

			angular.forEach($scope.mappingEnergyLabelList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingEnergyLabelList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:mappingEnergyLabelUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateEnergyLabelUISelect = function(item, model) {
			$scope.search();
		};
	});
