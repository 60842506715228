/** @format */

'use strict';

// Address has edit layout enabled. Edit layout template url: address-dialog.html
// Address has detail layout enabled. Detail layout template url: address-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Address'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('address'); // main translation loaded for address
		$translatePartialLoader.addPart('addresstype'); // one to many reference from main entity
		$translatePartialLoader.addPart('country'); // one to many reference from main entity
		$translatePartialLoader.addPart('relation'); // one to many reference from main entity
		$translatePartialLoader.addPart('tenant'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('relation-mgmt-address', {
		/* abstract state for DTO RelationMgmtAddressListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/relationmgmt/address',
		data: {
			authorities: ['ROLE_RELATION', 'ROLE_ADMIN'],
			pageTitle: 'Address',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO relation.mgmt */
	var dtoListState = {
		/* list state for DTO RelationMgmtAddressListDto */
		/* to access: ui-ref="relation-mgmt-address.list" */
		/* url: http://<hostname>/relationmgmt/address/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/address/address-list-relation-mgmt.html',
				controller: 'RelationMgmtAddressListDtoController',
			},
		},
	};

	$stateProvider.state('relation-mgmt-address.list', dtoListState);

	/* detail state for DTO relation.mgmt */
	var dtoDetailState = {
		/* detail state for DTO RelationMgmtAddressViewDto */
		/* to access: ui-sref="relation-mgmt-address.detail" */
		/* url: http://<hostname>/relationmgmt/address/detail/:addressId */
		url: '/detail/:addressId',
		views: {
			'content@': {
				templateUrl: 'app/entities/address/address-view-relation-mgmt.html',
				controller: 'AddressDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'RelationMgmtAddressViewDtoFactory',
				function($stateParams, RelationMgmtAddressViewDtoFactory) {
					return RelationMgmtAddressViewDtoFactory.get({ id: $stateParams.addressId });
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-address.detail', dtoDetailState);

	/* edit state for DTO relation.mgmt */
	var dtoEditState = {
		/* edit state for DTO RelationMgmtAddressEditDto */
		/* to access: ui-sref="relation-mgmt-address.edit" */
		/* url: http://<hostname>/relationmgmt/address/edit/:addressId */
		url: '/edit/:addressId',
		views: {
			'content@': {
				templateUrl: 'app/entities/address/address-edit-relation-mgmt.html',
				controller: 'RelationMgmtAddressEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtAddressEditDtoFactory',
				function($stateParams, $rootScope, RelationMgmtAddressEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtAddressEditDtoFactory.get({ id: $stateParams.addressId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-address.edit', dtoEditState);

	/* new (create) state for DTO relation.mgmt */
	var dtoCreateState = {
		/* new state for DTO RelationMgmtAddressEditDto */
		/* to access: ui-sref="relation-mgmt-address.new" */
		/* url: http://<hostname>/relationmgmt/address/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/address/address-edit-relation-mgmt.html',
				controller: 'RelationMgmtAddressEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'RelationMgmtAddressNewDtoFactory',
				function($stateParams, $rootScope, RelationMgmtAddressNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return RelationMgmtAddressNewDtoFactory.get({ id: $stateParams.addressId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('relation-mgmt-address.new', dtoCreateState);
});
