/** @format */

'use strict';
angular
	.module('mmmApp')
	.service('DocumentDownloadService', function(StockMgmtDocumentDownloadDtoFactory, GetHeaderFilename, FileDownloadError) {
		return function(id) {
			console.log('downloading requested file...');
			StockMgmtDocumentDownloadDtoFactory.download({ id: id })
				.$promise.then(function(result) {
					var url = URL.createObjectURL(new Blob([result.data]));
					var a = document.createElement('a');
					console.log(result.headerData);
					a.href = url;
					a.download = GetHeaderFilename(result.headerData);
					a.target = '_blank';
					a.click();
				})
				.catch(FileDownloadError)
				.catch(function(error) {
					console.log(error.data); // in JSON
				});
		};
	});
