/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ColorFactory', function($resource) {
	return $resource(
		'api/colors/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiColorEditDto
 */
angular.module('mmmApp').factory('ArticleApiColorEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/color/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiColorEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiColorNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/color/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigColorViewDto
 */
angular.module('mmmApp').factory('CarConfigColorViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/color/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigColorListDto's
 */
angular.module('mmmApp').factory('CarConfigColorListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/color',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigColorEditDto
 */
angular.module('mmmApp').factory('CarConfigColorEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/color/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigColorEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigColorNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/color/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewColorFactory', function($resource) {
	return $resource(
		'api/colors/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Colors without the use of paging
angular.module('mmmApp').factory('NoPagingColorFactory', function($resource) {
	return $resource(
		'api/colors/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/color/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ImagesByColorFactory', function($resource) {
	return $resource(
		'api/images/color/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllColorsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/colors/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
