/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('OrderLineGroupDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.orderLineGroup = entity;
		$scope.objectName = 'OrderLineGroup';
		$scope.orderLineGroupId = $stateParams.orderLineGroupId;

		if (angular.isUndefined($rootScope.orderLineGroupActiveTab)) {
			//set the default here
			$rootScope.orderLineGroupActiveTab = 0;
		}

		$scope.goToEditState = function(orderLineGroup) {
			$state.go('orderlinegroup.detail.edit({orderLineGroupId:orderLineGroup.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:orderLineGroupUpdate', function(event, result) {
			$scope.orderLineGroup = result;
		});
	});
