/** @format */

'use strict';

// Color has edit layout enabled. Edit layout template url: color-dialog.html
// Color has detail layout enabled. Detail layout template url: color-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Color'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('color'); // main translation loaded for color
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('rdwcolor'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('article-api-color', {
		/* abstract state for DTO ArticleApiColorListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/color',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'Color',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiColorEditDto */
		/* to access: ui-sref="article-api-color.edit" */
		/* url: http://<hostname>/articleapi/color/edit/:colorId */
		url: '/edit/:colorId',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-edit-article-api.html',
				controller: 'ArticleApiColorEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiColorEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiColorEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiColorEditDtoFactory.get({ id: $stateParams.colorId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-color.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiColorEditDto */
		/* to access: ui-sref="article-api-color.new" */
		/* url: http://<hostname>/articleapi/color/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-edit-article-api.html',
				controller: 'ArticleApiColorEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiColorNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiColorNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiColorNewDtoFactory.get({ id: $stateParams.colorId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-color.new', dtoCreateState);

	// create state for plus button on Image edit / new page for reference Color and dto property value article.api
	$stateProvider.state('article-api-image.new.newColor', getCreateState('/color/new'));
	$stateProvider.state('article-api-image.edit.newColor', getCreateState('/color/new'));
	// create state for plus button on CarPaint edit / new page for reference Color and dto property value article.api
	$stateProvider.state('article-api-carpaint.new.newColor', getCreateState('/color/new'));
	$stateProvider.state('article-api-carpaint.edit.newColor', getCreateState('/color/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/color/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('car-config-color', {
		/* abstract state for DTO CarConfigColorListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/color',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Color',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigColorListDto */
		/* to access: ui-ref="car-config-color.list" */
		/* url: http://<hostname>/car/config/color/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-list-car-config.html',
				controller: 'CarConfigColorListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-color.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigColorViewDto */
		/* to access: ui-sref="car-config-color.detail" */
		/* url: http://<hostname>/car/config/color/detail/:colorId */
		url: '/detail/:colorId',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-view-car-config.html',
				controller: 'ColorDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigColorViewDtoFactory',
				function($stateParams, CarConfigColorViewDtoFactory) {
					return CarConfigColorViewDtoFactory.get({ id: $stateParams.colorId });
				},
			],
		},
	};

	$stateProvider.state('car-config-color.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigColorEditDto */
		/* to access: ui-sref="car-config-color.edit" */
		/* url: http://<hostname>/car/config/color/edit/:colorId */
		url: '/edit/:colorId',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-edit-car-config.html',
				controller: 'CarConfigColorEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigColorEditDtoFactory',
				function($stateParams, $rootScope, CarConfigColorEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigColorEditDtoFactory.get({ id: $stateParams.colorId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-color.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigColorEditDto */
		/* to access: ui-sref="car-config-color.new" */
		/* url: http://<hostname>/car/config/color/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/color/color-edit-car-config.html',
				controller: 'CarConfigColorEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigColorNewDtoFactory',
				function($stateParams, $rootScope, CarConfigColorNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigColorNewDtoFactory.get({ id: $stateParams.colorId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-color.new', dtoCreateState);

	// create state for plus button on Image edit / new page for reference Color and dto property value car.config
	$stateProvider.state('car-config-image.new.newColor', getCreateState('/color/new'));
	$stateProvider.state('car-config-image.edit.newColor', getCreateState('/color/new'));
	// create state for plus button on CarPaint edit / new page for reference Color and dto property value car.config
	$stateProvider.state('car-config-carpaint.new.newColor', getCreateState('/color/new'));
	$stateProvider.state('car-config-carpaint.edit.newColor', getCreateState('/color/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/color/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
