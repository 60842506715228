/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticleCarModelYearListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleCarModelYearFactory,
		CarConfigArticleCarModelYearListDtoSearchFactory,
		CarConfigArticleCarModelYearViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.articleCarModelYearList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticleCarModelYear)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticleCarModelYear);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticleCarModelYear = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-articlecarmodelyear.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticleCarModelYearSearchOverview.article)) {
				return $scope.ArticleCarModelYearSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleCarModelYearSearchOverview = angular.copy($scope.ArticleCarModelYearSearchOverview);

			$rootScope.pageSizeArticleCarModelYear = angular.copy($scope.pageSize);

			CarConfigArticleCarModelYearListDtoSearchFactory.query(
				{
					page: $scope.ArticleCarModelYearSearchOverview.page - 1,
					size: $scope.pageSize,
					articleId: getArticleId(),
					carModelYearIdList: $scope.ArticleCarModelYearSearchOverview.carModelYearIdList,

					sort: [
						$scope.ArticleCarModelYearSearchOverview.predicate +
							',' +
							($scope.ArticleCarModelYearSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleCarModelYearList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.carModelYearReferenceFilterList = result.carModelYearSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleCarModelYearSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticleCarModelYearSearchOverview, $scope.originalArticleCarModelYearSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleCarModelYearSearchOverview = {};
			$rootScope.ArticleCarModelYearSearchOverview.page = 1;
			$rootScope.ArticleCarModelYearSearchOverview.predicate = 'id';
			$rootScope.ArticleCarModelYearSearchOverview.ascending = false;

			$scope.ArticleCarModelYearSearchOverview = angular.copy($rootScope.ArticleCarModelYearSearchOverview);

			$scope.originalArticleCarModelYearSearchOverview = angular.copy($scope.ArticleCarModelYearSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleCarModelYearSearchOverview) || $rootScope.ArticleCarModelYearSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleCarModelYearSearchOverview = angular.copy($rootScope.ArticleCarModelYearSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleCarModelYearId = id;
			$('#deleteArticleCarModelYearConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigArticleCarModelYearViewDtoFactory.delete(
				{ id: $scope.selectedArticleCarModelYearId },
				function() {
					$scope.search();
					$('#deleteArticleCarModelYearConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticleCarModelYearConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleCarModelYearSearchOverview.predicate === itemName) {
				if ($scope.ArticleCarModelYearSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarModelYearUISelect = function(item, model) {
			$scope.search();
		};
	});
