'use strict';

// User has edit layout enabled. Edit layout template url: user-dialog.html
// User has detail layout enabled. Detail layout template url: user-detail.html
angular.module('mmmApp').config(
		function($stateProvider) {

			/**
			 * Add all translations for a 'User'
			 */
			var getTranslations = function($translate, $translatePartialLoader) {
				$translatePartialLoader.addPart('user'); // main translation loaded for user
				$translatePartialLoader.addPart('tenant'); // one to many reference from main entity
				$translatePartialLoader.addPart('authority'); // one to many reference from main entity

				return $translate.refresh();
			};

			// custom/jhipster/state/additionalState.ftl: TODO: Add more states for generated user list

			$stateProvider.state('admin-user', {
				/* abstract state for DTO AdminUserListDto */
				/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
				abstract : true,
				parent : 'entity',
				url : '/admin/user',
				data : {
					authorities : [ 'ROLE_ADMIN', 'ROLE_DEALER_ADMIN_PREVIEW' ],
					pageTitle : 'User',
					isModal : false
				},
				params : {
					mappedBy : null,
					mappedByType : null,
					mappedById : null,
					dataModel : null, // this data model is set from the new relation state OR from the save and new logic
					mappedByDisplayValue : null,
					dataModelType : null, // this data model type is the name of the modelobject, lowercase
					referredBy : null, // will be populated once this new state is triggered from another new state with + button
					hiddenParam : 'YES' // hides params from url
				},
				resolve : {
					translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						return getTranslations($translate, $translatePartialLoader);
					} ]
				}
			});

			/* list state for DTO admin */
			var dtoListState = {
				/* list state for DTO AdminUserListDto */
				/* to access: ui-ref="admin-user.list" */
				/* url: http://<hostname>/admin/user/list */
				url : '/list',
				views : {
					'content@' : {
						templateUrl : 'app/entities/user/user-list-admin.html',
						controller : 'AdminUserListDtoController'
					}
				}
			};

			$stateProvider.state('admin-user.list', dtoListState);

			/* detail state for DTO admin */
			var dtoDetailState = {
				/* detail state for DTO AdminUserViewDto */
				/* to access: ui-sref="admin-user.detail" */
				/* url: http://<hostname>/admin/user/detail/:userId */
				url : '/detail/:userId',
				views : {
					'content@' : {
						templateUrl : 'app/entities/user/user-view-admin.html',
						controller : 'UserDetailController'
					}
				},
				resolve : {
					entity : [ '$stateParams', 'AdminUserViewDtoFactory', function($stateParams, AdminUserViewDtoFactory) {
						return AdminUserViewDtoFactory.get({
							id : $stateParams.userId
						});
					} ]
				}
			};

			$stateProvider.state('admin-user.detail', dtoDetailState);

			/* edit state for DTO admin */
			var dtoEditState = {
				/* edit state for DTO AdminUserEditDto */
				/* to access: ui-sref="admin-user.edit" */
				/* url: http://<hostname>/admin/user/edit/:userId */
				url : '/edit/:userId',
				views : {
					'content@' : {
						templateUrl : 'app/entities/user/user-edit-admin.html',
						controller : 'AdminUserEditDtoController'
					}
				},
				resolve : {
					entity : [ '$stateParams', '$rootScope', 'AdminUserEditDtoFactory',
							function($stateParams, $rootScope, AdminUserEditDtoFactory) {
								// if dataModel is defined, we're coming back from a new created x..1 reference
								if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {

									var model = $stateParams.dataModel;

									// reset 'remembered' values to prevent future misbehaviour
									$rootScope.previousStateParams.dataModel = null;
									$stateParams.dataModel = null;

									// instead of returning a new model, we return the updated model
									return model;
								} else {
									return AdminUserEditDtoFactory.get({
										id : $stateParams.userId
									}).$promise.then(function(result) {
										return result;
									});
								}
							} ]
				}
			};

			$stateProvider.state('admin-user.edit', dtoEditState);

		});
