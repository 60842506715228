/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerImageListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerImageFactory,
		StockMgmtDealerImageListDtoSearchFactory,
		StockMgmtDealerImageViewDtoFactory
	) {
		$scope.dealerImageList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealerImage)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealerImage);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealerImage = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-dealerimage.new');
		};

		var getDocumentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerImageSearchOverview.document)) {
				return $scope.DealerImageSearchOverview.document.id;
			} else {
				return undefined;
			}
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerImageSearchOverview.dealer)) {
				return $scope.DealerImageSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerImageSearchOverview.tenant)) {
				return $scope.DealerImageSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DealerImageSearchOverview = angular.copy($scope.DealerImageSearchOverview);

			$rootScope.pageSizeDealerImage = angular.copy($scope.pageSize);

			StockMgmtDealerImageListDtoSearchFactory.query(
				{
					page: $scope.DealerImageSearchOverview.page - 1,
					size: $scope.pageSize,
					documentId: getDocumentId(),
					imageTypeList: $scope.DealerImageSearchOverview.imageTypeList,
					dealerId: getDealerId(),
					tenantId: getTenantId(),

					sort: [$scope.DealerImageSearchOverview.predicate + ',' + ($scope.DealerImageSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerImageList = result.items;

					$scope.dealerImageTypeList = result.imageTypeSearchOptions;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDealerImageSearchOverviewDirty = function() {
			return !angular.equals($scope.DealerImageSearchOverview, $scope.originalDealerImageSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DealerImageSearchOverview = {};
			$rootScope.DealerImageSearchOverview.page = 1;
			$rootScope.DealerImageSearchOverview.predicate = 'id';
			$rootScope.DealerImageSearchOverview.ascending = false;

			$scope.DealerImageSearchOverview = angular.copy($rootScope.DealerImageSearchOverview);

			$scope.originalDealerImageSearchOverview = angular.copy($scope.DealerImageSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DealerImageSearchOverview) || $rootScope.DealerImageSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DealerImageSearchOverview = angular.copy($rootScope.DealerImageSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDealerImageId = id;
			$('#deleteDealerImageConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtDealerImageViewDtoFactory.delete(
				{ id: $scope.selectedDealerImageId },
				function() {
					$scope.search();
					$('#deleteDealerImageConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteDealerImageConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DealerImageSearchOverview.predicate === itemName) {
				if ($scope.DealerImageSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateImageTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
