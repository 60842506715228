/** @format */

'use strict';

angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'BatchVehicleAdvertisementPerform'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('batchvehicleadvertisementperform'); // main translation loaded for batchvehicleadvertisementperform
		$translatePartialLoader.addPart('publishsourcetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('batchstatus'); // one to many reference from main entity
		$translatePartialLoader.addPart('carstock'); // one to many reference from main entity

		return $translate.refresh();
	};
});
