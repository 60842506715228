/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('RdwColorDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.rdwColor = entity;
	$scope.objectName = 'RdwColor';
	$scope.rdwColorId = $stateParams.rdwColorId;

	if (angular.isUndefined($rootScope.rdwColorActiveTab)) {
		//set the default here
		$rootScope.rdwColorActiveTab = 0;
	}

	$scope.goToEditState = function(rdwColor) {
		$state.go('rdwcolor.detail.edit({rdwColorId:rdwColor.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:rdwColorUpdate', function(event, result) {
		$scope.rdwColor = result;
	});
});
