/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('DealerContentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.dealerContent = entity;
	$scope.objectName = 'DealerContent';
	$scope.dealerContentId = $stateParams.dealerContentId;

	if (angular.isUndefined($rootScope.dealerContentActiveTab)) {
		//set the default here
		$rootScope.dealerContentActiveTab = 0;
	}

	$scope.goToEditState = function(dealerContent) {
		$state.go('dealercontent.detail.edit({dealerContentId:dealerContent.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:dealerContentUpdate', function(event, result) {
		$scope.dealerContent = result;
	});
});
