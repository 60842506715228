/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarStockFactory', function($resource) {
	return $resource(
		'api/carStocks/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtCarStockViewDto
 */
angular.module('mmmApp').factory('StockMgmtCarStockViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtCarStockListDto's
 */
angular.module('mmmApp').factory('StockMgmtCarStockListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtCarStockEditDto
 */
angular.module('mmmApp').factory('StockMgmtCarStockEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtCarStockEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtCarStockNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtCarStockEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('StockMgmtCarStockNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of StockMgmtCarStockEditDto
 */
angular.module('mmmApp').factory('StockMgmtCarStockBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/carstock/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

/**
 * Factory to search / retrieve an array of RelationMgmtCarStockListDto's
 */
angular.module('mmmApp').factory('RelationMgmtCarStockListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/carstock',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to search / retrieve an array of WebsiteApiCarStockListDto's
 */
angular.module('mmmApp').factory('WebsiteApiCarStockListDtoSearchFactory', function($resource) {
	return $resource(
		'api/websiteapi/carstock',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of VehicleApiCarStockEditDto
 */
angular.module('mmmApp').factory('VehicleApiCarStockEditDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/carstock/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of VehicleApiCarStockEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('VehicleApiCarStockNewDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/carstock/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/*
 * Factory to fetch a new object of VehicleApiCarStockEditDto
 * without defaults from server, to use in bulkedit
 */
angular.module('mmmApp').factory('VehicleApiCarStockNewBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/carstock/bulkedit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to bulkupdate an instance of VehicleApiCarStockEditDto
 */
angular.module('mmmApp').factory('VehicleApiCarStockBulkEditDtoFactory', function($resource) {
	return $resource(
		'api/vehicleapi/carstock/bulkedit',
		{},
		{
			update: {
				method: 'PUT',
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarStockFactory', function($resource) {
	return $resource(
		'api/carStocks/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarStocks without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockFactory', function($resource) {
	return $resource(
		'api/carStocks/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockPriceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/priceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible StockStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingStockStatusFactory', function($resource) {
	return $resource(
		'api/carStock/stockStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible CarStockTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockTypeFactory', function($resource) {
	return $resource(
		'api/carStock/carStockTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SellingStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSellingStatusFactory', function($resource) {
	return $resource(
		'api/carStock/sellingStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PipelineStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPipelineStatusFactory', function($resource) {
	return $resource(
		'api/carStock/pipelineStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SwapStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingTransferStatusFactory', function($resource) {
	return $resource(
		'api/carStock/swapStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PublishSourceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPublishSourceTypeFactory', function($resource) {
	return $resource(
		'api/carStock/publishSourceTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible FinanceTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockOriginFactory', function($resource) {
	return $resource(
		'api/carStock/financeTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible CarStockDiscountOrigins Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCarStockDiscountOriginFactory', function($resource) {
	return $resource(
		'api/carStock/carStockDiscountOrigins',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSourceStatuses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceStatusFactory', function($resource) {
	return $resource(
		'api/carStock/imageSourceStatuses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ImageSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingImageSourceFactory', function($resource) {
	return $resource(
		'api/carStock/imageSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carStock/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible PriceSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingPriceSourceFactory', function($resource) {
	return $resource(
		'api/carStock/priceSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible DiscountSources Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingDiscountSourceFactory', function($resource) {
	return $resource(
		'api/carStock/discountSources',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carStocks/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/carStocks/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteStockMgmtCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/stockmgmt/autocomplete/carstocks',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteRelationMgmtCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/carstocks',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteWebsiteApiCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/websiteapi/autocomplete/carstocks',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteVehicleApiCarStocksByQueryParamsFactory', function($resource) {
	return $resource(
		'api/vehicleapi/autocomplete/carstocks',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
