/** @format */

'use strict';

// CarPowertrain has edit layout enabled. Edit layout template url: carpowertrain-dialog.html
// CarPowertrain has detail layout enabled. Detail layout template url: carpowertrain-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarPowertrain'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carpowertrain'); // main translation loaded for carpowertrain
		$translatePartialLoader.addPart('article'); // main translation loaded for carpowertrain
		$translatePartialLoader.addPart('status'); // one to many reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to many reference from main entity
		$translatePartialLoader.addPart('imagesize'); // one to many reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to many reference from main entity
		$translatePartialLoader.addPart('pricetype'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to many reference from main entity
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlefilteroption'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecarmodelyear'); // one to many reference from main entity
		$translatePartialLoader.addPart('billofmaterials'); // one to many reference from main entity
		$translatePartialLoader.addPart('image'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecombi'); // one to many reference from main entity
		$translatePartialLoader.addPart('combitype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('failtype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletag'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationpackagearticle'); // one to many reference from main entity
		$translatePartialLoader.addPart('equivalent'); // one to many reference from main entity
		$translatePartialLoader.addPart('articlecategory'); // one to many reference from main entity
		$translatePartialLoader.addPart('orderline'); // one to many reference from main entity
		$translatePartialLoader.addPart('articleprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carconfigurationdependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('caroptiondependentprice'); // one to many reference from main entity
		$translatePartialLoader.addPart('article'); // main translation loaded for parent object Article of reference carConfiguration
		$translatePartialLoader.addPart('carconfiguration'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carpowertrain', {
		/* abstract state for DTO CarConfigCarPowertrainListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carpowertrain',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarPowertrain',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarPowertrainListDto */
		/* to access: ui-ref="car-config-carpowertrain.list" */
		/* url: http://<hostname>/car/config/carpowertrain/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-list-car-config.html',
				controller: 'CarConfigCarPowertrainListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carpowertrain.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarPowertrainViewDto */
		/* to access: ui-sref="car-config-carpowertrain.detail" */
		/* url: http://<hostname>/car/config/carpowertrain/detail/:carPowertrainId */
		url: '/detail/:carPowertrainId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-view-car-config.html',
				controller: 'CarPowertrainDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarPowertrainViewDtoFactory',
				function($stateParams, CarConfigCarPowertrainViewDtoFactory) {
					return CarConfigCarPowertrainViewDtoFactory.get({ id: $stateParams.carPowertrainId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carpowertrain.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarPowertrainEditDto */
		/* to access: ui-sref="car-config-carpowertrain.edit" */
		/* url: http://<hostname>/car/config/carpowertrain/edit/:carPowertrainId */
		url: '/edit/:carPowertrainId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-edit-car-config.html',
				controller: 'CarConfigCarPowertrainEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarPowertrainEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarPowertrainEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarPowertrainEditDtoFactory.get({ id: $stateParams.carPowertrainId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carpowertrain.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarPowertrainEditDto */
		/* to access: ui-sref="car-config-carpowertrain.new" */
		/* url: http://<hostname>/car/config/carpowertrain/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-edit-car-config.html',
				controller: 'CarConfigCarPowertrainEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarPowertrainNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarPowertrainNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarPowertrainNewDtoFactory.get({ id: $stateParams.carPowertrainId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carpowertrain.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarPowertrain and dto property value car.config
	$stateProvider.state('car-config-carconfiguration.new.newCarPowertrain', getCreateState('/carpowertrain/new'));
	$stateProvider.state('car-config-carconfiguration.edit.newCarPowertrain', getCreateState('/carpowertrain/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carpowertrain/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}

	$stateProvider.state('article-api-carpowertrain', {
		/* abstract state for DTO ArticleApiCarPowertrainListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carpowertrain',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarPowertrain',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarPowertrainEditDto */
		/* to access: ui-sref="article-api-carpowertrain.edit" */
		/* url: http://<hostname>/articleapi/carpowertrain/edit/:carPowertrainId */
		url: '/edit/:carPowertrainId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-edit-article-api.html',
				controller: 'ArticleApiCarPowertrainEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarPowertrainEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarPowertrainEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarPowertrainEditDtoFactory.get({ id: $stateParams.carPowertrainId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carpowertrain.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarPowertrainEditDto */
		/* to access: ui-sref="article-api-carpowertrain.new" */
		/* url: http://<hostname>/articleapi/carpowertrain/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carpowertrain/carpowertrain-edit-article-api.html',
				controller: 'ArticleApiCarPowertrainEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarPowertrainNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarPowertrainNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarPowertrainNewDtoFactory.get({ id: $stateParams.carPowertrainId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carpowertrain.new', dtoCreateState);

	// create state for plus button on CarConfiguration edit / new page for reference CarPowertrain and dto property value article.api
	$stateProvider.state('article-api-carconfiguration.new.newCarPowertrain', getCreateState('/carpowertrain/new'));
	$stateProvider.state('article-api-carconfiguration.edit.newCarPowertrain', getCreateState('/carpowertrain/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carpowertrain/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
