/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDocumentSecuredListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DocumentSecuredFactory,
		StockMgmtDocumentSecuredListDtoSearchFactory,
		StockMgmtDocumentSecuredViewDtoFactory,
		DocumentSecuredDownloadService
	) {
		$scope.documentSecuredList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDocumentSecured)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDocumentSecured);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDocumentSecured = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-documentsecured.new');
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DocumentSecuredSearchOverview.tenant)) {
				return $scope.DocumentSecuredSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DocumentSecuredSearchOverview = angular.copy($scope.DocumentSecuredSearchOverview);

			$rootScope.pageSizeDocumentSecured = angular.copy($scope.pageSize);

			StockMgmtDocumentSecuredListDtoSearchFactory.query(
				{
					page: $scope.DocumentSecuredSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.DocumentSecuredSearchOverview.description,
					name: $scope.DocumentSecuredSearchOverview.name,
					fileName: $scope.DocumentSecuredSearchOverview.fileName,
					mimeType: $scope.DocumentSecuredSearchOverview.mimeType,
					tenantId: getTenantId(),

					sort: [$scope.DocumentSecuredSearchOverview.predicate + ',' + ($scope.DocumentSecuredSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.documentSecuredList = result.items;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDocumentSecuredSearchOverviewDirty = function() {
			return !angular.equals($scope.DocumentSecuredSearchOverview, $scope.originalDocumentSecuredSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DocumentSecuredSearchOverview = {};
			$rootScope.DocumentSecuredSearchOverview.page = 1;
			$rootScope.DocumentSecuredSearchOverview.predicate = 'id';
			$rootScope.DocumentSecuredSearchOverview.ascending = false;

			$scope.DocumentSecuredSearchOverview = angular.copy($rootScope.DocumentSecuredSearchOverview);

			$scope.originalDocumentSecuredSearchOverview = angular.copy($scope.DocumentSecuredSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DocumentSecuredSearchOverview) || $rootScope.DocumentSecuredSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DocumentSecuredSearchOverview = angular.copy($rootScope.DocumentSecuredSearchOverview);

			$scope.search();
		};

		$scope.downloadDocumentSecured = function(id) {
			DocumentSecuredDownloadService(id);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DocumentSecuredSearchOverview.predicate === itemName) {
				if ($scope.DocumentSecuredSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
