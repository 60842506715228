/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarEngineListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarEngineFactory,
		CarConfigCarEngineListDtoSearchFactory,
		CarConfigCarEngineViewDtoFactory
	) {
		$scope.carEngineList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarEngine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarEngine);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarEngine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carengine.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarEngineSearchOverview.equivalent)) {
				return $scope.CarEngineSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean
		// handle attribute cooling of javaType boolean
		// handle attribute heating of javaType boolean
		// handle attribute rentalBattery of javaType Boolean
		// handle attribute fastcharge of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarEngineSearchOverview = angular.copy($scope.CarEngineSearchOverview);

			$rootScope.pageSizeCarEngine = angular.copy($scope.pageSize);

			CarConfigCarEngineListDtoSearchFactory.query(
				{
					page: $scope.CarEngineSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarEngineSearchOverview.name,
					productCode: $scope.CarEngineSearchOverview.productCode,
					factoryCode: $scope.CarEngineSearchOverview.factoryCode,
					description: $scope.CarEngineSearchOverview.description,
					canBeOrdered: $scope.CarEngineSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarEngineSearchOverview.spotlightGroup,
					creator: $scope.CarEngineSearchOverview.creator,
					updater: $scope.CarEngineSearchOverview.updater,
					performanceFrom: $scope.CarEngineSearchOverview.performanceFrom,
					performanceTo: $scope.CarEngineSearchOverview.performanceTo,
					minTorqueFrom: $scope.CarEngineSearchOverview.minTorqueFrom,
					minTorqueTo: $scope.CarEngineSearchOverview.minTorqueTo,
					maxTorqueFrom: $scope.CarEngineSearchOverview.maxTorqueFrom,
					maxTorqueTo: $scope.CarEngineSearchOverview.maxTorqueTo,
					newtonMetersFrom: $scope.CarEngineSearchOverview.newtonMetersFrom,
					newtonMetersTo: $scope.CarEngineSearchOverview.newtonMetersTo,
					tpmFrom: $scope.CarEngineSearchOverview.tpmFrom,
					tpmTo: $scope.CarEngineSearchOverview.tpmTo,
					cylinderCapacityFrom: $scope.CarEngineSearchOverview.cylinderCapacityFrom,
					cylinderCapacityTo: $scope.CarEngineSearchOverview.cylinderCapacityTo,
					numberOfCylindersFrom: $scope.CarEngineSearchOverview.numberOfCylindersFrom,
					numberOfCylindersTo: $scope.CarEngineSearchOverview.numberOfCylindersTo,
					chargingCapacityFrom: $scope.CarEngineSearchOverview.chargingCapacityFrom,
					chargingCapacityTo: $scope.CarEngineSearchOverview.chargingCapacityTo,
					batteryCapacityFrom: $scope.CarEngineSearchOverview.batteryCapacityFrom,
					batteryCapacityTo: $scope.CarEngineSearchOverview.batteryCapacityTo,
					homeChargingTimeFrom: $scope.CarEngineSearchOverview.homeChargingTimeFrom,
					homeChargingTimeTo: $scope.CarEngineSearchOverview.homeChargingTimeTo,
					homeChargingSpeedFrom: $scope.CarEngineSearchOverview.homeChargingSpeedFrom,
					homeChargingSpeedTo: $scope.CarEngineSearchOverview.homeChargingSpeedTo,
					chargingTimeFrom: $scope.CarEngineSearchOverview.chargingTimeFrom,
					chargingTimeTo: $scope.CarEngineSearchOverview.chargingTimeTo,
					chargingSpeedFrom: $scope.CarEngineSearchOverview.chargingSpeedFrom,
					chargingSpeedTo: $scope.CarEngineSearchOverview.chargingSpeedTo,
					fastChargingTimeFrom: $scope.CarEngineSearchOverview.fastChargingTimeFrom,
					fastChargingTimeTo: $scope.CarEngineSearchOverview.fastChargingTimeTo,
					fastChargingSpeedFrom: $scope.CarEngineSearchOverview.fastChargingSpeedFrom,
					fastChargingSpeedTo: $scope.CarEngineSearchOverview.fastChargingSpeedTo,
					cooling: $scope.CarEngineSearchOverview.coolingGroup,
					heating: $scope.CarEngineSearchOverview.heatingGroup,
					rentalBattery: $scope.CarEngineSearchOverview.rentalBatteryGroup,
					fastcharge: $scope.CarEngineSearchOverview.fastchargeGroup,
					electricPerformanceFrom: $scope.CarEngineSearchOverview.electricPerformanceFrom,
					electricPerformanceTo: $scope.CarEngineSearchOverview.electricPerformanceTo,
					combinedPerformanceFrom: $scope.CarEngineSearchOverview.combinedPerformanceFrom,
					combinedPerformanceTo: $scope.CarEngineSearchOverview.combinedPerformanceTo,
					numberOfPhasesFrom: $scope.CarEngineSearchOverview.numberOfPhasesFrom,
					numberOfPhasesTo: $scope.CarEngineSearchOverview.numberOfPhasesTo,
					statusList: $scope.CarEngineSearchOverview.statusList,
					typeList: $scope.CarEngineSearchOverview.typeList,
					imageSize: $scope.CarEngineSearchOverview.imageSize,
					vatType: $scope.CarEngineSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarEngineSearchOverview.priceTypeList,
					brandIdList: $scope.CarEngineSearchOverview.brandIdList,
					countryList: $scope.CarEngineSearchOverview.countryList,
					locale: $scope.CarEngineSearchOverview.locale,
					fuelList: $scope.CarEngineSearchOverview.fuelList,
					hybridType: $scope.CarEngineSearchOverview.hybridType,
					hybridPowertrain: $scope.CarEngineSearchOverview.hybridPowertrain,
					batteryType: $scope.CarEngineSearchOverview.batteryType,
					batteryPlugType: $scope.CarEngineSearchOverview.batteryPlugType,
					batteryFastChargerPlugType: $scope.CarEngineSearchOverview.batteryFastChargerPlugType,

					sort: [$scope.CarEngineSearchOverview.predicate + ',' + ($scope.CarEngineSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carEngineList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.fuelTypeList = result.fuelSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarEngineSearchOverviewDirty = function() {
			return !angular.equals($scope.CarEngineSearchOverview, $scope.originalCarEngineSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarEngineSearchOverview = {};
			$rootScope.CarEngineSearchOverview.page = 1;
			$rootScope.CarEngineSearchOverview.predicate = 'id';
			$rootScope.CarEngineSearchOverview.ascending = false;

			$scope.CarEngineSearchOverview = angular.copy($rootScope.CarEngineSearchOverview);

			$scope.originalCarEngineSearchOverview = angular.copy($scope.CarEngineSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarEngineSearchOverview) || $rootScope.CarEngineSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarEngineSearchOverview = angular.copy($rootScope.CarEngineSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarEngineId = id;
			$('#deleteCarEngineConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarEngineViewDtoFactory.delete(
				{ id: $scope.selectedCarEngineId },
				function() {
					$scope.search();
					$('#deleteCarEngineConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarEngineConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carEngineUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarEngineSearchOverview.predicate === itemName) {
				if ($scope.CarEngineSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateFuelUISelect = function(item, model) {
			$scope.search();
		};
	});
