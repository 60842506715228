/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarPaintListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarPaintFactory,
		CarConfigCarPaintListDtoSearchFactory,
		CarConfigCarPaintViewDtoFactory
	) {
		$scope.carPaintList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarPaint)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarPaint);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarPaint = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carpaint.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarPaintSearchOverview.equivalent)) {
				return $scope.CarPaintSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarPaintSearchOverview = angular.copy($scope.CarPaintSearchOverview);

			$rootScope.pageSizeCarPaint = angular.copy($scope.pageSize);

			CarConfigCarPaintListDtoSearchFactory.query(
				{
					page: $scope.CarPaintSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarPaintSearchOverview.name,
					productCode: $scope.CarPaintSearchOverview.productCode,
					factoryCode: $scope.CarPaintSearchOverview.factoryCode,
					description: $scope.CarPaintSearchOverview.description,
					canBeOrdered: $scope.CarPaintSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarPaintSearchOverview.spotlightGroup,
					creator: $scope.CarPaintSearchOverview.creator,
					updater: $scope.CarPaintSearchOverview.updater,
					statusList: $scope.CarPaintSearchOverview.statusList,
					typeList: $scope.CarPaintSearchOverview.typeList,
					imageSize: $scope.CarPaintSearchOverview.imageSize,
					vatType: $scope.CarPaintSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarPaintSearchOverview.priceTypeList,
					brandIdList: $scope.CarPaintSearchOverview.brandIdList,
					countryList: $scope.CarPaintSearchOverview.countryList,
					locale: $scope.CarPaintSearchOverview.locale,
					paintTypeList: $scope.CarPaintSearchOverview.paintTypeList,
					colorIdList: $scope.CarPaintSearchOverview.colorIdList,

					sort: [$scope.CarPaintSearchOverview.predicate + ',' + ($scope.CarPaintSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carPaintList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$scope.paintTypeList = result.paintTypeSearchOptions;

					$scope.colorReferenceFilterList = result.colorSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarPaintSearchOverviewDirty = function() {
			return !angular.equals($scope.CarPaintSearchOverview, $scope.originalCarPaintSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarPaintSearchOverview = {};
			$rootScope.CarPaintSearchOverview.page = 1;
			$rootScope.CarPaintSearchOverview.predicate = 'id';
			$rootScope.CarPaintSearchOverview.ascending = false;

			$scope.CarPaintSearchOverview = angular.copy($rootScope.CarPaintSearchOverview);

			$scope.originalCarPaintSearchOverview = angular.copy($scope.CarPaintSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarPaintSearchOverview) || $rootScope.CarPaintSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarPaintSearchOverview = angular.copy($rootScope.CarPaintSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarPaintId = id;
			$('#deleteCarPaintConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarPaintViewDtoFactory.delete(
				{ id: $scope.selectedCarPaintId },
				function() {
					$scope.search();
					$('#deleteCarPaintConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarPaintConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carPaintUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarPaintSearchOverview.predicate === itemName) {
				if ($scope.CarPaintSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePaintTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateColorUISelect = function(item, model) {
			$scope.search();
		};
	});
