/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarModelListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarModelFactory,
		CarConfigCarModelListDtoSearchFactory,
		CarConfigCarModelViewDtoFactory
	) {
		$scope.carModelList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarModel)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarModel);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarModel = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carmodel.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarModelSearchOverview = angular.copy($scope.CarModelSearchOverview);

			$rootScope.pageSizeCarModel = angular.copy($scope.pageSize);

			CarConfigCarModelListDtoSearchFactory.query(
				{
					page: $scope.CarModelSearchOverview.page - 1,
					size: $scope.pageSize,
					description: $scope.CarModelSearchOverview.description,
					carBrandIdList: $scope.CarModelSearchOverview.carBrandIdList,
					locale: $scope.CarModelSearchOverview.locale,

					sort: [$scope.CarModelSearchOverview.predicate + ',' + ($scope.CarModelSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carModelList = result.items;

					$scope.carBrandReferenceFilterList = result.carBrandSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarModelSearchOverviewDirty = function() {
			return !angular.equals($scope.CarModelSearchOverview, $scope.originalCarModelSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarModelSearchOverview = {};
			$rootScope.CarModelSearchOverview.page = 1;
			$rootScope.CarModelSearchOverview.predicate = 'id';
			$rootScope.CarModelSearchOverview.ascending = false;

			$scope.CarModelSearchOverview = angular.copy($rootScope.CarModelSearchOverview);

			$scope.originalCarModelSearchOverview = angular.copy($scope.CarModelSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarModelSearchOverview) || $rootScope.CarModelSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarModelSearchOverview = angular.copy($rootScope.CarModelSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarModelId = id;
			$('#deleteCarModelConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarModelViewDtoFactory.delete(
				{ id: $scope.selectedCarModelId },
				function() {
					$scope.search();
					$('#deleteCarModelConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarModelConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carModelUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarModelSearchOverview.predicate === itemName) {
				if ($scope.CarModelSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarBrandUISelect = function(item, model) {
			$scope.search();
		};
	});
