/** @format */

angular.module('mmmApp').service('GetHeaderFilename', function() {
	return function(headers) {
		var result = headers
			.split(';')[1]
			.trim()
			.split('=')[1];
		return result.replace(/"/g, '');
	};
});

angular.module('mmmApp').service('FileDownloadError', function($q, AlertService) {
	var arrayBufferToString = function(buff) {
		var charCodeArray = Array.apply(null, new Uint8Array(buff));
		var result = '';
		for (var i = 0, len = charCodeArray.length; i < len; i++) {
			var code = charCodeArray[i];
			result += String.fromCharCode(code);
		}
		return result;
	};

	return function(error) {
		error.data = angular.fromJson(arrayBufferToString(error.data.data));
		AlertService.error('error.file.download', {});
		return $q.reject(error);
	};
});
