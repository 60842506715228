/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarPowertrainListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarPowertrainFactory,
		CarConfigCarPowertrainListDtoSearchFactory,
		CarConfigCarPowertrainViewDtoFactory
	) {
		$scope.carPowertrainList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarPowertrain)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarPowertrain);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarPowertrain = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carpowertrain.new');
		};

		var getEquivalentId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarPowertrainSearchOverview.equivalent)) {
				return $scope.CarPowertrainSearchOverview.equivalent.id;
			} else {
				return undefined;
			}
		};

		// handle attribute canBeOrdered of javaType boolean
		// handle attribute spotlight of javaType boolean

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarPowertrainSearchOverview = angular.copy($scope.CarPowertrainSearchOverview);

			$rootScope.pageSizeCarPowertrain = angular.copy($scope.pageSize);

			CarConfigCarPowertrainListDtoSearchFactory.query(
				{
					page: $scope.CarPowertrainSearchOverview.page - 1,
					size: $scope.pageSize,
					name: $scope.CarPowertrainSearchOverview.name,
					productCode: $scope.CarPowertrainSearchOverview.productCode,
					factoryCode: $scope.CarPowertrainSearchOverview.factoryCode,
					description: $scope.CarPowertrainSearchOverview.description,
					canBeOrdered: $scope.CarPowertrainSearchOverview.canBeOrderedGroup,
					spotlight: $scope.CarPowertrainSearchOverview.spotlightGroup,
					creator: $scope.CarPowertrainSearchOverview.creator,
					updater: $scope.CarPowertrainSearchOverview.updater,
					statusList: $scope.CarPowertrainSearchOverview.statusList,
					typeList: $scope.CarPowertrainSearchOverview.typeList,
					imageSize: $scope.CarPowertrainSearchOverview.imageSize,
					vatType: $scope.CarPowertrainSearchOverview.vatType,
					equivalentId: getEquivalentId(),
					priceTypeList: $scope.CarPowertrainSearchOverview.priceTypeList,
					brandIdList: $scope.CarPowertrainSearchOverview.brandIdList,
					countryList: $scope.CarPowertrainSearchOverview.countryList,
					locale: $scope.CarPowertrainSearchOverview.locale,

					sort: [$scope.CarPowertrainSearchOverview.predicate + ',' + ($scope.CarPowertrainSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carPowertrainList = result.items;

					$scope.statusList = result.statusSearchOptions;

					$scope.articleTypeList = result.typeSearchOptions;

					$scope.priceTypeList = result.priceTypeSearchOptions;

					$scope.brandReferenceFilterList = result.brandSearchOptions;

					$scope.supportedCountryList = result.countrySearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarPowertrainSearchOverviewDirty = function() {
			return !angular.equals($scope.CarPowertrainSearchOverview, $scope.originalCarPowertrainSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarPowertrainSearchOverview = {};
			$rootScope.CarPowertrainSearchOverview.page = 1;
			$rootScope.CarPowertrainSearchOverview.predicate = 'id';
			$rootScope.CarPowertrainSearchOverview.ascending = false;

			$scope.CarPowertrainSearchOverview = angular.copy($rootScope.CarPowertrainSearchOverview);

			$scope.originalCarPowertrainSearchOverview = angular.copy($scope.CarPowertrainSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.CarPowertrainSearchOverview) || $rootScope.CarPowertrainSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarPowertrainSearchOverview = angular.copy($rootScope.CarPowertrainSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarPowertrainId = id;
			$('#deleteCarPowertrainConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarPowertrainViewDtoFactory.delete(
				{ id: $scope.selectedCarPowertrainId },
				function() {
					$scope.search();
					$('#deleteCarPowertrainConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarPowertrainConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carPowertrainUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarPowertrainSearchOverview.predicate === itemName) {
				if ($scope.CarPowertrainSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateStatusUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updatePriceTypeUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateBrandUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateCountryUISelect = function(item, model) {
			$scope.search();
		};
	});
