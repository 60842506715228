/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarModelYearFactory', function($resource) {
	return $resource(
		'api/carModelYears/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarModelYearEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarModelYearEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carmodelyear/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarModelYearEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarModelYearNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carmodelyear/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarModelYearViewDto
 */
angular.module('mmmApp').factory('CarConfigCarModelYearViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodelyear/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarModelYearListDto's
 */
angular.module('mmmApp').factory('CarConfigCarModelYearListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carmodelyear',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarModelYearEditDto
 */
angular.module('mmmApp').factory('CarConfigCarModelYearEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodelyear/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarModelYearEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarModelYearNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodelyear/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarModelYearFactory', function($resource) {
	return $resource(
		'api/carModelYears/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarModelYears without the use of paging
angular.module('mmmApp').factory('NoPagingCarModelYearFactory', function($resource) {
	return $resource(
		'api/carModelYears/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarModelVersionsByCarModelYearFactory', function($resource) {
	return $resource(
		'api/carModelVersions/carModelYear/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('ArticleCarModelYearsByCarModelYearFactory', function($resource) {
	return $resource(
		'api/articleCarModelYears/carModelYear/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('ArticleFromArticleCarModelYearPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/articleCarModelYearList/AsArticleList/carModelYear/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarModelYearsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carModelYears/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
