/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RelationCarRoleFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtRelationCarRoleViewDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationCarRoleViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationcarrole/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtRelationCarRoleListDto's
 */
angular.module('mmmApp').factory('RelationMgmtRelationCarRoleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationcarrole',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtRelationCarRoleEditDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationCarRoleEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationcarrole/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of RelationMgmtRelationCarRoleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('RelationMgmtRelationCarRoleNewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relationcarrole/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRelationCarRoleFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all RelationCarRoles without the use of paging
angular.module('mmmApp').factory('NoPagingRelationCarRoleFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible CrmCarRoles Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingCrmCarRoleFactory', function($resource) {
	return $resource(
		'api/relationCarRole/crmCarRoles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRelationCarRolesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relationCarRoles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationCarRolesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/relationCarRoles/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtRelationCarRolesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/relationcarroles',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
