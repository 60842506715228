/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigBillOfMaterialsListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		BillOfMaterialsFactory,
		CarConfigBillOfMaterialsListDtoSearchFactory,
		CarConfigBillOfMaterialsViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.billOfMaterialsList = [];
		$scope.selectedBillOfMaterialsList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeBillOfMaterials)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeBillOfMaterials);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeBillOfMaterials = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-billofmaterials.new');
		};

		var getParentArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BillOfMaterialsSearchOverview.parentArticle)) {
				return $scope.BillOfMaterialsSearchOverview.parentArticle.id;
			} else {
				return undefined;
			}
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BillOfMaterialsSearchOverview.article)) {
				return $scope.BillOfMaterialsSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.BillOfMaterialsSearchOverview.brand)) {
				return $scope.BillOfMaterialsSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.BillOfMaterialsSearchOverview = angular.copy($scope.BillOfMaterialsSearchOverview);

			$rootScope.pageSizeBillOfMaterials = angular.copy($scope.pageSize);

			CarConfigBillOfMaterialsListDtoSearchFactory.query(
				{
					page: $scope.BillOfMaterialsSearchOverview.page - 1,
					size: $scope.pageSize,
					quantityFrom: $scope.BillOfMaterialsSearchOverview.quantityFrom,
					quantityTo: $scope.BillOfMaterialsSearchOverview.quantityTo,
					parentArticleId: getParentArticleId(),
					articleId: getArticleId(),
					brandId: getBrandId(),

					sort: [$scope.BillOfMaterialsSearchOverview.predicate + ',' + ($scope.BillOfMaterialsSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.billOfMaterialsList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isBillOfMaterialsSearchOverviewDirty = function() {
			return !angular.equals($scope.BillOfMaterialsSearchOverview, $scope.originalBillOfMaterialsSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.BillOfMaterialsSearchOverview = {};
			$rootScope.BillOfMaterialsSearchOverview.page = 1;
			$rootScope.BillOfMaterialsSearchOverview.predicate = 'id';
			$rootScope.BillOfMaterialsSearchOverview.ascending = false;

			$scope.BillOfMaterialsSearchOverview = angular.copy($rootScope.BillOfMaterialsSearchOverview);

			$scope.originalBillOfMaterialsSearchOverview = angular.copy($scope.BillOfMaterialsSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.BillOfMaterialsSearchOverview) || $rootScope.BillOfMaterialsSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.BillOfMaterialsSearchOverview = angular.copy($rootScope.BillOfMaterialsSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedBillOfMaterialsId = id;
			$('#deleteBillOfMaterialsConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigBillOfMaterialsViewDtoFactory.delete(
				{ id: $scope.selectedBillOfMaterialsId },
				function() {
					$scope.search();
					$('#deleteBillOfMaterialsConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteBillOfMaterialsConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleBillOfMaterialsConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedBillOfMaterialsList, function(value, key) {
				promises.push(CarConfigBillOfMaterialsViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleBillOfMaterialsConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.billOfMaterialsList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedBillOfMaterialsList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedBillOfMaterialsList = [];

			angular.forEach($scope.billOfMaterialsList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedBillOfMaterialsList.push(value);
				}
			});
		};

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:billOfMaterialsUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.BillOfMaterialsSearchOverview.predicate === itemName) {
				if ($scope.BillOfMaterialsSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
