/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('ApplicationLogFactory', function($resource) {
	return $resource(
		'api/applicationLogs/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of AdminApplicationLogViewDto
 */
angular.module('mmmApp').factory('AdminApplicationLogViewDtoFactory', function($resource) {
	return $resource(
		'api/admin/applicationlog/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of AdminApplicationLogListDto's
 */
angular.module('mmmApp').factory('AdminApplicationLogListDtoSearchFactory', function($resource) {
	return $resource(
		'api/admin/applicationlog',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewApplicationLogFactory', function($resource) {
	return $resource(
		'api/applicationLogs/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all ApplicationLogs without the use of paging
angular.module('mmmApp').factory('NoPagingApplicationLogFactory', function($resource) {
	return $resource(
		'api/applicationLogs/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ApplicationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingApplicationTypeFactory', function($resource) {
	return $resource(
		'api/applicationLog/applicationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible ApplicationLogStatusses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingApplicationLogStatusFactory', function($resource) {
	return $resource(
		'api/applicationLog/applicationLogStatusses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllApplicationLogsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/applicationLogs/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
