/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('InterestFactory', function($resource) {
	return $resource(
		'api/interests/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigInterestViewDto
 */
angular.module('mmmApp').factory('CarConfigInterestViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/interest/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigInterestListDto's
 */
angular.module('mmmApp').factory('CarConfigInterestListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/interest',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigInterestEditDto
 */
angular.module('mmmApp').factory('CarConfigInterestEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/interest/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigInterestEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigInterestNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/interest/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigInterestEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigInterestEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/interest/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewInterestFactory', function($resource) {
	return $resource(
		'api/interests/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Interests without the use of paging
angular.module('mmmApp').factory('NoPagingInterestFactory', function($resource) {
	return $resource(
		'api/interests/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible InterestTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingInterestTypeFactory', function($resource) {
	return $resource(
		'api/interest/interestTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllInterestsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/interests/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('InterestSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/interests/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
