/** @format */

'use strict';

// OpeningHour has edit layout enabled. Edit layout template url: openinghour-dialog.html
// OpeningHour has detail layout enabled. Detail layout template url: openinghour-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'OpeningHour'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('openinghour'); // main translation loaded for openinghour
		$translatePartialLoader.addPart('dayofweek'); // one to many reference from main entity
		$translatePartialLoader.addPart('dealer'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('stock-mgmt-openinghour', {
		/* abstract state for DTO StockMgmtOpeningHourListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/stockmgmt/openinghour',
		data: {
			authorities: ['ROLE_DEALER', 'ROLE_ADMIN'],
			pageTitle: 'OpeningHour',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase

			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO stock.mgmt */
	var dtoListState = {
		/* list state for DTO StockMgmtOpeningHourListDto */
		/* to access: ui-ref="stock-mgmt-openinghour.list" */
		/* url: http://<hostname>/stockmgmt/openinghour/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/openinghour/openinghour-list-stock-mgmt.html',
				controller: 'StockMgmtOpeningHourListDtoController',
			},
		},
	};

	$stateProvider.state('stock-mgmt-openinghour.list', dtoListState);

	/* detail state for DTO stock.mgmt */
	var dtoDetailState = {
		/* detail state for DTO StockMgmtOpeningHourViewDto */
		/* to access: ui-sref="stock-mgmt-openinghour.detail" */
		/* url: http://<hostname>/stockmgmt/openinghour/detail/:openingHourId */
		url: '/detail/:openingHourId',
		views: {
			'content@': {
				templateUrl: 'app/entities/openinghour/openinghour-view-stock-mgmt.html',
				controller: 'OpeningHourDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'StockMgmtOpeningHourViewDtoFactory',
				function($stateParams, StockMgmtOpeningHourViewDtoFactory) {
					return StockMgmtOpeningHourViewDtoFactory.get({ id: $stateParams.openingHourId });
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-openinghour.detail', dtoDetailState);

	/* edit state for DTO stock.mgmt */
	var dtoEditState = {
		/* edit state for DTO StockMgmtOpeningHourEditDto */
		/* to access: ui-sref="stock-mgmt-openinghour.edit" */
		/* url: http://<hostname>/stockmgmt/openinghour/edit/:openingHourId */
		url: '/edit/:openingHourId',
		views: {
			'content@': {
				templateUrl: 'app/entities/openinghour/openinghour-edit-stock-mgmt.html',
				controller: 'StockMgmtOpeningHourEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtOpeningHourEditDtoFactory',
				function($stateParams, $rootScope, StockMgmtOpeningHourEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtOpeningHourEditDtoFactory.get({ id: $stateParams.openingHourId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-openinghour.edit', dtoEditState);

	/* new (create) state for DTO stock.mgmt */
	var dtoCreateState = {
		/* new state for DTO StockMgmtOpeningHourEditDto */
		/* to access: ui-sref="stock-mgmt-openinghour.new" */
		/* url: http://<hostname>/stockmgmt/openinghour/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/openinghour/openinghour-edit-stock-mgmt.html',
				controller: 'StockMgmtOpeningHourEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'StockMgmtOpeningHourNewDtoFactory',
				function($stateParams, $rootScope, StockMgmtOpeningHourNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return StockMgmtOpeningHourNewDtoFactory.get({ id: $stateParams.openingHourId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('stock-mgmt-openinghour.new', dtoCreateState);
});
