/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('CarModelFactory', function($resource) {
	return $resource(
		'api/carModels/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get / update an instance of ArticleApiCarModelEditDto
 */
angular.module('mmmApp').factory('ArticleApiCarModelEditDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carmodel/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of ArticleApiCarModelEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('ArticleApiCarModelNewDtoFactory', function($resource) {
	return $resource(
		'api/articleapi/carmodel/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of ArticleApiCarModelEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('ArticleApiCarModelEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/articleapi/carmodel/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of CarConfigCarModelViewDto
 */
angular.module('mmmApp').factory('CarConfigCarModelViewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodel/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of CarConfigCarModelListDto's
 */
angular.module('mmmApp').factory('CarConfigCarModelListDtoSearchFactory', function($resource) {
	return $resource(
		'api/car/config/carmodel',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of CarConfigCarModelEditDto
 */
angular.module('mmmApp').factory('CarConfigCarModelEditDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodel/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of CarConfigCarModelEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('CarConfigCarModelNewDtoFactory', function($resource) {
	return $resource(
		'api/car/config/carmodel/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to save a new object of CarConfigCarModelEditDto
 * and returns a copy to create another one.
 */
angular.module('mmmApp').factory('CarConfigCarModelEditDtoSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/car/config/carmodel/saveandnew',
		{},
		{
			createAndNew: {
				method: 'POST',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			updateAndNew: {
				method: 'PUT',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewCarModelFactory', function($resource) {
	return $resource(
		'api/carModels/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all CarModels without the use of paging
angular.module('mmmApp').factory('NoPagingCarModelFactory', function($resource) {
	return $resource(
		'api/carModels/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible SupportedLocales Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingSupportedLocaleFactory', function($resource) {
	return $resource(
		'api/carModel/supportedLocales',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('CarModelYearsByCarModelFactory', function($resource) {
	return $resource(
		'api/carModelYears/carModel/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllCarModelsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/carModels/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('CarModelSaveAndCreateFactory', function($resource) {
	return $resource(
		'api/carmodels/createandnew/:id',
		{},
		{
			saveAndCreate: { method: 'POST' },
			updateAndCreate: { method: 'PUT' },
		}
	);
});
