/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtRelationCarRoleListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		RelationCarRoleFactory,
		RelationMgmtRelationCarRoleListDtoSearchFactory,
		RelationMgmtRelationCarRoleViewDtoFactory
	) {
		$scope.relationCarRoleList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeRelationCarRole)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeRelationCarRole);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeRelationCarRole = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('relation-mgmt-relationcarrole.new');
		};

		var getRelationId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.relation)) {
				return $scope.RelationCarRoleSearchOverview.relation.id;
			} else {
				return undefined;
			}
		};

		var getCarId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.car)) {
				return $scope.RelationCarRoleSearchOverview.car.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.tenant)) {
				return $scope.RelationCarRoleSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		// handle attribute fromDate of javaType java.time.LocalDate
		$scope.processFromDate = function() {
			var fromDateFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.fromDateFrom);
			var fromDateToDefined = $rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.fromDateTo);

			if (fromDateFromDefined) {
				$scope.RelationCarRoleSearchOverview.fromDateFromString = $rootScope.convertDateWithoutZone(
					$scope.RelationCarRoleSearchOverview.fromDateFrom
				);
			} else {
				$scope.RelationCarRoleSearchOverview.fromDateFromString = undefined;
			}

			if (fromDateToDefined) {
				$scope.RelationCarRoleSearchOverview.fromDateToString = $rootScope.convertDateWithoutZone(
					$scope.RelationCarRoleSearchOverview.fromDateTo
				);
			} else {
				$scope.RelationCarRoleSearchOverview.fromDateToString = undefined;
			}
		};

		// handle attribute toDate of javaType java.time.LocalDate
		$scope.processToDate = function() {
			var toDateFromDefined = $rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.toDateFrom);
			var toDateToDefined = $rootScope.isDefinedAndNotNull($scope.RelationCarRoleSearchOverview.toDateTo);

			if (toDateFromDefined) {
				$scope.RelationCarRoleSearchOverview.toDateFromString = $rootScope.convertDateWithoutZone(
					$scope.RelationCarRoleSearchOverview.toDateFrom
				);
			} else {
				$scope.RelationCarRoleSearchOverview.toDateFromString = undefined;
			}

			if (toDateToDefined) {
				$scope.RelationCarRoleSearchOverview.toDateToString = $rootScope.convertDateWithoutZone(
					$scope.RelationCarRoleSearchOverview.toDateTo
				);
			} else {
				$scope.RelationCarRoleSearchOverview.toDateToString = undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			// Call the javascript functions to handle search parameters
			$scope.processFromDate();
			$scope.processToDate();

			$rootScope.RelationCarRoleSearchOverview = angular.copy($scope.RelationCarRoleSearchOverview);

			$rootScope.pageSizeRelationCarRole = angular.copy($scope.pageSize);

			RelationMgmtRelationCarRoleListDtoSearchFactory.query(
				{
					page: $scope.RelationCarRoleSearchOverview.page - 1,
					size: $scope.pageSize,
					fromDateFrom: $scope.RelationCarRoleSearchOverview.fromDateFromString,
					fromDateTo: $scope.RelationCarRoleSearchOverview.fromDateToString,
					toDateFrom: $scope.RelationCarRoleSearchOverview.toDateFromString,
					toDateTo: $scope.RelationCarRoleSearchOverview.toDateToString,
					relationId: getRelationId(),
					carId: getCarId(),
					roleList: $scope.RelationCarRoleSearchOverview.roleList,
					tenantId: getTenantId(),

					sort: [$scope.RelationCarRoleSearchOverview.predicate + ',' + ($scope.RelationCarRoleSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.relationCarRoleList = result.items;

					$scope.autocompleteRelations = function(val) {
						var params = {
							relationTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtRelationsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.autocompleteCars = function(val) {
						var params = {
							crmCarTypeAheadSearchString: val,
						};

						return AutocompleteRelationMgmtCarsByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.crmCarRoleList = result.roleSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isRelationCarRoleSearchOverviewDirty = function() {
			return !angular.equals($scope.RelationCarRoleSearchOverview, $scope.originalRelationCarRoleSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.RelationCarRoleSearchOverview = {};
			$rootScope.RelationCarRoleSearchOverview.page = 1;
			$rootScope.RelationCarRoleSearchOverview.predicate = 'id';
			$rootScope.RelationCarRoleSearchOverview.ascending = false;

			$scope.RelationCarRoleSearchOverview = angular.copy($rootScope.RelationCarRoleSearchOverview);

			$scope.originalRelationCarRoleSearchOverview = angular.copy($scope.RelationCarRoleSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.RelationCarRoleSearchOverview) || $rootScope.RelationCarRoleSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.RelationCarRoleSearchOverview = angular.copy($rootScope.RelationCarRoleSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedRelationCarRoleId = id;
			$('#deleteRelationCarRoleConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtRelationCarRoleViewDtoFactory.delete(
				{ id: $scope.selectedRelationCarRoleId },
				function() {
					$scope.search();
					$('#deleteRelationCarRoleConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteRelationCarRoleConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.RelationCarRoleSearchOverview.predicate === itemName) {
				if ($scope.RelationCarRoleSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateRoleUISelect = function(item, model) {
			$scope.search();
		};
	});
