/** @format */

'use strict';

// Image has edit layout enabled. Edit layout template url: image-dialog.html
// Image has detail layout enabled. Detail layout template url: image-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'Image'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('image'); // main translation loaded for image
		$translatePartialLoader.addPart('article'); // one to many reference from main entity
		$translatePartialLoader.addPart('status'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('articletype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('vattype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('supportedcountry'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('color'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-image', {
		/* abstract state for DTO CarConfigImageListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/image',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'Image',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigImageListDto */
		/* to access: ui-ref="car-config-image.list" */
		/* url: http://<hostname>/car/config/image/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/image/image-list-car-config.html',
				controller: 'CarConfigImageListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-image.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigImageViewDto */
		/* to access: ui-sref="car-config-image.detail" */
		/* url: http://<hostname>/car/config/image/detail/:imageId */
		url: '/detail/:imageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/image/image-view-car-config.html',
				controller: 'ImageDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigImageViewDtoFactory',
				function($stateParams, CarConfigImageViewDtoFactory) {
					return CarConfigImageViewDtoFactory.get({ id: $stateParams.imageId });
				},
			],
		},
	};

	$stateProvider.state('car-config-image.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigImageEditDto */
		/* to access: ui-sref="car-config-image.edit" */
		/* url: http://<hostname>/car/config/image/edit/:imageId */
		url: '/edit/:imageId',
		views: {
			'content@': {
				templateUrl: 'app/entities/image/image-edit-car-config.html',
				controller: 'CarConfigImageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigImageEditDtoFactory',
				function($stateParams, $rootScope, CarConfigImageEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigImageEditDtoFactory.get({ id: $stateParams.imageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-image.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigImageEditDto */
		/* to access: ui-sref="car-config-image.new" */
		/* url: http://<hostname>/car/config/image/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/image/image-edit-car-config.html',
				controller: 'CarConfigImageEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigImageNewDtoFactory',
				function($stateParams, $rootScope, CarConfigImageNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as color has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.color)) {
							// push the created color to the list with selectable color items
							$stateParams.dataModel.colorList.push($stateParams.dataModel.color);

							// set the created color as selected color
							$stateParams.dataModel.selectedColor = $stateParams.dataModel.color;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigImageNewDtoFactory.get({ id: $stateParams.imageId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-image.new', dtoCreateState);
});
