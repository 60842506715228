/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('RelationFactory', function($resource) {
	return $resource(
		'api/relations/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

/**
 * Factory to get or delete an instance of RelationMgmtRelationViewDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationViewDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relation/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of RelationMgmtRelationListDto's
 */
angular.module('mmmApp').factory('RelationMgmtRelationListDtoSearchFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relation',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of RelationMgmtRelationEditDto
 */
angular.module('mmmApp').factory('RelationMgmtRelationEditDtoFactory', function($resource) {
	return $resource(
		'api/relationmgmt/relation/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewRelationFactory', function($resource) {
	return $resource(
		'api/relations/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all Relations without the use of paging
angular.module('mmmApp').factory('NoPagingRelationFactory', function($resource) {
	return $resource(
		'api/relations/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationStatusses Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationStatusFactory', function($resource) {
	return $resource(
		'api/relation/relationStatusses',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible RelationTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingRelationTypeFactory', function($resource) {
	return $resource(
		'api/relation/relationTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('PhonesByRelationFactory', function($resource) {
	return $resource(
		'api/phones/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('PhonesByPrimaryPhoneOfFactory', function($resource) {
	return $resource(
		'api/phones/primaryPhoneOf/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('AddressesByRelationFactory', function($resource) {
	return $resource(
		'api/addresses/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('AddressesByPrimaryAddressOfFactory', function($resource) {
	return $resource(
		'api/addresses/primaryAddressOf/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('EmailsByRelationFactory', function($resource) {
	return $resource(
		'api/emails/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('EmailsByPrimaryEmailOfFactory', function($resource) {
	return $resource(
		'api/emails/primaryEmailOf/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('NotesByRelationFactory', function($resource) {
	return $resource(
		'api/notes/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationRolesByRelationFromFactory', function($resource) {
	return $resource(
		'api/relationRoles/relationFrom/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationRolesByRelationToFactory', function($resource) {
	return $resource(
		'api/relationRoles/relationTo/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationRolesByRelationFromFactory', function($resource) {
	return $resource(
		'api/relationRoles/relationFrom/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationRolesByRelationToFactory', function($resource) {
	return $resource(
		'api/relationRoles/relationTo/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationDocumentsByRelationFactory', function($resource) {
	return $resource(
		'api/relationDocuments/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//The factory for getting the picklist options
angular.module('mmmApp').factory('DocumentFromRelationDocumentPicklistUpdateFactory', function($resource) {
	return $resource(
		'/api/relationDocumentList/AsDocumentList/relation/:id',
		{},
		{
			update: { method: 'PUT', isArray: false },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationSalesOrderRolesByRelationFactory', function($resource) {
	return $resource(
		'api/relationSalesOrderRoles/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('RelationCarRolesByRelationFactory', function($resource) {
	return $resource(
		'api/relationCarRoles/relation/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//For the tab-window of n-relations in the detail view.
angular.module('mmmApp').factory('SectionsByPrimaryContactFactory', function($resource) {
	return $resource(
		'api/sections/primaryContact/:id',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/relations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/autocomplete/relations/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('AutocompleteRelationMgmtRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/relationmgmt/autocomplete/relations',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
angular.module('mmmApp').factory('AutocompleteOwnerRelationsByQueryParamsFactory', function($resource) {
	return $resource(
		'api/owner/autocomplete/relations',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
