/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('UserCarStockAccessBoundDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.userCarStockAccessBound = entity;
		$scope.objectName = 'UserCarStockAccessBound';
		$scope.userCarStockAccessBoundId = $stateParams.userCarStockAccessBoundId;

		if (angular.isUndefined($rootScope.userCarStockAccessBoundActiveTab)) {
			//set the default here
			$rootScope.userCarStockAccessBoundActiveTab = 0;
		}

		$scope.goToEditState = function(userCarStockAccessBound) {
			$state.go(
				'usercarstockaccessbound.detail.edit({userCarStockAccessBoundId:userCarStockAccessBound.id, mappedBy:mappedBy, mappedById:mappedById})'
			);
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:userCarStockAccessBoundUpdate', function(event, result) {
			$scope.userCarStockAccessBound = result;
		});
	});
