/** @format */

'use strict';

// Created by frontend/angular/entity/controller/dto-edit.ftl
angular
	.module('mmmApp')
	.controller('StockMgmtDealerAuthenticationEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		StockMgmtDealerAuthenticationEditDtoFactory,
		AlertService
	) {
		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.submitButtonIsActive = true;
		};

		$scope.dealerAuthentication = entity;
		$scope.submitButtonIsActive = true;

		if ($rootScope.isDefinedAndNotNull($stateParams.mappedBy)) {
			$scope.mappedBy = $stateParams.mappedBy;
		}

		//This function preselects and disables the parent reference when called from tab
		$scope.dealerAuthentication['selected' + _.upperFirst($stateParams.mappedBy)] = {
			id: $stateParams.mappedById,
			displayValue: $stateParams.mappedByDisplayValue,
		};

		/**
		 * Checks if the page is in create or edit state based on the scope model and it's id value
		 */
		$scope.isEdit = function() {
			return $rootScope.isDefinedAndNotNull($scope.dealerAuthentication) && $rootScope.isDefinedAndNotNull($scope.dealerAuthentication.id);
		};

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('mmmApp:dealerAuthenticationUpdate', result);
			$scope.submitButtonIsActive = true;
			$rootScope.back();
		};

		$scope.save = function() {
			$scope.submitButtonIsActive = false;
			StockMgmtDealerAuthenticationEditDtoFactory.update($scope.dealerAuthentication, onSaveFinishedCallback, saveOrUpdateErrorCallback);
		};

		// triggered when click a plus button, navigates to creating a new document of type DocumentSecured
		// once DocumentSecured is saved, history is updated and the user automatically navigates back to this edit page for DealerAuthentication
		$scope.createDocument = function() {
			var referredBy = {
				refName: 'document',
				data: $scope.dealerAuthentication,
			};

			// navigating to new document will add this new dealerauthentication state to the history stack
			$state.go($state.current.name + '.newDocumentSecured', { referredBy: referredBy }, { notify: true });
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};
	});
