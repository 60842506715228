/** @format */

'use strict';

//The factory to crud the main object
angular.module('mmmApp').factory('HexonPublishRuleFactory', function($resource) {
	return $resource(
		'api/hexonPublishRules/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/**
 * Factory to get or delete an instance of StockMgmtHexonPublishRuleViewDto
 */
angular.module('mmmApp').factory('StockMgmtHexonPublishRuleViewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonpublishrule/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});
/**
 * Factory to search / retrieve an array of StockMgmtHexonPublishRuleListDto's
 */
angular.module('mmmApp').factory('StockMgmtHexonPublishRuleListDtoSearchFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonpublishrule',
		{},
		{
			query: { method: 'GET', isArray: false },
		}
	);
});

/**
 * Factory to get / update an instance of StockMgmtHexonPublishRuleEditDto
 */
angular.module('mmmApp').factory('StockMgmtHexonPublishRuleEditDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonpublishrule/edit/:id',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

/*
 * Factory to fetch a new object of StockMgmtHexonPublishRuleEditDto
 * with defaults from server
 */
angular.module('mmmApp').factory('StockMgmtHexonPublishRuleNewDtoFactory', function($resource) {
	return $resource(
		'api/stockmgmt/hexonpublishrule/edit/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('mmmApp').factory('NewHexonPublishRuleFactory', function($resource) {
	return $resource(
		'api/hexonPublishRules/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all HexonPublishRules without the use of paging
angular.module('mmmApp').factory('NoPagingHexonPublishRuleFactory', function($resource) {
	return $resource(
		'api/hexonPublishRules/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible HexonPublishRuleTypes Enum values without the use of paging
angular.module('mmmApp').factory('NoPagingHexonPublishRuleTypeFactory', function($resource) {
	return $resource(
		'api/hexonPublishRule/hexonPublishRuleTypes',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('mmmApp').factory('FindAllHexonPublishRulesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/hexonPublishRules/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
