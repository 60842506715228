/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigArticleFilterOptionListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		ArticleFilterOptionFactory,
		CarConfigArticleFilterOptionListDtoSearchFactory,
		CarConfigArticleFilterOptionViewDtoFactory,
		AutocompleteCarConfigArticlesByQueryParamsFactory
	) {
		$scope.articleFilterOptionList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeArticleFilterOption)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeArticleFilterOption);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeArticleFilterOption = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-articlefilteroption.new');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.ArticleFilterOptionSearchOverview.article)) {
				return $scope.ArticleFilterOptionSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.ArticleFilterOptionSearchOverview = angular.copy($scope.ArticleFilterOptionSearchOverview);

			$rootScope.pageSizeArticleFilterOption = angular.copy($scope.pageSize);

			CarConfigArticleFilterOptionListDtoSearchFactory.query(
				{
					page: $scope.ArticleFilterOptionSearchOverview.page - 1,
					size: $scope.pageSize,
					articleId: getArticleId(),
					filterOptionIdList: $scope.ArticleFilterOptionSearchOverview.filterOptionIdList,

					sort: [
						$scope.ArticleFilterOptionSearchOverview.predicate +
							',' +
							($scope.ArticleFilterOptionSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.articleFilterOptionList = result.items;

					$scope.autocompleteArticles = function(val) {
						var params = {
							articleTypeAheadSearchString: val,
						};

						return AutocompleteCarConfigArticlesByQueryParamsFactory.query(params).$promise.then(function(results) {
							return results;
						});
					};

					$scope.filterOptionReferenceFilterList = result.filterOptionSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isArticleFilterOptionSearchOverviewDirty = function() {
			return !angular.equals($scope.ArticleFilterOptionSearchOverview, $scope.originalArticleFilterOptionSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.ArticleFilterOptionSearchOverview = {};
			$rootScope.ArticleFilterOptionSearchOverview.page = 1;
			$rootScope.ArticleFilterOptionSearchOverview.predicate = 'id';
			$rootScope.ArticleFilterOptionSearchOverview.ascending = false;

			$scope.ArticleFilterOptionSearchOverview = angular.copy($rootScope.ArticleFilterOptionSearchOverview);

			$scope.originalArticleFilterOptionSearchOverview = angular.copy($scope.ArticleFilterOptionSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.ArticleFilterOptionSearchOverview) || $rootScope.ArticleFilterOptionSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.ArticleFilterOptionSearchOverview = angular.copy($rootScope.ArticleFilterOptionSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedArticleFilterOptionId = id;
			$('#deleteArticleFilterOptionConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigArticleFilterOptionViewDtoFactory.delete(
				{ id: $scope.selectedArticleFilterOptionId },
				function() {
					$scope.search();
					$('#deleteArticleFilterOptionConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteArticleFilterOptionConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.ArticleFilterOptionSearchOverview.predicate === itemName) {
				if ($scope.ArticleFilterOptionSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateFilterOptionUISelect = function(item, model) {
			$scope.search();
		};
	});
