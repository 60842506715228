/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('DealerDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.dealer = entity;
	$scope.objectName = 'Dealer';
	$scope.dealerId = $stateParams.dealerId;

	if (angular.isUndefined($rootScope.dealerActiveTab)) {
		//set the default here
		$rootScope.dealerActiveTab = 0;
	}

	$scope.goToEditState = function(dealer) {
		$state.go('dealer.detail.edit({dealerId:dealer.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:dealerUpdate', function(event, result) {
		$scope.dealer = result;
	});
});
