/** @format */

'use strict';

// CarBrand has edit layout enabled. Edit layout template url: carbrand-dialog.html
// CarBrand has detail layout enabled. Detail layout template url: carbrand-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarBrand'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carbrand'); // main translation loaded for carbrand
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('carmodel'); // one to many reference from main entity
		$translatePartialLoader.addPart('usercarstockaccessboundcarbrand'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('car-config-carbrand', {
		/* abstract state for DTO CarConfigCarBrandListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carbrand',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarBrand',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarBrandListDto */
		/* to access: ui-ref="car-config-carbrand.list" */
		/* url: http://<hostname>/car/config/carbrand/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbrand/carbrand-list-car-config.html',
				controller: 'CarConfigCarBrandListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carbrand.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarBrandViewDto */
		/* to access: ui-sref="car-config-carbrand.detail" */
		/* url: http://<hostname>/car/config/carbrand/detail/:carBrandId */
		url: '/detail/:carBrandId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbrand/carbrand-view-car-config.html',
				controller: 'CarBrandDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarBrandViewDtoFactory',
				function($stateParams, CarConfigCarBrandViewDtoFactory) {
					return CarConfigCarBrandViewDtoFactory.get({ id: $stateParams.carBrandId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carbrand.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarBrandEditDto */
		/* to access: ui-sref="car-config-carbrand.edit" */
		/* url: http://<hostname>/car/config/carbrand/edit/:carBrandId */
		url: '/edit/:carBrandId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbrand/carbrand-edit-car-config.html',
				controller: 'CarConfigCarBrandEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarBrandEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarBrandEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarBrandEditDtoFactory.get({ id: $stateParams.carBrandId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carbrand.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarBrandEditDto */
		/* to access: ui-sref="car-config-carbrand.new" */
		/* url: http://<hostname>/car/config/carbrand/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carbrand/carbrand-edit-car-config.html',
				controller: 'CarConfigCarBrandEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarBrandNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarBrandNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarBrandNewDtoFactory.get({ id: $stateParams.carBrandId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carbrand.new', dtoCreateState);

	// create state for plus button on CarModel edit / new page for reference CarBrand and dto property value car.config
	$stateProvider.state('car-config-carmodel.new.newCarBrand', getCreateState('/carbrand/new'));
	$stateProvider.state('car-config-carmodel.edit.newCarBrand', getCreateState('/carbrand/new'));
	// retrieve the create state object, and update the url in the state object as the default create state only adds "/new" to the location url, while we want the url to contain "/carbrand/new"
	function getCreateState(url) {
		var clonedDtoCreateState = Object.assign({}, dtoCreateState);
		clonedDtoCreateState.url = url;
		return clonedDtoCreateState;
	}
});
