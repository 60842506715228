/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular.module('mmmApp').controller('ArticleCombiDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
	$scope.articleCombi = entity;
	$scope.objectName = 'ArticleCombi';
	$scope.articleCombiId = $stateParams.articleCombiId;

	if (angular.isUndefined($rootScope.articleCombiActiveTab)) {
		//set the default here
		$rootScope.articleCombiActiveTab = 0;
	}

	$scope.goToEditState = function(articleCombi) {
		$state.go('articlecombi.detail.edit({articleCombiId:articleCombi.id, mappedBy:mappedBy, mappedById:mappedById})');
	};

	// by broadcasting this event from an edit dialog the model is refreshed
	$rootScope.$on('mmmApp:articleCombiUpdate', function(event, result) {
		$scope.articleCombi = result;
	});
});
