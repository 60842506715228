/** @format */

(function() {
	'use strict';

	angular.module('mmmApp').controller('HomeController', HomeController);

	HomeController.$inject = ['$rootScope', '$scope', 'Principal', 'LoginService', '$state'];

	function HomeController($rootScope, $scope, Principal, LoginService, $state) {
		if ($rootScope.isDefinedAndNotNull($rootScope.selectedWorkingCarBrand)) {
			$scope.selectedWorkingCarBrand.id = angular.copy($rootScope.selectedWorkingCarBrand.id);
		}

		var vm = this;

		vm.account = null;
		vm.isAuthenticated = null;
		vm.login = LoginService.open;
		vm.register = register;
		$scope.$on('authenticationSuccess', function() {
			getAccount();
		});

		getAccount();

		function getAccount() {
			Principal.identity().then(function(account) {
				vm.account = account;
				vm.isAuthenticated = Principal.isAuthenticated;
			});
		}
		function register() {
			$state.go('register');
		}
	}
})();
