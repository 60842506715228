/** @format */

'use strict';

// CarModel has edit layout enabled. Edit layout template url: carmodel-dialog.html
// CarModel has detail layout enabled. Detail layout template url: carmodel-detail.html
angular.module('mmmApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'CarModel'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('carmodel'); // main translation loaded for carmodel
		$translatePartialLoader.addPart('supportedlocale'); // one to many reference from main entity
		$translatePartialLoader.addPart('carbrand'); // one to many reference from main entity
		$translatePartialLoader.addPart('carmodelyear'); // one to many reference from main entity

		return $translate.refresh();
	};

	$stateProvider.state('article-api-carmodel', {
		/* abstract state for DTO ArticleApiCarModelListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/articleapi/carmodel',
		data: {
			authorities: ['ROLE_ARTICLE_API', 'ROLE_ADMIN'],
			pageTitle: 'CarModel',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* edit state for DTO article.api */
	var dtoEditState = {
		/* edit state for DTO ArticleApiCarModelEditDto */
		/* to access: ui-sref="article-api-carmodel.edit" */
		/* url: http://<hostname>/articleapi/carmodel/edit/:carModelId */
		url: '/edit/:carModelId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-edit-article-api.html',
				controller: 'ArticleApiCarModelEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarModelEditDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarModelEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarModelEditDtoFactory.get({ id: $stateParams.carModelId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carmodel.edit', dtoEditState);

	/* new (create) state for DTO article.api */
	var dtoCreateState = {
		/* new state for DTO ArticleApiCarModelEditDto */
		/* to access: ui-sref="article-api-carmodel.new" */
		/* url: http://<hostname>/articleapi/carmodel/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-edit-article-api.html',
				controller: 'ArticleApiCarModelEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'ArticleApiCarModelNewDtoFactory',
				function($stateParams, $rootScope, ArticleApiCarModelNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return ArticleApiCarModelNewDtoFactory.get({ id: $stateParams.carModelId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('article-api-carmodel.new', dtoCreateState);

	$stateProvider.state('car-config-carmodel', {
		/* abstract state for DTO CarConfigCarModelListDto */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/car/config/carmodel',
		data: {
			authorities: ['ROLE_CARCONFIG', 'ROLE_ADMIN'],
			pageTitle: 'CarModel',
			isModal: false,
		},
		params: {
			mappedBy: null,
			mappedByType: null,
			mappedById: null,
			dataModel: null, // this data model is set from the new relation state OR from the save and new logic
			mappedByDisplayValue: null,
			dataModelType: null, // this data model type is the name of the modelobject, lowercase
			referredBy: null, // will be populated once this new state is triggered from another new state with + button
			hiddenParam: 'YES', // hides params from url
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	/* list state for DTO car.config */
	var dtoListState = {
		/* list state for DTO CarConfigCarModelListDto */
		/* to access: ui-ref="car-config-carmodel.list" */
		/* url: http://<hostname>/car/config/carmodel/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-list-car-config.html',
				controller: 'CarConfigCarModelListDtoController',
			},
		},
	};

	$stateProvider.state('car-config-carmodel.list', dtoListState);

	/* detail state for DTO car.config */
	var dtoDetailState = {
		/* detail state for DTO CarConfigCarModelViewDto */
		/* to access: ui-sref="car-config-carmodel.detail" */
		/* url: http://<hostname>/car/config/carmodel/detail/:carModelId */
		url: '/detail/:carModelId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-view-car-config.html',
				controller: 'CarModelDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'CarConfigCarModelViewDtoFactory',
				function($stateParams, CarConfigCarModelViewDtoFactory) {
					return CarConfigCarModelViewDtoFactory.get({ id: $stateParams.carModelId });
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodel.detail', dtoDetailState);

	/* edit state for DTO car.config */
	var dtoEditState = {
		/* edit state for DTO CarConfigCarModelEditDto */
		/* to access: ui-sref="car-config-carmodel.edit" */
		/* url: http://<hostname>/car/config/carmodel/edit/:carModelId */
		url: '/edit/:carModelId',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-edit-car-config.html',
				controller: 'CarConfigCarModelEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarModelEditDtoFactory',
				function($stateParams, $rootScope, CarConfigCarModelEditDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carBrand has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBrand)) {
							// push the created carBrand to the list with selectable carBrand items
							$stateParams.dataModel.carBrandList.push($stateParams.dataModel.carBrand);

							// set the created carBrand as selected carBrand
							$stateParams.dataModel.selectedCarBrand = $stateParams.dataModel.carBrand;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarModelEditDtoFactory.get({ id: $stateParams.carModelId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodel.edit', dtoEditState);

	/* new (create) state for DTO car.config */
	var dtoCreateState = {
		/* new state for DTO CarConfigCarModelEditDto */
		/* to access: ui-sref="car-config-carmodel.new" */
		/* url: http://<hostname>/car/config/carmodel/new */
		url: '/new',
		views: {
			'content@': {
				templateUrl: 'app/entities/carmodel/carmodel-edit-car-config.html',
				controller: 'CarConfigCarModelEditDtoController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'$rootScope',
				'CarConfigCarModelNewDtoFactory',
				function($stateParams, $rootScope, CarConfigCarModelNewDtoFactory) {
					// if dataModel is defined, we're coming back from a new created x..1 reference
					if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
						// as carBrand has a plus button we need to process the result of creating a new instance
						if ($rootScope.isDefinedAndNotNull($stateParams.dataModel.carBrand)) {
							// push the created carBrand to the list with selectable carBrand items
							$stateParams.dataModel.carBrandList.push($stateParams.dataModel.carBrand);

							// set the created carBrand as selected carBrand
							$stateParams.dataModel.selectedCarBrand = $stateParams.dataModel.carBrand;
						}

						var model = $stateParams.dataModel;

						// reset 'remembered' values to prevent future misbehaviour
						$rootScope.previousStateParams.dataModel = null;
						$stateParams.dataModel = null;

						// instead of returning a new model, we return the updated model
						return model;
					} else {
						return CarConfigCarModelNewDtoFactory.get({ id: $stateParams.carModelId }).$promise.then(function(result) {
							return result;
						});
					}
				},
			],
		},
	};

	$stateProvider.state('car-config-carmodel.new', dtoCreateState);
});
