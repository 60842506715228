/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('RelationMgmtOrderLineListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineFactory,
		RelationMgmtOrderLineListDtoSearchFactory,
		RelationMgmtOrderLineViewDtoFactory
	) {
		$scope.orderLineList = [];
		$scope.selectedOrderLineList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLine);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('relation-mgmt-orderline.new', {
				mappedBy: $scope.mappedBy,
				mappedById: $scope.mappedById,
				mappedByDisplayValue: $scope.mappedByDisplayValue,
			});
		};

		$scope.getOrderLineList = function() {
			RelationMgmtOrderLineListDtoSearchFactory.query(
				{
					orderLineGroupId: angular.isDefined($scope.orderLineGroupId) ? $scope.orderLineGroupId : null,
					articleId: angular.isDefined($scope.articleId) ? $scope.articleId : null,
					vatType: angular.isDefined($scope.vatTypeId) ? $scope.vatTypeId : null,
					orderLineType: angular.isDefined($scope.orderLineTypeId) ? $scope.orderLineTypeId : null,
					tenantId: angular.isDefined($scope.tenantId) ? $scope.tenantId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.orderLineList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getOrderLineList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineId = id;
			$('#deleteOrderLineConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			RelationMgmtOrderLineViewDtoFactory.delete(
				{ id: $scope.selectedOrderLineId },
				function() {
					$scope.loadAll();
					$('#deleteOrderLineConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleUpdate = function() {
			$state.go('relation-mgmt-orderline.bulkedit', { selectedOrderLineList: $scope.selectedOrderLineList });
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.orderLineList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedOrderLineList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedOrderLineList = [];

			angular.forEach($scope.orderLineList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedOrderLineList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
