/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigTranslationListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		TranslationFactory,
		CarConfigTranslationListDtoSearchFactory
	) {
		$scope.translationList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeTranslation)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeTranslation);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeTranslation = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-translation.new');
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.TranslationSearchOverview = angular.copy($scope.TranslationSearchOverview);

			$rootScope.pageSizeTranslation = angular.copy($scope.pageSize);

			CarConfigTranslationListDtoSearchFactory.query(
				{
					page: $scope.TranslationSearchOverview.page - 1,
					size: $scope.pageSize,
					entityName: $scope.TranslationSearchOverview.entityName,
					entityIdFrom: $scope.TranslationSearchOverview.entityIdFrom,
					entityIdTo: $scope.TranslationSearchOverview.entityIdTo,
					propertyName: $scope.TranslationSearchOverview.propertyName,
					translationValue: $scope.TranslationSearchOverview.translationValue,
					localeList: $scope.TranslationSearchOverview.localeList,

					sort: [$scope.TranslationSearchOverview.predicate + ',' + ($scope.TranslationSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.translationList = result.items;

					$scope.supportedLocaleList = result.localeSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isTranslationSearchOverviewDirty = function() {
			return !angular.equals($scope.TranslationSearchOverview, $scope.originalTranslationSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.TranslationSearchOverview = {};
			$rootScope.TranslationSearchOverview.page = 1;
			$rootScope.TranslationSearchOverview.predicate = 'id';
			$rootScope.TranslationSearchOverview.ascending = false;

			$scope.TranslationSearchOverview = angular.copy($rootScope.TranslationSearchOverview);

			$scope.originalTranslationSearchOverview = angular.copy($scope.TranslationSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.TranslationSearchOverview) || $rootScope.TranslationSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.TranslationSearchOverview = angular.copy($rootScope.TranslationSearchOverview);

			$scope.search();
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.TranslationSearchOverview.predicate === itemName) {
				if ($scope.TranslationSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateLocaleUISelect = function(item, model) {
			$scope.search();
		};
	});
