/** @format */

// Created by frontend/angular/entity/controller/dto-view.ftl
'use strict';

angular
	.module('mmmApp')
	.controller('SectionDocumentDetailController', function($scope, $rootScope, $stateParams, $state, entity, $location) {
		$scope.sectionDocument = entity;
		$scope.objectName = 'SectionDocument';
		$scope.sectionDocumentId = $stateParams.sectionDocumentId;

		if (angular.isUndefined($rootScope.sectionDocumentActiveTab)) {
			//set the default here
			$rootScope.sectionDocumentActiveTab = 0;
		}

		$scope.goToEditState = function(sectionDocument) {
			$state.go('sectiondocument.detail.edit({sectionDocumentId:sectionDocument.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('mmmApp:sectionDocumentUpdate', function(event, result) {
			$scope.sectionDocument = result;
		});
	});
