/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtMappingFailedLogListDtoTabController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		MappingFailedLogFactory,
		StockMgmtMappingFailedLogListDtoSearchFactory,
		StockMgmtMappingFailedLogViewDtoFactory
	) {
		$scope.mappingFailedLogList = [];
		$scope.selectedMappingFailedLogList = [];
		$scope.predicate = 'id';
		$scope.ascending = false;
		$scope.paging = {};
		$scope.paging.page = 1;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeMappingFailedLog)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeMappingFailedLog);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeMappingFailedLog = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is in a tab and mapped by $scope.mappedBy / $scope.mappedById
			$state.go('stock-mgmt-mappingfailedlog.new', { mappedBy: $scope.mappedBy, mappedById: $scope.mappedById });
		};

		$scope.getMappingFailedLogList = function() {
			StockMgmtMappingFailedLogListDtoSearchFactory.query(
				{
					sourceList: angular.isDefined($scope.sourceId) ? $scope.sourceId : null,
					mappingTypeList: angular.isDefined($scope.mappingTypeId) ? $scope.mappingTypeId : null,

					page: $scope.paging.page - 1,
					size: $scope.pageSize,
					sort: [$scope.predicate + ',' + ($scope.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.mappingFailedLogList = result.items;
				}
			);
		};
		$scope.loadAll = function() {
			$scope.getMappingFailedLogList();
		};

		$scope.loadPage = function() {
			$scope.loadAll();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedMappingFailedLogId = id;
			$('#deleteMappingFailedLogConfirmationInTab').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtMappingFailedLogViewDtoFactory.delete(
				{ id: $scope.selectedMappingFailedLogId },
				function() {
					$scope.loadAll();
					$('#deleteMappingFailedLogConfirmationInTab').modal('hide');
				},
				function(error) {
					$('#deleteMappingFailedLogConfirmationInTab').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleMappingFailedLogConfirmationInTab').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedMappingFailedLogList, function(value, key) {
				promises.push(StockMgmtMappingFailedLogViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.loadAll();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleMappingFailedLogConfirmationInTab').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.mappingFailedLogList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedMappingFailedLogList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedMappingFailedLogList = [];

			angular.forEach($scope.mappingFailedLogList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedMappingFailedLogList.push(value);
				}
			});
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.predicate === itemName) {
				if ($scope.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateSourceUISelect = function(item, model) {
			$scope.search();
		};

		$scope.updateMappingTypeUISelect = function(item, model) {
			$scope.search();
		};
	});
