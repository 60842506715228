/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('CarConfigCarConfigurationPackageListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		CarConfigurationPackageFactory,
		CarConfigCarConfigurationPackageListDtoSearchFactory,
		CarConfigCarConfigurationPackageViewDtoFactory
	) {
		$scope.carConfigurationPackageList = [];
		$scope.selectedCarConfigurationPackageList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeCarConfigurationPackage)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeCarConfigurationPackage);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeCarConfigurationPackage = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('car-config-carconfigurationpackage.new');
		};

		var getBrandId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.CarConfigurationPackageSearchOverview.brand)) {
				return $scope.CarConfigurationPackageSearchOverview.brand.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.CarConfigurationPackageSearchOverview = angular.copy($scope.CarConfigurationPackageSearchOverview);

			$rootScope.pageSizeCarConfigurationPackage = angular.copy($scope.pageSize);

			CarConfigCarConfigurationPackageListDtoSearchFactory.query(
				{
					page: $scope.CarConfigurationPackageSearchOverview.page - 1,
					size: $scope.pageSize,
					productCode: $scope.CarConfigurationPackageSearchOverview.productCode,
					factoryCode: $scope.CarConfigurationPackageSearchOverview.factoryCode,
					description: $scope.CarConfigurationPackageSearchOverview.description,
					pricelistLine: $scope.CarConfigurationPackageSearchOverview.pricelistLine,
					brandId: getBrandId(),
					carConfigurationIdList: $scope.CarConfigurationPackageSearchOverview.carConfigurationIdList,
					country: $scope.CarConfigurationPackageSearchOverview.country,
					locale: $scope.CarConfigurationPackageSearchOverview.locale,

					sort: [
						$scope.CarConfigurationPackageSearchOverview.predicate +
							',' +
							($scope.CarConfigurationPackageSearchOverview.ascending ? 'asc' : 'desc'),
					],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.carConfigurationPackageList = result.items;

					$scope.carConfigurationReferenceFilterList = result.carConfigurationSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isCarConfigurationPackageSearchOverviewDirty = function() {
			return !angular.equals($scope.CarConfigurationPackageSearchOverview, $scope.originalCarConfigurationPackageSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.CarConfigurationPackageSearchOverview = {};
			$rootScope.CarConfigurationPackageSearchOverview.page = 1;
			$rootScope.CarConfigurationPackageSearchOverview.predicate = 'id';
			$rootScope.CarConfigurationPackageSearchOverview.ascending = false;

			$scope.CarConfigurationPackageSearchOverview = angular.copy($rootScope.CarConfigurationPackageSearchOverview);

			$scope.originalCarConfigurationPackageSearchOverview = angular.copy($scope.CarConfigurationPackageSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (
				angular.isUndefined($rootScope.CarConfigurationPackageSearchOverview) ||
				$rootScope.CarConfigurationPackageSearchOverview === null
			) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.CarConfigurationPackageSearchOverview = angular.copy($rootScope.CarConfigurationPackageSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedCarConfigurationPackageId = id;
			$('#deleteCarConfigurationPackageConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			CarConfigCarConfigurationPackageViewDtoFactory.delete(
				{ id: $scope.selectedCarConfigurationPackageId },
				function() {
					$scope.search();
					$('#deleteCarConfigurationPackageConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteCarConfigurationPackageConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.multipleDelete = function() {
			$('#deleteMultipleCarConfigurationPackageConfirmation').modal('show');
		};

		$scope.confirmMultipleDelete = function() {
			var promises = [];

			// add all promises to an array, they will be executed, but are not yet resolved
			angular.forEach($scope.selectedCarConfigurationPackageList, function(value, key) {
				promises.push(CarConfigCarConfigurationPackageViewDtoFactory.delete({ id: value.id }).$promise);
			});

			// wait for completion of all promises in array, then finalize everything
			$q.all(promises)
				.then(function(resolves) {
					// reload the data
					$scope.search();
				})
				.catch(function(error) {})
				.finally(function() {
					$scope.deselectAll();
					$('#deleteMultipleCarConfigurationPackageConfirmation').modal('hide');
				});
		};

		$scope.deselectAll = function() {
			angular.forEach($scope.carConfigurationPackageList, function(value, key) {
				// update the list with selected items
				value.isSelected = false;
				$scope.selectionChanged(value);
			});
			$scope.selectedCarConfigurationPackageList = [];
		};

		$scope.selectionChanged = function(item) {
			$scope.$broadcast('childClick', item);
			$scope.updateSelection();
		};

		$scope.toggleSelection = function() {
			$scope.updateSelection();
		};

		$scope.updateSelection = function() {
			// clean selection (if any)
			$scope.selectedCarConfigurationPackageList = [];

			angular.forEach($scope.carConfigurationPackageList, function(value, key) {
				// update the list with selected items
				if (value.isSelected) {
					$scope.selectedCarConfigurationPackageList.push(value);
				}
			});
		};

		$rootScope.$watch('selectedWorkingCountry', function() {
			$scope.loadPage();
		});

		// the dialog controller can emit an event to update the overview, here we react on it
		$rootScope.$on('mmmApp:carConfigurationPackageUpdate', function() {
			$scope.loadPage();
		});

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.CarConfigurationPackageSearchOverview.predicate === itemName) {
				if ($scope.CarConfigurationPackageSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};

		$scope.updateCarConfigurationUISelect = function(item, model) {
			$scope.search();
		};
	});
