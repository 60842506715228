/** @format */

'use strict';

angular
	.module('mmmApp')
	.controller('StockMgmtDealerFaqListDtoController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		DealerFaqFactory,
		StockMgmtDealerFaqListDtoSearchFactory,
		StockMgmtDealerFaqViewDtoFactory
	) {
		$scope.dealerFaqList = [];
		$scope.showSearchForm = true;
		$scope.totalCountIsKnownAtTheMoment = false;

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeDealerFaq)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeDealerFaq);
		} else {
			$scope.pageSize = 20;

			$rootScope.pageSizeDealerFaq = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('stock-mgmt-dealerfaq.new');
		};

		var getDealerId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerFaqSearchOverview.dealer)) {
				return $scope.DealerFaqSearchOverview.dealer.id;
			} else {
				return undefined;
			}
		};

		var getTenantId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.DealerFaqSearchOverview.tenant)) {
				return $scope.DealerFaqSearchOverview.tenant.id;
			} else {
				return undefined;
			}
		};

		$scope.search = function() {
			$scope.totalCountIsKnownAtTheMoment = false;
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.DealerFaqSearchOverview = angular.copy($scope.DealerFaqSearchOverview);

			$rootScope.pageSizeDealerFaq = angular.copy($scope.pageSize);

			StockMgmtDealerFaqListDtoSearchFactory.query(
				{
					page: $scope.DealerFaqSearchOverview.page - 1,
					size: $scope.pageSize,
					question: $scope.DealerFaqSearchOverview.question,
					answer: $scope.DealerFaqSearchOverview.answer,
					sortOrderFrom: $scope.DealerFaqSearchOverview.sortOrderFrom,
					sortOrderTo: $scope.DealerFaqSearchOverview.sortOrderTo,
					dealerId: getDealerId(),
					tenantId: getTenantId(),

					sort: [$scope.DealerFaqSearchOverview.predicate + ',' + ($scope.DealerFaqSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.dealerFaqList = result.items;

					$scope.dealerReferenceFilterList = result.dealerSearchOptions;

					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.isDealerFaqSearchOverviewDirty = function() {
			return !angular.equals($scope.DealerFaqSearchOverview, $scope.originalDealerFaqSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.DealerFaqSearchOverview = {};
			$rootScope.DealerFaqSearchOverview.page = 1;
			$rootScope.DealerFaqSearchOverview.predicate = 'id';
			$rootScope.DealerFaqSearchOverview.ascending = false;

			$scope.DealerFaqSearchOverview = angular.copy($rootScope.DealerFaqSearchOverview);

			$scope.originalDealerFaqSearchOverview = angular.copy($scope.DealerFaqSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.DealerFaqSearchOverview) || $rootScope.DealerFaqSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.DealerFaqSearchOverview = angular.copy($rootScope.DealerFaqSearchOverview);

			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedDealerFaqId = id;
			$('#deleteDealerFaqConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			StockMgmtDealerFaqViewDtoFactory.delete(
				{ id: $scope.selectedDealerFaqId },
				function() {
					$scope.search();
					$('#deleteDealerFaqConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteDealerFaqConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.DealerFaqSearchOverview.predicate === itemName) {
				if ($scope.DealerFaqSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
